import {
  ENDPOINTS,
  ParentSource,
  ShipmentType,
  SystemSettingCode,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { onShowEmail } from '@containers/Email/helpers';
import {
  useDataTable,
  useFetchSetting,
  useGlobalTable,
  useNewButton,
  useToolsButton
} from '@hooks';
import loadable from '@loadable/component';
import { TableUtils, UIUtils } from '@utils';
import React, { createRef, memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KChip, KColors, KContainer, KLabel } from 'uikit';

import { generateLevelColumns, onShowCompanyTariff } from './helpers';

const Print = loadable(() => import('@containers/Print'));

const CompanyTariff = () => {
  const ref = createRef<HTMLButtonElement>();

  const [tab] = useState(ShipmentType.FCL);

  const { data: numberOfLevels = 3 } = useFetchSetting(
    SystemSettingCode.PriceLevel,
    false
  );

  const columnsFunc = useCallback(
    (data: any[]) => {
      const finalData = data.map(i => {
        let result = { ...i };
        const companyTariffLevels = i.companyTariffLevels;
        for (let k = 0; k < numberOfLevels; k++) {
          const level = companyTariffLevels.find(
            (l: any) => l.levelNumber === k + 1
          );
          if (level) {
            result[`level${k + 1}`] = level;
          }
        }
        return result;
      });

      const levelColumns = generateLevelColumns(finalData, numberOfLevels);

      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowCompanyTariff(item.chargeCodeId)
            }
          ])
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            ...TableUtils.options.baseXMd,
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              return (
                <KLabel.Text
                  onPress={() => onShowCompanyTariff(item.chargeCodeId)}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('original_code'),
          name: 'chargeCode.code'
        },
        {
          label: trans('zone'),
          name: 'zone.name'
        },
        {
          label: trans('name'),
          name: 'name',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('remark'),
          name: 'remark'
        },
        {
          label: trans('category'),
          name: 'chargeCode.chargeCodeCategory.name',
          options: TableUtils.options.baseLg
        },
        {
          label: trans('per_unit'),
          name: 'chargeCode.chargeCodeUnit.name'
        },
        {
          label: trans('calculator_type'),
          name: 'chargeCode.calculateType',
          options: TableUtils.options.mappedOption
        },
        {
          label: trans('costing_price'),
          name: 'costingPrice',
          options: TableUtils.options.currency()
        },
        {
          label: trans('min_selling_price'),
          name: 'minSellingPrice',
          options: TableUtils.options.currency()
        },
        {
          label: trans('markup_level_type'),
          name: 'markupType',
          options: TableUtils.options.mappedOption
        },
        ...levelColumns,
        {
          label: trans('original_sub_charge_codes'),
          name: 'chargeCode.subChargeCodes',
          options: {
            sort: false,
            ...TableUtils.options.withMoreIcon({
              onPress: v => {
                UIUtils.popup.open({
                  title: trans('sub_charge_codes'),
                  maxWidth: 'md',
                  touchOutsideToDismiss: true,
                  content: () => (
                    <>
                      {v.map((i, idx) => {
                        const marginT = idx === 0 ? 0 : '0.75rem';
                        return (
                          <KContainer.View
                            key={`sub-charge-code-popup-${i.id}`}
                            dp="flex"
                            center
                            paddingV="0.25rem"
                            paddingH="0.75rem"
                            br="x"
                            brW={1}
                            brC={KColors.warning.normal}
                            marginT={marginT}
                          >
                            <KLabel.Text
                              typo="TextMdNormal"
                              textTransform="uppercase"
                            >
                              {i.code} - {i.name}
                            </KLabel.Text>
                          </KContainer.View>
                        );
                      })}
                    </>
                  )
                });
              },
              renderItem: (i, idx, rowIdx) => {
                const item = data?.[rowIdx];
                const marginL = idx === 0 ? 0 : '0.25rem';
                return (
                  <>
                    {idx === 0 && (
                      <KChip
                        key={`${item?.id}-rate`}
                        background={KColors.palette.warning.w50}
                        brC={KColors.warning.normal}
                        label={trans('rate')}
                        textTransform={'uppercase'}
                        color={KColors.warning.normal}
                        marginL={0}
                        marginR={'0.25rem'}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    )}

                    <KChip
                      key={`${item?.id}-sub-charge-code-${i.id}`}
                      background={KColors.palette.warning.w50}
                      brC={KColors.warning.normal}
                      label={i.code}
                      textTransform={'uppercase'}
                      color={KColors.warning.normal}
                      marginL={marginL}
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </>
                );
              }
            })
          }
        },
        {
          label: trans('price_instruction'),
          name: 'chargeCode.priceInstruction'
        },
        {
          label: trans('shipment_type'),
          name: 'chargeCode.shipmentType'
        },
        {
          label: trans('container_sizes'),
          name: 'chargeCode.containerSizes',
          options: {
            sort: false,
            ...TableUtils.options.withMoreIcon({
              onPress: v => {
                UIUtils.popup.open({
                  title: trans('container_sizes'),
                  maxWidth: 'xs',
                  touchOutsideToDismiss: true,
                  content: () => (
                    <>
                      {v.map((i, idx) => {
                        const marginT = idx === 0 ? 0 : '0.75rem';
                        return (
                          <KContainer.View
                            key={`container-size-popup-${i.id}`}
                            dp="flex"
                            center
                            paddingV="0.25rem"
                            paddingH="0.75rem"
                            br="x"
                            brW={1}
                            brC={KColors.warning.normal}
                            marginT={marginT}
                          >
                            <KLabel.Text
                              typo="TextMdNormal"
                              textTransform="uppercase"
                            >
                              {i.code}
                            </KLabel.Text>
                          </KContainer.View>
                        );
                      })}
                    </>
                  )
                });
              },
              renderItem: (i, idx, rowIdx) => {
                const item = data?.[rowIdx];
                const marginL = idx === 0 ? 0 : '0.25rem';
                return (
                  <KChip
                    key={`${item?.id}-container-size-${i.id}`}
                    background={KColors.palette.warning.w50}
                    brC={KColors.warning.normal}
                    label={i.code}
                    textTransform={'uppercase'}
                    color={KColors.warning.normal}
                    marginL={marginL}
                    style={{ whiteSpace: 'nowrap' }}
                  />
                );
              }
            })
          }
        },
        {
          label: trans('job_type'),
          name: 'chargeCode.jobType',
          options: TableUtils.options.mappedOptionCustom()
        },
        {
          label: trans('drop_mode'),
          name: 'chargeCode.dropMode.name',
          options: {
            ...TableUtils.options.baseNm,
            customBodyRender: (v: any) => v || trans('option.all').toUpperCase()
          }
        },
        {
          label: trans('with_unit.max_gross_weight'),
          name: 'chargeCode.maxGrossWeight',
          options: TableUtils.options.number()
        },
        {
          label: trans('overweight_surcharge'),
          name: 'chargeCode.isOverweightSurcharge',
          options: TableUtils.options.checkbox
        },
        {
          label: trans('with_unit.max_gross_overweight'),
          name: 'chargeCode.overweightChargeCode.maxGrossWeight',
          options: TableUtils.options.number()
        },
        {
          label: trans('overweight_surcharge_code'),
          name: 'chargeCode.overweightChargeCode.code'
        },
        {
          label: trans('hazardous_goods'),
          name: 'chargeCode.isDamagedGood',
          options: TableUtils.options.checkbox
        },
        {
          label: trans('refrigerated_storage'),
          name: 'chargeCode.isRefrigeratedStorage',
          options: TableUtils.options.checkbox
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.switch
        }
      ];
    },
    [numberOfLevels]
  );

  const mappedFields = useMemo(
    () => ({
      'chargeCode.code': 'chargeCode',
      'zone.name': 'zone',
      'chargeCode.shipmentType': 'shipmentType',
      'chargeCode.chargeCodeCategory.name': 'chargeCodeCategory',
      'chargeCode.chargeCodeUnit.name': 'chargeCodeUnit',
      'chargeCode.calculateType': 'calculateType',
      'chargeCode.priceInstruction': 'priceInstruction',
      'chargeCode.containerSizes': 'containerSize',
      'chargeCode.jobType': 'jobType',
      'chargeCode.dropMode.name': 'dropMode',
      'chargeCode.maxGrossWeight': 'maxGrossWeight',
      'chargeCode.overweightChargeCode.maxGrossWeight': 'maxGrossOverweight',
      'chargeCode.overweightChargeCode.code': 'overweightChargeCode'
    }),
    []
  );

  const otherParams = useMemo(
    () => ({
      shipmentType: tab
    }),
    [tab]
  );

  const excludedExportColumns = useMemo(
    () => [
      trans('hazardous_goods'),
      trans('refrigerated_storage'),
      trans('overweight_surcharge'),
      trans('original_sub_charge_codes'),
      ...Array.from(Array(Number(numberOfLevels)), (_, i) => {
        return [
          trans('level') + ` ${i + 1}`,
          trans('selling_price_level') + ` ${i + 1}`
        ];
      }).flat(1)
    ],
    [numberOfLevels]
  );

  const table = useDataTable({
    name: WebTable.COMPANY_TARIFF,
    tableName: TableName.COMPANY_TARIFF,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.companyTariff(),
    columnsFunc,
    mappedFields,
    otherParams
  });

  useGlobalTable(table);

  const onPrint = useCallback(() => {
    UIUtils.popup.open({
      title: trans('print'),
      content: () => <Print source={ParentSource.CompanyTariff} />
    });
  }, []);

  const onAdd = useCallback(() => {
    onShowCompanyTariff('new', { shipmentType: tab });
  }, [tab]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  const { onShowTools } = useToolsButton(ref, [
    {
      title: trans('print'),
      icon: 'Print',
      onPress: onPrint
    },
    {
      title: trans('email'),
      icon: 'EmailOutlined',
      onPress: () =>
        onShowEmail({
          source: ParentSource.CompanyTariff,
          webTable: WebTable.COMPANY_TARIFF_EMAIL
        })
    }
  ]);

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.tariffs_and_rates'),
          trans('routes.company_tariffs')
        ]}
        rightNode={{
          tools: {
            ref,
            onPress: onShowTools
          }
        }}
      />

      <KContainer.Card
        margin="0.75rem"
        // header={{
        //   renderHeader: () => (
        //     <Tabs.Shipment onChangeTab={setTab} disabled={table.isLoading} />
        //   )
        // }}
      >
        <DataTable
          {...table}
          onAdd={onAdd}
          excludedExportColumns={excludedExportColumns}
        />
      </KContainer.Card>

      {newButton}
    </>
  );
};

export default memo(CompanyTariff);
