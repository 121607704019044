import {
  NotificationReadStatus,
  NotificationReadStatusTab,
  ParentSource,
  generateOptions
} from '@constants';
import { NotificationManager, Prototype } from '@core';
import { useOneStopFetchNotificationList, useOneStopMarkAsRead } from '@hooks';
import { UIUtils } from '@utils';
import { mapMUITableColumnsToFilterColumns } from '@utils/tableHelper';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation } from 'react-router-dom';
import trans from 'translation';
import { KContainer, KTab, KLabel, KChip, KColors, KLoading } from 'uikit';

import TextFieldSearch from 'components/TextFieldSearch';

const Notifications1Stop = () => {
  const location = useLocation();
  const history = useHistory();

  const scrollRef = useRef<HTMLDivElement>();

  const [tabIndex, setTabIndex] = useState(0);

  const [searchField, setSearchField] = useState('keyword');
  const [searchValue, setSearchValue] = useState('');

  const { mutate: mutateRead } = useOneStopMarkAsRead();

  const tabs = useMemo(() => generateOptions(NotificationReadStatusTab), []);

  const {
    dataFlatten: data,
    isLoading,
    hasNextPage,
    onEndReached
  } = useOneStopFetchNotificationList(tabs[tabIndex].key, {
    source: searchField === 'keyword' ? undefined : searchField,
    keyword: searchValue
  });

  useEffect(() => {
    if (location.state?.scrollToBottom) {
      window.history.replaceState({}, document.title);
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state]);

  const handleOpenItem = useCallback(
    (item: any) => {
      mutateRead(item.id);

      const link =
        item.link ||
        NotificationManager.genRedirectLink(
          item.childSource,
          item.childSourceId,
          item.sourceId
        );
      if (link) {
        UIUtils.popper.dismiss();
        history.push(link);
      }
    },
    [history, mutateRead]
  );

  const renderRow = useCallback(
    (item: any) => {
      const timeAgo = (Prototype.date.toLocal(item.sendDate) as any).fromNow();
      const isUnread = item.readStatus !== NotificationReadStatus.Read;
      const contentArr = (item.content ?? '').split('\n');
      const content = contentArr.map((c: string, idx: number) =>
        c ? (
          <KLabel.Paragraph key={`content-${item.id}-${idx}`}>
            {c}
          </KLabel.Paragraph>
        ) : idx === contentArr.length - 1 ? null : (
          <br key={`br-${item.id}-${idx}`} />
        )
      );

      return (
        <KContainer.Touchable
          row
          alignItems
          marginB="1rem"
          key={`n-${item.id}-${item.readStatus}`}
          onPress={handleOpenItem.bind(this, item)}
        >
          <KContainer.View width={115}>
            <KChip
              background={'#FFF1E0'}
              margin={0}
              label={item.source?.slice(4) || trans('system').toUpperCase()}
            />
          </KContainer.View>

          <KContainer.View flex marginH="0.75rem">
            <KLabel.Text typo="TextMdMedium">{item.subject}</KLabel.Text>

            <KLabel.Text
              marginV="0.5rem"
              dp="block"
              typo={isUnread ? 'TextNmMedium' : 'TextNmNormal'}
            >
              {content}
            </KLabel.Text>

            <KLabel.Text
              typo={isUnread ? 'TextXsMedium' : 'TextXsNormal'}
              color={isUnread ? KColors.primary.normal : undefined}
              italic
            >
              {trans('notif_sent_by', {
                username: item.sentUsername || trans('system'),
                time: timeAgo
              })}
            </KLabel.Text>
          </KContainer.View>

          {isUnread && (
            <KContainer.View
              width={12}
              height={12}
              br="round"
              background={KColors.primary.normal}
            />
          )}
        </KContainer.Touchable>
      );
    },
    [handleOpenItem]
  );

  return (
    <KContainer.Card
      height={500}
      marginT={0}
      header={{
        title: trans('1_stop_notifications'),
        typo: 'TextMdBold'
      }}
      isLoading={isLoading && data.length === 0}
      ref={scrollRef as any}
    >
      <KContainer.View>
        <KTab.Solid
          marginB="0.5rem"
          marginT={0}
          //   @ts-ignore
          marginH={-16}
          height={40}
          tabs={tabs}
          onChangeTab={setTabIndex}
        />
      </KContainer.View>

      <KContainer.View marginB="0.5rem">
        <TextFieldSearch
          columns={generateOptions(ParentSource, {
            key: 'name',
            uppercase: false
          })}
          handleSearchValue={setSearchValue}
          handleChangeSearchField={setSearchField}
          mapColumns={mapMUITableColumnsToFilterColumns}
        />
      </KContainer.View>

      <KContainer.View
        id="1StopNotificationDiv"
        marginT="0.5rem"
        dp="flex"
        flex
        overflow
      >
        <InfiniteScroll
          dataLength={data.length}
          next={onEndReached}
          hasMore={hasNextPage as any}
          loader={isLoading ? <KLoading /> : null}
          scrollableTarget="1StopNotificationDiv"
          scrollThreshold={0.5}
        >
          {data.map(i => renderRow(i))}
        </InfiniteScroll>
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(Notifications1Stop);
