import { SYSTEM_FIELDS, WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { useFetchScreens, useLocalTable } from '@hooks';
import { isEmpty, omit } from 'lodash';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KContainer } from 'uikit';

import { IFormData, IFormPermissionsInstance } from './helpers';

interface IProps {
  currentData: any[];
}

const Screens = forwardRef<IFormPermissionsInstance, IProps>(
  ({ currentData }, ref) => {
    const methods = useFormContext<IFormData>();

    const [userData] = useWatch({
      control: methods.control,
      name: ['screens']
    });

    const { data: remoteData = [], isLoading } = useFetchScreens();

    const [data, setData] = useState(remoteData);
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

    useEffect(() => {
      if (!isEmpty(remoteData)) {
        const indexes: number[] = [];
        const newRemoteData = remoteData.map((i, idx) => {
          const pm = userData.find(p => p.screenId === i.screenId);
          if (pm) {
            indexes.push(idx);
          }
          return omit(pm ?? i, SYSTEM_FIELDS);
        });

        setData(newRemoteData);
        setSelectedIndexes(indexes);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(remoteData), userData]);

    const onRefresh = useCallback(() => {
      methods.setValue('screens', currentData);
    }, [currentData, methods]);

    const columns = useMemo(
      () => [
        {
          label: trans('menu_group'),
          name: 'screen.menuGroup'
        },
        {
          label: trans('menu_item'),
          name: 'screen.menuItem'
        },
        {
          label: trans('screen_code'),
          name: 'screen.code'
        },
        {
          label: trans('screen_name'),
          name: 'screen.name'
        }
      ],
      []
    );

    const table = useLocalTable({
      name: WebTable.ROLE_PERMISSION,
      columns,
      data,
      showDownload: false,
      otherOptions: {
        tableBodyMaxHeight: '28vh'
      },
      onRefresh
    });

    useImperativeHandle(ref, () => ({
      getData: () => selectedIndexes.map(i => omit(data?.[i] ?? {}, ['screen']))
    }));

    return (
      <KContainer.Card
        size="nm"
        border
        header={{
          title: trans('screen_details'),
          border: true
        }}
      >
        <DataTable
          {...table}
          isModifying={isLoading}
          options={{
            ...table.options,
            rowsSelected: selectedIndexes,
            onRowSelectionChange: (_, __, rows) => {
              if (rows) {
                setSelectedIndexes(rows);
              }
            }
          }}
        />
      </KContainer.Card>
    );
  }
);

export default memo(Screens);
