import 'react-toastify/dist/ReactToastify.min.css';
import 'react-calendar/dist/Calendar.css';
import './index.css';
import './patch';

import '@fontsource/roboto';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/700-italic.css';

import { DATE_FORMAT_SHORT, StorageKeys } from '@constants';
import { PreferencesProvider, ToastProvider } from '@context';
import { AppStorage } from '@core';
import { i18n as i18nLingui } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import APIManager from '@services';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { KContext } from 'uikit';
import { setLocale } from 'yup';

import App from './App';
import { messages as en } from './locales/en/messages.js';
import { messages as vi } from './locales/vi/messages.js';
import store from './redux/store';
import { initLocalization, i18n } from './translation';

i18nLingui.load('en', en);
i18nLingui.load('vi', vi);
i18nLingui.activate(AppStorage.get(StorageKeys.preferences)?.language ?? 'en');

initLocalization();

setLocale({
  mixed: {
    required: i18n.t('validation.required'),
    notType: ({ type }) => {
      switch (type) {
        case 'date':
          return i18n.t('validation.invalid_date');

        default:
          return i18n.t('validation.invalid_format');
      }
    }
  },
  string: {
    email: i18n.t('validation.invalid_email')
  },
  number: {
    min: ({ min }) => i18n.t('validation.min_number', { min }),
    max: ({ max }) => i18n.t('validation.max_number', { max }),
    moreThan: ({ more }) =>
      i18n.t('validation.more_than_number', { num: more }),
    lessThan: ({ less }) => i18n.t('validation.less_than_number', { num: less })
  },
  date: {
    min: ({ min }) =>
      i18n.t('validation.min_date', {
        date: moment(min).format(DATE_FORMAT_SHORT)
      }),
    max: ({ max }) =>
      i18n.t('validation.max_date', {
        date: moment(max).format(DATE_FORMAT_SHORT)
      })
  },
  array: {
    min: ({ min }) => i18n.t('validation.min_array', { min })
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 2e3,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        console.log(e?.message);
        // toast.error(e?.message);
      }
    },
    mutations: {
      onError: (e: any) => {
        console.log(e?.message);
        // toast.error(e?.message);
      }
    }
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <I18nProvider i18n={i18nLingui}>
      <BrowserRouter>
        <ToastProvider>
          <PreferencesProvider>
            <KContext.APIProvider apiInstance={APIManager.request}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Provider store={store}>
                  <App />
                </Provider>
              </LocalizationProvider>
            </KContext.APIProvider>
          </PreferencesProvider>
        </ToastProvider>
      </BrowserRouter>
    </I18nProvider>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
);
