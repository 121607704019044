import {
  ENDPOINTS,
  ShipmentType,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { Prototype } from '@core';
import { useDataTable, useGlobalTable, useNewButton } from '@hooks';
import { TableUtils } from '@utils';
import React, { memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer } from 'uikit';

import { onShowClientRate, useDeleteClientRate } from './helpers';

const ClientRate = () => {
  const [tab] = useState(ShipmentType.FCL);

  const { onAlert, deleteLoading } = useDeleteClientRate();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowClientRate(item.id)
          },
          {
            title: trans('delete'),
            icon: 'Delete',
            onPress: () => onAlert({ id: item.id })
          }
        ])
      },
      {
        label: trans('agent_code'),
        name: 'agentClient.code'
      },
      {
        label: trans('agent_name'),
        name: 'agentClient.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('consignee_consignor_code'),
        name: 'consignClient.code'
      },
      {
        label: trans('consignee_consignor_name'),
        name: 'consignClient.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('warehouse_code'),
        name: 'relatedPartySite.relatedClient.code'
      },
      {
        label: trans('warehouse_name'),
        name: 'relatedPartySite.relatedClient.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('warehouse_address'),
        name: 'relatedPartySite.relatedSite',
        options: {
          ...TableUtils.options.baseXLg,
          customBodyRender: (v: any) => Prototype.string.normalizeFullAddress(v)
        }
      },
      {
        label: trans('job_type'),
        name: 'jobType',
        options: TableUtils.options.mappedOption
      },
      {
        label: trans('status'),
        name: 'status',
        options: TableUtils.options.switch
      }
    ],
    [onAlert]
  );

  const mappedFields = useMemo(
    () => ({
      'agentClient.code': 'agentClientCode',
      'agentClient.name': 'agentClientName',
      'consignClient.code': 'consignClientCode',
      'consignClient.name': 'consignClientName',
      'relatedPartySite.relatedClient.code': 'relatedClientCode',
      'relatedPartySite.relatedClient.name': 'relatedClientName',
      'relatedPartySite.relatedSite': 'relatedSiteAddress'
    }),
    []
  );

  const otherParams = useMemo(
    () => ({
      shipmentType: tab
    }),
    [tab]
  );

  const table = useDataTable({
    name: WebTable.CLIENT_RATE,
    tableName: TableName.CLIENT_RATE,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.clientRate(),
    columnsFunc,
    mappedFields,
    otherParams
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    onShowClientRate('new', { shipmentType: tab });
  }, [tab]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.tariffs_and_rates'),
          trans('routes.client_rates')
        ]}
      />

      <KContainer.Card
        margin="0.75rem"
        // header={{
        //   renderHeader: () => (
        //     <Tabs.Shipment onChangeTab={setTab} disabled={table.isLoading} />
        //   )
        // }}
      >
        <DataTable {...table} onAdd={onAdd} isModifying={deleteLoading} />
      </KContainer.Card>

      {newButton}
    </>
  );
};

export default memo(ClientRate);
