import { ITransportJobReportDataResponse } from '@request-dto';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import trans from 'translation';
import { KColors, KContainer, KLabel, KViewProps } from 'uikit';

import { useReportContext } from './Report.Provider';

interface IOptionItemProps extends KViewProps {
  title: string;
}

interface IOptionProps {
  data: IOptionItemProps[];
  key: string;
}

export enum ReportType {
  Accounting = 'Accounting',
  Operations = 'Operations'
}

export const AR_INVOICES_OPTIONS = [
  {
    title: 'total',
    background: KColors.secondary.normal
  },
  {
    title: 'overdue',
    background: KColors.warning.normal
  },
  {
    title: 'paid',
    background: KColors.primary.normal
  },
  {
    title: 'partial_paid',
    background: KColors.blue.dark
  }
];

export const PAYMENT_OPTIONS = [
  {
    title: 'total',
    background: '#0263FF'
  },
  {
    title: 'synced',
    background: '#5DC42B'
  }
];

export const CLIENT_OPTIONS = [
  {
    title: 'paid',
    background: KColors.blue.normal
  },
  {
    title: 'overdue_amount',
    background: KColors.primary.normal
  },
  {
    title: 'balance_due_amount',
    background: KColors.warning.normal
  }
];

export const JOBS_OPTIONS = [
  {
    title: 'total',
    background: KColors.secondary.normal
  },
  {
    title: 'import',
    background: KColors.blue.dark
  },
  {
    title: 'export',
    background: KColors.warning.normal
  }
];

export const JOBS_CLIENT_OPTIONS = [
  {
    title: 'number_of_jobs',
    background: KColors.blue.normal
  },
  {
    title: 'number_of_containers',
    background: KColors.warning.normal
  }
];

export const JOBS_DRIVER_OPTIONS = [
  {
    title: 'number_of_schedule_trips',
    background: KColors.primary.normal
  },
  {
    title: 'number_of_minutes',
    background: KColors.warning.normal
  }
];

export const renderOptions = ({ data, key }: IOptionProps) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <KContainer.View row alignItems>
      {data.map((i, idx) => {
        const { title, ...rest } = i;
        const marginL = idx === 0 ? 0 : '2.5rem';

        return (
          <KContainer.View
            row
            alignItems
            key={`${key}-${title}`}
            marginL={marginL}
          >
            <KContainer.View br="round" size={8} marginR="0.5rem" {...rest} />

            <KLabel.Text typo="TextNmMedium">{trans(title)}</KLabel.Text>
          </KContainer.View>
        );
      })}
    </KContainer.View>
  );
};

export const useOnExportParams = (
  data?: ITransportJobReportDataResponse,
  template?: any,
  client?: any
) => {
  const { conditions } = useReportContext();

  const conditionData = useMemo(
    () =>
      conditions.length > 0
        ? conditions.map(c => {
            const {
              label,
              dataFormat,
              operatorType,
              min,
              max,
              maxDateFormat,
              minDateFormat
            } = c;

            return `${label} ${trans(`advanced_search.${operatorType.toLowerCase()}`)} ${
              dataFormat
                ? `'${dataFormat}'`
                : [min, max, minDateFormat, maxDateFormat]
                    .filter(i => i)
                    .map(i => `'${i}'`)
                    .join(' and ')
            }`;
          })
        : undefined,
    [conditions]
  );

  const headerData = useMemo(
    () => [
      {
        mergeCells: 'A1:E1',
        data: [template?.name ?? '']
      },
      template?.hasClient
        ? {
            data: [
              trans(template.code.toLowerCase()),
              client?.code,
              client?.name
            ]
          }
        : undefined,
      ...(conditionData
        ? conditionData.map((i, idx) => {
            return {
              data: [idx === 0 ? trans('job_conditions') : '', i]
            };
          })
        : [])
    ],
    [
      client?.code,
      client?.name,
      conditionData,
      template?.hasClient,
      template?.name,
      template?.code
    ]
  );

  const footerData = useMemo(
    () => [
      {
        data: [trans('total_containers'), data?.totalContainer ?? 0]
      },
      {
        data: [trans('total_jobs'), data?.totalJob ?? 0]
      }
    ],
    [data?.totalContainer, data?.totalJob]
  );

  const onExportParams = useMemo(() => {
    return {
      header: headerData,
      footer: footerData
    };
  }, [footerData, headerData]);

  return onExportParams;
};
