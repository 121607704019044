import {
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT_MAX,
  PAGE_SIZE_DEFAULT_TABLE
} from '@constants';
import { BaseResponse } from '@dto';
import { UseDataTableResult, UseLocalTableProps } from '@hooks-dto';
import APIManager from '@services';
import { isEmpty } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import trans from 'translation';
import { useCancelablePromise, useDidUpdate } from 'uikit-common';

import {
  cacheColumnOrder,
  cacheViewColumns,
  getColumnOrderFromCache,
  normalizeFinalColumns,
  reorderColumnsBasedOnCache
} from './helpers';

const useLocalTable = <T = any>({
  name,
  data,
  columns,
  mappedFields,
  otherParams,
  apiURL,
  otherOptions,
  showDownload = true,
  isInitFetch = false,
  onRefresh
}: UseLocalTableProps): UseDataTableResult<T> => {
  const [localData, setLocalData] = useState<T[]>([]);

  const [orderedColumns, setOrderedColumns] = useState<any[]>(
    reorderColumnsBasedOnCache(name, columns)
  );

  const [isLoading, setIsLoading] = useState(false);

  const [searchConditions, setSearchConditions] = useState<any[]>([]);

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  useDidUpdate(() => {
    setOrderedColumns(reorderColumnsBasedOnCache(name, columns));
  }, [columns, name]);

  useEffect(() => {
    if (data && JSON.stringify(localData) !== JSON.stringify(data)) {
      setLocalData(data);
    }
  }, [data, localData]);

  const { promise, handleNewPromise } = useCancelablePromise();

  const mParams = useMemo(() => {
    const _mParams: any = {
      page: PAGE_DEFAULT,
      size: PAGE_SIZE_DEFAULT_MAX,
      ...otherParams
    };

    return _mParams;
  }, [otherParams]);

  const initializeFetch = useCallback(async () => {
    if (apiURL) {
      let _apiURL = apiURL;
      if (!isEmpty(mParams)) {
        _apiURL = `${apiURL}?${qs.stringify(mParams)}`;
      }

      setIsLoading(true);
      handleNewPromise(
        APIManager.request({
          url: _apiURL,
          showToast: false
        })
      );
      const res: BaseResponse<any> = await promise.current;
      if (res?.data) {
        if (res.data?.data) {
          setLocalData(res?.data?.data);
        } else {
          setLocalData(res?.data);
        }
      }

      setIsLoading(false);
    }
  }, [apiURL, mParams, promise, handleNewPromise]);

  useEffect(() => {
    if (isInitFetch) {
      initializeFetch();
    }
  }, [initializeFetch, isInitFetch, promise]);

  const options: MUIDataTableOptions = useMemo(() => {
    return {
      rowsPerPage: PAGE_SIZE_DEFAULT_TABLE,
      print: false,
      download: false,
      filter: false,
      sort: true,
      responsive: 'standard',
      search: true,
      searchOpen: true,
      searchAlwaysOpen: true,
      selectableRows: 'multiple',
      selectToolbarPlacement: 'none',
      elevation: 0,
      rowsPerPageOptions: [15, 50, 100],
      rowsSelected: selectedIndexes,
      draggableColumns: {
        enabled: true
      },
      columnOrder: getColumnOrderFromCache(name, columns.length),
      enableNestedDataAccess: '.', // allows nested data separated by "."
      textLabels: {
        body: {
          noMatch: isLoading ? trans('loading_table') : trans('empty_table')
        }
      },
      customSearch: (searchQuery: any, currentRow: any) => {
        let isFound = false;
        currentRow.forEach((col: any) => {
          if (
            col?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >=
            0
          ) {
            isFound = true;
          }
        });
        return isFound;
      },
      onColumnOrderChange: (newColumnOrderInt: any) => {
        cacheColumnOrder(name, newColumnOrderInt);
      },
      onViewColumnsChange: (changedColumn: any, action: any) => {
        cacheViewColumns(name, changedColumn, action);
        setOrderedColumns(reorderColumnsBasedOnCache(name, columns));
      },
      onRowSelectionChange: (_: any, __: any, rows: any) => {
        setSelectedIndexes(rows as number[]);
      },
      ...(otherOptions ?? {})
    };
  }, [selectedIndexes, name, columns, isLoading, otherOptions]);

  const onDownload = useCallback(
    async (dParams: any): Promise<{ data: any }> => {
      if (showDownload && apiURL) {
        const _mParams = { ...dParams, ...otherParams };

        const res = await APIManager.request({
          url: `${apiURL}/export-excel`,
          body: _mParams,
          showToast: false
        });
        return Promise.resolve({ data: res.data });
      }

      return Promise.resolve({ data: undefined });
    },
    [otherParams, showDownload, apiURL]
  );

  const mColumns = useMemo(() => {
    return normalizeFinalColumns(orderedColumns, mappedFields);
  }, [mappedFields, orderedColumns]);

  return {
    name,
    columns: mColumns,
    data: { data: localData },
    options,
    advanceSearch: [],
    isLoading,
    selectedIndexes,
    selectedData: selectedIndexes.map(i => localData?.[i]),
    searchConditions,
    isLocal: true,
    showDownload,

    init: onRefresh || initializeFetch,
    setSearchConditions,
    setSelectedIndexes,
    setLocalData,
    onDownload
  };
};

export default useLocalTable;
