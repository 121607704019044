import React, { createContext, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';

interface IValue {
  showToast: typeof toast;
}

// @ts-ignore
const ToastContext = createContext<IValue>();

const ToastProvider = ({ children }: { children: any }) => {
  const showToast = toast;

  return (
    <ToastContext.Provider
      value={{
        showToast
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        limit={3}
        closeButton={false}
        theme="colored"
        pauseOnHover
        pauseOnFocusLoss
        icon={false}
      />

      {children}
    </ToastContext.Provider>
  );
};

const useToastContext = (): IValue => useContext(ToastContext);

export { ToastContext, ToastProvider, useToastContext };
