const { t } = require('@lingui/macro');

export const OperandTypes = {
  STRING: 'STRING',
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  BOOLEAN: 'BOOLEAN',
  REF_ID: 'REF_ID'
};

export const Operators = {
  CONTAIN: 'CONTAIN',
  START_WITH: 'START_WITH',
  END_WITH: 'END_WITH',
  EQUAL: 'EQUAL',
  GREATER_EQUAL: 'GREATER_EQUAL',
  LESS_EQUAL: 'LESS_EQUAL',
  BETWEEN: 'BETWEEN',
  IN: 'IN'
};

export const StringOperators = [
  {
    id: Operators.CONTAIN,
    name: t`Contains`
  },
  {
    id: Operators.START_WITH,
    name: t`Starts With`
  },
  {
    id: Operators.END_WITH,
    name: t`Ends With`
  }
];

export const NumberOperators = [
  {
    id: Operators.EQUAL,
    name: t`Equals`
  },
  {
    id: Operators.GREATER_EQUAL,
    name: t`Greater Equal`
  },
  {
    id: Operators.LESS_EQUAL,
    name: t`Less Equal`
  },
  {
    id: Operators.BETWEEN,
    name: t`Between`
  }
];

export const DateOperators = [
  {
    id: Operators.EQUAL,
    name: t`Equals`
  },
  {
    id: Operators.GREATER_EQUAL,
    name: t`Greater Equal`
  },
  {
    id: Operators.LESS_EQUAL,
    name: t`Less Equal`
  },
  {
    id: Operators.BETWEEN,
    name: t`Between`
  }
];

export const BooleanOperators = [
  {
    id: Operators.EQUAL,
    name: t`Equals`
  }
];

export const RefOperators = [
  {
    id: Operators.IN,
    name: t`In`
  }
];

export const OperatorData = {
  [OperandTypes.STRING]: StringOperators,
  [OperandTypes.INTEGER]: NumberOperators,
  [OperandTypes.DECIMAL]: NumberOperators,
  [OperandTypes.DATE]: DateOperators,
  [OperandTypes.TIME]: DateOperators,
  [OperandTypes.DATETIME]: DateOperators,
  [OperandTypes.BOOLEAN]: BooleanOperators,
  [OperandTypes.REF_ID]: RefOperators
};
