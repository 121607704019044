import {
  ENDPOINTS,
  OperandTypes,
  PAGE_SIZE_DEFAULT_MAX,
  ReportExcelCode,
  ReportExcelType,
  TableName
} from '@constants';
import Filter from '@containers/DataTable/Filter';
import { normalizeFilterData } from '@containers/DataTable/helpers';
import { Prototype } from '@core';
import { useFetchAdvanceSearch, useFetchTransportJobReportData } from '@hooks';
import Print from '@pages/Report/components/Print';
import { useReportContext } from '@pages/Report/Report.Provider';
import { UIUtils } from '@utils';
import React, { memo, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import trans from 'translation';
import { KContainer, KGrid, KInput, KLabel } from 'uikit';

import AccountReceivable from '../DocAgTable/AccountReceivable';
import Agent from '../DocAgTable/Agent';
import ConsignorConsignee from '../DocAgTable/ConsignorConsignee';
import JobGeneral from '../DocAgTable/JobGeneral';
import Trip from '../DocAgTable/Trip';
import WaitDepotStopBy from '../DocAgTable/WaitDepotStopBy';
import WaitingTime from '../DocAgTable/WaitingTime';
import Warehouse from '../DocAgTable/Warehouse';

// import Client from './Client';
// import Driver from './Driver';
// import Jobs from './Jobs';

const setupMappedFields = () => {
  const mappedFields = {
    jobNumber: 'job_number',
    jobProgress: 'job_status',
    jobType: 'job_type',
    referenceNumber: 'customer_reference',
    vessel: 'vessel',
    lloyd: 'lloyd',
    voyage: 'voyage',
    unlocoBoardOfLoading: 'unloco_board_of_loading',
    unlocoBoardOfDischarge: 'unloco_board_of_discharge',
    eta: 'eta',
    etd: 'etd',
    avail: 'imp_available_date',
    stor: 'imp_storage_start_date',
    firstFreeDate: '1st_free_date',
    firstDischargeDate: 'discharge_date',
    expRecvDate: 'exp_receival_commencement_date',
    cutOffDate: 'cargo_cutoff_date',
    reeferCutoffDate: 'reefer_cutoff_date',
    emptyReceivalCommencementDate: 'empty_receival_date',
    emptyCutoffDate: 'empty_cutoff_date',
    hazardousCutoffDate: 'hazardous_cutoff_date',
    hazardousReceivalCommencementDate: 'hazardous_receival_date',
    containerNumber: 'container_number',
    containerInvoiceStatus: 'invoice_status',
    containerProgress: 'container_status',
    containerSize: 'container_size',
    dropMode: 'drop_mode',
    agentClientCode: 'agent_code',
    agentClientName: 'agent_name',
    consignClientCode: 'consignee_consignor_code',
    consignClientName: 'consignee_consignor_name',
    accountsClientCode: 'account_receivable_code',
    accountsClientName: 'account_receivable_name',
    warehouseClientCode: 'warehouse_code',
    warehouseClientName: 'warehouse_name',
    createdAt: 'created_at'
  };

  return Object.keys(mappedFields).reduce<Record<string, string>>(
    (acc, cur) => {
      acc[cur] = trans(mappedFields[cur]);
      return acc;
    },
    {}
  );
};

const Details = () => {
  const { setConditions, conditions } = useReportContext();

  const [template, setTemplate] = useState<any>(undefined);
  const [client, setClient] = useState<any>(undefined);

  const { mutate, isLoading, data, setDataReturn } =
    useFetchTransportJobReportData();

  const { data: advanceSearchData = [], isLoading: advanceSearchLoading } =
    useFetchAdvanceSearch(TableName.TRANSPORT_JOB, setupMappedFields());

  const onPrint = useCallback((v: any[]) => {
    UIUtils.popup.open({
      title: trans('print'),
      content: () => (
        <Print
          conditions={v.map(i => ({
            ...i,
            dataFormat:
              i.operandType === OperandTypes.DATE
                ? Prototype.date.formatD(i.data as any)
                : i.operandType === OperandTypes.DATETIME
                  ? Prototype.date.formatDT(i.data as any)
                  : i.operandType === OperandTypes.TIME
                    ? Prototype.date.formatT(i.data as any)
                    : i.data,
            minDateFormat:
              i.operandType === OperandTypes.DATE
                ? Prototype.date.formatD(i.minDate as any)
                : i.operandType === OperandTypes.DATETIME
                  ? Prototype.date.formatDT(i.minDate as any)
                  : i.operandType === OperandTypes.TIME
                    ? Prototype.date.formatT(i.minDate as any)
                    : i.minDate,
            maxDateFormat:
              i.operandType === OperandTypes.DATE
                ? Prototype.date.formatD(i.maxDate as any)
                : i.operandType === OperandTypes.DATETIME
                  ? Prototype.date.formatDT(i.maxDate as any)
                  : i.operandType === OperandTypes.TIME
                    ? Prototype.date.formatT(i.maxDate as any)
                    : i.maxDate
          }))}
          reportType={ReportExcelType.TransportJob}
        />
      )
    });
  }, []);

  return (
    <KGrid.Container>
      <KGrid.Item xs={12}>
        <Filter
          title={trans('filter_by_transport_jobs')}
          advanceSearch={advanceSearchData}
          searchConditions={conditions}
          onSubmit={(v: any) => {
            setConditions(v, d => {
              if (template?.code) {
                if (template?.hasClient && !client) {
                  toast.error('Please select client');
                } else {
                  mutate({
                    page: 0,
                    pageSize: PAGE_SIZE_DEFAULT_MAX,
                    searchCondition: JSON.stringify(normalizeFilterData(d)),
                    reportExcelType: template.code,
                    reportClientId: client?.id
                  });
                }
              } else {
                toast.error('Please select template');
              }
            });
            // onPrint(v);
          }}
          wrapperType="card"
          wrapperProps={{
            // marginT: '0.25rem',
            width: '100%',
            isLoading: advanceSearchLoading
          }}
          headerBelow
          renderHeader={
            <KContainer.View row alignItems marginT="1rem" gap="0.75rem">
              <KContainer.View width={250}>
                <KInput.Autocomplete
                  name="template"
                  label={trans('document')}
                  apiURL={ENDPOINTS.reportExcelType()}
                  apiParams={{
                    type: ReportExcelType.TransportJob
                  }}
                  getOptionSelected={(o: any, v: any) => o.code === v?.code}
                  onChange={(v: any) => {
                    setTemplate(v);
                    setClient(undefined);
                    setDataReturn(undefined);
                  }}
                  value={template}
                />
              </KContainer.View>

              {template?.hasClient && (
                <>
                  <KContainer.View width={250}>
                    <KInput.Autocomplete
                      name="clientCode"
                      label={trans('client')}
                      apiURL={ENDPOINTS.client()}
                      getOptionLabel={(o: any) => o.code ?? ''}
                      onChange={(v: any) => {
                        setClient(v);
                        setDataReturn(undefined);
                      }}
                      value={client}
                    />
                  </KContainer.View>

                  <KContainer.View width={300}>
                    <KInput.Autocomplete
                      name="clientName"
                      label={trans('client')}
                      apiURL={ENDPOINTS.client()}
                      onChange={(v: any) => {
                        setClient(v);
                        setDataReturn(undefined);
                      }}
                      value={client}
                    />
                  </KContainer.View>
                </>
              )}
            </KContainer.View>
          }
        />
      </KGrid.Item>

      {!!template && (
        <KGrid.Item xs={12}>
          <KContainer.Card isLoading={isLoading}>
            <KContainer.RenderWhen isTrue>
              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.JobGeneral}
              >
                <JobGeneral data={data} document={template} />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.ConsignorConsignee}
              >
                <ConsignorConsignee
                  data={data}
                  document={template}
                  client={client}
                />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.AccountReceivable}
              >
                <AccountReceivable
                  data={data}
                  document={template}
                  client={client}
                />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.Agent}
              >
                <Agent data={data} document={template} client={client} />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.Warehouse}
              >
                <Warehouse data={data} document={template} client={client} />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.WaitDepotStopBy}
              >
                <WaitDepotStopBy
                  data={data}
                  document={template}
                  client={client}
                />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.TripReport}
              >
                <Trip data={data} document={template} />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If
                isTrue={template?.code === ReportExcelCode.WaitingTime}
              >
                <WaitingTime data={data} document={template} />
              </KContainer.RenderWhen.If>
            </KContainer.RenderWhen>

            {data && (
              <KContainer.View marginT="0.75rem" gap="0.25rem" dp="flex">
                <KLabel.Paragraph>
                  {trans('total_containers')}: {data?.totalContainer}
                </KLabel.Paragraph>

                <KLabel.Paragraph>
                  {trans('total_jobs')}: {data?.totalJob}
                </KLabel.Paragraph>
              </KContainer.View>
            )}
          </KContainer.Card>
        </KGrid.Item>
      )}
    </KGrid.Container>
  );
};

export default memo(Details);
