import {
  primaryGreen,
  whiteColor
} from '@assets/jss/material-dashboard-react.js';
const buttonStyle = {
  containedButton: {
    // backgroundColor: `${primaryGreen}!important`,
    color: whiteColor,
    height: 34
  },
  textButton: {
    // backgroundColor: `${primaryGreen}!important`,
    color: primaryGreen,
    height: 34
  },
  orangeIconButton: {
    backgroundColor: '#ff9f00!important',
    color: whiteColor,
    height: 24,
    width: 24,
    maxWidth: 24,
    minWidth: 24,
    padding: '6px'
  },
  primaryIconButton: {
    backgroundColor: `${primaryGreen}!important`,
    color: whiteColor,
    height: 24,
    width: 24,
    maxWidth: 24,
    minWidth: 24,
    padding: '6px'
  }
};

export default buttonStyle;
