import { JobTitle } from '@constants';
import * as yup from 'yup';

export default class ValidationUtils {
  static readonly requiredAny = () => yup.mixed().nullable().required();
  static readonly required = () => yup.string().nullable().required();
  static readonly requiredDate = () => yup.date().nullable().required();
  static readonly requiredNum = () =>
    yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required();
  static readonly requiredArray = () => yup.array().required().min(1);
  static readonly requiredEmail = () => this.required().email();

  static readonly requiredIfDriver = () =>
    yup
      .mixed()
      .nullable()
      .when('jobTitle', ([v], _schema) => {
        return v?.code === JobTitle.Driver ? _schema.required() : _schema;
      });

  static readonly nullableDate = () =>
    yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr));
  static readonly nullableNum = () =>
    yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr));
}
