import { ENDPOINTS, JobStatusId, QUERY_KEYS, WebTable } from '@constants';
import { ITransportJobReportDataResponse } from '@request-dto';
import APIManager from '@services';
import { UIUtils } from '@utils';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useParsePdf,
  useQueryEnhancer
} from '../core';

export * from './container';
export * from './routing';
export * from './invoice';

export const useFetchTransportJobList = (mParams?: any) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.transportJobList, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob(),
        body: mParams
      });

      return res.data?.data ?? [];
    },
    enabled: false,
    initialData: []
  });
};

export const useFetchTransportJob = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.transportJob, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDTransportJob = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.transportJob(),
    webTable: WebTable.TRANSPORT_JOB,
    queryKey: QUERY_KEYS.transportJob,
    redirectPath: '/admin/transport-jobs'
  });
};

export const useExportTransportJob = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async id => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob(':id/export-excel', { id }),
        method: 'POST',
        configs: {
          responseType: 'blob'
        }
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        );
        window.open(url);
      }
    }
  });
};

export const useCloseTransportJob = () => {
  return useMutationEnhancer<
    any,
    { id?: number | string; status: JobStatusId }
  >({
    mutationFn: async data => {
      const { id, status } = data;
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob(
          `:id/${status === JobStatusId.Closed ? 'unclose' : 'close'}`,
          { id }
        ),
        method: 'PUT'
      });

      return res.success;
    },
    mutationKeys: [[QUERY_KEYS.transportJob]]
  });
};

export const useAutoFillTransportJob = () => {
  const history = useHistory();
  const { mutateAsync } = useParsePdf();

  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const { pdfData, ...rest } = data;
      const pdfRes = await mutateAsync(pdfData);

      if (pdfRes) {
        const res = await APIManager.request({
          url: ENDPOINTS.transportJob('import'),
          method: 'POST',
          body: {
            ...pdfRes,
            ...rest
          },
          showToast: true
        });

        return res.data;
      }

      return false;
    },
    onSuccess: data => {
      if (data?.id) {
        UIUtils.popup.dismiss();
        history.replace(`/admin/transport-jobs/${data.id}`);
      }
    }
  });
};

export const useReportTransportJob = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob('report-excel'),
        // method: 'POST',
        body: data,
        configs: {
          responseType: 'blob'
        }
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
          })
        );
        window.open(url);
      }
    }
  });
};

export const useFetchTransportJobReportData = () => {
  const [dataReturn, setDataReturn] = useState<
    ITransportJobReportDataResponse | undefined
  >(undefined);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, ...rest } = useMutationEnhancer<
    ITransportJobReportDataResponse,
    any
  >({
    // mutationKey: [QUERY_KEYS.transportJob],
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob('report-excel-data'),
        body: mParams
      });

      return res.data;
    },
    onSuccess: d => {
      setDataReturn(d);
    }
  });

  return {
    ...rest,
    data: dataReturn,
    setDataReturn
  };
};
