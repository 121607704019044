import { PAGE_SIZE_DEFAULT } from '@constants';
import {
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFunction,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
  MutateOptions,
  MutationKey
} from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const useMutationEnhancer = <
  TData = unknown,
  TVariables = unknown,
  TContext = unknown,
  TError = unknown
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & {
    mutationKeys?: Array<MutationKey>;
  }
) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<TData, TError, TVariables, TContext>({
    ...options,
    onSuccess: (data, variables, context) => {
      if (options.mutationKey) {
        queryClient.invalidateQueries<TContext>(options.mutationKey, {
          type: 'active'
        });
      }
      if (options.mutationKeys) {
        options.mutationKeys.forEach(key => {
          queryClient.invalidateQueries<TContext>(key, {
            type: 'active'
          });
        });
      }
      options?.onSuccess?.(data, variables, context);
    }
  });

  const mutateEnhancer = useCallback(
    (
      v?: TVariables,
      o?: MutateOptions<TData, TError, TVariables, TContext> | undefined
    ) => {
      mutate(v as TVariables, o);
    },
    [mutate]
  );

  return {
    mutate: mutateEnhancer,
    ...rest
  };
};

export const useQueryEnhancer = <
  TData = unknown,
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TError = unknown
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const props = useQuery<TQueryFnData, TError, TData, TQueryKey>(options);

  return { ...props, isLoading: props.isInitialLoading || props.isFetching };
};

export const useInfiniteEnhancer = <
  TData = unknown,
  TQueryParams = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TError = unknown
>(
  options: Omit<
    UseInfiniteQueryOptions<
      TQueryParams,
      TError,
      TData,
      TQueryParams,
      TQueryKey
    >,
    'queryKey' | 'queryFn'
  > & {
    queryKey: TQueryKey;
    queryFn: QueryFunction<TQueryParams, TQueryKey>;
  },
  idKey = 'id'
) => {
  const { queryFn, queryKey, ...restOptions } = options;

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const queryClient = useQueryClient();
  const {
    data,
    isFetchingNextPage,
    hasNextPage,
    isRefetching,
    isFetching,
    refetch,
    fetchNextPage,
    ...rest
  } = useInfiniteQuery<TQueryParams, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    {
      suspense: false,
      retry: false,
      getNextPageParam: (lastPage: any, allPage) => {
        if (
          (!lastPage && allPage.length === 1) ||
          (lastPage &&
            lastPage.data?.length < (lastPage.size ?? PAGE_SIZE_DEFAULT))
        ) {
          return undefined;
        }

        return {
          pageSize: lastPage?.size ?? PAGE_SIZE_DEFAULT,
          page: !lastPage
            ? 0
            : lastPage?.data?.length === (lastPage?.size ?? PAGE_SIZE_DEFAULT)
              ? lastPage?.page + 1
              : lastPage?.page
        };
      },
      ...restOptions
    }
  );

  const dataFlatten = useMemo(() => {
    if (data?.pages) {
      return data.pages.reduce((prev: TData[], curr: any) => {
        if (curr) {
          let arr = prev.concat(curr.data || []);
          if (idKey) {
            return uniqBy(arr, idKey);
          }
          return arr;
        }
        return prev;
      }, [] as TData[]);
    }
    return [];
  }, [data?.pages, idKey]);

  const onRefresh = useCallback(() => {
    queryClient.setQueriesData(queryKey, (allData: any) => {
      return {
        pageParams: [allData?.pageParams?.[0]],
        pages: [allData?.pages?.[0]]
      };
    });
    setIsRefreshing(true);
    refetch()
      .then(() => setIsRefreshing(false))
      .catch(() => setIsRefreshing(false));
  }, [queryKey, queryClient, refetch]);

  const onEndReached = useCallback(() => {
    if (
      !isFetchingNextPage &&
      hasNextPage &&
      !rest.isInitialLoading &&
      !isFetching &&
      !isLoadingMore
    ) {
      setIsLoadingMore(true);
      fetchNextPage()
        .then(() => setIsLoadingMore(false))
        .catch(() => setIsLoadingMore(false));
    }
  }, [
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoadingMore,
    rest.isInitialLoading
  ]);

  return {
    ...rest,
    isFetchingNextPage,
    dataFlatten,
    isRefreshingEnhancer: isRefreshing,
    isRefetching,
    isLoadingMore,
    isFetching,
    hasNextPage,
    onRefreshEnhancer: onRefresh,
    onEndReached,
    fetchNextPage,
    refetch,
    isLoading: rest.isInitialLoading || isFetching
  };
};
