import { SyncStatus, WebTable } from '@constants';
import AgTable from '@containers/AgTable';
import { useAgGlobalTable, useAgTable } from '@hooks';
import { IExtraProps } from '@ui';
import { CellValueChangedEvent, IRowNode } from 'ag-grid-community';
import React, { memo, useCallback } from 'react';
import { KContainer } from 'uikit';

import { useColDefs } from './helpers';

interface IProps extends IExtraProps {
  onEndEditing?: (data: any) => void;
  data: any[];
}

const FormNewInvoices2 = ({ extraLoading, onEndEditing, data }: IProps) => {
  const onCellValueChanged = (event: CellValueChangedEvent<any>) => {
    const colId = event.column.getColId();
    if (colId === 'checked' && event.newValue !== event.oldValue) {
      event.node.setDataValue(
        'totalAmount',
        event.newValue === true ? event.node.data.totalDue : 0
      );
    }

    if (onEndEditing && colId === 'totalAmount') {
      onEndEditing(event.data);
    }
  };

  const isRowSelectable = useCallback((rowNode: IRowNode) => {
    return rowNode.data
      ? rowNode.data.invoice.syncStatus === SyncStatus.Success.toUpperCase()
      : false;
  }, []);

  const colDefs = useColDefs();

  const table = useAgTable({
    name: WebTable.LOCAL_PAYMENT_INVOICE,
    data,
    colDefs,
    onAddParams: {
      hidden: true
    }
  });

  useAgGlobalTable(table);

  return (
    <KContainer.View>
      <AgTable
        {...table}
        showSearch
        pagination={false}
        autoSizeStrategy={{
          type: 'fitGridWidth'
        }}
        onCellValueChanged={onCellValueChanged}
        isLoading={extraLoading}
        isRowSelectable={isRowSelectable}
        singleClickEdit={false}
      />
    </KContainer.View>
  );
};

export default memo(FormNewInvoices2);
