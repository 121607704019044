import { useImportClient } from '@hooks';
import { isArray, isEmpty } from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import trans from 'translation';
import { KButton, KContainer, KForm, KInput } from 'uikit';

const Import = () => {
  const [file, setFile] = useState<any>(undefined);

  const { mutate, isLoading } = useImportClient();

  const onChange = useCallback((files: any[], onSuccess: any) => {
    if (isArray(files)) {
      if (!isEmpty(files)) {
        const f = files[0];
        setFile(f);
      } else {
        setFile(undefined);
      }
      onSuccess?.(files);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const formData = new FormData();
    formData.append('file', file);
    mutate(formData);
  }, [file, mutate]);

  return (
    <KForm>
      <KInput.Dropzone
        maxFiles={1}
        onChange={onChange}
        showFiles
        accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />

      <KContainer.View
        row
        alignItems
        justifyContent="flex-end"
        marginT="0.75rem"
      >
        <KButton.Solid
          icon="Save"
          onPress={handleSubmit}
          isLoading={isLoading}
          disabled={!file}
          title={trans('submit')}
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(Import);
