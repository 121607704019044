import Cookies from 'js-cookie';
// use Cookie for now, easily switch to something else
class AppStorage {
  constructor(localStorage) {
    this.storage = localStorage;
  }

  setItem(key, value, data) {
    if (data) {
      this.storage.set(key, value, data);
      return;
    }
    this.storage.set(key, value);
  }
  getItem(key) {
    return this.storage.get(key);
  }
  removeItem(key) {
    this.storage.remove(key);
  }

  setAccessToken(accessToken, data) {
    this.setItem('accessToken', accessToken, data);
  }
  getAccessToken() {
    return this.getItem('accessToken');
  }
  removeAccessToken() {
    this.removeItem('accessToken');
  }

  setLoginType(loginType) {
    this.setItem('loginType', loginType);
  }
  getLoginType() {
    return this.getItem('loginType');
  }
  removeLoginType() {
    this.removeItem('loginType');
  }

  setRefreshToken(refreshToken, data) {
    this.setItem('refreshToken', refreshToken, data);
  }
  getRefreshToken() {
    return this.getItem('refreshToken');
  }
  removeRefreshToken() {
    this.removeItem('refreshToken');
  }

  setAccessTokenExpires(accessTokenExpires, data) {
    this.setItem('accessTokenExpires', accessTokenExpires, data);
  }
  getAccessTokenExpires() {
    return this.getItem('accessTokenExpires');
  }
  removeAccessTokenExpires() {
    this.removeItem('accessTokenExpires');
  }

  setRefreshTokenExpires(refreshTokenExpires, data) {
    this.setItem('refreshTokenExpires', refreshTokenExpires, data);
  }
  getRefreshTokenExpires() {
    return this.getItem('refreshTokenExpires');
  }
  removeRefreshTokenExpires() {
    this.removeItem('refreshTokenExpires');
  }

  setTokenData(data) {
    const accessTokenExpiresIn = Date.now() + data.expires_in * 1000;
    const refreshTokenExpiresIn = Date.now() + data.refresh_expires_in * 1000;

    this.setAccessToken(data.access_token, {
      expires: new Date(accessTokenExpiresIn)
    });
    this.setAccessTokenExpires(accessTokenExpiresIn, {
      expires: new Date(accessTokenExpiresIn)
    });

    this.setRefreshToken(data.refresh_token, {
      expires: new Date(refreshTokenExpiresIn)
    });
    this.setRefreshTokenExpires(refreshTokenExpiresIn, {
      expires: new Date(refreshTokenExpiresIn)
    });
  }

  clearAuthCookieData() {
    this.removeAccessToken();
    this.removeAccessTokenExpires();
    localStorage.removeItem('username');
    localStorage.removeItem('workspace');
  }
}
const instance = new AppStorage(Cookies);

export default instance;
