import { useResolverForm } from '@hooks';
import { IItemProps } from '@ui';
import { UIUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KForm, KGrid, KInput, KListItem } from 'uikit';
import * as yup from 'yup';

interface IProps extends IItemProps {
  onSuccess: (data: any) => void;
}

interface IFormData {
  isLimitedByOwner: boolean;
  limitedIp: string;
}

const schema = yup.object().shape({});

const Permission = ({ item, onSuccess }: IProps) => {
  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        isLimitedByOwner: item.isLimitedByOwner ?? false,
        limitedIp: item.limitedIp ?? ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...data,
        permissionId: item.permissionId
      };
      onSuccess(mParams);
      UIUtils.popup.dismiss();
    },
    [item.permissionId, onSuccess]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={4}>
          <KInput.TextField
            name="permissionGroup"
            label={trans('permission_group')}
            value={item?.permission?.permissionGroup ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="code"
            label={trans('code')}
            value={item?.permission?.code ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="name"
            label={trans('name')}
            value={item?.permission?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="limitedIp"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('limited_by_ip_address')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="isLimitedByOwner"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('limited_by_creator')}
                  checked={field.value}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid type="submit" title={trans('save')} />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(Permission);
