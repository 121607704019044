import {
  ArApType,
  ParentSource,
  SystemSettingCode,
  tableRef
} from '@constants';
import Emails from '@containers/Email';
import { useAccountingContext } from '@context/Accounting';
import {
  useCheckHasXero,
  useConnectXero,
  useFetchClient,
  useFetchSetting,
  useFetchXeroStatus,
  useFetchXeroTenantList,
  useMutateFetchPayments,
  useSyncXeroPayments
} from '@hooks';
import { XeroManager } from '@utils';
import { set } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KButton, KContainer, KGrid, KInput } from 'uikit';
import { useMount } from 'uikit-common';

import Client from './Details.Client';
import Statistic from './Details.Statistic';

import { OPTIONS } from '../helpers';
import Payment from '../Payment';
import Invoices from '../Statement.Invoices';

const Details = () => {
  const { clientId, tab, clearStatement, arApType } = useAccountingContext();

  const options = OPTIONS[arApType as ArApType];

  const { data: item } = useFetchClient(clientId);

  const { data: hasXero } = useCheckHasXero();

  const { data: isLoggedInXero, isLoading: xeroStatusLoading } =
    useFetchXeroStatus(hasXero);

  const { data: tenantList = [] } = useFetchXeroTenantList(isLoggedInXero);

  const { mutate: mutateConnect, isLoading: connectLoading } = useConnectXero();

  const { mutate: mutateSync, isLoading: syncLoading } = useSyncXeroPayments();

  const { data: invoiceProvider } = useFetchSetting(
    SystemSettingCode.InvoiceSyncType,
    false
  );

  const { mutateAsync: mutateFetchPayments, isLoading: paymentsLoading } =
    useMutateFetchPayments();

  const [tenantId, setTenantId] = useState<string | undefined>(undefined);

  const showXero = useMemo(
    () => invoiceProvider && hasXero && !xeroStatusLoading,
    [hasXero, invoiceProvider, xeroStatusLoading]
  );

  const onConnectXero = useCallback(() => {
    if (clientId && tab) {
      const returnUrl = XeroManager.prepareStatementLink(clientId, tab);
      mutateConnect(returnUrl, {
        onSuccess: _data => (window.location.href = _data)
      });
    }
  }, [clientId, mutateConnect, tab]);

  useMount(() => {
    return () => {
      clearStatement();
    };
  });

  const onBack = useCallback(() => {
    clearStatement();
  }, [clearStatement]);

  const onMutateFetchPayments = useCallback(() => {
    const mParams = {
      clientId,
      accountingType: arApType
    };
    return mutateFetchPayments(mParams);
  }, [arApType, clientId, mutateFetchPayments]);

  const onSyncXero = useCallback(async () => {
    const res = await onMutateFetchPayments();

    const tenant = tenantList.find(i => i.tenantId === tenantId);

    const mParams: any = {
      tenantId,
      cbData: {
        syncOrgId: tenantId,
        syncOrgName: tenant.tenantName,
        syncDate: new Date().toISOString().slice(0, 10)
      }
    };

    const syncData = XeroManager.preparePaymentData(res);

    if (syncData) {
      set(mParams, 'data.payments', syncData.payments);
      set(mParams, 'cbData.paymentIds', syncData.paymentIds);

      mutateSync(mParams, {
        onSuccess: () => {
          tableRef?.[options.webTable.invoice]?.init?.();
          tableRef?.[options.webTable.payment]?.init?.();
        }
      });
    }
  }, [
    mutateSync,
    onMutateFetchPayments,
    options.webTable.invoice,
    options.webTable.payment,
    tenantId,
    tenantList
  ]);

  const xeroButton = useCallback(() => {
    const _buttons: any[] = [];
    if (isLoggedInXero) {
      _buttons.push({
        id: 'sync',
        title: trans('sync_to', { name: invoiceProvider }),
        isLoading: paymentsLoading || syncLoading,
        onPress: onSyncXero,
        disabled: !tenantId
      });
    } else {
      _buttons.push({
        id: 'connect',
        title: trans('login_to', { name: invoiceProvider }),
        isLoading: connectLoading,
        onPress: onConnectXero
      });
    }

    return _buttons.map(i => {
      const { id: _btnId, ...r } = i;
      return <KButton.Solid key={_btnId} {...r} />;
    });
  }, [
    connectLoading,
    invoiceProvider,
    isLoggedInXero,
    onConnectXero,
    onSyncXero,
    paymentsLoading,
    syncLoading,
    tenantId
  ]);

  return (
    <KContainer.Card
      marginT="0.25rem"
      header={{
        icon: {
          icon: 'ArrowBack',
          onPress: onBack
          // size: 'md'
        },
        title: item?.code ?? trans('details'),
        rightNode: showXero ? (
          <KContainer.View row alignItems>
            {isLoggedInXero && (
              <KContainer.View width={150} marginR="0.75rem">
                <KInput.TextField
                  name="tenantId"
                  label={trans('organization')}
                  options={tenantList.map(i => ({
                    ...i,
                    key: i.tenantId,
                    label: i.tenantName
                  }))}
                  onChange={e => setTenantId(e.target.value)}
                />
              </KContainer.View>
            )}

            {xeroButton()}
          </KContainer.View>
        ) : undefined
      }}
    >
      <KGrid.Container alignItems="stretch">
        <KGrid.Item xs={12} sm={6} md={7}>
          <Client />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={6} md={5}>
          <Statistic />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Emails
            parentId={clientId}
            source={ParentSource.Statement}
            webTable={options.webTable.email}
            border
            header={{
              title: trans('emails'),
              border: true
            }}
            size="nm"
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Payment />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Invoices />
        </KGrid.Item>
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default memo(Details);
