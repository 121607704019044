import { Grid, GridProps } from '@material-ui/core';
import React, { memo } from 'react';

interface Props extends GridProps {}

const KGridItem = (props: Props) => {
  return <Grid {...props} />;
};

KGridItem.defaultProps = {
  item: true
};

export default memo(KGridItem);
