import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../core';

export const useCUDAutoRate = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.clientRate(':id/auto-rate', { id }),
    webTable: WebTable.CLIENT_RATE_AUTO_RATE
  });
};
