import { UIUtils } from '@utils';
import { omit } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import {
  KButton,
  KButtonProps,
  KColors,
  KContainer,
  KListItem,
  KListItemBaseItemProps,
  KSpacing,
  KSpacingValue
} from 'uikit';

interface IBtnProps extends KButtonProps {
  order?: number;
  isIcon?: boolean;
  isHorizontal?: boolean;
  extraPaddingEnd?: 0 | KSpacing;
}

interface IBtnContainerProps extends React.PropsWithChildren<IBtnProps> {}

interface IActionBtnProps extends IBtnProps {
  actions: KListItemBaseItemProps[];
}

const SEPARATOR = 48;
const INIT_SPACE = 24;
const WIDTH = 86;
const ICON_WIDTH = 32;

const useContainerStyle = (props: IBtnProps) => {
  const { isHorizontal, order = 0, extraPaddingEnd = 0, isIcon } = props;
  return {
    bottom: INIT_SPACE / 2 + (!isHorizontal ? order * SEPARATOR : 0),
    right:
      INIT_SPACE / 4 +
      (isHorizontal
        ? order * (isIcon ? ICON_WIDTH : WIDTH) +
          KSpacingValue['0.5rem'] +
          KSpacingValue[extraPaddingEnd]
        : KSpacingValue['0.25rem'])
  };
};

const BtnContainer = memo(
  ({ children, enhanceStyle, ...rest }: IBtnContainerProps) => {
    const containerStyle = useContainerStyle(rest);

    return (
      <KContainer.View
        style={{
          ...styles.wrapper,
          ...containerStyle,
          ...enhanceStyle
        }}
      >
        {children}
      </KContainer.View>
    );
  }
);

export const useNewButton = (props: IBtnProps) => {
  const { isIcon, enhanceStyle, ...rest } = props || {};

  const btn = useMemo(() => {
    return (
      <BtnContainer {...rest} enhanceStyle={enhanceStyle} isIcon={isIcon}>
        {isIcon ? (
          <KButton.Icon
            icon="Add"
            background={KColors.primary.normal}
            tintColor={KColors.white}
            hasShadow
            padding="0.25rem"
            br="x"
            tight
            size="xlg"
            {...rest}
          />
        ) : (
          <KButton.Outline
            icon="Add"
            title={trans('new')}
            kind="primary"
            hasShadow
            {...rest}
          />
        )}
      </BtnContainer>
    );
  }, [enhanceStyle, isIcon, rest]);

  return btn;
};

export const useSaveButton = (props: IBtnProps) => {
  const { isIcon, enhanceStyle, ...rest } = props || {};

  const btn = useMemo(() => {
    return (
      <BtnContainer {...rest} enhanceStyle={enhanceStyle} isIcon={isIcon}>
        {isIcon ? (
          <KButton.Icon
            icon="Save"
            type="submit"
            background={KColors.primary.normal}
            tintColor={KColors.white}
            hasShadow
            padding="0.25rem"
            br="x"
            {...rest}
          />
        ) : (
          <KButton.Solid
            icon="Save"
            type="submit"
            title={trans('save')}
            {...rest}
          />
        )}
      </BtnContainer>
    );
  }, [enhanceStyle, isIcon, rest]);

  return btn;
};

export const useActionsButton = (props: IActionBtnProps) => {
  const { isIcon, enhanceStyle, actions, ...rest } = props || {};

  const ref = React.createRef<HTMLButtonElement>();

  const onPress = useCallback(() => {
    UIUtils.popper.open({
      anchorEl: ref.current,
      touchOutsideToDismiss: true,
      padding: '0.5rem',
      style: { zIndex: 4 },
      cardProps: { marginB: '0.5rem' },
      ...omit(rest, ['isHorizontal', 'extraPaddingEnd']),
      placement: 'top-end',
      content: dismiss => {
        return (
          <KListItem.Base
            alignItems
            data={actions.map(i => ({
              ...i,
              onPress: () => {
                (i.onPress as any)?.();
                dismiss();
              }
            }))}
          />
        );
      }
    });
  }, [actions, ref, rest]);

  const btn = useMemo(() => {
    return (
      <BtnContainer {...rest} enhanceStyle={enhanceStyle} isIcon={isIcon}>
        {isIcon ? (
          <KButton.Icon
            icon="Menu"
            marginR="0.5rem"
            size="xlg"
            kind="primary"
            tight
            {...rest}
            ref={ref}
            onPress={onPress}
          />
        ) : (
          <KButton.Outline
            icon="Menu"
            title={trans('actions')}
            kind="primary"
            hasShadow
            {...rest}
            ref={ref}
            onPress={onPress}
          />
        )}
      </BtnContainer>
    );
  }, [enhanceStyle, isIcon, onPress, ref, rest]);

  return btn;
};

export const useToolsButton = (
  ref: React.RefObject<HTMLButtonElement>,
  data: KListItemBaseItemProps[]
) => {
  const onShowTools = useCallback(() => {
    UIUtils.popper.open({
      anchorEl: ref.current,
      touchOutsideToDismiss: true,
      placement: 'bottom-end',
      padding: '0.5rem',
      style: { zIndex: 4, marginTop: 4 },
      content: dismiss => {
        return (
          <KListItem.Base
            alignItems
            data={data.map(i => ({
              ...i,
              onPress: () => {
                (i.onPress as any)?.();
                dismiss();
              }
            }))}
          />
        );
      }
    });
  }, [data, ref]);

  return { onShowTools };
};

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    position: 'fixed',
    right: INIT_SPACE,
    zIndex: 10
  }
};
