import { DATE_FORMAT_SHORT, WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { Prototype } from '@core';
import { useFetchDashboardSTCWaitingTime, useLocalTable } from '@hooks';
import { ClickAwayListener } from '@material-ui/core';
import { onShowClient } from '@pages/Client/helpers';
import { onShowEquipment } from '@pages/Equipment/helpers';
import { onShowStaff } from '@pages/Staff/helpers';
import { onShowTransportJob } from '@pages/TransportJob/helpers';
import { TableUtils } from '@utils';
import React, { memo, useMemo, useRef, useState } from 'react';
import trans from 'translation';
import { KButton, KContainer, KLabel } from 'uikit';
import { KCalendar } from 'uikit-common';

const STCWaitingTime = () => {
  const calendarRef = useRef<any>();

  const [date, setDate] = useState(new Date());

  const { data, isLoading, refetch } = useFetchDashboardSTCWaitingTime(
    Prototype.date.formatDB(date as any)
  );

  const columns = useMemo(
    () => [
      {
        label: trans('container_number'),
        name: 'displayContainerNumber',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text
                  onPress={() => onShowTransportJob(item.transportJob?.id)}
                >
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('schedule_trip'),
        name: 'scheduleTrip',
        options: {
          customBodyRenderLite: (idx: number) => {
            const item = data?.[idx];
            if (item) {
              const start = Prototype.date.formatT(item.timeStart);
              const end = Prototype.date.formatT(item.timeEnd);
              return (
                <KLabel.Text>
                  {start} - {end}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('driver'),
        name: 'driverDisplayName',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text onPress={() => onShowStaff(item.driverId)}>
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('location'),
        name: 'locationDisplay',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text
                  onPress={() => onShowClient(item.locationClientId)}
                >
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('checkin_checkout'),
        name: 'checkInCheckOut',
        options: {
          customBodyRenderLite: (idx: number) => {
            const item = data?.[idx];
            if (item) {
              const checkIn = Prototype.date.formatT(
                item.actualCheckinDatetime
              );
              const checkOut = Prototype.date.formatT(
                item.actualCheckoutDatetime
              );
              return (
                <KLabel.Text>
                  {checkIn} - {checkOut}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('free_time'),
        name: 'freeTime',
        options: TableUtils.options.withUnit('mins')
      },
      {
        label: trans('waiting_time'),
        name: 'waitingTime',
        options: TableUtils.options.withUnit('mins')
      },
      {
        label: trans('routing_from'),
        name: 'jobContainerRoutingStartDisplay',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text
                  onPress={() =>
                    onShowClient(item.jobContainerRoutingStartClientId)
                  }
                >
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('routing_to'),
        name: 'jobContainerRoutingEndDisplay',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text
                  onPress={() =>
                    onShowClient(item.jobContainerRoutingEndClientId)
                  }
                >
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('vehicle'),
        name: 'truckDisplayName',
        options: {
          ...TableUtils.options.baseXLg,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text onPress={() => onShowEquipment(item.truckId)}>
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('trailer'),
        name: 'trailerDisplayName',
        options: {
          ...TableUtils.options.baseXLg,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            if (item) {
              return (
                <KLabel.Text onPress={() => onShowEquipment(item.trailerId)}>
                  {v}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('job_number'),
        name: 'transportJob',
        options: {
          ...TableUtils.options.baseMd,
          customBodyRender: (v: any) => {
            if (v) {
              return (
                <KLabel.Text onPress={() => onShowTransportJob(v.id)}>
                  {v.jobNumber}
                </KLabel.Text>
              );
            }
            return 'N/A';
          }
        }
      },
      {
        label: trans('job_type'),
        name: 'transportJob.jobType'
      },
      {
        label: trans('vessel'),
        name: 'transportJob.vessel.vessel',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('voyage'),
        name: 'transportJob.voyage'
      },
      {
        label: trans('eta'),
        name: 'transportJob.eta',
        options: TableUtils.options.date()
      },
      {
        label: trans('etd'),
        name: 'transportJob.etd',
        options: TableUtils.options.date()
      },
      {
        label: trans('imp_available_date'),
        name: 'transportJob.avail',
        options: TableUtils.options.date()
      },
      {
        label: trans('1st_free_date'),
        name: 'transportJob.firstFreeDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('imp_storage_start_date'),
        name: 'transportJob.stor',
        options: TableUtils.options.date()
      },
      {
        label: trans('discharge_date'),
        name: 'transportJob.firstDischargeDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('exp_receival_commencement_date'),
        name: 'transportJob.expRecvDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('cargo_cutoff_date'),
        name: 'transportJob.cutOffDate',
        options: TableUtils.options.date()
      }
    ],
    [data]
  );

  const table = useLocalTable({
    name: WebTable.OTHER,
    columns,
    data,
    otherOptions: {
      selectableRows: 'none',
      tableBodyMaxHeight: '35vh'
    },
    onRefresh: refetch
  });

  const isNow = useMemo(
    () =>
      Prototype.date.toMoment(date as any)?.isSame(Prototype.date.now(), 'd'),
    [date]
  );

  return (
    <KContainer.Card
      // height={450}
      marginT={0}
      header={{
        title: trans('schedule_trip_container_waiting_time'),
        typo: 'TextMdBold',
        rightNode: (
          <KContainer.View row alignItems>
            <KLabel.Text color={'#E91F63'} typo="TextMdNormal">
              {Prototype.date
                .toMoment(date as any)
                ?.format(
                  isNow ? `[Today] ${DATE_FORMAT_SHORT}` : DATE_FORMAT_SHORT
                )}
            </KLabel.Text>

            <KContainer.View style={{ position: 'relative' }}>
              <KButton.Outline
                icon={'EventNote'}
                title={trans('calendar')}
                onPress={() => calendarRef.current?.show()}
                marginL="1rem"
              />

              <KCalendar.Base
                ref={calendarRef}
                onChange={setDate}
                ClickAwayListener={ClickAwayListener}
              />
            </KContainer.View>
          </KContainer.View>
        )
      }}
    >
      <DataTable {...table} isLoading={isLoading} />
    </KContainer.Card>
  );
};

export default memo(STCWaitingTime);
