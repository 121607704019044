export const roundToTwo = num => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

export const formatNumber = (x: number | string, is2Digits = false): string => {
  if (x === '' || x === null || x === undefined || Number.isNaN(x)) {
    return '';
  }

  let result = roundToTwo(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (is2Digits) {
    const dotPos = result.indexOf('.');
    console.log(dotPos);
    if (dotPos === -1) {
      result += '.00';
    } else {
      const [p1, p2] = result.split('.');
      result = p1 + '.' + p2.padEnd(2, '0');
    }
  }

  return result;
};
