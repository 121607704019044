const cardBodyStyle = {
  cardBody: {
    padding: '0.438rem 0.9375rem 0.9375rem',
    flex: '1 1 auto',
    WebkitBoxFlex: '1',
    position: 'relative'
  },
  cardBodyNoHeader: {
    padding: '0.9375rem'
  },
  cardBodyPlain: {
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  cardBodyProfile: {
    marginTop: '15px'
  }
};

export default cardBodyStyle;
