import { ENDPOINTS, QUERY_KEYS, SystemSettingCode } from '@constants';
import { ISystemSettingParams } from '@request-dto';
import APIManager from '@services';
import { useQueryClient } from '@tanstack/react-query';

import { useMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchSetting = (
  code: SystemSettingCode,
  isJson: boolean = true
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.systemSetting, code],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.systemSetting('detail'),
        body: { code }
      });

      return res.data
        ? isJson
          ? JSON.parse(res.data.settingValue)
          : res.data.settingValue
        : null;
    },
    enabled: !!code
  });
};

export const useUpdateSetting = () => {
  const queryClient = useQueryClient();

  return useMutationEnhancer<any, ISystemSettingParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.systemSetting(':code', { code: data.code }),
        method: 'PUT',
        body: {
          settingValue: JSON.stringify(data.data)
        },
        showToast: true
      });

      return res.data;
    },
    onSuccess: (data, vars) => {
      if (data) {
        queryClient.invalidateQueries([QUERY_KEYS.systemSetting, vars.code]);
      }
    }
  });
};
