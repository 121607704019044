import constants from '@constants';

import {
  backgroundColor3Dark,
  grayColor,
  primaryBlack,
  primaryBlue,
  primaryBlueDark,
  primaryGreen,
  subGrey1,
  whiteColor
} from '../../material-dashboard-react';

const commonHeaderStyle = theme => ({
  wrapper: {
    backgroundColor:
      theme.palette.type === constants.THEME.LIGHT
        ? whiteColor
        : backgroundColor3Dark,
    borderRadius: '0px 0px 8px 8px',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px'
  },
  right: {
    display: 'flex'
  },
  avatar_wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > span': {
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '16px',
      color:
        theme.palette.type === constants.THEME.LIGHT
          ? primaryBlack
          : whiteColor,
      fontFamily: 'Roboto'
    }
  },
  avatar: {
    marginLeft: 8
  },
  icon_button: {
    marginRight: 8,
    '&:hover': {
      background: 'transparent'
    }
  },
  profileMenuList: {
    width: 215
  },
  profileMenuItem: {
    color:
      theme.palette.type === constants.THEME.LIGHT ? primaryBlack : whiteColor,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.005em',
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    whiteSpace: 'normal',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${grayColor[10]}`
    },
    '&:hover': {
      background: 'transparent'
    },
    '& > label': {
      fontWeight: 700
    }
  },
  profileMenuElem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0'
  },
  profileMenuIcon: {
    color: subGrey1,
    fontSize: 20
  },
  notificationHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  notificationItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 4,
    cursor: 'pointer'
  },
  notificationItemRead: {
    color: subGrey1
  },
  notificationItemContent: {
    flex: 1,
    marginLeft: 16,
    marginRight: 16
  },
  notificationItemTitle: {
    fontSize: 14,
    fontWeight: 500
  },
  notificationItemBody: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    marginTop: 8,
    marginBottom: 8
  },
  notificationItemTime: {
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'italic'
  },
  notificationItemTimeActive: {
    fontWeight: 500,
    color: primaryGreen
  },
  notificationItemDot: {
    width: 12,
    height: 12
  },
  notificationItemDotActive: {
    borderRadius: 6,
    backgroundColor: primaryGreen
  },
  notificationMenuList: {
    width: 400
  },
  notificationMenuElemHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  notificationMenuElem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  notificationMenuElemIcon: {
    marginRight: 16
  },
  notificationMenuElemTitle: {
    fontSize: 12,
    fontWeight: 500
  },
  notificationMenuElemBody: {
    marginTop: 8,
    marginBottom: 8
  },
  notificationMenuElemTime: {
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'italic'
  },
  link_text: {
    color:
      theme.palette.type === constants.THEME.LIGHT
        ? primaryBlue
        : primaryBlueDark
  },
  userTitle: {
    color: primaryGreen,
    background: 'rgba(19, 168, 158, 0.1)',
    // height: 20,
    fontSize: 12,
    lineHeight: '143%',
    letterSpacing: '0.15px',
    border: `1px solid ${primaryGreen}`,
    padding: 4,
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  }
});

export default commonHeaderStyle;
