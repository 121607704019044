import constants from '@constants';
import { createTheme, darken, lighten } from '@material-ui/core/styles';
import { KColors, KFontWeights } from 'uikit';

export const generateMaterialColor = color => {
  return {
    200: lighten(color, 0.4),
    400: lighten(color, 0.25),
    500: color,
    600: darken(color, 0.15)
  };
};

export const buildTheme = appearance => {
  const isLight = appearance === constants.THEME.LIGHT;

  return createTheme({
    spacing: 4,
    palette: {
      type: appearance,
      primary: {
        main: KColors.primary.normal,
        dark: KColors.primary.dark
      },
      secondary: {
        main: KColors.secondary.normal,
        dark: KColors.secondary.dark
      },
      error: {
        main: KColors.secondary.normal,
        dark: KColors.secondary.dark
      },
      success: {
        main: KColors.primary.normal,
        dark: KColors.primary.dark
      },
      text: {
        // main: Colors.text,
        primary: KColors.black
      },
      action: {
        hoverLighten: 0.8,
        activatedOpacity: 0.12
      }
    },
    typography: {
      fontFamily: 'Roboto',
      fontSize: 12,
      letterSpacing: '0.03333em'
    },
    overrides: {
      MUIDataTableToolbar: {
        root: {
          minHeight: '48px'
        },
        actions: {
          display: 'flex',
          flex: 'initial',
          // retarget toolbar icons
          // move all icons to the right
          '& > span, & > button': {
            order: 99
          },
          // arrange common icons
          // Download Icon
          '& > span:nth-child(2), & > button:nth-child(2)': {
            order: 1
          },
          // Refresh Icon
          '& > span:nth-child(3), & > button:nth-child(3)': {
            order: 2
          },
          // View Column Icon
          '& > span:nth-child(1), & > button:nth-child(1)': {
            order: 3,
            display: 'inline-flex'
          },
          // Create New Button Icon
          '& > span:nth-child(4), & > button:nth-child(4)': {
            order: 4
          }
        }
      },
      MUIDataTableHeadCell: {
        sortAction: {
          alignItems: 'center'
        }
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          padding: 0
        }
      },
      MUIDataTable: {
        responsiveBase: {
          zIndex: 0
        }
      },
      MuiTablePagination: {
        root: {
          overflow: 'hidden',
          '&:last-child': {
            padding: '0 1rem 0 1rem'
          }
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 15px',
          letterSpacing: '0.01071em'
        },
        footer: {
          borderBottom: 'none'
        }
      },
      MuiSelect: {
        selectMenu: {
          fontSize: 12,
          height: '1.1876em'
        },
        select: {
          paddingTop: 9.5,
          paddingBottom: 11.5
        },
        icon: {
          top: 8
        }
      },
      MuiOutlinedInput: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#EEEEEE'
          }
        },
        input: {
          fontSize: 12,
          padding: '10.5px 12px'
        },
        adornedEnd: {
          paddingRight: '0.75rem'
        }
      },
      MuiInputLabel: {
        root: {
          fontSize: 12
        },
        marginDense: {
          transform: 'translate(12px, 12px) scale(1)'
        },
        asterisk: {
          color: KColors.secondary.normal
        },
        shrink: {
          color: KColors.black,
          fontWeight: 500
        },
        outlined: {
          transform: 'translate(12px, 12px) scale(1) !important',

          '&.MuiInputLabel-shrink': {
            transform: 'translate(12px, -6px) scale(0.8) !important'
          }
        }
      },
      MuiIcon: {
        colorPrimary: {
          color: KColors.primary.normal
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: '1.25rem'
        },
        colorPrimary: {
          color: KColors.primary.normal
        },
        fontSizeSmall: {
          fontSize: '1.125rem'
        }
      },
      MuiCheckbox: {
        root: {
          color: isLight ? KColors.black : KColors.white,
          padding: '6px !important'
        },
        colorSecondary: {
          color: `${isLight ? KColors.black : KColors.white}!important`
        }
      },
      MuiRadio: {
        root: {
          color: isLight ? KColors.black : KColors.white
        },
        colorSecondary: {
          color: `${isLight ? KColors.black : KColors.white}!important`
        }
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: '-9px',
          marginRight: 0
        },
        label: {
          fontSize: 12
        },
        labelPlacementStart: {
          marginLeft: 0
        }
      },
      MuiListItem: {
        root: {
          fontSize: 12,
          '&$selected, &$selected:hover': {
            backgroundColor: KColors.primary.normal,
            color: KColors.white
          }
        }
      },
      MuiMenuItem: {
        root: {
          fontSize: 12
        }
      },
      MuiListItemText: {
        primary: {
          fontSize: 12,
          fontWeight: 500
        },
        secondary: {
          fontSize: '0.625rem',
          fontStyle: 'italic',
          lineHeight: '24px'
        }
      },
      MuiTab: {
        root: {
          '&$selected': {
            color: isLight ? KColors.black : KColors.white
          },
          '@media (min-width: 600px)': {
            minWidth: '100px'
          }
        },
        wrapper: {
          fontSize: 12,
          letterSpacing: '0.02857em'
        },
        textColorPrimary: {
          '&$selected': {
            color: isLight ? KColors.black : KColors.white
          }
        }
      },
      MuiTabs: {
        root: {
          overflow: 'auto'
        },
        indicator: {
          backgroundColor: KColors.primary.normal
        }
      },
      MuiButton: {
        root: {
          fontSize: '0.75rem !important',
          letterSpacing: 'normal !important'
        },
        iconSizeMedium: {
          '& > :first-child': {
            fontSize: 20
          }
        },
        contained: {
          backgroundColor: KColors.primary.normal
        },
        containedPrimary: {
          backgroundColor: KColors.primary.normal
        },
        containedSecondary: {
          backgroundColor: '#D4483E'
        },
        textPrimary: {
          color: `${KColors.primary.normal} !important`
        }
      },
      MuiToolbar: {
        root: {
          marginRight: '-6px'
        },
        gutters: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          '& > label': {
            flexGrow: 1,
            fontSize: 15,
            fontWeight: 500
          },
          '& .MuiIconButton-root': {
            padding: '6px !important'
          }
        }
      },
      MuiAvatar: {
        root: {
          width: 36,
          height: 36
        }
      },
      MuiTypography: {
        subtitle1: {
          fontWeight: 500
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 12,
          fontWeight: 'normal'
        }
      },
      MuiDialogContent: {
        dividers: {
          padding: '1rem'
        }
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
          marginRight: 0
        }
      },
      MuiPaginationItem: {
        page: {
          '&$selected': {
            backgroundColor: KColors.primary.normal,
            color: KColors.white,
            fontWeight: KFontWeights.bold
          }
        }
      },
      MuiChip: {
        root: {
          height: 24
        },
        deleteIcon: {
          width: 20,
          height: 20,
          marginRight: 2
        },
        colorSecondary: {
          backgroundColor: KColors.secondary.normal
        }
      }
    }
  });
};
