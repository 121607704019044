import constants, {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  ParentSource,
  QUERY_KEYS,
  UserDataGroup,
  WebTable,
  tableRef
} from '@constants';
import {
  IScheduleContainerTmpSetLabelParams,
  IScheduleTmpAddAnotherContainerParams,
  IScheduleTmpChangeReadyDateParams,
  IScheduleTmpEditPlanParams,
  IScheduleTmpSetLabelParams,
  IScheduleTmpSetOverMassParams,
  IScheduleTmpUpdatePositionParams
} from '@request-dto';
import APIManager from '@services';
import { UIUtils } from '@utils';
import { isEmpty } from 'lodash';

import {
  useCUDMutationEnhancer,
  useGenericMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchScheduleTripTmpList = (mParams?: any) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.scheduleTripTmpList, JSON.stringify(mParams)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripTmp(),
        body: {
          ...mParams
        }
      });

      return res.data;
    }
  });
};

export const useScheduleTmpChangeReadyDate = () => {
  return useMutationEnhancer<
    boolean | undefined,
    IScheduleTmpChangeReadyDateParams
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripTmp(':id/update-time', { id: data.id }),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_TRIP_TMP]?.init?.();
      }
    }
  });
};

export const useScheduleTmpUpdatePosition = () => {
  return useMutationEnhancer<
    boolean | undefined,
    IScheduleTmpUpdatePositionParams
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripTmp('update-position'),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_TRIP_TMP]?.init?.();
      }
    }
  });
};

export const useFetchScheduleTripContainerTmp = (id?: number) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.scheduleTripContainerTmp, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripContainerTmp(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useScheduleTmpEditPlan = (
  id: number,
  scheduleTripContainerTmpId: number
) => {
  return useGenericMutationEnhancer<IScheduleTmpEditPlanParams>({
    endPoint: ENDPOINTS.scheduleTripTmp(
      ':id/schedule-trip-container/:scheduleTripContainerTmpId',
      {
        id,
        scheduleTripContainerTmpId
      }
    ),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_TRIP_TMP,
    noDismiss: true
  });
};

export const useScheduleTmpResetPlan = (id: number) => {
  return useGenericMutationEnhancer<any>({
    endPoint: `${ENDPOINTS.scheduleTripTmp(':id/schedule-trip-container', {
      id
    })}/:id/reset`,
    method: 'PUT',
    webTable: WebTable.SCHEDULE_TRIP_TMP
  });
};

export const useScheduleTmpApprovePlan = (tmpId: number) => {
  return useMutationEnhancer<boolean | undefined, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripTmp(
          ':id/schedule-trip-container/:containerId/approve',
          {
            id: tmpId,
            containerId: data.id
          }
        ),
        method: 'PUT',
        body: data,
        showToast: true
      });

      if (res.data?.id && !isEmpty(res.data?.scheduleTripContainers)) {
        await Promise.all(
          res.data?.scheduleTripContainers?.map(async (i: any) => {
            const edocRes = await APIManager.request({
              url: ENDPOINTS.edoc(':source', {
                source: ParentSource.TransportJob
              }),
              body: {
                sourceId: i.transportJobId,
                dataGroup: UserDataGroup.Driver,
                childSourceId: i.jobContainerId,
                childSource: constants.SOURCE_TYPE.JOB_CONTAINER,
                includeParent: true,
                size: PAGE_SIZE_DEFAULT_MAX
              }
            });

            if (!isEmpty(edocRes.data?.data)) {
              await APIManager.request({
                method: 'PUT',
                url: ENDPOINTS.scheduleTripContainer('update-document'),
                body: {
                  id: i.id,
                  jobEdocIds: edocRes.data?.data?.map((e: any) => e.id) ?? []
                }
              });
            }
          })
        );
      }

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_TRIP_TMP]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useScheduleTmpAddAnotherContainer = (id: number) => {
  return useGenericMutationEnhancer<IScheduleTmpAddAnotherContainerParams>({
    endPoint: ENDPOINTS.scheduleTripTmp(':id/attach', { id }),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_TRIP_TMP
  });
};

export const useScheduleTmpSetOverMass = (id: number) => {
  return useGenericMutationEnhancer<IScheduleTmpSetOverMassParams>({
    endPoint: ENDPOINTS.scheduleTripTmp(':id/update-over-mass', { id }),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_TRIP_TMP
  });
};

export const useCUDScheduleContainerTmpLabel = (
  id: number,
  containerId: number
) => {
  return useCUDMutationEnhancer<IScheduleContainerTmpSetLabelParams>({
    endPoint: ENDPOINTS.scheduleTripTmp(
      ':id/schedule-trip-container/:containerId/label',
      { id, containerId }
    ),
    webTable: WebTable.SCHEDULE_TRIP_TMP,
    putWithoutId: true
  });
};

export const useCUDConnectionTripTmp = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.scheduleTripTmp(),
    webTable: WebTable.SCHEDULE_TRIP_TMP
  });
};

export const useCUDConnectionTripTmpLabel = (id: number) => {
  return useCUDMutationEnhancer<IScheduleTmpSetLabelParams>({
    endPoint: ENDPOINTS.scheduleTripTmp(':id/label', { id }),
    webTable: WebTable.SCHEDULE_TRIP_TMP,
    putWithoutId: true
  });
};

export const useScheduleTmpApproveConnectionTrip = () => {
  return useGenericMutationEnhancer<any>({
    endPoint: ENDPOINTS.scheduleTripTmp(':id/approve', { id: undefined }),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_TRIP_TMP
  });
};

export const useFetchScheduleNoteTmpList = (date: string) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.scheduleTripTmpNoteList, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripTmp('note'),
        body: {
          source: 'SCHEDULETRIPTMP',
          sourceId: date
        }
      });

      return res.data?.data;
    },
    enabled: !!date
  });
};

export const useCUDScheduleNoteTmp = (noDismiss: boolean = true) => {
  return useCUDMutationEnhancer<any>({
    mutationKeys: [[QUERY_KEYS.scheduleTripTmpNoteList]],
    endPoint: ENDPOINTS.scheduleTripTmp('note'),
    putWithoutId: true,
    noDismiss
  });
};

export const useFetchScheduleNoteTmpListSignals = (dates: string[]) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.scheduleTripTmpNoteList, JSON.stringify(dates)],
    queryFn: async () => {
      const requests: Promise<any>[] = [];
      dates.forEach(d => {
        const dRes = APIManager.request({
          url: ENDPOINTS.scheduleTripTmp('note'),
          body: {
            source: 'SCHEDULETRIPTMP',
            sourceId: d,
            size: 1
          }
        });
        requests.push(dRes);
      });
      const res = await Promise.all(requests);

      const data = res.map(i => i?.data?.data?.[0]);

      return data;
    },
    enabled: !isEmpty(dates)
  });
};
