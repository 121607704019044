import { OneStopCategory, SystemSettingCode } from '@constants';
import { useFetchSetting, useResolverForm, useUpdateSetting } from '@hooks';
import { ValidationUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

interface IProps {
  hasHeight?: boolean;
}

interface IFormData {
  username: string;
  password: string;
  senders: any;
}

const schema = yup.object().shape({
  username: ValidationUtils.required(),
  password: ValidationUtils.required()
});

const OneStopSetting = ({ hasHeight }: IProps) => {
  const { data: setting, isLoading } = useFetchSetting(
    SystemSettingCode.OneStop
  );

  const { mutate, isLoading: updateLoading } = useUpdateSetting();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        username: setting?.username ?? '',
        password: setting?.password ?? '',
        senders: Object.values(OneStopCategory).reduce(
          (prev: any, curr: string) => {
            prev[curr] = setting?.senders?.[curr]?.join(', ') ?? '';
            return prev;
          },
          {} as any
        )
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        data: {
          ...setting,
          ...data,
          senders: Object.values(OneStopCategory).reduce(
            (prev: any, curr: string) => {
              prev[curr] = data.senders[curr]
                .split(',')
                .map((i: any) => i.trim());
              return prev;
            },
            {}
          )
        },
        code: SystemSettingCode.OneStop
      };

      mutate(mParams);
    },
    [mutate, setting]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KContainer.Card
        header={{
          icon: 'DeviceHubOutlined',
          title: trans('one_stop_setting'),
          rightNode: (
            <KButton.Icon icon="Save" type="submit" kind="primary" size="md" />
          )
        }}
        isLoading={isLoading || updateLoading}
      >
        <KContainer.View height={hasHeight ? 272 : undefined}>
          <KGrid.Container>
            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="username"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('username')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="password"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.Password
                      {...field}
                      label={trans('password')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            {Object.values(OneStopCategory).map(i => {
              return (
                <KGrid.Item xs={12} key={i}>
                  <Controller
                    control={methods.control}
                    name={`senders.${i}`}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.TextField
                          {...field}
                          label={
                            trans('senders') +
                            ' - ' +
                            trans(`option.${i.toLowerCase()}`)
                          }
                          // required
                          message={error?.message}
                        />
                      );
                    }}
                  />
                </KGrid.Item>
              );
            })}
          </KGrid.Container>
        </KContainer.View>
      </KContainer.Card>
    </KForm>
  );
};

export default memo(OneStopSetting);
