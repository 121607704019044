import { agTableRef, tableRef } from '@constants';
import { IUseAgTableResult, UseDataTableResult } from '@hooks-dto';
import { set, unset } from 'lodash';
import { useEffect } from 'react';
import { useMount } from 'uikit-common';

export const useGlobalTable = (
  table: UseDataTableResult<any>,
  unmount: boolean = true
) => {
  useMount(() => {
    return () => {
      if (unmount) {
        unset(tableRef, table.name);
      }
    };
  });

  useEffect(() => {
    set(tableRef, table.name, table);
  }, [table, unmount]);
};

export const useAgGlobalTable = (
  table: IUseAgTableResult<any>,
  unmount: boolean = true
) => {
  useMount(() => {
    return () => {
      if (unmount) {
        unset(agTableRef, table.name);
      }
    };
  });

  useEffect(() => {
    set(agTableRef, table.name, table);
  }, [table, unmount]);
};
