const Spacing = {
  s0: 0,
  minusS72: -72,
  s1: 1,
  s2: 2,
  s4: 4,
  s6: 6,
  s8: 8,
  s9: 9,
  s10: 10,
  s11: 11,
  s12: 12,
  s13: 13,
  s14: 14,
  s15: 15,
  s16: 16,
  s17: 17,
  s18: 18,
  s20: 20,
  s22: 22,
  s24: 24,
  s25: 25,
  s26: 26,
  s28: 28,
  s30: 30,
  s32: 32,
  s34: 34,
  s35: 35,
  s36: 36,
  s38: 38,
  s40: 40,
  s42: 42,
  s48: 48,
  s50: 50,
  s52: 52,
  s54: 54,
  s56: 56,
  s60: 60,
  s64: 64,
  s68: 68,
  s70: 70,
  s72: 72,
  s80: 80,
  s86: 86,
  s90: 90,
  s94: 94,
  s100: 100,
  s104: 104,
  s108: 108,
  s120: 120,
  s124: 124,
  s126: 126,
  s130: 130,
  s136: 136,
  s140: 140,
  s150: 150,
  s160: 160,
  s170: 170,
  s180: 180,
  s190: 190,
  s200: 200,
  s270: 270,
  s300: 300,
  s330: 330,
  s350: 350,
  s362: 362
};

export default Spacing;
