import { DATE_FORMAT_SHORT } from '@constants';
import { Prototype } from '@core';
import { ClickAwayListener } from '@material-ui/core';
import React, { memo, useMemo, useRef, useState } from 'react';
import trans from 'translation';
import { KButton, KContainer, KLabel, KTab } from 'uikit';
import { KCalendar } from 'uikit-common';

import ClientRate from './Issues.ClientRate';
import Driver from './Issues.Driver';
import Equipment from './Issues.Equipment';

import { useDashboardIssues } from '../helpers';

const Issues = () => {
  const calendarRef = useRef<any>();

  const [tabIndex, setTabIndex] = useState(0);
  const [date, setDate] = useState(new Date());

  const isNow = useMemo(
    () =>
      Prototype.date.toMoment(date as any)?.isSame(Prototype.date.now(), 'd'),
    [date]
  );

  const { tabs } = useDashboardIssues();

  return (
    <KContainer.Card
      height={450}
      marginT={0}
      paddingT="0.75rem"
      dp="flex"
      header={{
        title: trans('issues'),
        typo: 'TextMdBold',
        rightNode: (
          <KContainer.View row alignItems>
            <KLabel.Text color={'#E91F63'} typo="TextMdNormal">
              {Prototype.date
                .toMoment(date as any)
                ?.format(
                  isNow ? `[Today] ${DATE_FORMAT_SHORT}` : DATE_FORMAT_SHORT
                )}
            </KLabel.Text>

            <KContainer.View style={{ position: 'relative' }}>
              <KButton.Outline
                icon={'EventNote'}
                title={trans('calendar')}
                onPress={() => calendarRef.current?.show()}
                marginL="1rem"
              />

              <KCalendar.Base
                ref={calendarRef}
                onChange={setDate}
                ClickAwayListener={ClickAwayListener}
              />
            </KContainer.View>
          </KContainer.View>
        )
      }}
    >
      <KContainer.View>
        <KTab.Solid
          marginB={'0.5rem'}
          marginT={0}
          //   @ts-ignore
          marginH={-16}
          height={40}
          tabs={tabs as any[]}
          onChangeTab={setTabIndex}
        />
      </KContainer.View>

      <KContainer.View dp="flex" flex overflow>
        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={tabs?.[tabIndex]?.code === 'EQUIPMENT'}
          >
            <Equipment date={Prototype.date.formatDB(date as any)} />
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={tabs?.[tabIndex]?.code === 'DRIVER'}
          >
            <Driver date={Prototype.date.formatDB(date as any)} />
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={tabs?.[tabIndex]?.code === 'CLIENT_RATE'}
          >
            <ClientRate date={Prototype.date.formatDB(date as any)} />
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(Issues);
