import { ENDPOINTS } from '@constants';
import { INoteOptions } from '@ui';

import { useCUDMutationEnhancer } from '../core';

export const useCUDAlert = (options: INoteOptions) => {
  const { source, ...rest } = options;
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.alert(':source', {
      source: source
    }),
    ...rest
  });
};
