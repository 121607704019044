import { Prototype } from '@core';

export default class FormatterUtils {
  static equipment = (o: any) =>
    [
      o.name,
      o.equipmentTareWeight
        ? Prototype.number.formatNumber(o.equipmentTareWeight, {
            revert: true,
            unit: 'Kg',
            ignoreUnit: false
          })
        : ''
    ]
      .filter(i => i)
      .join(' - ');

  static staff = (o: any) =>
    [o.displayName, o.email].filter(i => i).join(' - ');
}
