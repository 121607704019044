import { IAdvanceSearch, ISearchCondition } from '@dto';
import loadable from '@loadable/component';
import { DataTableButtonProps } from '@ui';
import { UIUtils } from '@utils';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KInput } from 'uikit';

const Filter = loadable(() => import('./Filter'));

interface Props {
  id?: string;
  keyword?: string;
  showFilter?: boolean;
  advanceSearch: IAdvanceSearch[];
  searchConditions: ISearchCondition[];
  selectedData: any[];
  status?: boolean;
  showStatus?: boolean;
  timeRange?: number;
  showTimeRange?: boolean;

  leftButtons?: DataTableButtonProps[];
  rightButtons?: DataTableButtonProps[];
  rightNode?: JSX.Element;

  onAdd?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  setKeyword?: (keyword: string) => void;
  setSearchConditions: (searchCondition: any[]) => void;
  setStatus?: (status: boolean) => void;
  setTimeRange?: (timeRange: number) => void;
}

const LeftActions = (props: Props) => {
  const {
    id,
    keyword,
    showFilter,
    advanceSearch,
    searchConditions,
    selectedData,
    showStatus,
    showTimeRange,
    status,
    timeRange,

    leftButtons,
    rightButtons,
    rightNode,

    setKeyword,
    setSearchConditions,
    setStatus,
    setTimeRange,

    onAdd,
    onCopy,
    onDelete
  } = props;

  const ref = React.createRef<HTMLButtonElement>();

  const count = useMemo(
    () => searchConditions.length,
    [searchConditions.length]
  );

  const renderLeftButtons = useMemo(() => {
    if (!leftButtons) {
      return null;
    }

    return leftButtons.map(i => {
      const { id: btnId, type, props: btnProps } = i;
      const Wrapper =
        type === 'icon'
          ? KButton.Icon
          : type === 'outline'
            ? KButton.Outline
            : type === 'transparent'
              ? KButton.Transparent
              : KButton.Solid;

      return <Wrapper key={`${btnId}-${id}`} {...btnProps} marginR="0.5rem" />;
    });
  }, [leftButtons, id]);

  const renderRightButtons = useMemo(() => {
    if (rightNode) {
      return rightNode;
    }

    if (!rightButtons) {
      return null;
    }

    return rightButtons.map((i, idx) => {
      const marginL = idx === 0 && !showFilter ? 0 : '0.5rem';
      const { id: btnId, type, props: btnProps } = i;
      const Wrapper =
        type === 'icon'
          ? KButton.Icon
          : type === 'outline'
            ? KButton.Outline
            : type === 'transparent'
              ? KButton.Transparent
              : KButton.Solid;

      return <Wrapper key={`${btnId}-${id}`} {...btnProps} marginL={marginL} />;
    });
  }, [id, rightButtons, rightNode, showFilter]);

  const onChangeKeyword = useCallback(
    (e: any) => {
      setKeyword?.(e?.target?.value ?? '');
    },
    [setKeyword]
  );

  const onFilter = useCallback(() => {
    UIUtils.popper.dismiss();
    setTimeout(
      () =>
        UIUtils.popper.open({
          anchorEl: ref.current,
          placement: showStatus || showTimeRange ? 'bottom' : 'bottom-start',
          content: dismiss => (
            <Filter
              dismiss={dismiss}
              advanceSearch={advanceSearch}
              searchConditions={searchConditions}
              onSubmit={setSearchConditions}
            />
          ),
          maxW: 700,
          style: { zIndex: 5 }
        }),
      100
    );
  }, [
    advanceSearch,
    ref,
    searchConditions,
    setSearchConditions,
    showStatus,
    showTimeRange
  ]);

  return (
    <KContainer.View row alignItems>
      {onAdd && (
        <KButton.Icon
          key={`add-${id}`}
          icon="AddBox"
          onPress={onAdd}
          marginR="0.5rem"
          size="xlg"
          kind="primary"
          tight
          // @ts-ignore
          marginL={-4}
        />
      )}

      {renderLeftButtons}

      {selectedData.length > 0 && onCopy && (
        <KButton.Icon
          key={`copy-${id}`}
          icon="FileCopyOutlined"
          onPress={onCopy}
          marginR="0.5rem"
          br="x"
          brC={KColors.primary.normal}
          brW={1}
          negativePadding="0.25rem"
        />
      )}

      {selectedData.length > 0 && onDelete && (
        <KButton.Icon
          key={`delete-${id}`}
          icon="Delete"
          onPress={onDelete}
          marginR="0.5rem"
          br="x"
          brC={KColors.primary.normal}
          brW={1}
          negativePadding="0.25rem"
        />
      )}

      {showStatus && (
        <KButton.Solid
          key={`status-${id}`}
          title={trans('unclosed')}
          onPress={() => setStatus?.(!status)}
          hasShadow={false}
          background={status ? '#13A8A82B' : '#D8D8D8'}
          icon={status ? 'CheckRounded' : undefined}
          textColor={status ? KColors.black : '#707277'}
          iconColor={KColors.primary.normal}
          marginR="0.5rem"
        />
      )}

      {showTimeRange && (
        <KInput.TextField
          key={`timeRange-${id}`}
          name="timeRange"
          value={timeRange}
          options={[
            { key: 30, label: trans('30days') },
            { key: 60, label: trans('60days') },
            { key: 90, label: trans('90days') },
            { key: 365, label: trans('1year') }
          ]}
          onChange={e => setTimeRange?.(parseInt(e.target.value))}
          fullWidth={false}
          minW={140}
          marginR="0.5rem"
        />
      )}

      <KInput.Search
        key={`keyword-${id}`}
        name="keyword"
        placeholder={trans('search_placeholder')}
        value={keyword}
        onChange={onChangeKeyword}
        marginR="0.5rem"
        minW={200}
        fullWidth={false}
      />

      {showFilter && (
        <KButton.Outline
          key={`filter-${id}`}
          ref={ref}
          icon="FilterList"
          aria-haspopup="true"
          onPress={onFilter}
          title={trans('filter') + (count > 0 ? ` (${count})` : '')}
          kind={count > 0 ? 'primary' : 'normal'}
        />
      )}

      {count > 0 && (
        <KButton.Outline
          key={`filter-count-${id}`}
          kind="secondary"
          icon="Close"
          onPress={setSearchConditions.bind(null, [])}
          title={trans('reset_filter')}
          marginL="0.5rem"
        />
      )}

      {renderRightButtons}
    </KContainer.View>
  );
};

export default memo(LeftActions);
