import {
  ENDPOINTS,
  QUERY_KEYS,
  StorageKeys,
  UIMenuItem,
  updateAuthorizationToken
} from '@constants';
import { NotificationManager, AppStorage } from '@core';
import { auth } from '@reducers';
import {
  ISignInParams,
  IRootSignInParams,
  IForgotPasswordParams,
  ISignUpParams,
  IResetPasswordParams
} from '@request-dto';
import {
  IRootSignInResponse,
  ISignInResponse,
  IUserResponse
} from '@response-dto';
import APIManager from '@services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import appStorage from '@utils/appStorage';
import StorageEnhance, { STORAGE_KEYS } from '@utils/storage';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useMutationEnhancer } from '../core';
import { useLogoutXero } from '../xero';

export const useUser = () => {
  const dispatch = useDispatch();

  const { data: user, ...rest } = useQuery<IUserResponse | null>({
    queryKey: [QUERY_KEYS.user],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.user('current-profile')
      });

      if (res.data) {
        const data = res.data;
        NotificationManager.setExternalUserId(`${data.id}`);
        AppStorage.set(StorageKeys.user, res);
        dispatch(auth.actions.getAuthProfileSuccess(data));

        const storageUser = StorageEnhance.get(STORAGE_KEYS.user);
        const newUser = {
          ...storageUser,
          account: {
            ...storageUser?.account,
            ...data
          }
        };
        StorageEnhance.set(STORAGE_KEYS.user, newUser);

        return newUser;
      }
      return null;
    },
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: StorageEnhance.get(STORAGE_KEYS.user),
    onError: () => {
      StorageEnhance.delete(STORAGE_KEYS.user);
    }
  });

  return {
    user,
    account: user?.account,
    ...rest,
    isLoading: rest.isLoading && rest.isFetching
  };
};

export const useUserId = () => {
  const { account } = useUser();

  return account?.id;
};

export const useRootSignIn = () => {
  return useMutationEnhancer<IRootSignInResponse, IRootSignInParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.common('account/sign-in'),
        method: 'POST',
        body: data
      });

      return res.data;
    },
    onSuccess: data => {
      const workspace = data?.account?.company?.workspace;
      if (workspace) {
        window.location.href = `http://${workspace}.${process.env.REACT_APP_BASE_DOMAIN}/auth/sign-in`;
      }
    }
  });
};

export const useSignIn = () => {
  const { refetch } = useUser();

  return useMutationEnhancer<ISignInResponse, ISignInParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.common('user/sign-in'),
        method: 'POST',
        body: data
      });

      return res.data;
    },
    onSuccess: data => {
      if (data?.token) {
        appStorage.setAccessToken(data.token);
        updateAuthorizationToken(data.token);
        StorageEnhance.set(STORAGE_KEYS.user, data);
        refetch();
      }
    }
  });
};

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: mutateXero } = useLogoutXero();

  return useMutationEnhancer({
    mutationFn: () => {
      return Promise.resolve(true);
    },
    onSuccess: () => {
      mutateXero();
      NotificationManager.removeExternalUserId();

      setTimeout(() => {
        appStorage.clearAuthCookieData();
        dispatch(auth.actions.authUserCleanUp());
        updateAuthorizationToken('');
        StorageEnhance.delete(STORAGE_KEYS.user);
        queryClient.setQueryData([QUERY_KEYS.user], null);
      }, 0);
    }
  });
};

export const useCheckScreenWithCallback = (
  cb: () => any[],
  menuItem?: UIMenuItem
) => {
  const { account } = useUser();

  const _tabs = useMemo(() => {
    if (!!account?.isFullRole || !menuItem) {
      return cb();
    } else {
      return cb().filter(i =>
        (account?.userScreens ?? []).some(
          s => s.screen.menuItem === menuItem && s.screen.code === i.code
        )
      );
    }
  }, [account?.isFullRole, account?.userScreens, cb, menuItem]);

  return _tabs;
};

export const useForgotPassword = () => {
  return useMutationEnhancer<boolean | undefined, IForgotPasswordParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.user('forget-password'),
        method: 'POST',
        body: data
      });

      return res.success;
    }
  });
};

export const useResetPassword = () => {
  return useMutationEnhancer<boolean | undefined, IResetPasswordParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.user('reset-password'),
        method: 'POST',
        body: data
      });

      return res.success;
    }
  });
};

export const useSignUp = () => {
  return useMutationEnhancer<boolean | undefined, ISignUpParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.common('company/sign-up'),
        method: 'POST',
        body: data,
        showToast: true
      });

      return res.success;
    }
  });
};
