import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { cloneDeep } from 'lodash';
import { Moment } from 'moment-timezone';
import React, { forwardRef, memo } from 'react';

import { KColors } from '../../constants';
import KInput from '../Input';
import { KPickerProps } from '../types';

interface Props
  extends Omit<DatePickerProps<any, Moment>, 'renderInput' | 'value'>,
    KPickerProps {}

const KDatePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value,
    name,
    message,
    onBlur,
    required,
    startAdornment,
    endAdornment,
    ...rest
  } = props;

  return (
    <DatePicker
      inputRef={ref}
      {...rest}
      value={value}
      renderInput={({ inputRef, inputProps, InputProps }) => {
        const _InputProps = InputProps ? cloneDeep(InputProps) : {};
        _InputProps.endAdornment = endAdornment;
        _InputProps.startAdornment = startAdornment;

        return (
          <KInput.TextField
            ref={inputRef}
            inputProps={inputProps}
            // @ts-ignore
            InputProps={InputProps}
            name={name}
            placeholder="DD/MM/YYYY"
            label={rest.label}
            message={message}
            onBlur={onBlur}
            required={required}
            disabled={rest.disabled}
          />
        );
      }}
      inputFormat="DD/MM/YYYY"
      disableOpenPicker
      PaperProps={{
        sx: {
          '& .MuiDayPicker-weekDayLabel': {
            fontWeight: 500
          },
          '& .MuiPickersDay-root': {
            fontWeight: 500,

            '&.Mui-selected': {
              color: KColors.white,
              backgroundColor: `${KColors.primary.normal} !important`
            },

            '&.Mui-disabled': {
              backgroundColor: KColors.palette.gray.w50
            }
          }
        }
      }}
    />
  );
});

export default memo(KDatePicker);
