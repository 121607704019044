class AppEvent {
  subscribe = (name: string, cb: EventListenerOrEventListenerObject) => {
    document.addEventListener(name, cb);
  };

  unsubscribe = (name: string, cb: EventListenerOrEventListenerObject) => {
    document.removeEventListener(name, cb);
  };

  dispatch = (
    name: string,
    eventInitDict?: CustomEventInit<unknown> | undefined
  ) => {
    const event = new CustomEvent(name, eventInitDict);
    document.dispatchEvent(event);
  };
}

export default new AppEvent();
