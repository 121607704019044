import {
  ENDPOINTS,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import {
  useDataTable,
  useGlobalTable,
  useNewButton,
  useToolsButton
} from '@hooks';
import { TableUtils, UIUtils } from '@utils';
import React, { createRef, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import trans from 'translation';
import { KBreadcrumbs, KChip, KColors, KContainer, KLabel } from 'uikit';

import { useDeleteClient } from './helpers';
import Import from './Import';

const Client = () => {
  const history = useHistory();

  const ref = createRef<HTMLButtonElement>();

  const onShowClient = useCallback(
    (item: any) => {
      if (item) {
        history.push(`clients/${item.id}`, { canBack: true });
      }
    },
    [history]
  );

  const { onAlert, deleteLoading } = useDeleteClient();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowClient(item)
          },
          {
            title: trans('delete'),
            icon: 'Delete',
            onPress: () => onAlert({ id: item.id })
          }
        ])
      },
      {
        label: trans('code'),
        name: 'code'
      },
      {
        label: trans('name'),
        name: 'name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('country'),
        name: 'defaultSite.country.name'
      },
      {
        label: trans('city'),
        name: 'defaultSite.city.name'
      },
      {
        label: trans('unloco'),
        name: 'defaultSite.city.unloco'
      },
      {
        label: trans('office_address'),
        name: 'defaultSite.address',
        options: TableUtils.options.base3XLg
      },
      {
        label: trans('suburb'),
        name: 'defaultSite.suburb.name',
        options: TableUtils.options.baseXMd
      },
      {
        label: trans('state'),
        name: 'defaultSite.state.name',
        options: TableUtils.options.baseXMd
      },
      {
        label: trans('postal_code'),
        name: 'defaultSite.postCode'
      },
      {
        label: trans('opening_hour'),
        name: 'defaultSite.openingHourStart'
      },
      {
        label: trans('closing_hour'),
        name: 'defaultSite.openingHourEnd'
      },
      {
        label: trans('website'),
        name: 'website'
      },
      {
        label: trans('abn'),
        name: 'abn'
      },
      {
        label: trans('stage'),
        name: 'stage.name'
      },
      {
        label: trans('category'),
        name: 'category.name'
      },
      {
        label: trans('ctc_name'),
        name: 'defaultContact.fullname'
      },
      {
        label: trans('telephone'),
        name: 'defaultContact.telephone',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('mobile'),
        name: 'defaultContact.mobile',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('email'),
        name: 'defaultContact.email'
      },
      {
        label: trans('type'),
        name: 'clientTypes',
        options: TableUtils.options.withMoreIcon({
          onPress: v => {
            UIUtils.popup.open({
              title: trans('client_types'),
              maxWidth: 'xs',
              touchOutsideToDismiss: true,
              content: () => (
                <>
                  {v.map((i, idx) => {
                    const marginT = idx === 0 ? 0 : '0.75rem';
                    return (
                      <KContainer.View
                        key={`client-type-popup-${i.id}`}
                        dp="flex"
                        center
                        paddingV="0.25rem"
                        paddingH="0.75rem"
                        br="x"
                        brW={1}
                        brC={KColors.warning.normal}
                        marginT={marginT}
                      >
                        <KLabel.Text
                          typo="TextMdNormal"
                          textTransform="uppercase"
                        >
                          {i.code}
                        </KLabel.Text>
                      </KContainer.View>
                    );
                  })}
                </>
              )
            });
          },
          renderItem: (i, idx, rowIdx) => {
            const item = data?.[rowIdx];
            const marginL = idx === 0 ? 0 : '0.5rem';
            return (
              <KChip
                key={`${item?.id}-client-type-${i.id}`}
                background={KColors.palette.warning.w50}
                brC={KColors.warning.normal}
                label={i.code}
                textTransform={'uppercase'}
                color={KColors.warning.normal}
                marginL={marginL}
                style={{ whiteSpace: 'nowrap' }}
              />
            );
          }
        })
      },
      {
        label: trans('status'),
        name: 'status',
        options: TableUtils.options.switch
      }
    ],
    [onAlert, onShowClient]
  );

  const mappedFields = useMemo(() => {
    return {
      'defaultSite.country.name': 'siteCountry',
      'defaultSite.city.name': 'siteCity',
      'defaultSite.city.unloco': 'siteUnloco',
      'defaultSite.address': 'siteAddress',
      'defaultSite.suburb.name': 'siteSuburb',
      'defaultSite.state.name': 'siteState',
      'defaultSite.postCode': 'sitePostCode',
      'defaultSite.openingHourStart': 'siteOpeningHourStart',
      'defaultSite.openingHourEnd': 'siteOpeningHourEnd',
      'defaultContact.fullname': 'contactName',
      'defaultContact.telephone': 'contactTelephone',
      'defaultContact.mobile': 'contactMobile',
      'defaultContact.email': 'contactEmail',
      clientTypes: 'clientType',
      'category.name': 'category',
      'stage.name': 'stage'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.CLIENT,
    tableName: TableName.CLIENT,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.client(),
    columnsFunc,
    mappedFields
  });

  useGlobalTable(table);

  const onImport = useCallback(() => {
    UIUtils.popup.open({
      title: trans('import_from_excel'),
      content: () => <Import />
    });
  }, []);

  const { onShowTools } = useToolsButton(ref, [
    {
      title: trans('import_from_excel'),
      icon: {
        name: 'CloudUploadOutlined',
        color: KColors.primary.normal
      },
      onPress: onImport
    }
  ]);

  const onAdd = useCallback(() => history.push('clients/new'), [history]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        title={trans('routes.clients')}
        rightNode={{
          tools: {
            ref,
            onPress: onShowTools
          }
        }}
      />

      <KContainer.Card margin={'0.75rem'}>
        <DataTable {...table} onAdd={onAdd} isModifying={deleteLoading} />
      </KContainer.Card>

      {newButton}
    </>
  );
};

export default memo(Client);
