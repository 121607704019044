import { t } from '@lingui/macro';
import React from 'react';
import { Prompt } from 'react-router-dom';

export const usePrompt = (when: Boolean, message?: String = '') => {
  return (
    <Prompt
      when={when}
      message={
        message ||
        t`Changes that you made may not be saved. Are you sure you want to leave?`
      }
    />
  );
};
