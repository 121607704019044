import { ENDPOINTS, ResourceType } from '@constants';
import { Prototype } from '@core';
import { useUploadFile } from '@hooks';
import { isEmpty } from 'lodash';
import React, { memo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import trans from 'translation';
import { KContainer, KGrid, KInput } from 'uikit';

import { IFormData } from './helpers';

interface IProps {
  isView?: boolean;
}

const AP = ({ isView }: IProps) => {
  const methods = useFormContext<IFormData>();

  const { mutateAsync: mutateFile } = useUploadFile();

  const onChangeFile = useCallback(
    async (e: any) => {
      if (e.target.files && !isEmpty(e.target.files)) {
        const v = e.target.files[0];
        const formData = new FormData();
        formData.append('file', v);
        formData.append('resourceType', ResourceType.Edoc);

        try {
          const res = await mutateFile(formData);
          methods.setValue('attachedEdoc', res);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [methods, mutateFile]
  );

  return (
    <KContainer.Card
      border
      marginT={0}
      size="nm"
      header={{
        title: trans('account_payable'),
        border: true
      }}
    >
      <KGrid.Container>
        <KGrid.Item xs={12} sm={4} md={4}>
          <Controller
            name="client"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('code')}
                  apiURL={ENDPOINTS.client()}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  disabled={isView}
                  isInitFetch={false}
                  inputProps={{
                    message: error?.message
                  }}
                  hasAddNew
                  addNewURL="clients"
                  addNewKey="code"
                  hasEdit
                  editURL="clients"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={8} md={8}>
          <Controller
            name="client"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('name')}
                  apiURL={ENDPOINTS.client()}
                  disabled={isView}
                  isInitFetch={false}
                  inputProps={{
                    message: error?.message
                  }}
                  hasAddNew
                  addNewURL="clients"
                  hasEdit
                  editURL="clients"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="client"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  name="apOfficeAddress"
                  label={trans('office_address')}
                  value={Prototype.string.normalizeFullAddress(
                    field.value?.defaultSite
                  )}
                  disabled
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="attachedEdoc"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.File
                  {...field}
                  label={trans('attached_file')}
                  accept="*"
                  value={field.value?.fileName ?? ''}
                  onChange={onChangeFile}
                  onPress={() => {
                    if (field.value?.url) {
                      window.open(field.value.url, '_blank');
                    }
                  }}
                  disabled={isView}
                />
              );
            }}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default memo(AP);
