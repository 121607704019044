import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import Draggable from 'react-draggable';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: theme.palette.grey[500]
  },
  title: {
    fontWeight: 500,
    fontSize: 14
  }
});

const PaperComponent = props => {
  return (
    <Draggable
      handle={`#${props['aria-labelledby']}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <label className={classes.title}>{children}</label>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const Popup = (
  {
    children,
    maxWidth = 'sm',
    fullWidth = true,
    hasDivider = true,
    title,
    titleId = 'draggable-dialog-title',
    onDismissed
  },
  ref
) => {
  const [open, setOpen] = useState(false);

  const show = useCallback(() => {
    setOpen(true);
  }, []);

  const hide = useCallback(() => {
    onDismissed?.();
    setOpen(false);
  }, [onDismissed]);

  useImperativeHandle(ref, () => ({
    show,
    hide
  }));

  return (
    <Dialog
      aria-labelledby={titleId}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id={titleId} onClose={hide}>
        {title}
      </DialogTitle>

      <DialogContent dividers={hasDivider}>{children}</DialogContent>
    </Dialog>
  );
};

export default memo(forwardRef(Popup));
