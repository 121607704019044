import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const styles = {
  grid: noPadding => ({
    // margin: '0 -15px !important',
    padding: !noPadding ? '0 12px' : 0,
    width: 'unset'
  })
};

const useStyles = makeStyles(styles);

const GridContainer = ({ noPadding, ...rest }) => {
  const classes = useStyles(noPadding);

  return <Grid container className={classes.grid} {...rest} />;
};

export default memo(GridContainer);

GridContainer.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool
};

GridContainer.defaultProps = {
  noPadding: false,
  spacing: 3
};
