import { ENDPOINTS, QUERY_KEYS, Status } from '@constants';
import APIManager from '@services';
import { INoteOptions } from '@ui';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchDataGroup = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dataGroup],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.dataGroup(),
        body: {
          status: Status.Active
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useCUDNote = (options: INoteOptions) => {
  const { source, ...rest } = options;
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.note(':source', {
      source: source
    }),
    ...rest
  });
};
