import { useAlertMutationEnhancer, useCUDStaff } from '@hooks';

export const useDeleteStaff = () => {
  const { deleteMutation } = useCUDStaff();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const onShowStaff = (id?: string | number | 'new') => {
  window.open(`/admin/staff/${id}`, '_blank');
};
