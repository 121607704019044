import {
  errorMessage,
  primaryBlack,
  primaryBlue,
  primaryGreen
} from '@assets/jss/material-dashboard-react.js';

const profileStyle = theme => ({
  profileWrapper: {
    width: '868px',
    margin: '0 auto',
    color: primaryBlack,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  changePasswordIcon: {
    color: primaryGreen
  },
  passwordWrapper: {
    position: 'relative',
    marginBottom: 30
  },
  showPasswordIcon: {
    position: 'absolute',
    right: 10,
    top: 8,
    cursor: 'pointer'
  },
  uploadBtn: {
    color: primaryBlue,
    marginTop: 10,
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.15px'
  },
  editBtn: {
    position: 'absolute',
    right: 10,
    top: 5,
    cursor: 'pointer'
  },
  errorMessage
});

export default profileStyle;
