import { MarkupType, Status } from '@constants';
import { Prototype } from '@core';

export interface IFormData {
  code: string;
  quotation: any;
  quotationRequest: any;
  contractStart: any;
  contractEnd: any;
  reviewDate: any;
  salePerson: any;
  termResource: any;
  status: Status;
  zone: any;
  charges: any[];
}

export const onGenCode = (code: string) => {
  return `${code}${Prototype.date.now().format('YYMMDDHHmm')}`;
};

export const genSellingPrice = (data: any): any => {
  let result = '';

  const markupType = data.levelValueType;
  const components = data.autoRateChargeComponents;

  if (markupType === MarkupType.Composite) {
    const componentsPriceArr = components
      .filter((i: any) => i.weight > 0)
      .map(
        (i: any) => `${i.weight}% ${i.chargeCodeComponent.subChargeCode.code}`
      );
    const resultArr = data.rateValue
      ? [Prototype.number.formatCurrency(data.rateValue), ...componentsPriceArr]
      : componentsPriceArr;
    result = resultArr.join(' + ');
  } else {
    result = data.levelValue;
  }

  return result;
};
