import {
  NotificationReadStatus,
  NotificationReadStatusTab,
  generateOptions
} from '@constants';
import { NotificationManager, Prototype } from '@core';
import {
  useOneStopFetchNotificationList,
  useOneStopMarkAllAsRead,
  useOneStopMarkAsRead
} from '@hooks';
import { UIUtils } from '@utils';
import React, { memo, useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel, KLoading, KTab } from 'uikit';

const Notification1Stop = () => {
  const history = useHistory();

  const [tab, setTab] = useState<NotificationReadStatus>(
    NotificationReadStatus.All
  );

  const {
    dataFlatten: data,
    isLoading,
    hasNextPage,
    onEndReached
  } = useOneStopFetchNotificationList(tab);

  const { mutate: mutateReadAll, isLoading: readAllLoading } =
    useOneStopMarkAllAsRead();
  const { mutate: mutateRead } = useOneStopMarkAsRead();

  const onMarkAllAsRead = useCallback(() => {
    mutateReadAll();
  }, [mutateReadAll]);

  const handleOpenItem = useCallback(
    (item: any) => {
      mutateRead(item.id);

      const link =
        item.link ||
        NotificationManager.genRedirectLink(
          item.childSource,
          item.childSourceId,
          item.sourceId
        );
      if (link) {
        UIUtils.popper.dismiss();
        history.push(link);
      }
    },
    [history, mutateRead]
  );

  const onGoToDashboard = useCallback(() => {
    history.push('/admin/dashboard');
  }, [history]);

  return (
    <KContainer.View width={500}>
      <KContainer.View row alignItems justifyContent="space-between">
        <KLabel.Text typo="TextMdMedium">
          {trans('1_stop_notifications')}
        </KLabel.Text>

        <KLabel.Text onPress={onMarkAllAsRead}>
          {trans('mark_all_as_read')}
        </KLabel.Text>
      </KContainer.View>

      <KContainer.View row alignItems marginV="0.75rem">
        <KTab.Outline
          flex
          marginT={0}
          onChangeTab={setTab as any}
          tabs={generateOptions(NotificationReadStatusTab)}
        />

        <KLabel.Text onPress={() => onGoToDashboard()}>
          {trans('view_all')}
        </KLabel.Text>
      </KContainer.View>

      <KContainer.View id="scrollableDiv2" maxH={400} overflow dp="flex">
        <InfiniteScroll
          dataLength={data.length}
          next={onEndReached}
          hasMore={hasNextPage as any}
          loader={isLoading || readAllLoading ? <KLoading /> : null}
          scrollableTarget="scrollableDiv2"
          scrollThreshold={0.5}
        >
          {data.map((i: any) => {
            const timeAgo = (
              Prototype.date.toLocal(i.sendDate) as any
            ).fromNow();
            const isUnread = i.readStatus !== NotificationReadStatus.Read;
            const contentArr = (i.content ?? '').split('\n');
            const content = contentArr.map((c: string, idx: number) =>
              c ? (
                <KLabel.Paragraph key={`1-stop-content-${i.id}-${idx}`}>
                  {c}
                </KLabel.Paragraph>
              ) : idx === contentArr.length - 1 ? null : (
                <br key={`1-stop-br-${i.id}-${idx}`} />
              )
            );

            return (
              <KContainer.Touchable
                key={`h-n-${i.id}-${i.readStatus}`}
                onPress={handleOpenItem.bind(this, i)}
                row
                alignItems
                marginB="1rem"
              >
                <KContainer.View width={115}>
                  <KChip
                    background={'#FFF1E0'}
                    margin={0}
                    label={i.source?.slice(4) || trans('system').toUpperCase()}
                  />
                </KContainer.View>

                <KContainer.View flex marginH="0.75rem">
                  <KLabel.Text typo="TextMdMedium">{i.subject}</KLabel.Text>

                  <KLabel.Text
                    marginV="0.5rem"
                    dp="block"
                    typo={isUnread ? 'TextNmMedium' : 'TextNmNormal'}
                  >
                    {content}
                  </KLabel.Text>

                  <KLabel.Text
                    typo={isUnread ? 'TextXsMedium' : 'TextXsNormal'}
                    color={isUnread ? KColors.primary.normal : undefined}
                    italic
                  >
                    {trans('notif_sent_by', {
                      username: i.sentUsername || trans('system'),
                      time: timeAgo
                    })}
                  </KLabel.Text>
                </KContainer.View>

                {isUnread && (
                  <KContainer.View
                    width={12}
                    height={12}
                    br="round"
                    background={KColors.primary.normal}
                  />
                )}
              </KContainer.Touchable>
            );
          })}
        </InfiniteScroll>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(Notification1Stop);
