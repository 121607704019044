import { ENDPOINTS, TableName } from '@constants';
import { useDataTable, useGlobalTable } from '@hooks';
import { INoteOptions } from '@ui';
import { TableUtils } from '@utils';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import { KCardProps, KContainer } from 'uikit';

import { onShowEmail } from './helpers';

import DataTable from '../DataTable';

interface IProps extends KCardProps, INoteOptions {}

const Note = (props: IProps) => {
  const {
    title,
    source,
    webTable,
    dependentWebTable,
    parentId,
    isView,
    noHeader,
    otherParams: _otherParams,
    isLoading,
    ...rest
  } = props;

  const mOptions = useMemo(
    () => ({
      parentId,
      source,
      webTable,
      dependentWebTable,
      otherParams: _otherParams
    }),
    [_otherParams, dependentWebTable, parentId, source, webTable]
  );

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('view'),
              icon: 'Visibility',
              onPress: () =>
                onShowEmail({
                  item,
                  ...mOptions,
                  source: item.source,
                  parentId: item.sourceId
                })
            }
          ])
        },
        {
          label: trans('sent_to'),
          name: 'persons',
          options: {
            ...TableUtils.options.base3XLg,
            customBodyRender: (v: any) =>
              v
                ? v.map((i: any) => i.fullname + ' - ' + i.email).join(',\n ')
                : ''
          }
        },
        {
          label: trans('bcc'),
          name: 'users',
          options: {
            ...TableUtils.options.base3XLg,
            customBodyRender: (v: any) =>
              v
                ? v
                    .map((i: any) => i.displayName + ' - ' + i.email)
                    .join(',\n ')
                : ''
          }
        },
        {
          label: trans('subject'),
          name: 'subject',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('template'),
          name: 'template.name',
          options: TableUtils.options.baseMd
        },
        {
          label: trans('another_edoc_files'),
          name: 'edocs',
          options: {
            customBodyRender: (v: any) =>
              v ? v.map((i: any) => i.resource?.fileName).join(', ') : ''
          }
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        }
      ];
    },
    [mOptions]
  );

  const mappedFields = useMemo(() => {
    return {
      'template.name': 'template'
    };
  }, []);

  const otherParams = useMemo(() => {
    return {
      sourceId: parentId
    };
  }, [parentId]);

  const table = useDataTable({
    name: webTable,
    tableName: TableName.NOTE,
    apiURL: ENDPOINTS.email(':source', {
      source: source
    }),
    columnsFunc,
    mappedFields,
    otherOptions: {
      tableBodyMaxHeight: '35vh'
    },
    showFilter: false,
    otherParams
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => onShowEmail(mOptions), [mOptions]);

  return (
    <KContainer.Card
      marginT="0.25rem"
      header={
        !noHeader
          ? {
              icon: 'EmailOutlined',
              title: title || trans('emails')
            }
          : undefined
      }
      {...rest}
    >
      <DataTable
        {...table}
        onAdd={!isView ? onAdd : undefined}
        isModifying={isLoading}
      />
    </KContainer.Card>
  );
};

export default memo(Note);
