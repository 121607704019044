import React, { memo } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KGrid } from 'uikit';

import Email from './Email';
import Invoice from './Invoice';
import Notification from './Notification';
import OneStop from './OneStop';

const ApiIntegration = () => {
  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.system_settings'),
          trans('routes.api_integration')
        ]}
      />

      <KContainer.View padding="0.75rem">
        <KContainer.Hidden smUp>
          <KGrid.Container style={{ marginBottom: 12 }}>
            <KGrid.Item xs={12}>
              <Invoice />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Email />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Notification />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <OneStop />
            </KGrid.Item>
          </KGrid.Container>
        </KContainer.Hidden>

        <KContainer.Hidden xsDown>
          <KGrid.Container style={{ marginBottom: 12 }}>
            <KGrid.Item xs={6}>
              <KGrid.Container>
                <KGrid.Item xs={12}>
                  <Invoice hasHeight={true} />
                </KGrid.Item>

                <KGrid.Item xs={12}>
                  <Email hasHeight={true} />
                </KGrid.Item>
              </KGrid.Container>
            </KGrid.Item>

            <KGrid.Item xs={6}>
              <KGrid.Container>
                <KGrid.Item xs={12}>
                  <Notification hasHeight={true} />
                </KGrid.Item>

                <KGrid.Item xs={12}>
                  <OneStop hasHeight={true} />
                </KGrid.Item>
              </KGrid.Container>
            </KGrid.Item>
          </KGrid.Container>
        </KContainer.Hidden>
      </KContainer.View>
    </>
  );
};

export default memo(ApiIntegration);
