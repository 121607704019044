import constants, { GlobalEvent } from '@constants';
import { usePreferences } from '@context';
import { AppEvent } from '@core';
import { useUser } from '@hooks';
import { UIUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel, KListItem } from 'uikit';

const cIcon = {
  color: KColors.gray.normal
};

const UserMenu = () => {
  const history = useHistory();

  const { toggleLanguage, toggleAppearance, appearance } = usePreferences();

  const { account } = useUser();

  const { company, staff } = account || {};

  const onPressWrapper = useCallback((onPress?: () => void) => {
    onPress?.();
    UIUtils.popper.dismiss();
  }, []);

  const onLogout = useCallback(() => {
    UIUtils.alert.open({
      message: trans('logout_message'),
      buttons: [
        {
          title: trans('ok'),
          kind: 'danger',
          color: KColors.danger.normal,
          onPress: () => AppEvent.dispatch(GlobalEvent.SignOut)
        },
        {
          title: trans('cancel')
        }
      ]
    });
  }, []);

  return (
    // @ts-ignore
    <KContainer.View width={215} marginH={-8}>
      <KContainer.View row alignItems paddingH="0.75rem" paddingB="0.5rem">
        <KContainer.View flex dp="flex">
          <KLabel.Text typo="TextMdNormal">{trans('workspace')}</KLabel.Text>

          <KLabel.Text typo="TextNmBold" marginT="0.25rem">
            {company?.workspace ?? ''}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View flex dp="flex">
          <KChip
            background={KColors.opacity.primary[16]}
            brC={KColors.primary.normal}
            color={KColors.primary.normal}
            margin={0}
            label={staff?.jobTitle?.name?.toUpperCase() ?? ''}
            textAlign
            lineHeight={1.3}
          />
        </KContainer.View>
      </KContainer.View>

      <KListItem.Base
        border
        data={[
          {
            paddingV: '0.5rem',
            paddingH: '0.25rem',
            title: trans('company_profile'),
            iconAlignment: 'right',
            icon: {
              name: 'Business',
              ...cIcon
            },
            onPress: () =>
              onPressWrapper(() => history.push('/admin/company-profile'))
          },
          {
            paddingV: '0.5rem',
            paddingH: '0.25rem',
            title: trans('profile'),
            iconAlignment: 'right',
            icon: {
              name: 'AccountCircleOutlined',
              ...cIcon
            },
            onPress: () => onPressWrapper(() => history.push('/admin/profile'))
          },
          {
            paddingV: '0.5rem',
            paddingH: '0.25rem',
            title: trans('change_mode'),
            iconAlignment: 'right',
            icon: {
              name:
                appearance === constants.THEME.LIGHT
                  ? 'Brightness7'
                  : 'Brightness2Outlined',
              ...cIcon
            },
            onPress: () => onPressWrapper(toggleAppearance)
          },
          {
            paddingV: '0.5rem',
            paddingH: '0.25rem',
            title: trans('change_language'),
            iconAlignment: 'right',
            icon: {
              name: 'Language',
              ...cIcon
            },
            onPress: () => onPressWrapper(toggleLanguage)
          },
          {
            paddingT: '0.5rem',
            paddingH: '0.25rem',
            title: trans('logout'),
            iconAlignment: 'right',
            icon: {
              name: 'ExitToAppOutlined',
              ...cIcon
            },
            onPress: () => onPressWrapper(onLogout)
          }
        ]}
      />
    </KContainer.View>
  );
};

export default memo(UserMenu);
