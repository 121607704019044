import constants, {
  ENDPOINTS,
  JobType,
  RelatedClientType,
  ResourceType,
  Status,
  UserDataGroup,
  UserType,
  generateOptions
} from '@constants';
import { Prototype } from '@core';
import {
  useFetchQuotationRequest,
  useFileClientRate,
  useUploadFile,
  useResolverForm
} from '@hooks';
import { ValidationUtils } from '@utils';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel,
  KLoading,
  KPicker
} from 'uikit';
import * as yup from 'yup';

interface IProps {
  isAgent: boolean;
  quotation: any;
  quotationRequest: any;
  needRefetch?: boolean;
}

interface IFormData {
  code: string;
  jobType: JobType;
  termResource?: any;
  agent?: any;
  consignee?: any;
  warehouseClient: any;
  warehouse: any;
  contractStart: any;
  contractEnd: any;
  reviewDate: any;
  salePerson: any;
  status: Status.Active;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  consignee: ValidationUtils.requiredAny(),
  warehouseClient: ValidationUtils.requiredAny(),
  warehouse: ValidationUtils.requiredAny(),
  contractStart: ValidationUtils.requiredDate(),
  contractEnd: ValidationUtils.nullableDate().test(
    'minDate',
    'Must greater or equal than Contract Start',
    function (v: any) {
      const start = this.parent.contractStart;
      if (v && start) {
        return moment(v).isSameOrAfter(moment(start));
      }
      return true;
    }
  ),
  reviewDate: ValidationUtils.nullableDate().test(
    'minDate',
    'Must greater or equal than Contract Start',
    function (v: any) {
      const start = this.parent.contractStart;
      if (v && start) {
        return moment(v).isSameOrAfter(moment(start));
      }
      return true;
    }
  )
});

const consignData = {
  [constants.JOB_TYPE.IMPORT]: {
    title: 'consignee_information',
    type: constants.CLIENT_TYPE_SYSTEM.CONSIGNEE
  },
  [constants.JOB_TYPE.EXPORT]: {
    title: 'consignor_information',
    type: constants.CLIENT_TYPE_SYSTEM.CONSIGNOR
  },
  [constants.JOB_TYPE.MISC]: {
    title: 'consignee_consignor_information',
    type: [
      constants.CLIENT_TYPE_SYSTEM.CONSIGNOR,
      constants.CLIENT_TYPE_SYSTEM.CONSIGNEE
    ]
  },
  [constants.JOB_TYPE.RAIL_INBOUND]: {
    title: 'consignee_information',
    type: constants.CLIENT_TYPE_SYSTEM.CONSIGNEE
  },
  [constants.JOB_TYPE.RAIL_OUTBOUND]: {
    title: 'consignor_information',
    type: constants.CLIENT_TYPE_SYSTEM.CONSIGNOR
  }
};

const warehouseData = {
  [constants.JOB_TYPE.IMPORT]: {
    title: 'delivery_warehouse',
    typeIn: constants.RELATED_SITE_TYPE.DELIVERY_WAREHOUSE
  },
  [constants.JOB_TYPE.EXPORT]: {
    title: 'pickup_warehouse',
    typeIn: constants.RELATED_SITE_TYPE.PICKUP_WAREHOUSE
  },
  [constants.JOB_TYPE.MISC]: {
    title: 'warehouse',
    typeIn: [
      constants.RELATED_SITE_TYPE.DELIVERY_WAREHOUSE,
      constants.RELATED_SITE_TYPE.PICKUP_WAREHOUSE
    ]
  },
  [constants.JOB_TYPE.RAIL_INBOUND]: {
    title: 'delivery_warehouse',
    typeIn: constants.RELATED_SITE_TYPE.DELIVERY_WAREHOUSE
  },
  [constants.JOB_TYPE.RAIL_OUTBOUND]: {
    title: 'pickup_warehouse',
    typeIn: constants.RELATED_SITE_TYPE.PICKUP_WAREHOUSE
  }
};

const mapAutoRateCharges = (data: any[]): any[] => {
  return data.map(i => ({
    companyTariffId: i.companyTariffId,
    companyTariffLevelId: i.companyTariffLevelId,
    selectedLevelNumber: i.selectedLevelNumber,
    levelValueType: i.levelValueType,
    levelValue: i.levelValue,
    rateValue: i.rateValue,
    sellingPriceValue: i.sellingPriceValue,
    companyTariff: i.companyTariff,
    status: i.status,
    autoRateChargeComponents: (i.quotationRequestChargeComponents ?? []).map(
      (j: any) => ({
        weight: j.weight,
        chargeCodeComponentId: j.chargeCodeComponentId,
        status: i.status
      })
    )
  }));
};

const FileClientRate = ({
  isAgent,
  quotation,
  quotationRequest: localData,
  needRefetch
}: IProps) => {
  const { mutate, isLoading } = useFileClientRate();

  const { data: remoteData, isLoading: isFetching } = useFetchQuotationRequest(
    quotation.id,
    localData.id,
    needRefetch
  );

  const quotationRequest = useMemo(
    () => (needRefetch ? remoteData ?? localData : localData),
    [localData, needRefetch, remoteData]
  );

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        code: !isAgent
          ? `${quotation?.client?.code}${moment().format('YYMMDDHHmm')}`
          : '',
        jobType: quotationRequest?.jobType,
        termResource: undefined,
        agent: isAgent ? quotation?.client : undefined,
        consignee: !isAgent ? quotation?.client : undefined,
        warehouseClient: undefined,
        warehouse: undefined,
        contractStart: Prototype.date.toLocal(quotation?.contractStart),
        contractEnd: Prototype.date.toLocal(quotation?.contractEnd),
        reviewDate: Prototype.date.toLocal(quotation?.reviewDate),
        salePerson: quotation?.salePerson,
        status: Status.Active
      }
    }
  });

  const [agent, consignee, warehouseClient, warehouse, jobType] = useWatch({
    control: methods.control,
    name: ['agent', 'consignee', 'warehouseClient', 'warehouse', 'jobType']
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      if (!warehouse?.relatedSite?.suburbId) {
        toast.error('Warehouse Address has no suburb');
      } else if (
        warehouse?.relatedSite?.suburbId !== quotationRequest.suburbId
      ) {
        toast.error(
          `Warehouse Address Suburb: ${warehouse?.relatedSite?.suburb?.name} (${warehouse?.relatedSite?.postCode}) is different from Quotation Request Suburb: ${quotationRequest.suburb.name} (${quotationRequest.suburb.postcode})`
        );
      } else {
        const mParams = {
          suburbId: quotationRequest.suburbId,
          zoneId: quotationRequest.zoneId,
          dropModeId: quotationRequest.dropModeId,
          containerSizeId: quotationRequest.containerSizeId,
          grossWeight: quotationRequest.grossWeight,
          isDamagedGood: quotationRequest.isDamagedGood,
          // isRefrigeratedStorage: quotationRequest.isRefrigeratedStorage,
          chargeCodeTemplateId: quotationRequest.chargeCodeTemplateId,
          autoRateCharges: mapAutoRateCharges(
            quotationRequest.quotationRequestCharges ?? []
          ),
          code: data.code,
          quotationId: quotation.id,
          quotationRequestId: quotationRequest.id,
          contractStart: Prototype.date.formatDB(data.contractStart),
          contractEnd: Prototype.date.formatDB(data.contractEnd),
          reviewDate: Prototype.date.formatDB(data.reviewDate),
          salePersonId: data.salePerson?.id,
          status: data.status,
          termResourceId: data.termResource?.id,
          clientRate: {
            agentClientId: data.agent?.id,
            consignClientId: data.consignee.id,
            relatedPartySiteId: data.warehouse.id,
            shipmentType: quotation.shipmentType,
            jobType: quotationRequest.jobType,
            status: Status.Active
          }
        };

        mutate(mParams);
      }
    },
    [
      mutate,
      quotation.id,
      quotation.shipmentType,
      quotationRequest.chargeCodeTemplateId,
      quotationRequest.containerSizeId,
      quotationRequest.dropModeId,
      quotationRequest.grossWeight,
      quotationRequest.id,
      quotationRequest.isDamagedGood,
      // quotationRequest.isRefrigeratedStorage,
      quotationRequest.jobType,
      quotationRequest.quotationRequestCharges,
      quotationRequest.suburb.name,
      quotationRequest.suburb.postcode,
      quotationRequest.suburbId,
      quotationRequest.zoneId,
      warehouse?.relatedSite?.postCode,
      warehouse?.relatedSite?.suburb?.name,
      warehouse?.relatedSite?.suburbId
    ]
  );

  const { mutateAsync: mutateFile } = useUploadFile();

  const onChangeFile = useCallback(
    async (e: any) => {
      if (e.target.files && !isEmpty(e.target.files)) {
        const v = e.target.files[0];
        const formData = new FormData();
        formData.append('file', v);
        formData.append('resourceType', ResourceType.AutoRateTerm);

        const res = await mutateFile(formData);
        if (res) {
          methods.setValue('termResource', res.url);
        }
      }
    },
    [methods, mutateFile]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container style={{ position: 'relative' }}>
        {isFetching && <KLoading />}

        <KGrid.Item xs={6}>
          <KContainer.Card
            border
            size="nm"
            header={{
              title: trans('agent_information'),
              border: true
            }}
          >
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentCode"
                  label={trans('code')}
                  value={agent?.code ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={8}>
                <KInput.TextField
                  name="agentName"
                  label={trans('name')}
                  value={agent?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentCountry"
                  label={trans('country')}
                  value={agent?.defaultSite?.country?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentCity"
                  label={trans('city')}
                  value={agent?.defaultSite?.city?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentUnloco"
                  label={trans('unloco')}
                  value={agent?.defaultSite?.city?.unloco ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={12}>
                <KInput.TextField
                  name="agentOfficeAddress"
                  label={trans('office_address')}
                  value={Prototype.string.normalizeFullAddress(
                    agent?.defaultSite
                  )}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentSuburb"
                  label={trans('suburb')}
                  value={agent?.defaultSite?.suburb?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentState"
                  label={trans('state')}
                  value={agent?.defaultSite?.state?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentPostCode"
                  label={trans('postal_code')}
                  value={agent?.defaultSite?.postCode ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentAbn"
                  label={trans('abn')}
                  value={agent?.defaultSite?.abn ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentStage"
                  label={trans('stage')}
                  value={agent?.stage?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="agentCategory"
                  label={trans('category')}
                  value={agent?.category?.name ?? ''}
                  disabled
                />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KContainer.Card
            border
            size="nm"
            header={{
              title: trans(consignData[jobType].title),
              content: (
                <KLabel.Text color={KColors.secondary.normal} marginL="0.25rem">
                  *
                </KLabel.Text>
              ),
              border: true
            }}
          >
            <KGrid.Container>
              <KContainer.RenderWhen>
                <KContainer.RenderWhen.If isTrue={!agent}>
                  <KGrid.Item xs={4}>
                    <KInput.TextField
                      name="consigneeCode"
                      label={trans('code')}
                      value={consignee?.code ?? ''}
                      disabled
                    />
                  </KGrid.Item>

                  <KGrid.Item xs={8}>
                    <KInput.TextField
                      name="consigneeName"
                      label={trans('name')}
                      value={consignee?.name ?? ''}
                      disabled
                    />
                  </KGrid.Item>
                </KContainer.RenderWhen.If>

                <KContainer.RenderWhen.If isTrue={!!agent}>
                  <KGrid.Item xs={4}>
                    <Controller
                      name="consignee"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KInput.Autocomplete
                            {...field}
                            label={trans('code')}
                            onChange={(v: any) => {
                              methods.setValue('consignee', v);
                              methods.setValue('warehouseClient', null);
                              methods.setValue('warehouse', null);
                              methods.setValue(
                                'code',
                                `${v?.code}${moment().format('YYMMDDHHmm')}`
                              );
                            }}
                            apiURL={ENDPOINTS.client(
                              ':id/related-client/:type',
                              {
                                id: agent?.id,
                                type: RelatedClientType.ConsignorConsignee
                              }
                            )}
                            apiParams={{
                              clientTypeIn: consignData[jobType]?.type
                            }}
                            apiField="relatedClient"
                            getOptionLabel={(o: any) => o?.code ?? ''}
                            inputProps={{
                              message: error?.message
                            }}
                          />
                        );
                      }}
                    />
                  </KGrid.Item>

                  <KGrid.Item xs={8}>
                    <Controller
                      name="consignee"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KInput.Autocomplete
                            {...field}
                            label={trans('code')}
                            onChange={(v: any) => {
                              methods.setValue('consignee', v);
                              methods.setValue('warehouseClient', null);
                              methods.setValue('warehouse', null);
                              methods.setValue(
                                'code',
                                `${v?.code}${moment().format('YYMMDDHHmm')}`
                              );
                            }}
                            apiURL={ENDPOINTS.client(
                              ':id/related-client/:type',
                              {
                                id: agent?.id,
                                type: RelatedClientType.ConsignorConsignee
                              }
                            )}
                            apiParams={{
                              clientTypeIn: consignData[jobType].type
                            }}
                            apiField="relatedClient"
                            inputProps={{
                              message: error?.message
                            }}
                          />
                        );
                      }}
                    />
                  </KGrid.Item>
                </KContainer.RenderWhen.If>
              </KContainer.RenderWhen>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeCountry"
                  label={trans('country')}
                  value={consignee?.defaultSite?.country?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeCity"
                  label={trans('city')}
                  value={consignee?.defaultSite?.city?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeUnloco"
                  label={trans('unloco')}
                  value={consignee?.defaultSite?.city?.unloco ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={12}>
                <KInput.TextField
                  name="consigneeOfficeAddress"
                  label={trans('office_address')}
                  value={Prototype.string.normalizeFullAddress(
                    consignee?.defaultSite
                  )}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeSuburb"
                  label={trans('suburb')}
                  value={consignee?.defaultSite?.suburb?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeState"
                  label={trans('state')}
                  value={consignee?.defaultSite?.state?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneePostCode"
                  label={trans('postal_code')}
                  value={consignee?.defaultSite?.postCode ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeAbn"
                  label={trans('abn')}
                  value={consignee?.defaultSite?.abn ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeStage"
                  label={trans('stage')}
                  value={consignee?.stage?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="consigneeCategory"
                  label={trans('category')}
                  value={consignee?.category?.name ?? ''}
                  disabled
                />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KContainer.Card
            border
            size="nm"
            header={{
              title: trans(warehouseData[jobType].title),
              content: (
                <KLabel.Text color={KColors.secondary.normal} marginL="0.25rem">
                  *
                </KLabel.Text>
              ),
              border: true
            }}
          >
            <KGrid.Container>
              <KGrid.Item xs={2}>
                <Controller
                  name="warehouseClient"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('code')}
                        onChange={(v: any) => {
                          methods.setValue('warehouseClient', v);
                          methods.setValue('warehouse', null);
                        }}
                        apiURL={ENDPOINTS.client(':id/related-site/client', {
                          id: consignee?.id
                        })}
                        apiParams={{
                          typeIn: warehouseData[jobType].typeIn
                        }}
                        getOptionLabel={(o: any) => o?.code ?? ''}
                        inputProps={{
                          message: error?.message
                        }}
                        disabled={!consignee}
                        hasAddNew
                        addNewKey="code"
                        addNewURL="clients"
                        hasEdit
                        editURL="clients"
                        searchKey="clientCode"
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <Controller
                  name="warehouseClient"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('name')}
                        onChange={(v: any) => {
                          methods.setValue('warehouseClient', v);
                          methods.setValue('warehouse', null);
                        }}
                        apiURL={ENDPOINTS.client(':id/related-site/client', {
                          id: consignee?.id
                        })}
                        apiParams={{
                          typeIn: warehouseData[jobType].typeIn
                        }}
                        inputProps={{
                          message: error?.message
                        }}
                        disabled={!consignee}
                        hasAddNew
                        addNewURL="clients"
                        hasEdit
                        editURL="clients"
                        searchKey="clientName"
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <Controller
                  name="warehouse"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('address_name')}
                        apiURL={ENDPOINTS.client(':id/related-site', {
                          id: consignee?.id
                        })}
                        apiParams={{
                          relatedClientId: warehouseClient?.id,
                          typeIn: warehouseData[jobType].typeIn
                        }}
                        getOptionLabel={(o: any) => o?.relatedSite?.name ?? ''}
                        inputProps={{
                          message: error?.message
                        }}
                        disabled={!consignee || !warehouseClient}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <KInput.TextField
                  name="warehouseAddress"
                  label={trans('address')}
                  value={Prototype.string.normalizeFullAddress(
                    warehouse?.relatedSite
                  )}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <KInput.TextField
                  name="warehousePostCode"
                  label={trans('postal_code')}
                  value={warehouse?.relatedSite?.postCode ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <KInput.TextField
                  name="warehouseCountry"
                  label={trans('country')}
                  value={warehouse?.relatedSite?.country?.name ?? ''}
                  disabled
                />
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <KInput.TextField
                  name="warehouseCity"
                  label={trans('city')}
                  value={warehouse?.relatedSite?.city?.name ?? ''}
                  disabled
                />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KContainer.Card
            border
            size="nm"
            header={{
              title: trans('auto_rate_information'),
              border: true
            }}
          >
            <KGrid.Container>
              <KGrid.Item xs={3}>
                <Controller
                  name="code"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans('contract_code')}
                        required
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={3}>
                <Controller
                  name="contractStart"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KPicker.Date
                        {...field}
                        label={trans('contract_start')}
                        required
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={3}>
                <Controller
                  name="contractEnd"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KPicker.Date
                        {...field}
                        label={trans('contract_end')}
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={3}>
                <Controller
                  name="reviewDate"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KPicker.Date
                        {...field}
                        label={trans('review_date')}
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <Controller
                  name="termResource"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.File
                        label={trans('term_and_conditions')}
                        accept="*"
                        value={field.value?.fileName ?? ''}
                        onChange={onChangeFile}
                        onPress={() => {
                          if (field.value?.url) {
                            window.open(field.value?.url, '_blank');
                          }
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={3}>
                <Controller
                  name="salePerson"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('sales_person')}
                        apiURL={ENDPOINTS.user()}
                        apiParams={{
                          type: UserType.Internal,
                          dataGroup: UserDataGroup.Sales
                        }}
                        getOptionLabel={(o: any) => o?.displayName ?? ''}
                        hasAddNew
                        addNewKey="displayName"
                        addNewURL="users"
                        hasEdit
                        editURL="users"
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={3}>
                <Controller
                  name="status"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans('status')}
                        options={generateOptions(Status)}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid
            type="submit"
            isLoading={isLoading}
            title={trans('save')}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(FileClientRate);
