import {
  useAlertMutationEnhancer,
  useCUDQuotation,
  useCUDQuotationRequest
} from '@hooks';
import { UIUtils } from '@utils';
import qs from 'query-string';
import React from 'react';
import trans from 'translation';

import RequestForm from './Request/Form';

export const onShowQuotation = (id: number | string | 'new', query?: any) => {
  window.open(
    `/admin/quotations/${id}${query ? `?${qs.stringify(query)}` : ''}`,
    '_blank'
  );
};

export const useDeleteQuotation = () => {
  const { deleteMutation } = useCUDQuotation();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useDeleteRequest = (id: string | number) => {
  const { deleteMutation } = useCUDQuotationRequest(id);

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const onShowRequest = (quotationId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('quotation_request'),
    maxWidth: 'lg',
    content: () => <RequestForm item={v} quotationId={quotationId} />
  });
};
