import constants, { ShipmentType } from '@constants';
import React, { memo, useMemo } from 'react';
import { KTab, KTabProps } from 'uikit';

interface IProps extends Omit<KTabProps, 'tabs'> {
  disabled?: boolean;
}

const ShipmentTabs = (props: IProps) => {
  const { disabled, ...rest } = props;

  const tabs = useMemo(() => {
    return Object.keys(ShipmentType).map(i => ({
      key: ShipmentType[i],
      label: ShipmentType[i],
      tooltipLabel: constants.SHIPMENT_TYPE_STR[ShipmentType[i]],
      disabled
    }));
  }, [disabled]);

  return (
    <KTab.Outline
      marginB="0.75rem"
      marginT={0}
      {...rest}
      tabs={tabs}
      hasTooltip
    />
  );
};

export default memo(ShipmentTabs);
