import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import { IClientDto } from '@dto';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchCompany = () => {
  return useQueryEnhancer<IClientDto>({
    queryKey: [QUERY_KEYS.company],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.company('client/detail')
      });

      return res.data;
    },
    enabled: true
  });
};

export const useCUDCompany = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.company('client'),
    queryKey: QUERY_KEYS.company,
    putWithoutId: true
  });
};

export const useCUDCompanyAddress = (id?: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.company('client/:id/site', { id }),
    webTable: WebTable.COMPANY_ADDRESS
  });
};

export const useCUDCompanyContact = (id?: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.company('client/:id/person', { id }),
    webTable: WebTable.COMPANY_CONTACT
  });
};

export const useCUDCompanyBankAccount = (id?: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.company('client/:id/bank-account/AR', { id }),
    webTable: WebTable.COMPANY_BANK_ACCOUNT
  });
};
