import {
  ActionType,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  SignatureSignType,
  Status,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { UIUtils } from '@utils';
import { camelCase, kebabCase } from 'lodash';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useUploadFile,
  useQueryEnhancer
} from '../core';

export const useFetchEquipmentMaintenance = (
  id?: number,
  equipmentId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentMaintenance,
      equipmentId ? parseInt(equipmentId as string) : null,
      id
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/maintenance/:maintenanceId', {
          id: equipmentId,
          maintenanceId: id
        })
      });
      return res.data;
    },
    enabled: !!id && !!equipmentId
  });
};

export const useFetchEquipmentLatestMaintenance = (
  equipmentId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentLatestMaintenance,
      equipmentId ? parseInt(equipmentId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/maintenance', {
          id: equipmentId
        }),
        body: {
          sortBy: 'createdAt:DESC',
          isCancelled: false,
          size: 1
        }
      });

      return res.data?.data?.[0];
    },
    enabled: !!equipmentId
  });
};

export const useFetchMaintenanceActionList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.maintenanceActionList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.action(),
        body: {
          status: Status.Active,
          type: ActionType.Maintenance,
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useCUDEquipmentMaintenance = (equipmentId?: string | number) => {
  return useCUDMutationEnhancer({
    endPoint: ENDPOINTS.equipment(':equipmentId/maintenance', { equipmentId }),
    webTable: WebTable.EQUIPMENT_MAINTENANCE,
    dependentWebTable: [WebTable.MAINTENANCE],
    noDismiss: true,
    isPage: true,
    mutationKeys: [[QUERY_KEYS.equipmentMaintenance]],
    deleteWithoutRefetch: true
  });
};

export const useSignMaintenance = (signType: SignatureSignType) => {
  const { mutateAsync: mutateFile } = useUploadFile();

  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.equipmentMaintenance]],
    mutationFn: async data => {
      const { blob, equipmentId, id, ...rest } = data;
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob);
        const fRes = await mutateFile(formData);

        if (fRes?.id) {
          rest[
            `${
              signType === SignatureSignType.Maintain
                ? 'maintenance'
                : camelCase(signType)
            }SignatureId`
          ] = fRes.id;
        }
      }

      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/maintenance/:maintenanceId/:signType', {
          id: equipmentId,
          maintenanceId: id,
          signType: kebabCase(signType)
        }),
        method: 'PUT',
        body: rest
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        tableRef?.[WebTable.EQUIPMENT_MAINTENANCE]?.init();
      }
    }
  });
};
