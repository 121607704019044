import { WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { useLocalTable } from '@hooks';
import { UIUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KContainer } from 'uikit';

import EditChargeCodePrice from './components/EditChargeCodePrice';
import ExtraCompanyTariff from './components/ExtraCompanyTariff';
import ExtraFromClientTariff from './components/ExtraFromClientTariff';
import {
  IFormData,
  mapChargeByCompanyTariff,
  useChargeColumns
} from './helpers';

interface IProps {
  item?: any;
  fParams: any;
}

const Charges = ({ item, fParams }: IProps) => {
  const methods = useFormContext<IFormData>();

  const [data] = useWatch({
    control: methods.control,
    name: ['charges']
  });

  const onEditSuccess = useCallback(
    (v: any) => {
      UIUtils.popup.dismiss();
      if (v?.isDelete) {
        methods.setValue(
          'charges',
          data.filter(i => i.companyTariffId !== v.companyTariffId),
          { shouldDirty: true }
        );
      } else {
        const index = data.findIndex(
          i => i.companyTariffId === v.companyTariffId
        );
        if (index !== -1) {
          data[index] = {
            ...data[index],
            ...v
          };
        }

        methods.setValue('charges', data, { shouldDirty: true });
      }
    },
    [data, methods]
  );

  const onShowPopup = useCallback(
    (v?: any) => {
      UIUtils.popup.open({
        title: trans('edit_price_of_charge_code'),
        content: () => (
          <EditChargeCodePrice
            item={v}
            chargeCodeIds={data.map(i => i.companyTariff.chargeCodeId)}
            onSuccess={onEditSuccess}
          />
        )
      });
    },
    [data, onEditSuccess]
  );

  const columns = useChargeColumns(data, { onShowPopup });

  const table = useLocalTable({
    name: WebTable.OTHER,
    data,
    columns,
    onRefresh: () =>
      methods.setValue('charges', item?.quotationRequestCharges ?? []),
    otherOptions: {
      selectableRows: 'none'
    }
  });

  const onClientTariffSuccess = useCallback(
    (v: any[]) => {
      const newCharges = [
        ...data,
        ...v.map(i => ({
          ...i,
          quotationRequestChargeComponents: i.components
        }))
      ];
      methods.setValue('charges', newCharges, { shouldDirty: true });
    },
    [data, methods]
  );

  const onCompanyTariffSuccess = useCallback(
    (v: any) => {
      const newCharges = [...data, ...mapChargeByCompanyTariff([v])];
      methods.setValue('charges', newCharges, {
        shouldDirty: true
      });
    },
    [data, methods]
  );

  const onAdd = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      if (!!fParams?.isAgent) {
        UIUtils.popup.open({
          title: trans('add_extra_company_tariff_from_client_tariff'),
          maxWidth: 'md',
          content: () => (
            <ExtraFromClientTariff
              fParams={fParams}
              onSuccess={onClientTariffSuccess}
            />
          )
        });
      } else {
        UIUtils.popup.open({
          title: trans('add_extra_company_tariff'),
          maxWidth: 'md',
          content: () => (
            <ExtraCompanyTariff
              fParams={fParams}
              onSuccess={onCompanyTariffSuccess}
              chargeCodeIds={data
                .map(i => i?.companyTariff?.chargeCodeId)
                .filter(i => i)}
            />
          )
        });
      }
    }
  }, [data, fParams, methods, onClientTariffSuccess, onCompanyTariffSuccess]);

  return (
    <KContainer.Card
      border
      size="nm"
      header={{
        title: trans('charge_code_details'),
        border: true
      }}
    >
      <DataTable {...table} showDownload={false} onAdd={() => onAdd()} />
    </KContainer.Card>
  );
};

export default memo(Charges);
