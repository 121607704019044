import {
  GlobalEvent,
  apiInstance,
  apiInstanceWithoutBase,
  updateAuthorizationToken
} from '@constants';
import { AppEvent } from '@core';
import { BaseResponse } from '@dto';
import StorageEnhance, { STORAGE_KEYS } from '@utils/storage';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { toast } from 'react-toastify';
import trans from 'translation';
import { v4 as uuidv4 } from 'uuid';

interface IParams {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  url: string;
  configs?: AxiosRequestConfig;
  body?: any;
  showError?: boolean;
  showToast?: boolean;
  noBase?: boolean;
}

apiInstance.interceptors.request.use((config: any) => {
  config.url = config.baseURL + config.url;

  const accessToken = Cookies.get('accessToken');
  const localUser = StorageEnhance.get(STORAGE_KEYS.user);
  if (accessToken || localUser?.token) {
    config.headers.Authorization = `Bearer ${accessToken || localUser?.token}`;
  }

  config.headers = {
    ...config.headers,
    'X-Request-Id': uuidv4()
  };

  return config;
});

apiInstanceWithoutBase.interceptors.request.use((config: any) => {
  config.url = 'http://localhost:3002' + config.url;

  const accessToken = Cookies.get('accessToken');
  const localUser = StorageEnhance.get(STORAGE_KEYS.user);
  if (accessToken || localUser?.token) {
    config.headers.Authorization = `Bearer ${accessToken || localUser?.token}`;
  }

  config.headers = {
    ...config.headers,
    'X-Request-Id': uuidv4()
  };

  return config;
});

class APIManager {
  static initialize = () => {
    const localUser = StorageEnhance.get(STORAGE_KEYS.user);
    if (localUser?.token) {
      updateAuthorizationToken(localUser.token);
    }
  };

  static prepareParams = (params: Record<string, any>) => {
    return params ? `?${qs.stringify(params)}` : '';
  };

  static request = async ({
    method = 'GET',
    url,
    configs,
    body,
    showToast = false,
    showError = true,
    noBase = false
  }: IParams): Promise<BaseResponse<any>> => {
    try {
      const instance: any = noBase ? apiInstanceWithoutBase : apiInstance;
      const res =
        method === 'GET' || method === 'DELETE'
          ? await instance[method.toLowerCase()](
              body ? `${url}?${qs.stringify(body)}` : url,
              configs
            )
          : await instance[method.toLowerCase()](url, body, configs);

      const isBlob = configs?.responseType === 'blob';

      if (
        !res ||
        (!res.data?.success && !isBlob && !url.includes('/export-excel'))
      ) {
        throw {
          ...(res?.data ?? {}),
          code: (res as any).code,
          status: res.status
        };
      } else {
        if (isBlob || url.includes('/export-excel')) {
          return Promise.resolve({
            data: res.data,
            error: undefined
          });
        } else {
          if (showToast) {
            toast.success(res.data.message);
          }
          return Promise.resolve({
            data: res.data.payload,
            error: undefined,
            success: res.data.success
          });
        }
      }
    } catch (e: any) {
      const { response, message } = e || {};
      if (!response) {
        toast.error(message, {
          toastId: 'api-status-network'
        });

        return Promise.resolve({
          data: undefined,
          error: e,
          eData: undefined
        });
      } else {
        if (response.data?.code === 401) {
          toast.error(trans('session_expired'), {
            toastId: 'api-status-401'
          });
          AppEvent.dispatch(GlobalEvent.SignOut);
        } else {
          if (showError) {
            toast.error(
              response.data?.message ?? trans('something_went_wrong'),
              {
                toastId: `api-status-${response.data?.code || '500'}`
              }
            );
          }
        }
        return Promise.resolve({
          data: undefined,
          error: e,
          eData: response?.data
        });
      }
    }
  };
}

export default APIManager;

export * from './config';
