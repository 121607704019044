import { ArApType, CreditPaymentType } from '@constants';
import { Prototype } from '@core';
import { isNumber } from 'lodash';
import { useEffect } from 'react';

import { useFetchAccountSetting } from '../client';

interface IUseInvoiceEffect {
  invoiceDate?: any;
  clientId?: number;
  arApType: ArApType;
  cb?: (dueDate?: any) => void;
  isEdit?: boolean;
}

export const useInvoiceEffect = (options: IUseInvoiceEffect) => {
  const { clientId, invoiceDate, arApType, cb, isEdit } = options;

  const { data: accountSetting } = useFetchAccountSetting({
    id: clientId,
    accountSettingType: arApType
  });

  const { paymentType, creditHold } = accountSetting || {};

  useEffect(() => {
    if (
      !isEdit &&
      invoiceDate &&
      paymentType &&
      paymentType !== CreditPaymentType.Cod &&
      isNumber(creditHold)
    ) {
      const dueDate = Prototype.date
        .toLocal(invoiceDate)
        ?.add(Number(creditHold), 'days');
      cb?.(dueDate);
    }
  }, [cb, creditHold, invoiceDate, isEdit, paymentType]);
};
