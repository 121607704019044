import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useGenericMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchContainer = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.container, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob('job-container/:id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchContainerList = (transportJobId?: number | string) => {
  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.containerList,
      transportJobId ? parseInt(transportJobId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob(':id/job-container', {
          id: transportJobId
        }),
        body: {
          excludeFields: [
            'transportJob',
            'jobContainerRoutings',
            'currentRouting'
          ]
        }
      });

      return res.data?.data;
    },
    enabled: !!transportJobId
  });
};

export const useCUDContainer = (transportJobId?: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.transportJob(':id/job-container', {
      id: transportJobId
    }),
    webTable: WebTable.CONTAINER,
    mutationKeys: [[QUERY_KEYS.containerRouting]]
  });
};

export const useCheckContainerNo = () => {
  return useMutationEnhancer<
    any[],
    { containerNumber: string; excludeId?: number }
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob('job-container/check-duplicate-container'),
        method: 'POST',
        body: data
      });

      return res.data;
    }
  });
};

export const useCloneContainer = () => {
  return useGenericMutationEnhancer<{
    id: number;
    containerId: number;
  }>({
    endPoint: ENDPOINTS.transportJob(':id/job-container/:containerId/clone'),
    method: 'POST',
    webTable: WebTable.CONTAINER
  });
};
