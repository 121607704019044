import { UIMenuItem, UITab } from '@constants';
import {
  useAccountingContext,
  withAccountingProvider
} from '@context/Accounting';
import { useCheckScreenWithCallback } from '@hooks';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KTab, KTabInstance } from 'uikit';
import { useMount } from 'uikit-common';

import Purchases from './Accounting.Purchases';
import Sales from './Accounting.Sales';

const MAPPED_CODES = {
  [UITab.AccountingSales]: UITab.AccountingSales,
  [UITab.AccountingArInvoices]: UITab.AccountingSales,
  [UITab.AccountingArClientsSummary]: UITab.AccountingSales,

  [UITab.AccountingPurchases]: UITab.AccountingPurchases,
  [UITab.AccountingApInvoices]: UITab.AccountingPurchases,
  [UITab.AccountingApWipInvoices]: UITab.AccountingPurchases,
  [UITab.AccountingApAccounts]: UITab.AccountingPurchases,

  [UITab.AccountingReport]: UITab.AccountingReport
};

const MAPPED_NAMES = {
  [UITab.AccountingSales]: 'ar_invoices',
  [UITab.AccountingArInvoices]: 'ar_invoices',
  [UITab.AccountingArClientsSummary]: 'ar_clients_summary',

  [UITab.AccountingPurchases]: 'ap_invoices',
  [UITab.AccountingApInvoices]: 'ap_invoices',
  [UITab.AccountingApWipInvoices]: 'ap_wip_invoices',
  [UITab.AccountingApAccounts]: 'ap_accounts'
};

const Accounting = () => {
  const tabRef = useRef<KTabInstance>(null);

  const { setTab, tab, setClientId } = useAccountingContext();

  const [tabIndex, setTabIndex] = useState(0);

  const cb = useCallback(() => {
    let _tabs = [
      {
        code: UITab.AccountingSales,
        label: trans('sales')
      },
      {
        code: UITab.AccountingPurchases,
        label: trans('purchases')
      },
      {
        code: UITab.AccountingReport,
        label: trans('report')
      }
    ];

    return _tabs;
  }, []);

  const tabs = useCheckScreenWithCallback(cb, UIMenuItem.Accounting);

  const onChangeTab = useCallback(
    (index: number) => {
      const url = new URL(window.location as any);
      const _tabCode = url.searchParams.get('tab');
      const _tab = _tabCode ? MAPPED_CODES[_tabCode] : undefined;

      setTabIndex(index);
      setTab(tabs?.[index]?.code);
      setClientId(undefined);

      if (_tab !== tabs?.[index]?.code) {
        url.searchParams.set('tab', tabs?.[index]?.code);
      }
      window.history.replaceState({}, '', url);
    },
    [setClientId, setTab, tabs]
  );

  useMount(() => {
    const url = new URL(window.location as any);

    const _tabCode = url.searchParams.get('tab');
    const _tab = _tabCode
      ? tabs.findIndex(i => i.code === MAPPED_CODES[_tabCode])
      : -1;

    if (_tab >= 0) {
      tabRef.current?.onChange(_tab, true);
    }
  });

  const breadcrumbs = useMemo(() => {
    const arr = [trans('routes.accounting')];
    arr.push(tabs?.[tabIndex]?.label);
    if (tab) {
      arr.push(trans(MAPPED_NAMES[tab]));
    }
    return arr;
  }, [tab, tabIndex, tabs]);

  return (
    <>
      <KBreadcrumbs hasBackIcon={false} breadcrumbs={breadcrumbs} />

      <KContainer.View padding="0.75rem">
        <KTab.White
          ref={tabRef}
          tabIndex={tabIndex}
          onChangeTab={onChangeTab}
          tabs={tabs}
          brBL={0}
          brBR={0}
        />

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.code === UITab.AccountingSales)}
        >
          <Sales />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.code === UITab.AccountingPurchases)}
        >
          <Purchases />
        </KTab.Panel>

        <KTab.Panel
          value={tabIndex}
          index={tabs.findIndex(i => i.code === UITab.AccountingReport)}
        >
          {/* <Invoice /> */}
        </KTab.Panel>
      </KContainer.View>
    </>
  );
};

export default memo(withAccountingProvider(Accounting));
