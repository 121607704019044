import {
  ENDPOINTS,
  QUERY_KEYS,
  ShipmentType,
  Status,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

interface IZoneParams {
  suburbId: number;
  shipmentType: ShipmentType;
}

export const useFetchQuotationRequest = (
  quotationId?: string | number,
  id?: number | string,
  needRefetch?: boolean
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.quotationRequest,
      quotationId ? parseInt(quotationId as string) : null,
      id ? parseInt(id as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.quotation(':id/quotation-request/:requestId', {
          id: quotationId,
          requestId: id
        })
      });

      return res.data;
    },
    enabled: !!quotationId && !!id && needRefetch
  });
};

export const useCUDQuotationRequest = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.quotation(':id/quotation-request', { id }),
    webTable: WebTable.QUOTATION_REQUEST
  });
};

export const useFetchZoneFromSuburb = () => {
  return useMutationEnhancer<any, IZoneParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.zoneSuburb(),
        body: {
          page: 0,
          size: 1,
          status: Status.Active,
          ...data
        }
      });

      return res.data?.data?.[0]?.zone;
    }
  });
};

export const useFetchCompanyTariffForQuotationRequest = () => {
  return useMutationEnhancer<any[], any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.companyTariff('for-quotation-request'),
        body: data
      });

      return res.data?.data ?? [];
    }
  });
};

export const useFetchClientTariffChargeForQuotationRequest = () => {
  return useMutationEnhancer<any[], any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientTariffCharge('for-quotation-request'),
        body: data
      });

      return res.data?.data ?? [];
    }
  });
};

export const useFetchQuotationRequestList = (quotationId?: number | string) => {
  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.quotationRequestList,
      quotationId ? parseInt(quotationId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.quotation(':id/quotation-request', {
          id: quotationId
        }),
        body: { excludeFields: ['quotationRequestCharges', 'quotation'] }
      });

      return res.data?.data;
    },
    enabled: !!quotationId
  });
};
