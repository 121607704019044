import { useForm } from '@hooks';
import { t } from '@lingui/macro';
import { ToastManager } from '@utils';
import { isEmpty } from 'lodash';
import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { Controller } from 'react-hook-form';
import { KButton, KContainer, KGrid, KLabel, KListItem } from 'uikit';
import * as yup from 'yup';

import { APopup } from 'components';

interface IParams {
  columns: any[];
  onDownload: () => void;
}

export const downloadFile = ({ contentType, fileName, base64 }) => {
  const a = document.createElement('a'); // Create tag <a>
  a.href = `data:${contentType};base64,${base64}`; // File with base64 goes here
  a.download = fileName; // File name here
  a.click(); // Downloaded file
  a.remove();
};

const DownloadCsvContext = createContext();

const DownloadCsvProvider = ({ children }) => {
  const onDownload = useRef(() => {});

  const dialogRef = useRef();

  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const show = useCallback((payload: IParams) => {
    if (!isEmpty(payload)) {
      onDownload.current = payload.onDownload;
      setColumns(payload.columns);
    }
    dialogRef.current?.show();
  }, []);

  const onDismissed = useCallback(() => {
    onDownload.current = () => {};

    setColumns([]);
    setIsLoading(false);
  }, []);

  const validationSchema = useCallback(() => yup.object().shape({}), []);

  const initValues = useMemo(() => {
    return {
      columns: columns,
      sortBy: null,
      isChecked: true
    };
  }, [columns]);

  const form = useForm(validationSchema, initValues);

  const mColumns = form.watch('columns', []);

  const onFormValid = useCallback(
    async v => {
      const sColumns = v.columns.filter(i => i.isSelected);
      const mParams = {
        columns: sColumns.map(i => i.mappedName).join(','),
        fieldNameMap: JSON.stringify(
          sColumns.reduce((acc, cur) => {
            return {
              ...acc,
              [cur.mappedName]: cur.label
            };
          }, {})
        ),
        sortBy: v.sortBy
      };

      setIsLoading(true);
      const { data } = await onDownload.current?.(mParams);
      if (data) {
        downloadFile({
          contentType: data.contentType,
          fileName: data.fileName,
          base64: data.bytes
        });
        form?.reset?.();
        dialogRef?.current?.hide();
      } else {
        ToastManager.notify();
      }
      setIsLoading(false);
    },
    [form]
  );

  const renderHeader = useMemo(() => {
    return (
      <KGrid.Item xs={12}>
        <KContainer.View row alignItems>
          <KContainer.View flex={4} row alignItems>
            <Controller
              control={form.control}
              render={({ field: { value, onChange } }) => {
                return (
                  <KListItem.Checkbox
                    name="isChecked"
                    label={t`All Columns`}
                    typo="TextXNmMedium"
                    checked={value}
                    onChange={v => {
                      form.setValue(
                        'columns',
                        mColumns.map(i => ({
                          ...i,
                          isSelected: v
                        }))
                      );
                      onChange(v);
                    }}
                  />
                );
              }}
              name="isChecked"
            />
          </KContainer.View>

          <KContainer.View dp="flex" flex alignItems>
            <KLabel.Text typo="TextXNmMedium">{t`Ascending`}</KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex alignItems>
            <KLabel.Text typo="TextXNmMedium">{t`Descending`}</KLabel.Text>
          </KContainer.View>
        </KContainer.View>
      </KGrid.Item>
    );
  }, [form, mColumns]);

  const renderContent = useMemo(() => {
    return mColumns.map((i, idx) => {
      const sortDisabled = !i.allowedToSort || !i.isSelected;

      return (
        <KGrid.Item xs={12} key={`${JSON.stringify(i)}`}>
          <KContainer.View row alignItems marginT="0.5rem">
            <KContainer.View dp="flex" flex={4}>
              <Controller
                control={form.control}
                render={({ field }) => {
                  return (
                    <KListItem.Checkbox
                      name={`columns[${idx}].isSelected`}
                      label={i.label}
                      checked={i.isSelected}
                      {...field}
                    />
                  );
                }}
                name={`columns[${idx}].isSelected`}
              />
            </KContainer.View>

            <KContainer.View dp="flex" flex alignItems>
              <Controller
                control={form.control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <KListItem.Radio
                      name="sortBy-asc"
                      checked={value === `${i.mappedName}:ASC`}
                      disabled={sortDisabled}
                      onChange={v => {
                        if (v) {
                          onChange(`${i.mappedName}:ASC`);
                        } else {
                          onChange(null);
                        }
                      }}
                    />
                  );
                }}
                name="sortBy"
              />
            </KContainer.View>

            <KContainer.View dp="flex" flex alignItems>
              <Controller
                control={form.control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <KListItem.Radio
                      name="sortBy-desc"
                      checked={value === `${i.mappedName}:DESC`}
                      disabled={sortDisabled}
                      onChange={v => {
                        if (v) {
                          onChange(`${i.mappedName}:DESC`);
                        } else {
                          onChange(null);
                        }
                      }}
                    />
                  );
                }}
                name="sortBy"
              />
            </KContainer.View>
          </KContainer.View>
        </KGrid.Item>
      );
    });
  }, [form.control, mColumns]);

  return (
    <DownloadCsvContext.Provider value={{ show }}>
      {children}

      <APopup
        title={t`Download Excel`}
        ref={dialogRef}
        onDismissed={onDismissed}
      >
        <form onSubmit={form.handleSubmit(onFormValid)}>
          <KGrid.Container>
            {renderHeader}

            {renderContent}

            <KGrid.Item xs={12} container justifyContent="flex-end">
              <KButton.Solid
                type="submit"
                isLoading={isLoading}
                title={t`Download`}
                marginT="0.5rem"
              />
            </KGrid.Item>
          </KGrid.Container>
        </form>
      </APopup>
    </DownloadCsvContext.Provider>
  );
};

export { DownloadCsvContext, DownloadCsvProvider };
