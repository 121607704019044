import request from '@utils/requestHelper';

export const changePasswordService = payload => {
  return request.putAsync('/user/change-password', payload);
};

export const getAuthProfileService = () => {
  return request.getAsync('/user/current-profile');
};

export const updateAuthProfileService = payload => {
  return request.putAsync('/user/current-profile', payload);
};
