import { useCallback, useState } from 'react';

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const triggerPopper = useCallback(
    e => {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    },
    [anchorEl]
  );

  const handleClickAway = useCallback(
    e => {
      e?.stopPropagation?.();
      if (anchorEl) {
        setAnchorEl(null);
      }
    },
    [anchorEl]
  );

  return [anchorEl, triggerPopper, handleClickAway];
};

export default useAnchorEl;
