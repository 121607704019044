import { StorageKeys, WebTable } from '@constants';
import { AppStorage } from '@core';
import { UIUtils } from '@utils';
import { ColDef, Column } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep, set } from 'lodash';
import React, { memo, useCallback, useRef, useState } from 'react';
import trans from 'translation';
import { KButton, KContainer, KLabel, KListItem } from 'uikit';

interface IProps<T> {
  id: string;
  name: WebTable;

  showViewColumns?: boolean;

  rightNode?: JSX.Element;

  gridRef: React.RefObject<AgGridReact<T>>;

  onRefresh?: () => void;
}

const RightActions = <T extends any>({
  id,
  name,
  showViewColumns,
  rightNode,
  gridRef,
  onRefresh
}: IProps<T>) => {
  const colRef = useRef<any>(null);

  const onColDefsChange = useCallback(
    (keys: (string | Column)[], visible: boolean) => {
      gridRef.current?.api.setColumnsVisible(keys, visible);
      const agGridConfigs = AppStorage.get(StorageKeys.agGrid) ?? {};
      const _configs: any = cloneDeep(agGridConfigs);
      set(
        _configs,
        `columnStates.${name}`,
        gridRef.current?.api.getColumnState()
      );
      AppStorage.set(StorageKeys.agGrid, _configs);
    },
    [gridRef, name]
  );

  const onViewColumns = useCallback(() => {
    const colDefs = gridRef.current?.api.getColumnDefs();

    if (colDefs) {
      UIUtils.popper.open({
        anchorEl: colRef.current,
        placement: 'bottom-end',
        touchOutsideToDismiss: true,
        withMaxZIndex: true,
        cardProps: { marginT: '0.75rem', minW: 180, overflow: 'auto' },
        content: () => (
          <ViewColumns
            colDefs={colDefs as any}
            onColDefsChange={onColDefsChange}
          />
        )
      });
    }
  }, [gridRef, onColDefsChange]);

  return (
    <KContainer.View row alignItems gap="0.5rem" key={`ag-table-right-${id}`}>
      {rightNode}

      {showViewColumns && (
        <KButton.Icon
          ref={colRef}
          key={`ag-table-column-${id}`}
          icon="ViewColumn"
          onPress={onViewColumns}
        />
      )}

      {onRefresh && (
        <KButton.Icon
          key={`ag-table-refresh-${id}`}
          icon="RefreshOutlined"
          onPress={onRefresh}
        />
      )}
    </KContainer.View>
  );
};

export default memo(RightActions);

interface IViewColumnsProps<T extends any> {
  colDefs: ColDef<T>[];
  onColDefsChange: (keys: (string | Column)[], visible: boolean) => void;
}

const ViewColumns = memo(
  <T extends any>({ colDefs, onColDefsChange }: IViewColumnsProps<T>) => {
    const [state, setState] = useState<ColDef<T>[]>(
      colDefs.filter(i => !!i.headerName?.trim())
    );

    const onChange = useCallback(
      (v: ColDef<T>, checked: boolean) => {
        const _colDefs = cloneDeep(state);
        onColDefsChange([v.colId as string], checked);
        setState(
          _colDefs.map(i => {
            if (i.colId === v.colId) {
              i.hide = !checked;
            }

            return i;
          })
        );
      },
      [onColDefsChange, state]
    );

    return (
      <>
        <KLabel.Paragraph typo="TextLgMedium" marginB="0.75rem">
          {trans('view_columns')}
        </KLabel.Paragraph>

        <KListItem.CheckboxGroup
          data={state.map(i => {
            return {
              id: i.field,
              label: i.headerName,
              checked: !i.hide,
              onChange: v => onChange(i, v)
            };
          })}
        />
      </>
    );
  }
);
