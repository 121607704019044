import { WebTable } from '@constants';
import AgTable from '@containers/AgTable';
import { useAgTable } from '@hooks';
import { ITransportJobReportDataResponse } from '@request-dto';
import React, { memo, useMemo } from 'react';
import trans from 'translation';
import { KButton, KContainer, KLabel } from 'uikit';

import { useOnExportParams } from '../../helpers';

interface IProps {
  data?: ITransportJobReportDataResponse;
  document?: any;
  client?: any;
}

const ConsignorConsignee = ({ data, document, client }: IProps) => {
  const colDefs = useMemo(() => {
    return [
      {
        headerName: trans('job_number'),
        field: 'jobNumber'
      },
      {
        headerName: trans('container_number'),
        field: 'containerNumber',
        minWidth: 160
      },
      {
        headerName: trans('job_type'),
        field: 'jobType'
      },
      {
        headerName: trans('drop_mode'),
        field: 'dropMode',
        minWidth: 150
      },
      {
        headerName: trans('delivery_pickup_warehouse_code'),
        field: 'warehouseClientCode',
        minWidth: 240
      },
      {
        headerName: trans('delivery_pickup_warehouse_name'),
        field: 'warehouseClientName',
        minWidth: 245
      },
      {
        headerName: trans('delivery_pickup_warehouse_address'),
        field: 'warehouseClientAddress',
        minWidth: 255
      },
      {
        headerName: trans('account_receivable_code'),
        field: 'arClientCode',
        minWidth: 210
      },
      {
        headerName: trans('account_receivable_name'),
        field: 'arClientName',
        minWidth: 220
      },
      {
        headerName: trans('account_receivable_address'),
        field: 'arClientAddress',
        minWidth: 255
      },
      {
        headerName: trans('agent_code'),
        field: 'agentClientCode'
      },
      {
        headerName: trans('agent_name'),
        field: 'agentClientName',
        minWidth: 220
      },
      {
        headerName: trans('agent_address'),
        field: 'agentClientAddress',
        minWidth: 255
      },
      {
        headerName: trans('job_status'),
        field: 'jobProgress'
      },
      {
        headerName: trans('customer_reference'),
        field: 'referenceNumber',
        minWidth: 170
      },
      {
        headerName: trans('vessel'),
        field: 'vessel'
      },
      {
        headerName: trans('lloyd'),
        field: 'vessel'
      },
      {
        headerName: trans('voyage'),
        field: 'voyage'
      },
      {
        headerName: trans('unloco_board_of_loading'),
        field: 'unlocoBoardOfLoading',
        minWidth: 180
      },
      {
        headerName: trans('unloco_board_of_discharge'),
        field: 'unlocoBoardOfDischarge',
        minWidth: 180
      },
      {
        headerName: trans('eta'),
        field: 'eta'
      },
      {
        headerName: trans('etd'),
        field: 'etd'
      },
      {
        headerName: trans('imp_available_date'),
        field: 'avail'
      },
      {
        headerName: trans('imp_storage_start_date'),
        field: 'stor'
      },
      {
        headerName: trans('1st_free_date'),
        field: 'firstFreeDate'
      },
      {
        headerName: trans('discharge_date'),
        field: 'firstDischargeDate'
      },
      {
        headerName: trans('exp_receival_commencement_date'),
        field: 'expRecvDate'
      },
      {
        headerName: trans('cargo_cutoff_date'),
        field: 'cutOffDate'
      },
      {
        headerName: trans('reefer_cutoff_date'),
        field: 'reeferCutoffDate'
      },
      {
        headerName: trans('empty_receival_date'),
        field: 'emptyReceivalCommencementDate'
      },
      {
        headerName: trans('empty_cutoff_date'),
        field: 'emptyCutoffDate'
      },
      {
        headerName: trans('hazardous_receival_date'),
        field: 'hazardousReceivalCommencementDate'
      },
      {
        headerName: trans('hazardous_cutoff_date'),
        field: 'hazardousCutoffDate'
      },
      {
        headerName: trans('container_status'),
        field: 'containerProgress'
      },
      {
        headerName: trans('invoice_status'),
        field: 'containerInvoiceStatus'
      },
      {
        headerName: trans('container_size'),
        field: 'containerSize'
      }
    ];
  }, []);

  const onExportParams = useOnExportParams(data, document, client);

  const table = useAgTable({
    name: WebTable.OTHER,
    data: data?.items ?? [],
    colDefs,
    onExportParams
  });

  return (
    <KContainer.View position="relative">
      <KContainer.View
        row
        alignItems
        justifyContent="space-between"
        marginB="0.75rem"
      >
        <KLabel.Text typo="TextMdMedium">{document?.name}</KLabel.Text>

        <KButton.Solid
          title={trans('export_excel')}
          onPress={() => table.onExport(document?.name)}
          disabled={!document}
        />
      </KContainer.View>

      <AgTable {...table} />
    </KContainer.View>
  );
};

export default memo(ConsignorConsignee);
