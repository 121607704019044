import i18n from 'i18next';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import numeral from 'numeral';
import { initReactI18next } from 'react-i18next';

import 'moment/locale/vi';
import 'moment/locale/en-au';

import * as resources from './resources';
// import localeConfig from './localeConfig';

// @ts-ignore
const trans = (...args): string => i18n.t(...args) as any;

const setLanguage = (language: string) => {
  i18n.changeLanguage(language);
  moment.locale(language);
  momentTZ.localeData(language);

  // @ts-ignore
  //   numeral.register('locale', language, localeConfig[language]);
  numeral.locale(language);

  // @ts-ignore
  //   LocaleConfig.locales[language] = localeConfig[language];
};

const initLocalization = () => {
  const defaultLocale = 'en';
  i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: defaultLocale,
    lng: defaultLocale
  });
  setLanguage(defaultLocale);
};

const getLanguage = () => i18n.language;

export { i18n, initLocalization, setLanguage, getLanguage };

export default trans;
