import {
  DATE_FORMAT_SERVER,
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  EquipmentType,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { Tabs } from '@containers/Tabs';
import { Prototype } from '@core';
import { useDataTable, useGlobalTable } from '@hooks';
import { onShowEquipment } from '@pages/Equipment/helpers';
import { TableUtils, UIUtils } from '@utils';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel, KPicker } from 'uikit';

import Calendar from '../Schedule/Schedule.Calendar';

interface IProps {
  from: string;
}

const DamagedEquipment = ({ from }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [tab, setTab] = useState(EquipmentType.Vehicle);
  const [currentDate, setCurrentDate] = useState(from);

  const isCombination = useMemo(() => tab === EquipmentType.Combination, [tab]);

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('code'),
        name: 'code',
        options: {
          ...TableUtils.options.baseXLg,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowEquipment(item.id)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('name'),
        name: 'name',
        options: {
          ...TableUtils.options.baseXLg,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowEquipment(item.id)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('sub_type'),
        name: 'subType',
        options: {
          ...TableUtils.options.chip({ uppercase: true }),
          ...TableUtils.options.base2XLg
        }
      },
      {
        label: trans('working_condition'),
        name: 'workingCondition',
        options: TableUtils.options.chip({ uppercase: true })
      },
      {
        label: trans('last_faulty_report_date'),
        name: 'lastFaultyReportDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('level_of_fault'),
        name: 'levelOfFault',
        options: TableUtils.options.chip({ uppercase: true })
      },
      {
        label: trans('expected_good_condition_date'),
        name: 'expectedGoodConditionDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('registration_expiry_date'),
        name: 'registrationExpiredDate',
        options: {
          ...TableUtils.options.toggle(!isCombination),
          ...TableUtils.options.date(DATE_FORMAT_SHORT)
        }
      }
    ],
    [isCombination]
  );

  const otherParams = useMemo(() => {
    return {
      equipmentType: tab,
      subType: tab === EquipmentType.Vehicle ? 'SUB_TRUCK' : undefined,
      filterDamaged: true,
      filterDamagedFromDate: currentDate
    };
  }, [currentDate, tab]);

  const table = useDataTable({
    name: WebTable.TRANSPORT_JOB_AR_INVOICE,
    apiURL: ENDPOINTS.equipment(),
    columnsFunc,
    // mappedFields,
    otherParams,
    showFilter: false
  });

  useGlobalTable(table);

  const onChangeCalendar = useCallback((v: any) => {
    setCurrentDate(v);
  }, []);

  const onOpenCalendar = useCallback(() => {
    UIUtils.popper.dismiss();
    setTimeout(
      () =>
        UIUtils.popper.open({
          anchorEl: ref.current,
          placement: 'bottom-start',
          content: () => (
            <Calendar
              current={from}
              isFetchData={false}
              onChange={onChangeCalendar}
            />
          ),
          maxW: 700,
          touchOutsideToDismiss: true,
          withMaxZIndex: true
        }),
      100
    );
  }, [onChangeCalendar, from]);

  const onNext = useCallback(() => {
    if (currentDate) {
      setCurrentDate(
        (Prototype.date.toMoment(currentDate) as any)
          .clone()
          .add('1', 'days')
          .format(DATE_FORMAT_SERVER)
      );
    }
  }, [currentDate]);

  const onPrevious = useCallback(() => {
    if (currentDate) {
      setCurrentDate(
        (Prototype.date.toMoment(currentDate) as any)
          .clone()
          .subtract('1', 'days')
          .format(DATE_FORMAT_SERVER)
      );
    }
  }, [currentDate]);

  return (
    <>
      <KContainer.View row alignItems justifyContent="space-between">
        <Tabs.Equipment onChangeTab={setTab} disabled={table.isLoading} />

        <KContainer.View row alignItems>
          <KContainer.Touchable
            ref={ref}
            width={200}
            onPress={onOpenCalendar}
            disabled={table.isLoading}
          >
            <KPicker.Date
              name="scheduleDate"
              label={trans('schedule_date')}
              value={currentDate}
              onChange={() => {}}
              readOnly
            />
          </KContainer.Touchable>

          <KButton.Icon
            icon="KeyboardArrowLeft"
            tintColor={KColors.primary.normal}
            background={KColors.white}
            brW={1}
            size="md"
            br="x"
            hasShadow
            marginL="0.75rem"
            onPress={onPrevious}
            disabled={
              table.isLoading ||
              currentDate === Prototype.date.now().format(DATE_FORMAT_SERVER)
            }
          />

          <KButton.Icon
            icon="KeyboardArrowRight"
            tintColor={KColors.primary.normal}
            background={KColors.white}
            brW={1}
            size="md"
            br="x"
            hasShadow
            marginL="0.75rem"
            onPress={onNext}
            disabled={table.isLoading}
          />
        </KContainer.View>
      </KContainer.View>

      <DataTable {...table} />
    </>
  );
};

export default memo(DamagedEquipment);
