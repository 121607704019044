import { ArApType, TableName } from '@constants';
import DataTable from '@containers/DataTable';
import { useAccountingContext } from '@context/Accounting';
import { useDataTable } from '@hooks';
import { TableUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KContainer, KLabel } from 'uikit';

import { OPTIONS } from './helpers';

const StatementList = () => {
  const { setClientId, arApType } = useAccountingContext();

  const options = OPTIONS[arApType as ArApType];

  const onShowStatement = useCallback(
    (item: any) => {
      setClientId(item?.id);
    },
    [setClientId]
  );

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('view'),
            icon: 'Visibility',
            onPress: () => onShowStatement(item)
          }
        ])
      },
      {
        label: trans('code'),
        name: 'code',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowStatement(item)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('name'),
        name: 'name'
      },
      {
        label: trans('email'),
        name: 'email'
      },
      {
        label: trans('address'),
        name: 'address',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('outstanding_balance'),
        name: 'outstanding',
        options: TableUtils.options.currency()
      },
      {
        label: trans('overdue'),
        name: 'overdue',
        options: TableUtils.options.currency()
      }
    ],
    [onShowStatement]
  );

  const table = useDataTable({
    name: options.webTable.statement,
    tableName: TableName.STATEMENT,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: options.apiURL,
    columnsFunc
  });

  return (
    <>
      {/* <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.accounting'), trans('routes.statements')]}
      /> */}

      <KContainer.Card
        marginT="0.25rem"
        header={{
          icon: 'InfoOutlined',
          title: trans(options.title.statement)
        }}
      >
        <DataTable {...table} showDownload={false} />
      </KContainer.Card>
    </>
  );
};

export default memo(StatementList);
