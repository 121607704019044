import { DashboardSection } from '@constants';
import { useUser } from '@hooks';
import { useCallback, useMemo } from 'react';
import trans from 'translation';

export const useDashboardItem = () => {
  const { account } = useUser();
  const { features = [], isFullRole, userScreens = [] } = account || {};

  const showDashboard = useMemo(
    () =>
      isFullRole ||
      (userScreens ?? []).some(s => s.screen?.code?.includes('DASHBOARD')),
    [isFullRole, userScreens]
  );

  const onCheckDashboardItem = useCallback(
    (e: DashboardSection) => {
      return (
        isFullRole || (userScreens ?? []).some(s => s.screen?.code?.includes(e))
      );
    },
    [isFullRole, userScreens]
  );

  return {
    showDashboard,
    showTransportJobs: onCheckDashboardItem(DashboardSection.TransportJobs),
    showContainers: onCheckDashboardItem(DashboardSection.Containers),
    showPayments: onCheckDashboardItem(DashboardSection.Payments),
    showIssues: onCheckDashboardItem(DashboardSection.Issues),
    showAllocation: onCheckDashboardItem(DashboardSection.Allocation),
    showInvoiceOverdue: onCheckDashboardItem(DashboardSection.InvoiceOverdue),
    showAROverdue: onCheckDashboardItem(DashboardSection.AROverdue),
    showScheduleTripContainerWaitingTime: onCheckDashboardItem(
      DashboardSection.ScheduleTripContainerWaitingTime
    ),
    show1Stop: features.length > 0,

    onCheckDashboardItem
  };
};

export const useDashboardIssues = () => {
  const { onCheckDashboardItem } = useDashboardItem();

  const tabs = useMemo(() => {
    const _tabs = [
      {
        code: 'EQUIPMENT',
        label: trans('equipment')
      },
      {
        code: 'DRIVER',
        label: trans('driver')
      },
      {
        code: 'CLIENT_RATE',
        label: trans('client_rate')
      }
    ].filter(i =>
      onCheckDashboardItem(
        `${DashboardSection.Issues}_${i.code}` as DashboardSection
      )
    );

    return _tabs;
  }, [onCheckDashboardItem]);

  const onExtraField = useCallback((issue?: string) => {
    let field;
    switch (issue) {
      // EQUIPMENT
      case 'REGISTRATION_EXPIRED':
        field = 'registrationExpiredDate';
        break;

      case 'HVIS_INSPECTION_BY_DATE':
        field = 'hvisInspectionByDate';
        break;

      case 'FAULTY':
        field = 'lastFaultyReportDate';
        break;

      case 'IN_REPAIR':
        field = 'expectedGoodConditionDate';
        break;

      // DRIVER
      case 'LICENSE_EXPIRED':
        field = 'licenseExpiredDate';
        break;

      case 'MSIC_CARD_EXPIRED':
        field = 'msicExpiredDate';
        break;

      case 'END_WORKING_DATE':
        field = 'endWorkingDate';
        break;

      case 'BIRTHDATE':
        field = 'birthdate';
        break;

      // DRIVER
      // case 'CONTRACT_END':
      //   field = 'licenseExpiredDate';
      //   break;

      // case 'CONTRACT_REVIEW':
      //   field = 'licenseExpiredDate';
      //   break;

      default:
        break;
    }
    return field;
  }, []);

  return { tabs, onExtraField };
};

export const useDashboardAllocation = (count: number) => {
  const tabs = useMemo(() => {
    const _tabs = [
      {
        code: 'REPORTS',
        label: trans('reports')
      },
      {
        code: 'UNSEEN_MESSAGES',
        label: trans('unseen_messages', { count })
      }
    ];

    return _tabs;
  }, [count]);

  return { tabs };
};
