import 'perfect-scrollbar/css/perfect-scrollbar.css';

import styles from '@assets/jss/material-dashboard-react/layouts/adminStyle.js';
import { GlobalEvent, updateAuthorizationToken } from '@constants';
// import ChatwootWidget from '@containers/ChatwootWidget';
import { AppEvent } from '@core';
import { useUser } from '@hooks';
import { makeStyles } from '@material-ui/core/styles';
import Profile from '@pages/Profile/Profile';
import routes from '@routes';
import { IAdminRoute } from '@ui';
import StorageEnhance, { STORAGE_KEYS } from '@utils/storage';
import cx from 'classnames';
import CommonHeader from 'components/Header';
import Sidebar from 'components/Sidebar';
import { compact } from 'lodash';
import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles as any);

const AdminLayout = (props: any) => {
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  const { user, account } = useUser();

  useEffect(() => {
    if (!user || !user?.token) {
      AppEvent.dispatch(GlobalEvent.SignOut);
      const hostname = window.location.hostname;
      if (hostname === process.env.REACT_APP_BASE_DOMAIN) {
        history.replace('/auth/account-sign-in');
      } else {
        history.replace('/auth/sign-in');
      }
    } else if (user?.token) {
      StorageEnhance.set(STORAGE_KEYS.user, user);
      updateAuthorizationToken(user.token);
    }
  }, [history, user]);

  const userScreens = React.useMemo(
    () => account?.userScreens ?? [],
    [account?.userScreens]
  );

  const _routes: IAdminRoute[] = React.useMemo(
    () =>
      account?.isFullRole
        ? routes
        : compact(
            routes
              .filter(i =>
                userScreens.some(
                  s =>
                    (i.collapse && s.screen.menuGroup === i.key) ||
                    (!i.collapse &&
                      s.screen.menuItem === i.key &&
                      s.screen.code === i.code)
                )
              )
              .map(i => {
                if (i.collapse && i.views) {
                  const newRoute = {
                    ...i,
                    views: i.views.filter(v =>
                      userScreens.some(
                        s =>
                          s.screen.menuItem === v.key &&
                          s.screen.code === v.code
                      )
                    )
                  };
                  return newRoute.views.length > 0 ? newRoute : null;
                }

                return i;
              })
          ),
    [account?.isFullRole, userScreens]
  );

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive
    });

  const mainPanel = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);

    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getRoutes = (r?: IAdminRoute[]): any => {
    return (r ?? []).map((i, idx) => {
      if (i.collapse) {
        return getRoutes(i.views);
      }

      if (i.layout === '/admin') {
        return (
          <Route
            key={idx}
            path={i.layout + i.path}
            component={i.component}
            exact={i.exact}
          />
        );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Sidebar
          routes={_routes}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          miniActive={miniActive}
          {...props}
        />

        <div className={mainPanelClasses} ref={mainPanel}>
          <CommonHeader
            sidebarMinimize={sidebarMinimize.bind(this)}
            openMobileSidebar={handleDrawerToggle}
          />

          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(_routes)}
                  <Route
                    path={'/admin/profile'}
                    component={Profile}
                    key={_routes.length}
                  />
                  <Redirect from="/admin" to="/admin/dashboard" />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(_routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          )}
        </div>
      </div>

      {/* <ChatwootWidget /> */}
    </>
  );
};

export default memo(AdminLayout);
