import { useAlertMutationEnhancer, useCUDClientTariff } from '@hooks';
import qs from 'query-string';

export const onShowClientTariff = (
  id: number | string | 'new',
  query?: any
) => {
  window.open(
    `/admin/client-tariffs/${id}${query ? `?${qs.stringify(query)}` : ''}`,
    '_blank'
  );
};

export const useDeleteClientTariff = () => {
  const { deleteMutation } = useCUDClientTariff();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};
