import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KButton, KContainer, KInput } from 'uikit';

interface IProps {
  id: string;
  keyword?: string;

  leftNode?: JSX.Element;

  showSearch?: boolean;

  onAdd?: () => void;
  onSearch?: (keyword: string) => void;
}

const LeftActions = (props: IProps) => {
  const { id, leftNode, onAdd, showSearch, keyword, onSearch } = props;

  const onSearchWrapper = useCallback(
    (e: any) => {
      onSearch?.(e?.target?.value ?? '');
    },
    [onSearch]
  );

  return (
    <KContainer.View row alignItems gap="0.5rem">
      {onAdd && (
        <KButton.Icon
          key={`ag-table-add-${id}`}
          tight
          kind="primary"
          icon="AddBox"
          size="xlg"
          onPress={onAdd}
        />
      )}

      {showSearch && (
        <KInput.Search
          key={`ag-table-search-${id}`}
          name="agSearch"
          placeholder={trans('search_placeholder')}
          value={keyword}
          onChange={onSearchWrapper}
          minW={200}
          fullWidth={false}
        />
      )}

      {leftNode}
    </KContainer.View>
  );
};

export default memo(LeftActions);
