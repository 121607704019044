import React, { memo } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KGrid } from 'uikit';

import Allocation from './Allocation';
import ArOverdue from './AROverdue';
import Containers from './Containers';
import { useDashboardItem } from './helpers';
import InvoicesOverdue from './InvoicesOverdue';
import Issues from './Issues';
import Notifications from './Notifications';
import Notifications1Stop from './Notifications1Stop';
import Payments from './Payments';
import STCWaitingTime from './STCWaitingTime';
import TransportJobs from './TransportJobs';

const Dashboard = () => {
  const {
    showAROverdue,
    showAllocation,
    showContainers,
    showInvoiceOverdue,
    showIssues,
    showPayments,
    showTransportJobs,
    showScheduleTripContainerWaitingTime,
    show1Stop
  } = useDashboardItem();

  return (
    <KContainer.View marginB={'1rem'}>
      <KBreadcrumbs hasBackIcon={false} title={trans('dashboard')} />

      <KContainer.View padding="0.75rem">
        <KGrid.Container>
          <KContainer.RenderWhen>
            <KContainer.RenderWhen.If
              isTrue={showTransportJobs || showContainers || showPayments}
            >
              <KGrid.Item xs={12}>
                <KGrid.Container>
                  {showTransportJobs && (
                    <KGrid.Item xs={3}>
                      <TransportJobs />
                    </KGrid.Item>
                  )}

                  {showContainers && (
                    <KGrid.Item xs={6}>
                      <Containers />
                    </KGrid.Item>
                  )}

                  {showPayments && (
                    <KGrid.Item xs={3}>
                      <Payments />
                    </KGrid.Item>
                  )}
                </KGrid.Container>
              </KGrid.Item>
            </KContainer.RenderWhen.If>
          </KContainer.RenderWhen>

          <KGrid.Item xs={show1Stop ? 6 : 12}>
            <Notifications />
          </KGrid.Item>

          <KContainer.RenderWhen limit={6}>
            <KContainer.RenderWhen.If isTrue={show1Stop}>
              <KGrid.Item xs={6}>
                <Notifications1Stop />
              </KGrid.Item>
            </KContainer.RenderWhen.If>

            <KContainer.RenderWhen.If isTrue={showIssues}>
              <KGrid.Item xs={6}>
                <Issues />
              </KGrid.Item>
            </KContainer.RenderWhen.If>

            <KContainer.RenderWhen.If isTrue={showAllocation}>
              <KGrid.Item xs={6}>
                <Allocation />
              </KGrid.Item>
            </KContainer.RenderWhen.If>

            <KContainer.RenderWhen.If isTrue={showInvoiceOverdue}>
              <KGrid.Item xs={6}>
                <InvoicesOverdue />
              </KGrid.Item>
            </KContainer.RenderWhen.If>

            <KContainer.RenderWhen.If isTrue={showAROverdue}>
              <KGrid.Item xs={6}>
                <ArOverdue />
              </KGrid.Item>
            </KContainer.RenderWhen.If>

            <KContainer.RenderWhen.If
              isTrue={showScheduleTripContainerWaitingTime}
            >
              <KGrid.Item xs={12}>
                <STCWaitingTime />
              </KGrid.Item>
            </KContainer.RenderWhen.If>
          </KContainer.RenderWhen>
        </KGrid.Container>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(Dashboard);
