import {
  ENDPOINTS,
  ArInvoiceType,
  QUERY_KEYS,
  ApInvoiceType,
  IS_LOCAL_XERO
} from '@constants';
import APIManager from '@services';

import { useMutationEnhancer, useQueryEnhancer } from '../core';

export const useCheckHasXero = () => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.hasXero],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.xero('has-xero'),
        noBase: IS_LOCAL_XERO
      });

      return res.data;
    }
  });
};

export const useFetchXeroStatus = (hasXero?: boolean) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.xeroStatus],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.xero('status'),
        noBase: IS_LOCAL_XERO
      });

      return res.data;
    },
    enabled: !!hasXero
  });
};

export const useFetchXeroTenantList = (isLoggedInXero?: boolean) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.xeroTenantList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.xero('tenants'),
        noBase: IS_LOCAL_XERO
      });

      return res.data ?? [];
    },
    enabled: !!isLoggedInXero,
    initialData: []
  });
};

export const useConnectXero = () => {
  return useMutationEnhancer<string, string>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.xero('connect'),
        method: 'POST',
        body: { returnUrl: data },
        noBase: IS_LOCAL_XERO
      });

      return res.data;
    }
  });
};

export const useSyncXero = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const { invoiceType } = data;
      const res = await APIManager.request({
        url: [ArInvoiceType.ArCreditNote, ApInvoiceType.ApCreditNote].includes(
          invoiceType
        )
          ? ENDPOINTS.xero('sync-credit-note')
          : ENDPOINTS.xero('sync-invoice'),
        method: 'POST',
        body: data,
        showToast: true,
        noBase: IS_LOCAL_XERO
      });

      return res.data;
    },
    mutationKeys: [[QUERY_KEYS.invoice]]
  });
};

export const useSyncXeroPayments = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.xero('sync-payments'),
        method: 'POST',
        body: data,
        showToast: true,
        noBase: IS_LOCAL_XERO
      });

      return res.data;
    }
  });
};

export const useLogoutXero = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.xero('logout'),
        method: 'POST'
      });

      return res.success;
    }
  });
};
