import { InvoiceStatusId, UITab } from '@constants';
import qs from 'query-string';

export interface IProps {
  invoiceProgressId?: InvoiceStatusId;
  isWip?: boolean;
}

export interface IOnShowAccounting {
  invoiceId?: number | 'new';
  tab?: UITab;
}

export const onShowAccounting = (query: IOnShowAccounting) => {
  window.open(
    `/admin/accounting${query ? `?${qs.stringify(query)}` : ''}`,
    '_blank'
  );
};
