import { GlobalEvent, growthBook } from '@constants';
import { Portal } from '@containers/Portal';
import { DownloadCsvProvider, usePreferences } from '@context';
import { NotificationManager, AppEvent } from '@core';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import {
  useCountNotification,
  useOneStopCountNotification,
  useSignOut,
  useSyncGrowthbook,
  useUserId
} from '@hooks';
import loadable from '@loadable/component';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import AdminLayout from '@pages/Layout';
import APIManager from '@services';
import { buildTheme } from '@utils/theme';
import pMinDelay from 'p-min-delay';
import React, { Suspense, memo, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useMount, useRunOnce } from 'uikit-common';

import { GlobalDebug } from './glDebug';

const SignIn = loadable(() => pMinDelay(import('@pages/Auth/SignIn'), 200));
const ForgotPassword = loadable(() =>
  pMinDelay(import('@pages/Auth/ForgotPassword'), 200)
);
const SignUp = loadable(() => pMinDelay(import('@pages/Auth/SignUp'), 200));
const ResetPassword = loadable(() =>
  pMinDelay(import('@pages/Auth/ResetPassword'), 200)
);

const MainNavigator = memo(() => {
  return (
    <DownloadCsvProvider>
      <Switch>
        <Route path="/auth/sign-in">
          <SignIn />
        </Route>

        <Route path="/auth/account-sign-in">
          <SignIn />
        </Route>

        <Route path="/auth/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/auth/password-reset">
          <ResetPassword />
        </Route>

        <Route path="/auth/sign-up">
          <SignUp />
        </Route>

        <Route path="/admin">
          <AdminLayout />
        </Route>

        <Route path="/" render={() => <Redirect to="/admin" />} />
      </Switch>
    </DownloadCsvProvider>
  );
});

const AppNavigator = () => {
  const { appearance } = usePreferences();

  const userId = useUserId();

  const { mutate: mutateSignOut } = useSignOut();
  const { refetch: refetchCount } = useCountNotification();
  const { refetch: refetchCountOneStop } = useOneStopCountNotification();

  const theme = useMemo(() => buildTheme(appearance), [appearance]);

  useSyncGrowthbook();

  const onSignOut = useRunOnce(mutateSignOut, 2000);

  useMemo(() => {
    APIManager.initialize();
  }, []);

  useEffect(() => {
    if (userId) {
      NotificationManager.initialize();
    }
  }, [userId]);

  useMount(() => {
    // overrideConsole();

    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      GlobalDebug(false);
    }

    AppEvent.subscribe(GlobalEvent.SignOut, onSignOut);

    AppEvent.subscribe(GlobalEvent.NewNotification, () => {
      refetchCount();
      refetchCountOneStop();
    });
  });

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Suspense fallback={null}>
          <MainNavigator />
        </Suspense>

        <Portal />
      </ThemeProvider>
    </GrowthBookProvider>
  );
};

export default memo(AppNavigator);
