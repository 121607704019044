/* eslint-disable */
import { createAction, handleActions } from 'redux-actions';

export const types = {
  SET_ALLOCATION_CONTAINER: 'SET_ALLOCATION_CONTAINER'
};

export const actions = {
  setAllocationContainer: createAction(types.SET_ALLOCATION_CONTAINER)
};

export const selectors = {
  getAllocationContainer: state => state.allocation.container
};

const defaultState = {
  container: undefined
};

export const reducer = handleActions(
  {
    [types.SET_ALLOCATION_CONTAINER]: (state, action) => {
      return {
        ...state,
        container: action.payload
      };
    }
  },
  defaultState
);
