import {
  backgroundColor3Dark,
  blackColor,
  hexToRgb,
  primaryBlack,
  whiteColor
} from '@assets/jss/material-dashboard-react.js';
import constants from '@constants';

const cardStyle = theme => ({
  card: {
    border: '0',
    marginTop: '12px',
    borderRadius: '4px',
    color:
      theme.palette.type === constants.THEME.LIGHT ? primaryBlack : whiteColor,
    background:
      theme.palette.type === constants.THEME.LIGHT
        ? whiteColor
        : backgroundColor3Dark,
    width: '100%',
    boxShadow: '0 4px 4px rgba(' + hexToRgb(blackColor) + ', 0.25)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: '.875rem'
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none'
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center'
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px'
    }
  },
  cardBelowTab: {
    marginTop: '4px'
  }
});

export default cardStyle;
