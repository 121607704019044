import loadable from '@loadable/component';
import { IItemProps, INoteOptions } from '@ui';
import { UIUtils } from '@utils';
import { isEmpty } from 'lodash';
import React from 'react';
import trans from 'translation';

const Form = loadable(() => import('./Form'));

export const onShowEmail = (options: INoteOptions & IItemProps) => {
  return UIUtils.popup.open({
    title: trans('email'),
    maxWidth: 'md',
    content: () => <Form {...options} />
  });
};

export const onFmSendTo = (v: any) => {
  return {
    id: v.id,
    email: v.email,
    name: `(${v.code}) ${v.position} - ${v.fullname} - ${v.email}`
  };
};

export const onFmSendToItem = (v: any) => {
  return {
    id: v.id,
    email: v.email,
    name: `(${v.client?.code ?? ''}) ${v.position} - ${v.fullname} - ${v.email}`
  };
};

export const onGenContainerSubject = (
  subject: string,
  data: any[],
  transportJob: any
) => {
  const subjectArr = [];
  const fileNameArr = [];

  if (subject) {
    subjectArr.push(subject);
    fileNameArr.push(subject);
  }
  if (transportJob?.jobNumber) {
    subjectArr.push(transportJob?.jobNumber);
    fileNameArr.push(transportJob?.jobNumber);
  }
  if (transportJob?.refNumber) {
    subjectArr.push(`Cus. Ref. ${transportJob?.refNumber}`.trim());
  }
  if (!isEmpty(data)) {
    subjectArr.push(...data.map(e => e.containerNumber.trim()));
    fileNameArr.push(...data.map(e => e.containerNumber.trim()));
  }
  return [subjectArr.join(' - '), fileNameArr.join(' - ')];
};

export const onGenInvoiceSubject = (
  subject: string,
  data: any[],
  transportJob: any
) => {
  const subjectArr = [];
  const fileNameArr = [];

  if (subject) {
    subjectArr.push(subject);
    fileNameArr.push(subject);
  }
  if (!isEmpty(data)) {
    subjectArr.push(...data.map(e => e.code));
    fileNameArr.push(...data.map(e => e.code));
  }
  if (transportJob?.refNumber) {
    subjectArr.push(`Cus. Ref. ${transportJob?.refNumber}`.trim());
  }
  if (transportJob?.jobNumber) {
    subjectArr.push(`Job Number - ${transportJob?.jobNumber}`);
  }
  return [subjectArr.join(' - '), fileNameArr.join(' - ')];
};

export const onGenQuotationRequestSubject = (
  subject: string,
  data: any[],
  quotation: any
) => {
  const subjectArr = [];
  const fileNameArr = [];

  if (subject) {
    subjectArr.push(subject);
    fileNameArr.push(subject);
  }
  if (quotation?.code) {
    subjectArr.push(quotation?.code);
  }
  if (quotation?.client?.name) {
    subjectArr.push(quotation?.client?.name);
  }
  if (!isEmpty(data)) {
    data.forEach(i => {
      fileNameArr.push(i.code);
      if (i.dropMode?.name) {
        subjectArr.push(i.dropMode.name);
      }
      if (i.containerSize?.code) {
        subjectArr.push(i.containerSize.code);
      }
      if (i.suburb?.name) {
        subjectArr.push(i.suburb.name);
      }
    });
  }
  return [subjectArr.join(' - '), fileNameArr.join(' - ')];
};

export const onGenClientTariffSubject = (
  subject: string,
  clientTariff: any
) => {
  const subjectArr = [];
  const fileNameArr = [];

  if (subject) {
    subjectArr.push(subject);
    fileNameArr.push(subject);
  }
  if (clientTariff?.code) {
    subjectArr.push(clientTariff?.code);
    fileNameArr.push(clientTariff?.code);
  }
  if (clientTariff?.client?.name) {
    subjectArr.push(clientTariff?.client?.name);
  }
  return [subjectArr.join(' - '), fileNameArr.join(' - ')];
};
