import {
  CategorySource,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  SafetyCheckType,
  Status,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useCUDCategory = (source: CategorySource) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.category(':source', { source }),
    webTable: WebTable[`${source}_CATEGORY`]
  });
};

export const useCUDTax = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.tax(),
    webTable: WebTable.TAX
  });
};

export const useCUDClientStage = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.clientStage(),
    webTable: WebTable.CLIENT_STAGE
  });
};

export const useCUDClientType = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.clientType(),
    webTable: WebTable.CLIENT_TYPE
  });
};

export const useCUDDivision = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.division(),
    webTable: WebTable.DIVISION
  });
};

export const useCUDAddressCapability = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.addressCapability(),
    webTable: WebTable.ADDRESS_CAPABILITY
  });
};

export const useCUDState = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.state(),
    webTable: WebTable.STATE
  });
};

export const useCUDSuburb = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.suburb(),
    webTable: WebTable.SUBURB
  });
};

export const useCUDContainerSize = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.containerSize(),
    webTable: WebTable.CONTAINER_SIZE
  });
};

export const useCUDShippingLinePolicy = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.shippingLinePolicy(),
    webTable: WebTable.SHIPPING_LINE_POLICY
  });
};

export const useCUDVessel = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.vessel(),
    webTable: WebTable.VESSEL
  });
};

export const useCUDOverweight = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.overweight(),
    webTable: WebTable.OVERWEIGHT
  });
};

export const useFindOverweight = () => {
  return useMutationEnhancer<
    any,
    { dropModeId: number; containerLengthId: number; cb?: (data: any) => void }
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.overweight('find'),
        body: data
      });

      return res.data;
    },
    onSuccess: (data, vars) => {
      if (data) {
        vars.cb?.(data);
      }
    }
  });
};

export const useCUDCheckItem = (type: SafetyCheckType) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.checkItem(),
    webTable: WebTable[`${type}_CHECK_ITEM`.toUpperCase()]
  });
};

export const useCUDFreeTimeSetting = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.freeTimeSetting(),
    webTable: WebTable.FREE_TIME_SETTING
  });
};

export const useCUDJobTitle = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.jobTitle(),
    webTable: WebTable.JOB_TITLE
  });
};

export const useCUDDataGroup = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.dataGroup(),
    webTable: WebTable.DATA_GROUP
  });
};

export const useCUDRole = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.role(),
    webTable: WebTable.ROLE
  });
};

export const useFetchRole = (id?: number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.role, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.role(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchPermissions = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.permissions],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.permission(),
        body: {
          status: Status.Active,
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        permissionId: i.id,
        permission: i,
        isLimitedByOwner: false,
        limitedIp: ''
      }));
    },
    initialData: []
  });
};

export const useFetchScreens = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.screens],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.screen(),
        body: {
          status: Status.Active,
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        screenId: i.id,
        screen: i
      }));
    },
    initialData: []
  });
};

export const useCUDMake = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.make(),
    webTable: WebTable.MAKE
  });
};

export const useCUDModel = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.model(),
    webTable: WebTable.MODEL
  });
};

export const useCUDSubType = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.equipmentType(),
    webTable: WebTable.SUB_TYPE
  });
};

export const useCUDAction = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.action(),
    webTable: WebTable.ACTION_ITEM
  });
};

export const useCUDLevelOfFault = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.levelOfFault(),
    webTable: WebTable.LEVEL_OF_FAULT
  });
};

export const useCUDZone = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.zone(),
    webTable: WebTable.ZONE
  });
};

export const useCUDZoneSuburb = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.zoneSuburb(),
    webTable: WebTable.ZONE_SUBURB
  });
};

export const useCUDChargeCode = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.chargeCode(),
    webTable: WebTable.CHARGE_CODE
  });
};

export const useCUDChargeCodeCategory = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.chargeCodeCategory(),
    webTable: WebTable.CHARGE_CODE_CATEGORY
  });
};

export const useCUDChargeCodeTemplate = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.chargeCodeTemplate(),
    webTable: WebTable.CHARGE_CODE_TEMPLATE
  });
};
