import { ArApType } from '@constants';
import { useAccountingContext } from '@context/Accounting';
import { Prototype } from '@core';
import { useFetchStatement } from '@hooks';
import React, { memo } from 'react';
import trans from 'translation';
import { KColors, KContainer, KImage, KLabel } from 'uikit';

import { OPTIONS } from '../helpers';

const Statistic = () => {
  const { clientId, arApType, isAr } = useAccountingContext();

  const { data: item, isLoading } = useFetchStatement(isAr, clientId);

  const options = OPTIONS[arApType as ArApType];

  return (
    <KContainer.Card
      background={
        'linear-gradient(270deg, #13A89E 0%, rgba(105, 200, 193, 0.635273) 0.01%, rgba(105, 200, 193, 0.635273) 0.02%, #13A89E 100%, rgba(19, 168, 158, 0) 100%, #61C5BE 100%)'
      }
      noShadow
      height="100%"
      alignItems
      justifyContent="space-between"
      size="md"
      isLoading={isLoading}
    >
      <KImage.MuiIcon icon="AttachMoney" color={KColors.white} />

      <KLabel.Text typo="TextMdMedium" color={KColors.white}>
        {trans('overdue_invoices_amount')}
      </KLabel.Text>

      <KLabel.Text typo="Text4xLgMedium" color={KColors.white}>
        {Prototype.number.formatCurrency(item?.overdue)}
      </KLabel.Text>

      <KContainer.View row alignItems alignSelf="stretch">
        <KContainer.View flex>
          <KLabel.Text color={KColors.white}>
            {trans('total_balance')}:{' '}
            {Prototype.number.formatCurrency(item?.outstanding)}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View flex>
          <KLabel.Text color={KColors.white}>
            {trans('total_invoices')}: {item?.receivables || 0}
          </KLabel.Text>
        </KContainer.View>
      </KContainer.View>

      <KContainer.View row alignItems alignSelf="stretch">
        <KContainer.View flex>
          <KLabel.Text color={KColors.white}>
            {trans(options.title.lastAmount)}:{' '}
            {Prototype.number.formatCurrency(item?.lastReceived, {
              withAbs: item?.lastReceived < 0
            })}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View flex>
          <KLabel.Text color={KColors.white}>
            {trans(options.title.lastDate)}:{' '}
            {Prototype.date.formatD(
              item?.lastReceivedDate,
              trans('no_data_found')
            )}
          </KLabel.Text>
        </KContainer.View>
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(Statistic);
