import { EquipmentType, growthBook } from '@constants';
import React, { memo, useMemo } from 'react';
import trans from 'translation';
import { KTab, KTabProps } from 'uikit';

interface IProps extends Omit<KTabProps, 'tabs'> {
  disabled?: boolean;
  onlyParent?: boolean;
}

const EquipmentTabs = (props: IProps) => {
  const { disabled, onlyParent, ...rest } = props;

  const doubleTrailerConfigs = growthBook.getFeatureValue<any>(
    'double_trailer',
    {}
  );

  const tabs = useMemo(() => {
    let _tabs = Object.keys(EquipmentType).map(i => ({
      key: EquipmentType[i],
      label: onlyParent
        ? trans(i.toLowerCase()).toUpperCase()
        : trans(EquipmentType[i].toLowerCase()).toUpperCase(),
      disabled
    }));

    if (!doubleTrailerConfigs.setup) {
      _tabs = _tabs.filter(i => i.key !== EquipmentType.Combination);
    }

    return _tabs;
  }, [disabled, doubleTrailerConfigs.setup, onlyParent]);

  return <KTab.Outline marginB="0.75rem" marginT={0} {...rest} tabs={tabs} />;
};

export default memo(EquipmentTabs);
