import { ApInvoiceType, ArInvoiceType, generateOptions } from '@constants';
import { Prototype } from '@core';
import { useFetchInvoice } from '@hooks';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import trans from 'translation';
import { KContainer, KGrid, KInput, KPicker } from 'uikit';

import { IFormData } from './helpers';

interface IProps {
  totalAmount: number;
  invoiceId?: number;
  isView?: boolean;
  isAr: boolean;
}

const Basic = ({ totalAmount, invoiceId, isView, isAr }: IProps) => {
  const methods = useFormContext<IFormData>();

  const { data: item } = useFetchInvoice(invoiceId);

  return (
    <KContainer.Card
      size="nm"
      height="100%"
      border
      header={{
        title: trans('invoice_information'),
        border: true
      }}
    >
      <KGrid.Container>
        <KGrid.Item xs={4}>
          <Controller
            name="invoiceType"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('invoice_type')}
                  options={generateOptions(
                    (isAr ? ArInvoiceType : ApInvoiceType) as any
                  )}
                  disabled={isView}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="invoiceDate"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KPicker.Date
                  {...field}
                  label={trans('invoice_date')}
                  required={!isView}
                  message={error?.message}
                  disabled={isView}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="referenceNumber"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('reference_number')}
                  disabled={isView}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="totalAmount"
            label={trans('total_amount')}
            value={Prototype.number.formatCurrency(totalAmount, {
              withAbs: totalAmount < 0
            })}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="totalDue"
            label={trans('total_due')}
            value={Prototype.number.formatCurrency(totalAmount, {
              withAbs: totalAmount < 0
            })}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="dueDate"
            control={methods.control}
            render={({ field }) => {
              return (
                <KPicker.Date
                  {...field}
                  label={trans('due_date')}
                  disabled={isView}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="lastPaymentDate"
            label={trans('last_payment_date')}
            value={Prototype.date.formatDT(item?.lastPaymentDate, '')}
            disabled
          />
        </KGrid.Item>

        {/* <KGrid.Item xs={8}>
          <Controller
            name="attachedEdoc"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.File
                  {...field}
                  label={trans('attached_file')}
                  accept="*"
                  value={field.value?.fileName ?? ''}
                  onChange={onChangeFile}
                  onPress={() => {
                    if (field.value?.url) {
                      window.open(field.value.url, '_blank');
                    }
                  }}
                  disabled={isView}
                />
              );
            }}
          />
        </KGrid.Item> */}

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default memo(Basic);
