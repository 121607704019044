import {
  ArApType,
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  INVOICE_MAPPED_FIELDS,
  TableName,
  UITab
} from '@constants';
import DataTable from '@containers/DataTable';
import { useAccountingContext } from '@context/Accounting';
import { useDataTable, useGlobalTable } from '@hooks';
import { onShowAccounting } from '@pages/Invoices/helpers';
import { onShowTransportJob } from '@pages/TransportJob/helpers';
import { TableUtils } from '@utils';
import { mappedInvoiceType, mappedSyncStatus } from '@utils/mapped';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import { KContainer, KLabel } from 'uikit';

import { OPTIONS } from './helpers';

const StatementInvoices = () => {
  const { clientId, arApType, isAr } = useAccountingContext();

  const options = OPTIONS[arApType as ArApType];

  const onShowInvoice = useCallback(
    (item: any) => {
      if (item.transportJobId) {
        onShowTransportJob(item.transportJobId, {
          tab: UITab.TransportJobInvoices,
          invoiceId: item.id,
          arApType
        });
      } else {
        onShowAccounting({
          invoiceId: item.id,
          tab: isAr ? UITab.AccountingArInvoices : UITab.AccountingApInvoices
        });
      }
    },
    [arApType, isAr]
  );

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowInvoice(item)
          }
        ])
      },
      {
        label: trans('invoice_number'),
        name: 'code',
        options: {
          ...TableUtils.options.baseMd,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowInvoice(item)}>{v}</KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('invoice_type'),
        name: 'invoiceType',
        options: TableUtils.options.chips({
          css: { minWidth: 140 },
          uppercase: true,
          mapLabelData: mappedInvoiceType()
        })
      },
      {
        label: trans('invoice_status'),
        name: 'progressStatus',
        options: TableUtils.options.chip()
      },
      {
        label: trans('invoice_date'),
        name: 'invoiceDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('sync_status'),
        name: 'syncStatus',
        options: TableUtils.options.chips({
          uppercase: true,
          mapLabelData: mappedSyncStatus()
        })
      },
      {
        label: trans('sync_org_name'),
        name: 'syncOrgName'
      },
      {
        label: trans('sync_date'),
        name: 'syncDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('sync_by'),
        name: 'syncByPerson.displayName',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('sales_person'),
        name: 'salePerson.displayName'
      },
      {
        label: trans('reference_number'),
        name: 'referenceNumber'
      },
      {
        label: trans('total_amount'),
        name: 'totalAmount',
        options: TableUtils.options.currency()
      },
      {
        label: trans('total_due'),
        name: 'totalDue',
        options: TableUtils.options.currency()
      },
      {
        label: trans('due_date'),
        name: 'dueDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('last_payment_date'),
        name: 'lastPaymentDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('job_number'),
        name: 'transportJob.jobNumber',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('job_status'),
        name: 'transportJob.jobProgress',
        options: TableUtils.options.chip()
      },
      {
        label: trans('job_type'),
        name: 'transportJob.jobType'
      },
      {
        label: trans('vessel'),
        name: 'transportJob.vessel.vessel',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('lloyd'),
        name: 'transportJob.vessel.lloyd'
      },
      {
        label: trans('voyage'),
        name: 'transportJob.voyage'
      },
      {
        label: trans('eta'),
        name: 'transportJob.eta',
        options: TableUtils.options.date()
      },
      {
        label: trans('etd'),
        name: 'transportJob.etd',
        options: TableUtils.options.date()
      },
      {
        label: trans('consignee_consignor'),
        name: 'transportJob.consignClient.name',
        options: TableUtils.options.toggle(isAr)
      },
      {
        label: trans('account_receivable'),
        name: 'transportJob.relatedPartyArClient.relatedClient.name',
        options: TableUtils.options.toggle(isAr)
      },
      {
        label: trans('account_payable'),
        name: 'client.name',
        options: TableUtils.options.toggle(!isAr)
      },
      {
        label: trans('container_numbers'),
        name: 'containerNumbers'
      }
    ],
    [isAr, onShowInvoice]
  );

  const otherParams = useMemo(() => {
    return {
      arClientId: isAr ? clientId : undefined,
      clientId: !isAr ? clientId : undefined,
      isNotFinish: true,
      excludeFields: ['invoiceContainerCharges'],
      isAR: isAr
    };
  }, [clientId, isAr]);

  const table = useDataTable({
    name: options.webTable.invoice,
    tableName: TableName.INVOICE,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.invoice(),
    columnsFunc,
    mappedFields: INVOICE_MAPPED_FIELDS,
    otherParams
  });

  useGlobalTable(table);

  return (
    <KContainer.Card
      border
      size="nm"
      header={{
        title: trans(options.title.invoice),
        border: true
      }}
    >
      <DataTable {...table} />
    </KContainer.Card>
  );
};

export default memo(StatementInvoices);
