import { Status, generateOptions } from '@constants';
import { Prototype } from '@core';
import { useCUDRole, useFetchRole, useResolverForm } from '@hooks';
import { IItemProps } from '@ui';
import { ValidationUtils } from '@utils';
import { isEmpty, omit } from 'lodash';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import trans from 'translation';
import { KButton, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

import Permissions from './Form.Permissions';
import Screens from './Form.Screens';
import { IFormData, IFormPermissionsInstance } from './helpers';

import { useDeleteRole } from '../helpers';

const schema = yup.object().shape({
  name: ValidationUtils.required()
});

const Form = ({ item: localItem }: IItemProps) => {
  const pRef = useRef<IFormPermissionsInstance>(null);
  const sRef = useRef<IFormPermissionsInstance>(null);

  const {
    createMutation: { mutate: mutateCreate },
    updateMutation: { mutate: mutateUpdate },
    modifyLoading
  } = useCUDRole();

  const { onAlert, deleteLoading } = useDeleteRole();

  const { data: remoteItem, isLoading } = useFetchRole(localItem?.id);

  const item = useMemo(() => remoteItem ?? localItem, [localItem, remoteItem]);

  const isEdit = !isEmpty(item);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        name: item?.name ?? '',
        description: item?.description ?? '',
        status: item?.status ?? Status.Active,
        permissions: item?.rolePermissions ?? [],
        screens: item?.roleScreens ?? []
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...omit(data, ['permissions', 'screens']),
        rolePermissions: pRef.current?.getData(),
        roleScreens: sRef.current?.getData(),
        id: item?.id
      };

      if (!!mParams.id) {
        mutateUpdate(mParams);
      } else {
        mutateCreate(mParams);
      }
    },
    [item?.id, mutateCreate, mutateUpdate]
  );

  const onDelete = useCallback(() => {
    onAlert({ id: item?.id });
  }, [item?.id, onAlert]);

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container>
          <KGrid.Item xs={3}>
            <Controller
              name="name"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('name')}
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField {...field} label={trans('description')} />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('status')}
                    options={generateOptions(Status)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="createdBy"
              label={trans('created_by')}
              value={item?.createdUsername ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="createdAt"
              label={trans('created_at')}
              value={Prototype.date.formatDT(item?.createdAt, '')}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="updatedBy"
              label={trans('updated_by')}
              value={item?.updatedUsername ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="updatedAt"
              label={trans('updated_at')}
              value={Prototype.date.formatDT(item?.updatedAt, '')}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Permissions ref={pRef} currentData={item?.rolePermissions ?? []} />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Screens ref={sRef} currentData={item?.roleScreens ?? []} />
          </KGrid.Item>

          <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
            {isEdit && (
              <KButton.Solid
                kind="secondary"
                marginR="0.5rem"
                isLoading={deleteLoading}
                disabled={modifyLoading}
                onPress={onDelete}
                title={trans('delete')}
              />
            )}

            <KButton.Solid
              type="submit"
              title={trans('save')}
              isLoading={modifyLoading}
              disabled={deleteLoading || isLoading}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KForm>
    </FormProvider>
  );
};

export default memo(Form);
