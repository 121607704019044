import {
  ArApType,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  SyncStatus
} from '@constants';
import APIManager from '@services';
import { isEmpty } from 'lodash';

import { useMutationEnhancer } from '../core';

interface IMutateFetchPaymentsParams {
  clientId?: number;
  accountingType?: ArApType;
}

export const useMutateFetchPayments = () => {
  return useMutationEnhancer<any, IMutateFetchPaymentsParams>({
    mutationFn: async data => {
      const { clientId, accountingType } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.payment(),
        body: {
          page: 0,
          size: PAGE_SIZE_DEFAULT_MAX,
          clientId,
          accountingType
        }
      });

      if (res.data?.data && !isEmpty(res.data?.data)) {
        const promises = res.data.data
          .filter((i: any) => i.syncStatus !== SyncStatus.Success.toUpperCase())
          .map(async (i: any) => {
            const _res = await APIManager.request({
              url: ENDPOINTS.payment(':id', { id: i.id })
            });

            return _res.data;
          });

        return await Promise.all(promises);
      }

      return [];
    }
  });
};
