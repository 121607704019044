import {
  ENDPOINTS,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { useDataTable, useGlobalTable } from '@hooks';
import { useMaintenanceColumnsFunc } from '@pages/Equipment/helpers';
import React, { memo } from 'react';
import trans from 'translation';
import { KContainer, KBreadcrumbs } from 'uikit';

const Maintenance = () => {
  const { columnsFunc, mappedFields, deleteLoading } =
    useMaintenanceColumnsFunc();

  const table = useDataTable({
    name: WebTable.MAINTENANCE,
    tableName: TableName.MAINTENANCE,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.maintenance(),
    columnsFunc,
    mappedFields
  });

  useGlobalTable(table);

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.workshop'), trans('routes.maintenance')]}
      />

      <KContainer.Card margin="0.75rem">
        <DataTable {...table} isModifying={deleteLoading} />
      </KContainer.Card>
    </>
  );
};

export default memo(Maintenance);
