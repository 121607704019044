import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchStaff = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.staff, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDStaff = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.staff(),
    webTable: WebTable.STAFF,
    queryKey: QUERY_KEYS.staff,
    redirectPath: '/admin/staff'
  });
};
