import { ENDPOINTS, PAGE_SIZE_DEFAULT_MAX, Status, WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import {
  useFetchClientTariffChargeForQuotationRequest,
  useLocalTable,
  useResolverForm
} from '@hooks';
import { UIUtils, ValidationUtils } from '@utils';
import { omit, pick } from 'lodash';
import React, { memo, useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

import { useChargeColumns } from '../helpers';

interface IProps {
  fParams: any;
  onSuccess: (v: any[]) => void;
}

interface IFormData {
  tariff: any;
  charges: any[];
  selectedIndexes: any[];
}

const schema = yup.object().shape({
  tariff: ValidationUtils.requiredAny()
});

const AddClientTariff = (props: IProps) => {
  const { fParams, onSuccess } = props;

  const { mutateAsync: mutateClientTariff, isLoading: clientTariffLoading } =
    useFetchClientTariffChargeForQuotationRequest();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        tariff: null,
        charges: [],
        selectedIndexes: []
      }
    }
  });

  const [charges, selectedIndexes] = useWatch({
    control: methods.control,
    name: ['charges', 'selectedIndexes']
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      onSuccess(data.selectedIndexes.map(i => data.charges?.[i]));
      UIUtils.popup.dismiss();
    },
    [onSuccess]
  );

  const onFetchCharges = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const { tariff } = methods.getValues();
      const clientTariffId = tariff.id;

      const mParams = {
        ...omit(fParams, ['clientId', 'isAgent']),
        page: 0,
        size: PAGE_SIZE_DEFAULT_MAX,
        status: Status.Active,
        clientTariffId
      };

      const data = await mutateClientTariff(mParams);

      const result = data
        .filter(i => i.companyTariff.status === Status.Active)
        .map(i => {
          return {
            companyTariffId: i.companyTariffId,
            status: i.status,
            selectedLevelNumber: i.selectedLevelNumber,
            levelValueType: i.levelValueType,
            levelValue: i.levelValue,
            rateValue: i.rateValue,
            sellingPriceValue: i.matchedSellingPriceValue,
            companyTariff: i.companyTariff,
            companyTariffLevel: i.companyTariffLevel,
            companyTariffLevelId: i.companyTariffLevelId,
            clientTariffId,
            components: (i.matchedClientTariffLevelComponents ?? []).map(
              (c: any) => {
                const rest = pick(c, [
                  'chargeCodeComponent',
                  'chargeCodeComponentId',
                  'weight'
                ]);
                return rest;
              }
            )
          };
        });

      methods.setValue('charges', result);
      methods.setValue('selectedIndexes', []);
    }
  }, [fParams, methods, mutateClientTariff]);

  const columns = useChargeColumns(charges, { noAction: true });

  const table = useLocalTable({
    name: WebTable.OTHER,
    data: charges,
    columns,
    onRefresh: () => onFetchCharges()
  });

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="tariff"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('client_tariff')}
                  apiURL={ENDPOINTS.clientTariff()}
                  apiParams={{
                    shipmentType: fParams?.shipmentType,
                    clientId: fParams?.clientId
                  }}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs>
          <KButton.Solid
            title={trans('get_charge_code_details')}
            onPress={() => onFetchCharges()}
            isLoading={clientTariffLoading}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KContainer.Card
            border
            size="nm"
            header={{
              title: trans('charge_code_details'),
              border: true
            }}
          >
            <DataTable
              {...table}
              isLoading={clientTariffLoading}
              showDownload={false}
              options={{
                ...table.options,
                rowsSelected: selectedIndexes,
                onRowSelectionChange: (_cur, _, rows) => {
                  if (rows) {
                    methods.setValue('selectedIndexes', rows);
                  }
                }
              }}
            />
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid type="submit" title={trans('add')} />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(AddClientTariff);
