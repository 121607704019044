import { Colors, Spacing } from '@constants';
import { t } from '@lingui/macro';
import {
  IconButton,
  InputAdornment,
  OutlinedTextFieldProps,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import React, { memo, useCallback, useState } from 'react';

interface IInput extends OutlinedTextFieldProps {
  pattern?: RegExp;
}

const styles = {
  root: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.green
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: Colors.green
    }
  },
  warning: {
    '& .MuiFormLabel-root.Mui-error': {
      color: Colors.warning
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: Colors.warning
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.warning
    }
  },
  eyeIcon: {
    padding: Spacing.s8
  }
};

const useStyles = makeStyles(styles);

const Input = memo((props: IInput) => {
  const { pattern, isWarning, onChange, ...otherProps } = props;

  const classes = useStyles();

  const _onChange = useCallback(
    e => {
      let event = e;
      if (pattern) {
        let v = e.target.value;
        if (v) {
          v = v.replace(pattern, '');
          event.target.value = v;
        }
      }
      onChange?.(event);
    },
    [onChange, pattern]
  );

  return (
    <TextField
      className={clsx(classes.root, { [classes.warning]: Boolean(isWarning) })}
      autoComplete="off"
      variant="outlined"
      size="small"
      id={otherProps.name}
      onChange={_onChange}
      {...otherProps}
    />
  );
});

const PasswordInput = memo((props: IInput) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const triggerShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <Input
      name="password"
      label={t`Password`}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={triggerShowPassword}
              edge="end"
              className={classes.eyeIcon}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
});

export { Input, PasswordInput };
