import { SyncStatus } from '@constants';
import { Prototype } from '@core';
import { useFetchDashboardInvoicesOverdue } from '@hooks';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KColors, KContainer, KLabel, KChip } from 'uikit';

const InvoicesOverdue = () => {
  const { data = [], isLoading } = useFetchDashboardInvoicesOverdue();

  const renderRow = useCallback((item: any) => {
    return (
      <KContainer.View
        key={item.code}
        marginB={'0.5rem'}
        background={'#F8F9FA'}
        br={'2x'}
        padding={'0.75rem'}
      >
        <KContainer.View row alignItems marginB={'0.25rem'}>
          <KContainer.View
            row
            alignItems
            justifyContent="space-between"
            flex={7}
          >
            <KLabel.Text
              typo="TextNmBold"
              color={KColors.primary.normal}
              isLink
              to={`/admin/transport-jobs/${item.transportJobId}?tab=2&invoiceId=${item.id}`}
              target="_blank"
            >
              {item.code}
            </KLabel.Text>

            <KContainer.View row alignItems paddingR={'2rem'}>
              <KChip label={trans('invoiced')} />

              {item.syncStatus === SyncStatus.Success.toUpperCase() && (
                <KChip
                  label={trans('synced')}
                  marginL={'0.5rem'}
                  kind="primary"
                />
              )}
            </KContainer.View>
          </KContainer.View>

          <KContainer.View
            row
            alignItems
            justifyContent="space-between"
            flex={5}
          >
            <KLabel.Text typo="TextNmBold" color={KColors.secondary.normal}>
              {trans('due_date')}: {Prototype.date.formatD(item.dueDate, '')}
            </KLabel.Text>

            <KLabel.Text typo="TextNmBold">
              {Prototype.number.formatCurrency(item.totalDue)}
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View row alignItems>
          <KContainer.View flex={7}>
            <KLabel.Text color={'#7B809A'} paddingR={'2rem'}>
              {trans('account_receivable')}:{' '}
              <KLabel.Text
                typo="TextNmBold"
                color={KColors.primary.normal}
                isLink
                to={`/admin/clients/${item.transportJob?.relatedPartyArClient?.relatedClientId}`}
                target="_blank"
              >
                {item.transportJob?.relatedPartyArClient?.relatedClient?.code ??
                  ''}
              </KLabel.Text>
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View flex={5}>
            <KLabel.Text color={'#7B809A'}>
              {trans('email_address')}:{' '}
              <KLabel.Text typo="TextNmBold">
                {item.transportJob?.relatedPartyArClient?.relatedClient
                  ?.defaultContact?.email ?? ''}
              </KLabel.Text>
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>
      </KContainer.View>
    );
  }, []);

  return (
    <KContainer.Card
      height={400}
      marginT={0}
      isLoading={isLoading}
      header={{
        title: trans('invoices_overdue'),
        typo: 'TextMdBold'
      }}
    >
      <KContainer.View flex overflow>
        {data.map(i => renderRow(i))}
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(InvoicesOverdue);
