import { Prototype } from '@core';
import { useFetchDashboardPayments } from '@hooks';
import React, { memo } from 'react';
import trans from 'translation';
import { KContainer, KLabel } from 'uikit';

const Payments = () => {
  const { data, isLoading } = useFetchDashboardPayments();

  return (
    <KContainer.Card isLoading={isLoading}>
      <KContainer.View
        marginB={'0.25rem'}
        row
        alignItems
        justifyContent="space-between"
      >
        <KLabel.Text typo="TextMdBold">{trans('payments')}</KLabel.Text>

        <KLabel.Text color={'#848AA2'}>
          {Prototype.date.now().format('[Today] DD/MM/YYYY')}
        </KLabel.Text>
      </KContainer.View>

      <KContainer.View row alignItems justifyContent="space-between">
        <KLabel.Text typo="Text2xLgBold" color={'#344767'}>
          {data?.numOfNewPayment ?? '--'} {trans('new')}
        </KLabel.Text>

        <KLabel.Text typo="Text2xLgBold" color={'#344767'}>
          {Prototype.number.formatCurrency(data?.totalReceived)}
        </KLabel.Text>
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(Payments);
