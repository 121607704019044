import {
  ENDPOINTS,
  NotificationReadStatus,
  PAGE_SIZE_DEFAULT,
  QUERY_KEYS,
  Status
} from '@constants';
import APIManager from '@services';

import { useUserId } from '../auth';
import {
  useInfiniteEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './oneStop';

export const useCountNotification = () => {
  const userId = useUserId();

  return useQueryEnhancer<number>({
    queryKey: [QUERY_KEYS.notificationCount],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification('count-unread'),
        body: {
          include3PT: false
        }
      });

      return res.data;
    },
    initialData: 0,
    enabled: !!userId
  });
};

export const useFetchNotificationList = (
  readStatus: NotificationReadStatus,
  otherParams?: any
) => {
  const userId = useUserId();

  return useInfiniteEnhancer({
    queryKey: [
      QUERY_KEYS.notification,
      userId,
      readStatus,
      JSON.stringify(otherParams)
    ],
    queryFn: async ({ pageParam }) => {
      const { page = 0, pageSize = PAGE_SIZE_DEFAULT } = pageParam || {};
      const res = await APIManager.request({
        url: ENDPOINTS.notification(),
        body: {
          size: pageSize,
          status: Status.Active,
          page,
          sortBy: 'createdAt:DESC',
          include3PT: false,
          userId,
          readStatus:
            readStatus === NotificationReadStatus.All ? null : readStatus,
          ...otherParams
        }
      });

      return res.data;
    },
    enabled: !!userId
  });
};

export const useMarkAllAsRead = () => {
  return useMutationEnhancer({
    mutationKeys: [[QUERY_KEYS.notificationCount], [QUERY_KEYS.notification]],
    mutationFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification('read-all'),
        method: 'POST'
      });

      return res.success;
    }
  });
};

export const useMarkAsRead = () => {
  return useMutationEnhancer<any, number>({
    mutationKeys: [[QUERY_KEYS.notificationCount], [QUERY_KEYS.notification]],
    mutationFn: async id => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification(':id/read', { id }),
        method: 'POST'
      });

      return res.success;
    }
  });
};
