import { Status, generateOptions } from '@constants';
import { Prototype } from '@core';
import { useCUDDataGroup, useResolverForm } from '@hooks';
import { IItemProps } from '@ui';
import { ValidationUtils } from '@utils';
import { isEmpty } from 'lodash';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

import { useDeleteDataGroup } from '../helpers';

interface IFormData {
  code: string;
  name: string;
  description: string;
  status: Status;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required()
});

const Form = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: mutateCreate },
    updateMutation: { mutate: mutateUpdate },
    modifyLoading
  } = useCUDDataGroup();

  const { onAlert, deleteLoading } = useDeleteDataGroup();

  const isEdit = !isEmpty(item);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        name: item?.name ?? '',
        description: item?.description ?? '',
        status: item?.status ?? Status.Active
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        id: item?.id,
        ...data
      };

      if (!!mParams.id) {
        mutateUpdate(mParams);
      } else {
        mutateCreate(mParams);
      }
    },
    [item?.id, mutateCreate, mutateUpdate]
  );

  const onDelete = useCallback(() => {
    onAlert({ id: item?.id });
  }, [item?.id, onAlert]);

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={6}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('code')}
                  required
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('name')}
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('description')}
                  multiline
                  minRows={3}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          {isEdit && !item?.isSystem && (
            <KButton.Solid
              kind="secondary"
              marginR="0.5rem"
              isLoading={deleteLoading}
              disabled={modifyLoading}
              onPress={onDelete}
              title={trans('delete')}
            />
          )}

          <KButton.Solid
            type="submit"
            title={trans('save')}
            isLoading={modifyLoading}
            disabled={deleteLoading}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(Form);
