import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  Status,
  SystemSettingCode,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import {
  useFetchClientTariffChargeForQuotationRequest,
  useFetchCompanyTariffForQuotationRequest,
  useFetchSetting,
  useLocalTable,
  useResolverForm
} from '@hooks';
import { UIUtils, ValidationUtils } from '@utils';
import { omit, pick } from 'lodash';
import React, { memo, useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

import { useChargeColumns } from '../helpers';

interface IProps {
  fParams: any;
  onSuccess: (v: any) => void;
}

interface IFormData {
  template: any;
  isAgent: boolean;
  tariff: any;
  level: number;
  charges: any[];
}

const schema = yup.object().shape({
  template: ValidationUtils.requiredAny(),
  isAgent: yup.boolean(),
  tariff: yup
    .mixed()
    .nullable()
    .when('isAgent', ([v], _schema) => {
      return v ? _schema.required() : _schema;
    })
});

const ChooseFromTemplate = (props: IProps) => {
  const { fParams, onSuccess } = props;

  const { data: numberOfLevels = 3 } = useFetchSetting(
    SystemSettingCode.PriceLevel,
    false
  );

  const { mutateAsync: mutateCompanyTariff, isLoading: companyTariffLoading } =
    useFetchCompanyTariffForQuotationRequest();

  const { mutateAsync: mutateClientTariff, isLoading: clientTariffLoading } =
    useFetchClientTariffChargeForQuotationRequest();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        template: null,
        isAgent: fParams.isAgent,
        tariff: null,
        level: 1,
        charges: []
      }
    }
  });

  const [charges] = useWatch({
    control: methods.control,
    name: ['charges']
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      onSuccess(data);
      UIUtils.popup.dismiss();
    },
    [onSuccess]
  );

  const onFetchCharges = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const { template, tariff, level } = methods.getValues();

      const mParams = {
        ...omit(fParams, ['clientId', 'isAgent']),
        page: 0,
        size: PAGE_SIZE_DEFAULT_MAX,
        status: Status.Active,
        chargeCodeTemplateId: template.id
      };

      if (!fParams?.isAgent) {
        const data = await mutateCompanyTariff({
          ...mParams,
          selectedLevelNumber: level
        });

        const result = data.map(i => {
          const ctLevel = i.companyTariffLevels.find(
            (c: any) => c.levelNumber === level
          );

          return {
            companyTariffId: i.id,
            status: i.status,
            selectedLevelNumber: level,
            levelValueType: ctLevel.levelValueType,
            levelValue: ctLevel.levelValue,
            rateValue: ctLevel.rateValue,
            sellingPriceValue: ctLevel.matchedSellingPriceValue,
            companyTariff: i,
            companyTariffLevel: ctLevel,
            companyTariffLevelId: ctLevel.id,
            quotationRequestChargeComponents: (
              ctLevel.matchedCompanyTariffLevelComponents ?? []
            ).map((c: any) => {
              const rest = pick(c, [
                'chargeCodeComponent',
                'chargeCodeComponentId',
                'weight'
              ]);
              return rest;
            })
          };
        });

        methods.setValue('charges', result);
      } else {
        const data = await mutateClientTariff({
          ...mParams,
          clientTariffId: tariff?.id
        });

        const result = data
          .filter(i => i.companyTariff.status === Status.Active)
          .map(i => {
            return {
              companyTariffId: i.companyTariffId,
              status: i.status,
              selectedLevelNumber: i.selectedLevelNumber,
              levelValueType: i.levelValueType,
              levelValue: i.levelValue,
              rateValue: i.rateValue,
              sellingPriceValue: i.matchedSellingPriceValue,
              companyTariff: i.companyTariff,
              companyTariffLevel: i.companyTariffLevel,
              companyTariffLevelId: i.companyTariffLevelId,
              quotationRequestChargeComponents: (
                i.matchedClientTariffLevelComponents ?? []
              ).map((c: any) => {
                const rest = pick(c, [
                  'chargeCodeComponent',
                  'chargeCodeComponentId',
                  'weight'
                ]);
                return rest;
              })
            };
          });

        methods.setValue('charges', result);
      }
    }
  }, [fParams, methods, mutateClientTariff, mutateCompanyTariff]);

  const columns = useChargeColumns(charges, { noAction: true });

  const table = useLocalTable({
    name: WebTable.OTHER,
    data: charges,
    columns,
    onRefresh: () => onFetchCharges(),
    otherOptions: {
      selectableRows: 'none'
    }
  });

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="template"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('template')}
                  apiURL={ENDPOINTS.chargeCodeTemplate()}
                  apiParams={{
                    shipmentType: fParams?.shipmentType
                  }}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={!!fParams?.isAgent}>
            <KGrid.Item xs={3}>
              <Controller
                name="tariff"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.Autocomplete
                      {...field}
                      label={trans('client_tariff')}
                      apiURL={ENDPOINTS.clientTariff()}
                      apiParams={{
                        shipmentType: fParams?.shipmentType,
                        clientId: fParams?.clientId
                      }}
                      getOptionLabel={(o: any) => o?.code ?? ''}
                      inputProps={{
                        required: true,
                        message: error?.message
                      }}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If isTrue>
            <KGrid.Item xs={2}>
              <Controller
                name="level"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('default_level')}
                      options={Array.from(
                        Array(Number(numberOfLevels)),
                        (_, i) => ({
                          key: i + 1,
                          label: `${i + 1}`
                        })
                      )}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KGrid.Item xs>
          <KButton.Solid
            title={trans('get_charge_code_details')}
            onPress={() => onFetchCharges()}
            // disabled={!fParams?.isAgent}
            isLoading={companyTariffLoading || clientTariffLoading}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KContainer.Card
            border
            size="nm"
            header={{
              title: trans('charge_code_details'),
              border: true
            }}
          >
            <DataTable
              {...table}
              isLoading={companyTariffLoading || clientTariffLoading}
              showDownload={false}
            />
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid type="submit" title={trans('add')} />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(ChooseFromTemplate);
