import {
  ArApType,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  RelatedAddressType,
  RelatedClientType,
  Status,
  WebTable,
  tableRef
} from '@constants';
import { IClientDto, IClientTypeDto } from '@dto';
import APIManager from '@services';
import { UIUtils } from '@utils';

import {
  useCUDMutationEnhancer,
  useGenericMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useDeleteClients = (onSuccess?: () => void) => {
  return useGenericMutationEnhancer<number | string>({
    endPoint: ENDPOINTS.client('bulk-delete'),
    method: 'DELETE',
    // webTable: WebTable.CLIENT,
    onSuccess
  });
};

export const useFetchClient = (id?: number | string) => {
  return useQueryEnhancer<IClientDto>({
    queryKey: [QUERY_KEYS.client, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.client(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchClientTypeList = () => {
  return useQueryEnhancer<IClientTypeDto[] | undefined>({
    queryKey: [QUERY_KEYS.clientTypes],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientType(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC',
          status: Status.Active
        }
      });

      return res.data?.data;
    }
  });
};

export const useCUDClient = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(),
    webTable: WebTable.CLIENT,
    queryKey: QUERY_KEYS.client,
    redirectPath: '/admin/clients'
  });
};

export const useCUDAddress = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/site', { id }),
    webTable: WebTable.CLIENT_ADDRESS
  });
};

export const useFetchAddressCapabilityList = () => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.addressCapabilities],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.addressCapability(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC',
          status: Status.Active
        }
      });

      return res.data?.data;
    }
  });
};

export const useCUDContact = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/person', { id }),
    webTable: WebTable.CLIENT_CONTACT
  });
};

export const useFetchAccountSetting = (options: {
  id?: string | number;
  accountSettingType: ArApType;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.clientAccountSetting, options],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.client(
          ':id/payment-account/:accountSettingType',
          options
        )
      });

      return res.data;
    },
    enabled: !!options.id
  });
};

export const useCUDAccountSetting = (
  id: string | number,
  accountSettingType: ArApType
) => {
  return useCUDMutationEnhancer<any>({
    mutationKeys: [[QUERY_KEYS.clientAccountSetting]],
    endPoint: ENDPOINTS.client(':id/payment-account/:accountSettingType', {
      id,
      accountSettingType
    }),
    putWithoutId: true
  });
};

export const useCUDBankAccount = (
  id: string | number,
  accountSettingType: ArApType
) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/bank-account/:accountSettingType', {
      id,
      accountSettingType
    }),
    webTable: WebTable.CLIENT_BANK_ACCOUNT
  });
};

export const useCUDCurrencyUplift = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/uplift', { id }),
    webTable: WebTable.CLIENT_CURRENCY_UPLIFT
  });
};

export const useCUDSalePerson = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/sale-person', { id }),
    webTable: WebTable.CLIENT_SALE_PERSON
  });
};

export const useImportClient = () => {
  return useMutationEnhancer<boolean | undefined, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.client('import-excel'),
        method: 'POST',
        showToast: true,
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.CLIENT]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useCUDRelatedClient = (
  id: string | number,
  type: RelatedClientType
) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/related-client/:type', { id, type }),
    webTable: WebTable[`CLIENT_RELATED_${type}`]
  });
};

export const useCUDRelatedAddress = (
  id: string | number,
  type: RelatedAddressType
) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/related-site/:type', { id, type }),
    webTable: WebTable[`CLIENT_RELATED_${type}`]
  });
};

export const useFetchBankAccountList = (accountSettingType: ArApType) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.bankAccounts, accountSettingType],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.client('bank-account/:accountSettingType', {
          accountSettingType
        }),
        body: {
          page: 0,
          size: PAGE_SIZE_DEFAULT_MAX,
          status: Status.Active
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!accountSettingType
  });
};
