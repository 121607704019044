import { GlobalEvent, ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import { AppEvent } from '@core';
import { IResetUserPasswordParams } from '@request-dto';
import APIManager from '@services';
import { UIUtils } from '@utils';

import { useUserId } from '../auth';
import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchUser = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.user, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.user(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDUser = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.user(),
    webTable: WebTable.USER,
    queryKey: QUERY_KEYS.user,
    redirectPath: '/admin/users'
  });
};

export const useResetUserPassword = () => {
  const userId = useUserId();

  return useMutationEnhancer<boolean | undefined, IResetUserPasswordParams>({
    mutationFn: async data => {
      const { id, ...rest } = data;
      const res = await APIManager.request({
        url: ENDPOINTS.user(':id/update-password', { id }),
        method: 'PUT',
        body: rest,
        showToast: true
      });

      return res.success;
    },
    onSuccess: (data, variables) => {
      if (data) {
        UIUtils.popup.dismiss();
        if (variables.id === userId) {
          AppEvent.dispatch(GlobalEvent.SignOut);
        }
      }
    }
  });
};
