import { Prototype } from '@core';
import {
  useAlertMutationEnhancer,
  useCUDPayment,
  useFetchPayment
} from '@hooks';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KGrid,
  KInput,
  KLabel,
  KLoading
} from 'uikit';

import Invoices from './Form.View.Invoices';

interface IProps {
  item: any;
  isLastPayment?: boolean;
}

const FormView = ({ item: localItem, isLastPayment }: IProps) => {
  const { data: remoteData, isLoading } = useFetchPayment(localItem.id);

  const item = useMemo(() => remoteData ?? localItem, [localItem, remoteData]);

  const { deleteMutation } = useCUDPayment();

  const { onAlert, isLoading: deleteLoading } = useAlertMutationEnhancer({
    mutation: deleteMutation
  });

  const onDelete = useCallback(() => {
    onAlert({ id: item?.id });
  }, [item?.id, onAlert]);

  return (
    <KGrid.Container position="relative">
      {isLoading && <KLoading />}

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="paymentDate"
          label={trans('payment_date')}
          value={Prototype.date.formatD(item?.paymentDate, '')}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="referenceNumber"
          label={trans('reference_number')}
          value={item?.referenceNumber ?? ''}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.Currency
          name="totalAmount"
          label={trans('total_amount')}
          value={item?.totalAmount ?? 0}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="paymentType"
          label={trans('payment_type')}
          value={trans(
            `option.${item?.paymentType?.toLowerCase()}`
          ).toUpperCase()}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="accountNumber"
          label={trans('account_number')}
          value={item?.accountNumber ?? ''}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="accountName"
          label={trans('account_name')}
          value={item?.accountName ?? ''}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="bankCode"
          label={trans('bank_name')}
          value={item?.bankCode ?? ''}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="bankBranch"
          label={trans('bank_branch')}
          value={item?.bankBranch ?? ''}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="note"
          label={trans('note')}
          value={item?.note ?? ''}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="attachedFile"
          label={trans('attached_file')}
          value={item?.attachedEdoc?.resource?.fileName ?? ''}
          onClick={() => {
            if (item?.attachedEdoc?.resource?.url) {
              window.open(item.attachedEdoc.resource.url, '_blank');
            }
          }}
          readOnly
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="createdBy"
          label={trans('created_by')}
          value={item?.createdUsername ?? ''}
          disabled
        />
      </KGrid.Item>

      <KGrid.Item xs={3}>
        <KInput.TextField
          name="createdAt"
          label={trans('created_at')}
          value={Prototype.date.formatDT(item?.createdAt, '')}
          disabled
        />
      </KGrid.Item>

      <KGrid.Item xs={12}>
        <KContainer.View
          row
          alignItems
          justifyContent="space-between"
          background="#DAFCFA"
          paddingV="0.75rem"
          paddingH="1rem"
          brW={1}
          brC={KColors.primary.normal}
        >
          <KLabel.Text typo="TextMdMedium" textTransform="uppercase">
            {trans('total_received')}:{' '}
            {Prototype.number.formatCurrency(item?.totalReceived, {
              withAbs: item?.totalReceived < 0
            })}
          </KLabel.Text>

          <KLabel.Text typo="TextMdMedium" textTransform="uppercase">
            {trans('total_applied')}:{' '}
            {Prototype.number.formatCurrency(item?.totalApplied, {
              withAbs: item?.totalApplied < 0
            })}
          </KLabel.Text>

          <KLabel.Text typo="TextMdMedium" textTransform="uppercase">
            {trans('out_of_balance')}: {Prototype.number.formatCurrency(0)}
          </KLabel.Text>
        </KContainer.View>
      </KGrid.Item>

      <KGrid.Item xs={12}>
        <Invoices data={item?.paymentDetails ?? []} />
      </KGrid.Item>

      <KContainer.RenderWhen>
        <KContainer.RenderWhen.If isTrue={!!isLastPayment}>
          <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
            <KButton.Solid
              isLoading={deleteLoading}
              kind="secondary"
              onPress={onDelete}
              title={trans('delete')}
            />
          </KGrid.Item>
        </KContainer.RenderWhen.If>
      </KContainer.RenderWhen>
    </KGrid.Container>
  );
};

export default memo(FormView);
