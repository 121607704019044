import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  ShipmentType,
  Status,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchClientTariff = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.clientTariff, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientTariff(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDClientTariff = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.clientTariff(),
    webTable: WebTable.CLIENT_TARIFF,
    queryKey: QUERY_KEYS.clientTariff,
    redirectPath: '/admin/client-tariffs'
  });
};

export const useMutateFetchCompanyTariffForClientTariff = () => {
  return useMutationEnhancer<any[], any>({
    mutationFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.companyTariff(),
        method: 'GET',
        body: {
          page: 0,
          size: PAGE_SIZE_DEFAULT_MAX,
          status: Status.Active,
          shipmentType: ShipmentType.FCL
        }
      });

      return res.data?.data ?? [];
    }
  });
};
