import { ENDPOINTS, MarkupType, SystemSettingCode } from '@constants';
import { Prototype } from '@core';
import { useFetchSetting, useResolverForm } from '@hooks';
import { UIUtils, ValidationUtils } from '@utils';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KChip,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel
} from 'uikit';
import * as yup from 'yup';

interface IProps {
  chargeCodeIds: number[];
  fParams: any;
  onSuccess: (data: any) => void;
}

interface IFormData {
  companyTariff: any;
  level: number;
  sellingPriceValue?: number | string;
  levelValue?: number | string;
  rateValue?: number | string;
  components: any[];
}

const schema = yup.object().shape({
  companyTariff: ValidationUtils.requiredAny(),
  levelValue: yup
    .mixed()
    .nullable()
    .when('companyTariff', ([v], _schema) => {
      return [
        MarkupType.Currency,
        MarkupType.Descriptive,
        MarkupType.Percentage
      ].includes(v?.markupType)
        ? _schema.required()
        : _schema;
    }),
  components: yup.array().of(
    yup.object().shape({
      weight: ValidationUtils.requiredNum()
    })
  )
});

const ExtraCompanyTariff = (props: IProps) => {
  const { chargeCodeIds, fParams, onSuccess } = props;

  const { data: numberOfLevels = 3 } = useFetchSetting(
    SystemSettingCode.PriceLevel,
    false
  );

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        companyTariff: null,
        level: 1,
        sellingPriceValue: '',
        levelValue: '',
        rateValue: '',
        components: []
      }
    }
  });

  const [tariff, components] = useWatch({
    control: methods.control,
    name: ['companyTariff', 'components']
  });

  const markupType = useMemo(() => tariff?.markupType, [tariff?.markupType]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'components',
    keyName: 'formId'
  });

  const onChangeLevel = useCallback(
    (v: number, dataTariff?: any) => {
      const data = (dataTariff || tariff)?.companyTariffLevels?.[v - 1];
      methods.setValue('levelValue', data?.levelValue ?? '');
      methods.setValue('sellingPriceValue', data?.sellingPriceValue ?? '');
      methods.setValue('rateValue', data?.rateValue ?? '');
      methods.setValue(
        'components',
        data?.matchedCompanyTariffLevelComponents ?? []
      );
    },
    [methods, tariff]
  );

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...data,
        companyTariffId: data.companyTariff.id,
        selectedLevelNumber: data.level,
        components: data.components.map(i => {
          if (chargeCodeIds.includes(i.chargeCodeComponent?.subChargeCodeId)) {
            return i;
          }

          return {
            ...i,
            weight: 0
          };
        })
      };

      onSuccess(mParams);
      UIUtils.popup.dismiss();
    },
    [chargeCodeIds, onSuccess]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="companyTariff"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('code')}
                  onChange={(v: any) => {
                    methods.setValue('companyTariff', v);
                    onChangeLevel(1, v);
                  }}
                  apiURL={ENDPOINTS.companyTariff('for-quotation-request')}
                  apiParams={fParams}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  hasAddNew
                  addNewURL="company-tariffs"
                  addNewKey="code"
                  hasEdit
                  editURL="company-tariffs"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="companyTariff"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('name')}
                  onChange={(v: any) => {
                    methods.setValue('companyTariff', v);
                    onChangeLevel(1, v);
                  }}
                  apiURL={ENDPOINTS.companyTariff('for-quotation-request')}
                  apiParams={fParams}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  hasAddNew
                  addNewURL="company-tariffs"
                  hasEdit
                  editURL="company-tariffs"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="originalCode"
            label={trans('original_code')}
            value={tariff?.chargeCode?.code ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="perUnit"
            label={trans('per_unit')}
            value={tariff?.chargeCode?.chargeCodeUnit?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="calculatorType"
            label={trans('calculator_type')}
            value={
              tariff?.chargeCode?.calculateType
                ? trans(
                    `option.${tariff?.chargeCode?.calculateType.toLowerCase()}`
                  ).toUpperCase()
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KInput.TextField
            name="category"
            label={trans('category')}
            value={tariff?.chargeCode?.chargeCodeCategory?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KInput.TextField
            name="remark"
            label={trans('remark')}
            value={tariff?.remark ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="zone"
            label={trans('zone')}
            value={
              tariff?.zone ? tariff.zone.name ?? trans('all').toUpperCase() : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="costingPrice"
            label={trans('costing_price')}
            value={
              tariff?.costingPrice
                ? Prototype.number.formatCurrency(tariff?.costingPrice) ?? ''
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="minSellingPrice"
            label={trans('min_selling_price')}
            value={
              tariff?.minSellingPrice
                ? Prototype.number.formatCurrency(tariff?.minSellingPrice) ?? ''
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="markupLevelType"
            label={trans('markup_level_type')}
            value={
              markupType
                ? trans(`option.${markupType.toLowerCase()}`).toUpperCase()
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="level"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('selected_level')}
                  options={Array.from(
                    Array(Number(numberOfLevels)),
                    (_, i) => ({
                      key: i + 1,
                      label: `${i + 1}`
                    })
                  )}
                  onChange={e => {
                    field.onChange(e);
                    onChangeLevel(e.target.value as any);
                  }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={[MarkupType.Currency, MarkupType.Percentage].includes(
              markupType
            )}
          >
            <KGrid.Item xs={3}>
              <Controller
                name="levelValue"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('selected_markup_value')}
                      value={
                        markupType === MarkupType.Percentage
                          ? `${field.value} %`
                          : Prototype.number.formatCurrency(field.value)
                      }
                      disabled
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={3}>
              <Controller
                name="sellingPriceValue"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.Currency
                      {...field}
                      label={trans('selected_selling_price')}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={markupType === MarkupType.Composite}
          >
            <KGrid.Item xs={12}>
              <KContainer.Fieldset
                title={trans('selected_selling_price')}
                marginT={0}
                paddingB="0.75rem"
                paddingT="0.5rem"
              >
                <KGrid.Container spacing={2}>
                  <KContainer.View row alignItems flexW="wrap">
                    <KContainer.View
                      width={100}
                      marginL="0.5rem"
                      marginT="0.75rem"
                    >
                      <Controller
                        name="rateValue"
                        control={methods.control}
                        render={({ field }) => {
                          return (
                            <KInput.Currency
                              {...field}
                              label={trans('with_unit.base_value')}
                            />
                          );
                        }}
                      />
                    </KContainer.View>

                    {fields.map((d, idx) => {
                      return (
                        <React.Fragment key={d.formId}>
                          <KContainer.View row alignItems marginT="0.75rem">
                            <KContainer.View
                              dp="flex"
                              size={30}
                              br="x"
                              brW={1}
                              brC={KColors.hexToRgba(KColors.black, 0.26)}
                              center
                              background="#EEEEEE"
                              marginH="0.5rem"
                            >
                              <KLabel.Text
                                color={KColors.hexToRgba(KColors.black, 0.4)}
                                typo="TextLgNormal"
                              >
                                +
                              </KLabel.Text>
                            </KContainer.View>

                            <KContainer.View width={60} marginR="0.5rem">
                              <Controller
                                name={`components.${idx}.weight`}
                                control={methods.control}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <KInput.TextField
                                      {...field}
                                      label="%"
                                      type="number"
                                      message={error?.message}
                                    />
                                  );
                                }}
                              />
                            </KContainer.View>

                            <KContainer.View dp="flex" flex>
                              <KChip
                                label={
                                  components?.[idx]?.chargeCodeComponent
                                    ?.subChargeCode?.code ?? ''
                                }
                              />
                            </KContainer.View>
                          </KContainer.View>
                        </React.Fragment>
                      );
                    })}
                  </KContainer.View>
                </KGrid.Container>
              </KContainer.Fieldset>
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={markupType === MarkupType.Descriptive}
          >
            <KGrid.Item xs={9}>
              <Controller
                name="levelValue"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('selected_selling_price')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid type="submit" title={trans('add')} />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(ExtraCompanyTariff);
