import {
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  INVOICE_MAPPED_FIELDS,
  ShipmentType,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  UITab,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { useAccountingContext } from '@context/Accounting';
import { useDataTable, useGlobalTable, useNewButton } from '@hooks';
import { onShowTransportJob } from '@pages/TransportJob/helpers';
import { TableUtils } from '@utils';
import { mappedInvoiceType, mappedSyncStatus } from '@utils/mapped';
import React, { memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KContainer, KLabel } from 'uikit';

import { IProps } from './helpers';

const InvoicesList = ({ invoiceProgressId, isWip }: IProps) => {
  const [tab] = useState(ShipmentType.FCL);

  const { arApType, isAr } = useAccountingContext();

  const { setInvoiceId, setArApType } = useAccountingContext();

  const onShowInvoice = useCallback(
    (item?: any) => {
      if (item?.id && item?.transportJobId) {
        onShowTransportJob(item.transportJobId, {
          tab: UITab.TransportJobInvoices,
          invoiceId: item.id,
          arApType
        });
      } else {
        setArApType(arApType);
        setInvoiceId(item?.id ?? 'new');
      }
    },
    [arApType, setArApType, setInvoiceId]
  );

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowInvoice(item)
          }
        ])
      },
      {
        label: trans('invoice_number'),
        name: 'code',
        options: {
          ...TableUtils.options.baseMd,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowInvoice(item)}>{v}</KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('invoice_type'),
        name: 'invoiceType',
        options: TableUtils.options.chips({
          css: { minWidth: 140 },
          uppercase: true,
          mapLabelData: mappedInvoiceType()
        })
      },
      {
        label: trans('invoice_status'),
        name: 'progressStatus',
        options: TableUtils.options.chip()
      },
      {
        label: trans('invoice_date'),
        name: 'invoiceDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('sync_status'),
        name: 'syncStatus',
        options: TableUtils.options.chips({
          uppercase: true,
          mapLabelData: mappedSyncStatus()
        })
      },
      {
        label: trans('sync_org_name'),
        name: 'syncOrgName'
      },
      {
        label: trans('sync_date'),
        name: 'syncDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('sync_by'),
        name: 'syncByPerson.displayName',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('reference_number'),
        name: 'referenceNumber'
      },
      {
        label: trans('total_amount'),
        name: 'totalAmount',
        options: TableUtils.options.currency()
      },
      {
        label: trans('total_due'),
        name: 'totalDue',
        options: TableUtils.options.currency()
      },
      {
        label: trans('due_date'),
        name: 'dueDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('last_payment_date'),
        name: 'lastPaymentDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('job_number'),
        name: 'transportJob.jobNumber',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('job_status'),
        name: 'transportJob.jobProgress',
        options: TableUtils.options.chip()
      },
      {
        label: trans('job_type'),
        name: 'transportJob.jobType'
      },
      {
        label: trans('vessel'),
        name: 'transportJob.vessel.vessel',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('lloyd'),
        name: 'transportJob.vessel.lloyd'
      },
      {
        label: trans('voyage'),
        name: 'transportJob.voyage'
      },
      {
        label: trans('eta'),
        name: 'transportJob.eta',
        options: TableUtils.options.date()
      },
      {
        label: trans('etd'),
        name: 'transportJob.etd',
        options: TableUtils.options.date()
      },
      {
        label: trans('consignee_consignor'),
        name: 'transportJob.consignClient.name',
        options: TableUtils.options.toggle(isAr)
      },
      {
        label: trans('account_receivable'),
        name: 'transportJob.relatedPartyArClient.relatedClient.name',
        options: TableUtils.options.toggle(isAr)
      },
      {
        label: trans('account_payable'),
        name: 'client.name',
        options: TableUtils.options.toggle(!isAr)
      },
      {
        label: trans('container_numbers'),
        name: 'containerNumbers'
      }
    ],
    [isAr, onShowInvoice]
  );

  const otherParams = useMemo(() => {
    return {
      shipmentType: tab,
      excludeFields: ['invoiceContainerCharges'],
      isAR: isAr,
      invoiceProgressId
    };
  }, [invoiceProgressId, isAr, tab]);

  const table = useDataTable({
    name: isAr
      ? WebTable.ACCOUNTING_AR_INVOICE
      : isWip
        ? WebTable.ACCOUNTING_AP_WIP_INVOICE
        : WebTable.TRANSPORT_JOB_AP_INVOICE,
    tableName: TableName.INVOICE,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.invoice(),
    columnsFunc,
    mappedFields: INVOICE_MAPPED_FIELDS,
    otherParams
    // isInitFetch: false
  });

  useGlobalTable(table);

  const newButton = useNewButton({
    onPress: () => onShowInvoice()
  });

  return (
    <>
      {/* <KBreadcrumbs
          hasBackIcon={false}
          breadcrumbs={[trans('routes.accounting'), trans('routes.invoices')]}
        /> */}

      <KContainer.Card
        marginT="0.25rem"
        // header={{
        //   renderHeader: () => (
        //     <Tabs.Shipment onChangeTab={setTab} disabled={table.isLoading} />
        //   )
        // }}
        header={{
          icon: 'Payment',
          title: trans(
            isAr ? 'ar_invoices' : isWip ? 'ap_wip_invoices' : 'ap_invoices'
          )
        }}
      >
        <DataTable
          {...table}
          onAdd={isAr ? undefined : () => onShowInvoice()}
        />
      </KContainer.Card>

      {!isAr && newButton}
    </>
  );
};

export default memo(InvoicesList);
