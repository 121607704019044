import { ENDPOINTS, WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { useDataTable, useGlobalTable } from '@hooks';
import { TableUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KContainer } from 'uikit';

import { onShowDataGroup, useDeleteDataGroup } from '../helpers';

const DataGroup = () => {
  const { onAlert, deleteLoading } = useDeleteDataGroup();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowDataGroup(item)
          },
          {
            title: trans('delete'),
            icon: 'Delete',
            onPress: () => onAlert({ id: item.id })
          }
        ])
      },
      {
        label: trans('code'),
        name: 'code'
      },
      {
        label: trans('name'),
        name: 'name',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('description'),
        name: 'description'
      },
      {
        label: trans('is_system'),
        name: 'isSystem',
        options: TableUtils.options.iconCheck()
      },
      {
        label: trans('status'),
        name: 'status',
        options: TableUtils.options.switch
      }
    ],
    [onAlert]
  );

  const table = useDataTable({
    name: WebTable.DATA_GROUP,
    apiURL: ENDPOINTS.dataGroup(),
    columnsFunc,
    showFilter: false
  });

  useGlobalTable(table);

  return (
    <KContainer.Card
      size="nm"
      border
      header={{
        title: trans('data_groups'),
        border: true
      }}
      marginT="0.25rem"
      height="100%"
    >
      <DataTable
        {...table}
        onAdd={() => onShowDataGroup()}
        isModifying={deleteLoading}
      />
    </KContainer.Card>
  );
};

export default memo(DataGroup);
