import { alertRef, popupRef, popperRef } from '@constants';
import React, { memo } from 'react';

import Alert from './Alert';
import Popper from './Popper';
import Popup from './Popup';

export const Portal = memo(() => {
  return (
    <>
      <Alert ref={alertRef} />
      <Popup ref={popupRef} />
      <Popper ref={popperRef} />
    </>
  );
});
