import { DATE_FORMAT, Status, YesOrNo } from '@constants';
import { Prototype } from '@core';
import { UIUtils } from '@utils';
import { cloneDeep, isNumber } from 'lodash';
import { CSSProperties, createRef } from 'react';
import trans from 'translation';
import {
  KColors,
  KButton,
  KChip,
  KContainer,
  KImage,
  KLabel,
  KSwitch,
  KChipProps,
  MIcon,
  KListItemBaseItemProps,
  KListItem
} from 'uikit';

interface ITableChip extends Omit<KChipProps, 'label'> {
  label?: string;
}

export default class TableUtils {
  static baseOptions = (css?: CSSProperties) => {
    return {
      setCellHeaderProps: () => {
        return {
          style: { whiteSpace: 'pre', ...css }
        };
      }
    };
  };

  static pureBaseOptions = (css?: CSSProperties) => {
    return {
      ...this.baseOptions(css),
      sort: false,
      draggable: false,
      empty: true
    };
  };

  static options = {
    pureBaseOptions: this.pureBaseOptions,

    base: this.baseOptions(),
    baseCenter: this.baseOptions({
      textAlign: 'center'
    }),

    baseSm: this.baseOptions({ minWidth: 80 }),
    baseNm: this.baseOptions({ minWidth: 100 }),
    baseMd: this.baseOptions({ minWidth: 120 }),
    baseXMd: this.baseOptions({ minWidth: 130 }),
    baseLg: this.baseOptions({ minWidth: 140 }),
    baseXLg: this.baseOptions({ minWidth: 160 }),
    base2XLg: this.baseOptions({ minWidth: 180 }),
    base3XLg: this.baseOptions({ minWidth: 200 }),
    base4XLg: this.baseOptions({ minWidth: 220 }),
    base5XLg: this.baseOptions({ minWidth: 240 }),

    action: this.pureBaseOptions({}),
    // eslint-disable-next-line no-unused-vars
    actionWithEdit: (dataList: any[], onPress: (data: any) => void) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const data = dataList?.[index];
        return (
          <KButton.Icon
            icon="Edit"
            size="sm"
            background={KColors.warning.normal}
            tintColor={KColors.white}
            br="x"
            onPress={() => onPress(data)}
            hasShadow
          />
        );
      }
    }),
    // eslint-disable-next-line no-unused-vars
    actionWithView: (dataList: any[], onPress: (data: any) => void) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const data = dataList?.[index];
        return (
          <KButton.Icon
            icon="Visibility"
            size="sm"
            background={KColors.warning.normal}
            tintColor={KColors.white}
            br="x"
            onPress={() => onPress(data)}
            hasShadow
          />
        );
      }
    }),
    actionWithMenuList: (
      dataList: any[],
      menuData: (data: any, dismiss: () => void) => KListItemBaseItemProps[],
      autoDismiss: boolean = true,
      displayFn?: (item: any) => boolean
    ) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const data = dataList?.[index];
        const ref = createRef<HTMLButtonElement>();
        const isHidden = displayFn?.(data) ?? false;

        if (isHidden) return null;

        return (
          <KButton.Icon
            ref={ref}
            icon="MoreVertOutlined"
            size="sm"
            // avoidParentPress
            onPress={() => {
              UIUtils.popper.open({
                anchorEl: ref.current,
                touchOutsideToDismiss: true,
                placement: 'right-start',
                withMaxZIndex: true,
                content: dismiss => (
                  <KListItem.Base
                    alignItems
                    data={menuData(data, dismiss).map(i => ({
                      ...i,
                      onPress: () => {
                        (i.onPress as any)?.();
                        autoDismiss && dismiss();
                      }
                    }))}
                  />
                )
              });
            }}
          />
        );
      }
    }),

    icon: (
      mappedData: {
        key: any;
        icon: MIcon;
        color?: string;
      }[],
      center?: boolean
    ) => {
      return {
        ...this.options.baseCenter,
        customBodyRender: (v?: any) => {
          const data = mappedData.find(i => i.key === v);
          if (data) {
            if (center) {
              return (
                <KContainer.View dp="flex" center>
                  <KImage.MuiIcon icon={data.icon} color={data.color} />
                </KContainer.View>
              );
            }
            return <KImage.MuiIcon icon={data.icon} color={data.color} />;
          }
          return undefined;
        }
      };
    },
    iconCheck: (data?: {
      revert?: boolean;
      center?: boolean;
      revertKey?: boolean;
    }) => {
      const { revert, revertKey, center } = data || {};
      return this.options.icon(
        [
          {
            key: revertKey ? false : true,
            icon: 'CheckCircleOutlineOutlined',
            color: revert ? KColors.secondary.normal : KColors.primary.normal
          }
        ],
        center
      );
    },

    mappedOption: {
      customBodyRender: (v?: string) =>
        v ? trans(`option.${v.toLowerCase()}`).toUpperCase() : ''
    },
    mappedOptionCustom: (defaultValue = trans('option.all').toUpperCase()) => ({
      customBodyRender: (v?: string) =>
        v
          ? trans(`option.${v.toLowerCase()}`).toUpperCase()
          : defaultValue || ''
    }),

    date: (f: string = DATE_FORMAT) => ({
      ...this.baseOptions({ minWidth: 130 }),
      customBodyRender: (v?: string) => Prototype.date.toLocal(v)?.format(f)
    }),

    withEllipsis: {
      ...this.baseOptions({ minWidth: 200 }),
      customBodyRender: (v?: string) =>
        v ? <KLabel.Text numberOfLines={4}>{v}</KLabel.Text> : undefined
    },
    withNA: {
      ...this.baseOptions(),
      customBodyRender: (v?: string) => v || 'N/A'
    },
    withUnit: (unit?: string) => ({
      customBodyRender: (v?: string) => (v ? `${v} ${unit}` : '')
    }),
    withTooltip: (normalizeData: (data: any) => string) => {
      return {
        ...this.options.base3XLg,
        customBodyRender: (v?: any) => {
          const data = v ? normalizeData(v) : undefined;
          if (data) {
            return (
              <KLabel.Text numberOfLines={1} withTooltip>
                {data}
              </KLabel.Text>
            );
          }
          return undefined;
        }
      };
    },
    withMoreIcon: (data: {
      onPress: (data: any[]) => void;
      renderItem: (i: any, index: number, rowIndex: number) => React.ReactNode;
      maxLength?: number;
      onNormalizeData?: (rowData: any) => any;
      isHorizontal?: boolean;
    }) => {
      const {
        onPress,
        maxLength = 2,
        renderItem,
        onNormalizeData,
        isHorizontal = true
      } = data;

      return {
        ...this.options.base3XLg,
        customBodyRender: (value: any[], rowData: any) => {
          const v = onNormalizeData ? onNormalizeData(rowData) : value;

          if (v && Array.isArray(v)) {
            const arr = cloneDeep(v);
            const displayArr = arr.slice(0, maxLength);

            if (!isHorizontal) {
              return (
                <KContainer.View>
                  {displayArr.map((i, idx) => {
                    const marginT = idx === 0 ? 0 : '0.25rem';
                    return idx === displayArr.length - 1 ? (
                      <KContainer.View
                        row
                        alignItems
                        marginT={marginT}
                        key={idx}
                      >
                        {renderItem(i, idx, rowData.rowIndex)}

                        {arr.length > maxLength && (
                          <KButton.Icon
                            icon="MoreHoriz"
                            tintColor={KColors.white}
                            background={KColors.primary.normal}
                            br="x"
                            size="sm"
                            onPress={() => onPress(arr)}
                            marginL="0.25rem"
                          />
                        )}
                      </KContainer.View>
                    ) : (
                      <KContainer.View marginT={marginT} key={idx}>
                        {renderItem(i, idx, rowData.rowIndex)}
                      </KContainer.View>
                    );
                  })}
                </KContainer.View>
              );
            }

            return (
              <KContainer.View row alignItems>
                {displayArr.map((i, idx) =>
                  renderItem(i, idx, rowData.rowIndex)
                )}

                {arr.length > maxLength && (
                  <KButton.Icon
                    icon="MoreHoriz"
                    tintColor={KColors.white}
                    background={KColors.primary.normal}
                    br="x"
                    size="sm"
                    onPress={() => onPress(arr)}
                    marginL="0.25rem"
                  />
                )}
              </KContainer.View>
            );
          }
          return undefined;
        }
      };
    },
    withBreakLines: (data?: any[]) => {
      return {
        customBodyRender: (v: string, rowData: any) => {
          const item = data?.[rowData.rowIndex];
          if (item && v?.includes('\n')) {
            const arr = v.split('\n');
            const displayArr = arr.slice(0, 5);

            const displays = displayArr.map((i, idx) =>
              i ? (
                <KLabel.Paragraph key={`${item.id}-${i}-${idx}`}>
                  {i}
                  {idx === 3 && arr.length > displayArr.length && (
                    <KLabel.Text key={`${item.id}-more`}>...</KLabel.Text>
                  )}
                </KLabel.Paragraph>
              ) : (
                <br key={`${item.id}-br-${idx}`} />
              )
            );

            return displays;
          }

          return v ? <KLabel.Text numberOfLines={4}>{v}</KLabel.Text> : '';
        }
      };
    },

    chip: (data?: {
      css?: CSSProperties;
      uppercase?: boolean;
      width?: number;
      props?: ITableChip;
    }) => ({
      ...this.baseOptions(data?.css),
      customBodyRender: (v?: any) => {
        if (v) {
          return (
            <KChip
              background={v?.backgroundColor || '#DEFFFD'}
              brC={v?.border || KColors.primary.normal}
              label={v?.name ?? v}
              textTransform={data?.uppercase ? 'uppercase' : 'capitalize'}
              color={v?.color || KColors.primary.normal}
              width={data?.width}
              {...data?.props}
            />
          );
        }

        return undefined;
      }
    }),
    chips: (data: {
      css?: CSSProperties;
      uppercase?: boolean;
      width?: number;
      mapLabelData: {
        [key: string]: ITableChip;
      };
      notFoundKey?: string;
    }) => ({
      ...this.baseOptions(data?.css),
      customBodyRender: (v?: any) => {
        let item = v;
        if (v) {
          if (typeof v === 'string') {
            item = {
              name: v,
              ...(data?.mapLabelData?.[v] ??
                data?.mapLabelData?.[v.toLowerCase()])
            };
          } else if (typeof v === 'boolean') {
            item = {
              name: v,
              ...(data?.mapLabelData?.[`${v}`] ??
                data?.mapLabelData?.[`${v}`.toLowerCase()])
            };
          }
        } else if (data.notFoundKey) {
          item = {
            name: data.notFoundKey,
            ...(data?.mapLabelData?.[`${data.notFoundKey}`] ??
              data?.mapLabelData?.[`${data.notFoundKey}`.toLowerCase()])
          };
        }

        if (item) {
          const { name, label, ...rest } = item;
          return (
            <KChip
              background={item?.backgroundColor || '#DEFFFD'}
              brC={KColors.primary.normal}
              label={label ?? name ?? ''}
              textTransform={data?.uppercase ? 'uppercase' : 'capitalize'}
              color={KColors.primary.normal}
              width={data?.width}
              {...rest}
            />
          );
        }

        return undefined;
      }
    }),
    switch: {
      ...this.pureBaseOptions(),
      customBodyRender: (v?: string) => (
        <KSwitch id="status" checked={v === Status.Active} disabled />
      )
    },
    checkbox: {
      ...this.baseOptions(),
      sort: false,
      customBodyRender: (v?: boolean) => (
        <KContainer.View center>
          <KImage.MuiIcon
            icon={v ? 'CheckBoxOutlined' : 'CheckBoxOutlineBlankOutlined'}
            color={v ? KColors.primary.normal : KColors.black}
            style={{ verticalAlign: 'middle' }}
          />
        </KContainer.View>
      )
    },
    chipList: {
      ...this.baseOptions(),
      customBodyRender: (v?: any[]) =>
        (v?.length ?? 0) > 0 ? (
          <KContainer.View dp="flex" row alignItems flexW="wrap">
            {v?.map(i => (
              <KChip
                key={i}
                background={'#FFF1E0'}
                margin={'0.25rem'}
                marginL={0}
                label={i.code ?? ''}
              />
            ))}
          </KContainer.View>
        ) : undefined
    },
    chipListLg: {
      ...this.baseOptions({ minWidth: 140 }),
      customBodyRender: (v?: any[]) =>
        (v?.length ?? 0) > 0 ? (
          <KContainer.View dp="flex" row alignItems flexW="wrap">
            {v?.map(i => (
              <KChip
                key={i}
                background={'#FFF1E0'}
                margin={'0.25rem'}
                marginL={0}
                label={i.code ?? ''}
              />
            ))}
          </KContainer.View>
        ) : undefined
    },
    chipListLgCustom: (data?: {
      onNormalizeData?: (rowData: any) => any;
      onPress: (data: any) => void;
    }) => {
      const { onNormalizeData, onPress } = data || {};
      return {
        ...this.baseOptions({ minWidth: 140 }),
        customBodyRender: (value: any[], rowData?: any) => {
          const v = onNormalizeData?.(rowData) ?? value;
          if (v && Array.isArray(v)) {
            const arr = cloneDeep(v);
            return (
              <KContainer.View dp="flex" row alignItems flexW="wrap">
                {arr.map(i => (
                  <KChip
                    key={i}
                    background={'#FFF1E0'}
                    margin={'0.25rem'}
                    marginL={0}
                    label={i.code ?? ''}
                    onPress={onPress ? () => onPress?.(i) : undefined}
                  />
                ))}
              </KContainer.View>
            );
          }
          return undefined;
        }
      };
    },

    number: (defaultValue: any = '', options?: any) => ({
      ...this.baseOptions(),
      customBodyRender: (v?: number | string) =>
        !Prototype.core.isNullOrUndefined(v) &&
        (isNumber(v) || isNumber(parseFloat(v as string)))
          ? Prototype.number.formatNumber(v, options)
          : defaultValue
    }),
    currency: (defaultValue: any = '', options?: any) => ({
      ...this.baseOptions(),
      customBodyRender: (v?: number | string) =>
        !Prototype.core.isNullOrUndefined(v) && isNumber(v)
          ? Prototype.number.formatCurrency(v, { withAbs: v < 0, ...options })
          : !Prototype.core.isNullOrUndefined(v) &&
              isNumber(parseFloat(v as string))
            ? Prototype.number.formatCurrency(v, {
                withAbs: parseFloat(v as string) < 0,
                ...options
              })
            : defaultValue
    }),

    toggle: (flag = true): any => ({
      display: !flag ? 'excluded' : true,
      viewColumns: flag
    }),
    boolean: (data: [any, any] = [YesOrNo.Yes, YesOrNo.No]) => ({
      customBodyRender: (v: any) => (v ? data[0] : data[1])
    })
  };
}
