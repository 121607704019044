import { ArApType, InvoiceStatusId, UIMenuItem, UITab } from '@constants';
import { useAccountingContext } from '@context/Accounting';
import { useCheckScreenWithCallback } from '@hooks';
import Invoices from '@pages/Invoices';
import Statement from '@pages/Statement';
import React, { memo, useCallback, useRef, useState } from 'react';
import trans from 'translation';
import { KTab, KTabInstance } from 'uikit';
import { useMount } from 'uikit-common';

const Purchases = () => {
  const tabRef = useRef<KTabInstance>(null);

  const { setInvoiceId, setDefaultArApType, setTab, setClientId } =
    useAccountingContext();

  const [tabIndex, setTabIndex] = useState(0);

  const cb = useCallback(() => {
    let _tabs = [
      {
        code: UITab.AccountingApInvoices,
        label: trans('ap_invoices_ns'),
        defCode: UITab.AccountingPurchases
      },
      {
        code: UITab.AccountingApWipInvoices,
        label: trans('ap_wip_invoices_ns')
      },
      {
        code: UITab.AccountingApAccounts,
        label: trans('ap_accounts_ns')
      }
    ];

    return _tabs;
  }, []);

  const tabs = useCheckScreenWithCallback(cb, UIMenuItem.Accounting);

  const onChangeTab = useCallback(
    (index: number) => {
      const url = new URL(window.location as any);
      url.searchParams.set('tab', tabs?.[index]?.code);
      window.history.replaceState({}, '', url);

      setTabIndex(index);
      setTab(tabs?.[index]?.code);
    },
    [setTab, tabs]
  );

  useMount(() => {
    setDefaultArApType(ArApType.Ap);

    setTimeout(() => {
      const url = new URL(window.location as any);

      const _tabCode = url.searchParams.get('tab');
      const _tab = _tabCode
        ? tabs.findIndex(i => i.code === _tabCode || i.defCode === _tabCode)
        : -1;

      const _invoiceId = url.searchParams.get('invoiceId');
      const _statementId = url.searchParams.get('statementId');

      if (_tab >= 0 || _invoiceId) {
        if (_statementId) {
          setClientId(parseInt(_statementId as string));
          url.searchParams.delete('statementId');
        } else if (_invoiceId) {
          setInvoiceId(parseInt(_invoiceId as string));
          url.searchParams.delete('invoiceId');
        }

        if (_tab >= 0) {
          setTab(_tabCode as UITab);
          tabRef.current?.onChange(_tab, true);
          // url.searchParams.delete('tab');
        }

        window.history.replaceState({}, '', url);
      }
    }, 100);
  });

  return (
    <>
      <KTab.Solid
        ref={tabRef}
        tabIndex={tabIndex}
        onChangeTab={onChangeTab}
        tabs={tabs}
        br={0}
      />

      <KTab.Panel
        value={tabIndex}
        index={tabs.findIndex(i => i.code === UITab.AccountingApInvoices)}
      >
        <Invoices />
      </KTab.Panel>

      <KTab.Panel
        value={tabIndex}
        index={tabs.findIndex(i => i.code === UITab.AccountingApWipInvoices)}
      >
        <Invoices invoiceProgressId={InvoiceStatusId.Wip} isWip />
      </KTab.Panel>

      <KTab.Panel
        value={tabIndex}
        index={tabs.findIndex(i => i.code === UITab.AccountingApAccounts)}
      >
        <Statement />
      </KTab.Panel>
    </>
  );
};

export default memo(Purchases);
