import { ENDPOINTS, QUERY_KEYS, Status, WebTable } from '@constants';
import APIManager from '@services';
import { useMemo } from 'react';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchEquipment = (id?: number | string) => {
  const { data, ...rest } = useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.equipment, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });

  const isCombination = useMemo(
    () =>
      !!data?.subType?.isCombination || (data?.subEquipments ?? []).length > 0,
    [data?.subEquipments, data?.subType?.isCombination]
  );

  return {
    data,
    isCombination,
    subEquipments: data?.subEquipments,
    ...rest
  };
};

export const useCUDEquipment = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.equipment(),
    webTable: WebTable.EQUIPMENT,
    queryKey: QUERY_KEYS.equipment,
    redirectPath: '/admin/equipment'
  });
};

export const useFetchEquipmentList = (mParams?: any, enabled = true) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.equipmentList, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(),
        body: {
          status: Status.Active,
          ...mParams
        }
      });

      return res.data?.data ?? [];
    },
    enabled,
    initialData: []
  });
};

export const useFetchScheduleHasDamagedEquipment = (
  from: string, // YYYY-MM-DD
  to?: string, // YYYY-MM-DD
  enabled = true
) => {
  const { data, ...rest } = useFetchEquipmentList(
    {
      filterDamaged: true,
      filterDamagedFromDate: from,
      filterDamagedToDate: to,
      size: 1
    },
    enabled
  );

  return { data: enabled && (data ?? []).length > 0, ...rest };
};
