import constants, { StorageKeys } from '@constants';
import { AppStorage } from '@core';
import { i18n } from '@lingui/core';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from 'react';

type Action = {
  type: string;
  payload?: any;
};

interface State {
  appearance: 'light' | 'dark';
  language: 'en' | 'vi';
}

interface TPreferences {
  appearance: 'light' | 'dark';
  language: 'en' | 'vi';
  toggleAppearance: () => void;
  toggleLanguage: () => void;
}

const { DARK, LIGHT } = constants.APPEARANCE;

const initState: State = {
  appearance: LIGHT,
  language: 'en'
};

const reducer = (state: State, action: Action) => {
  let newState;

  switch (action.type) {
    case 'toggleAppearance':
      newState = {
        ...state,
        appearance: state.appearance === LIGHT ? DARK : LIGHT
      };
      AppStorage.set(StorageKeys.preferences, newState);
      return newState;

    case 'toggleLanguage':
      newState = {
        ...state,
        language: state.language === 'en' ? 'vi' : 'en'
      };
      i18n.activate(newState.language);
      AppStorage.set(StorageKeys.preferences, newState);
      return newState;

    default:
      return { ...state };
  }
};

const PreferencesContext = createContext();

const PreferencesProvider = ({ children }) => {
  const [state, setState] = useReducer(reducer, initState, undefined);

  const toggleAppearance = useCallback(() => {
    setState({
      type: 'toggleAppearance'
    });
  }, []);

  const toggleLanguage = useCallback(() => {
    setState({
      type: 'toggleLanguage'
    });
  }, []);

  const value: TPreferences = useMemo(
    () => ({
      ...state,
      toggleAppearance,
      toggleLanguage
    }),
    [state, toggleAppearance, toggleLanguage]
  );

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = (): TPreferences =>
  useContext(PreferencesContext);

export { PreferencesContext, PreferencesProvider };
