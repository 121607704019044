import { WebTable } from '@constants';
import AgTable from '@containers/AgTable';
import { useAgTable } from '@hooks';
import React, { memo } from 'react';

import { useColDefs } from './helpers';

interface IProps {
  data: any[];
}

const FormViewInvoices = ({ data }: IProps) => {
  const colDefs = useColDefs(true);

  const table = useAgTable({
    name: WebTable.LOCAL_PAYMENT_INVOICE,
    data,
    colDefs,
    onAddParams: {
      hidden: true
    }
  });

  return (
    <AgTable
      {...table}
      showSearch
      pagination={false}
      autoSizeStrategy={{
        type: 'fitGridWidth'
      }}
    />
  );
};

export default memo(FormViewInvoices);
