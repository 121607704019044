import { ClientType, JobType, RelatedAddressType } from '@constants';
import { useAlertMutationEnhancer, useCUDTransportJob } from '@hooks';
import loadable from '@loadable/component';
import { UIUtils } from '@utils';
import qs from 'query-string';
import React from 'react';
import trans from 'translation';

const ContainerForm = loadable(() => import('./Container/Form'));

export const useDeleteTransportJob = () => {
  const { deleteMutation } = useCUDTransportJob();

  return useAlertMutationEnhancer({
    mutation: deleteMutation,
    message: trans('message_delete_transport_job')
  });
};

export const onShowTransportJob = (
  id: number | string | 'new',
  query?: any
) => {
  window.open(
    `/admin/transport-jobs/${id}${query ? `?${qs.stringify(query)}` : ''}`,
    '_blank'
  );
};

export const onShowContainer = (
  v?: any,
  mParams?: {
    transportJobId?: number | string;
    selectedContainer?: any;
    setItem?: (v: any) => void;
  }
) => {
  UIUtils.popup.open({
    title: trans('container'),
    maxWidth: 'md',
    content: () => <ContainerForm item={v} {...mParams} />
  });
};

export const mappedConsignCardData = {
  [JobType.Import]: {
    title: 'consignee',
    typeIn: [ClientType.Consignee]
  },
  [JobType.RailInbound]: {
    title: 'consignee',
    typeIn: [ClientType.Consignee]
  },
  [JobType.Export]: {
    title: 'consignor',
    typeIn: [ClientType.Consignor]
  },
  [JobType.RailOutbound]: {
    title: 'consignor',
    typeIn: [ClientType.Consignor]
  },
  [JobType.Misc]: {
    title: 'consignee_consignor',
    typeIn: [ClientType.Consignee, ClientType.Consignor]
  }
};

export const mappedWarehouseCardData = {
  [JobType.Import]: {
    title: 'delivery_warehouse',
    typeIn: [RelatedAddressType.DeliveryWarehouse]
  },
  [JobType.RailInbound]: {
    title: 'delivery_warehouse',
    typeIn: [RelatedAddressType.DeliveryWarehouse]
  },
  [JobType.Export]: {
    title: 'pickup_warehouse',
    typeIn: [RelatedAddressType.PickupWarehouse]
  },
  [JobType.RailOutbound]: {
    title: 'pickup_warehouse',
    typeIn: [RelatedAddressType.PickupWarehouse]
  },
  [JobType.Misc]: {
    title: 'warehouse',
    typeIn: [
      RelatedAddressType.DeliveryWarehouse,
      RelatedAddressType.PickupWarehouse
    ]
  }
};
