import {
  DEFAULT_TAX_ID,
  ENDPOINTS,
  MarkupType,
  SystemSettingCode,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { Prototype } from '@core';
import {
  useFetchInvoice,
  useFetchSetting,
  useFetchTaxList,
  useLocalTable,
  useMutateDataForInvoiceContainer
} from '@hooks';
import ExtraCompanyTariff from '@pages/Quotation/Request/components/ExtraCompanyTariff';
import { TableUtils, UIUtils } from '@utils';
import { omit } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KGrid, KInput } from 'uikit';

import { IFormData, useInvoiceFParams } from './helpers';

import ChooseCharges from '../components/ChooseCharges';
import EditChargeCodePrice from '../components/EditChargeCodePrice';

interface IProps {
  invoiceId?: number;
  isView?: boolean;
  isAr: boolean;
}

const InvoiceCharges = ({ invoiceId, isView, isAr }: IProps) => {
  const methods = useFormContext<IFormData>();

  const { data: numberOfLevels = 3 } = useFetchSetting(
    SystemSettingCode.PriceLevel,
    false
  );

  const invoiceFParams = useInvoiceFParams();

  const { data: item } = useFetchInvoice(invoiceId);

  const { data: taxList = [] } = useFetchTaxList();
  const defaultTax = taxList.find(i => i.id === DEFAULT_TAX_ID);

  const [data, template, defaultLevel] = useWatch({
    control: methods.control,
    name: [
      'charges',
      'chargeParams.chargeCodeTemplate',
      'chargeParams.defaultLevel'
    ]
  });

  const onDelete = useCallback(
    (_item: any) => {
      methods.setValue(
        'charges',
        data.filter(i => i.companyTariffId !== _item.companyTariffId)
      );
    },
    [data, methods]
  );

  const onEditSuccess = useCallback(
    (v: any) => {
      if (v?.isDelete) {
        methods.setValue(
          'charges',
          data.filter(i => i.companyTariffId !== v.companyTariffId),
          { shouldDirty: true }
        );
      } else {
        const index = data.findIndex(
          i => i.companyTariffId === v.companyTariffId
        );
        if (index !== -1) {
          data[index] = {
            ...data[index],
            ...v
          };
        }
        methods.setValue('charges', data, { shouldDirty: true });
      }
    },
    [data, methods]
  );

  const onShowPopup = useCallback(
    (v?: any) => {
      UIUtils.popup.open({
        title: trans('edit_price_of_charge_code'),
        maxWidth: 'md',
        content: () => (
          <EditChargeCodePrice
            item={v}
            chargeCodeIds={data
              .map(i => i?.companyTariff?.chargeCodeId)
              .filter(i => i)}
            onSuccess={onEditSuccess}
            isView={isView}
          />
        )
      });
    },
    [data, isView, onEditSuccess]
  );

  const columns = useMemo(() => {
    return [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, _item => {
          const actions = [
            {
              title: isView ? trans('view') : trans('edit'),
              icon: isView ? 'Visibility' : 'EditOutlined',
              onPress: () => onShowPopup(_item)
            }
          ];

          if (!isView) {
            actions.push({
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete(_item)
            });
          }

          return actions as any;
        })
      },
      {
        label: trans('charge_code'),
        name: 'companyTariff.code',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('charge_code_name'),
        name: 'companyTariff.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('note'),
        name: 'note',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('remark'),
        name: 'companyTariff.remark',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('category'),
        name: 'companyTariff.chargeCode.chargeCodeCategory.name',
        options: TableUtils.options.baseLg
      },
      {
        label: trans('per_unit'),
        name: 'companyTariff.chargeCode.chargeCodeUnit.name',
        options: TableUtils.options.baseSm
      },
      {
        label: trans('unit_price'),
        name: 'actualSellingPriceValue',
        options: TableUtils.options.currency()
      },
      {
        label: trans('quantity'),
        name: 'quantity'
      },
      {
        label: trans('amount'),
        name: 'amountWithoutTax',
        options: TableUtils.options.currency()
      },
      {
        label: trans('taxable_value'),
        name: 'taxableValue',
        options: TableUtils.options.number('', {
          unit: '%',
          revert: true,
          ignoreUnit: false
        })
      },
      {
        label: trans('taxable_amount'),
        name: 'taxableAmount',
        options: TableUtils.options.currency()
      },
      {
        label: trans('total'),
        name: 'totalAmount',
        options: TableUtils.options.currency()
      }
    ];
  }, [data, isView, onDelete, onShowPopup]);

  const onRefresh = useCallback(() => {
    methods.setValue('charges', item?.invoiceContainerCharges ?? []);
  }, [item?.invoiceContainerCharges, methods]);

  const table = useLocalTable({
    name: WebTable.INVOICE_CHARGES,
    data,
    columns,
    onRefresh,
    otherOptions: {
      selectableRows: 'none',
      pagination: false
    },
    showDownload: false
  });

  const { mutateFetchCompanyTariffForInvoice, invoiceContainerLoading } =
    useMutateDataForInvoiceContainer();

  const onCompanyTariffSuccess = useCallback(
    (v: any) => {
      const newCharges = [v].map(i => {
        const {
          components,
          sellingPriceValue: mspValue,
          rateValue,
          companyTariff: cTariff
        } = i;
        const level = cTariff.companyTariffLevels.find(
          (c: any) => c.levelNumber === i.selectedLevelNumber
        );
        const isCurrency = [
          MarkupType.Currency,
          MarkupType.Percentage
        ].includes(level.levelValueType);

        return {
          ...i,
          levelValueType: level.levelValueType,
          originalPriceValue: mspValue,
          originalRateValue: rateValue,
          companyTariffLevel: level,
          companyTariffLevelId: level.id,
          originalCostingPrice: cTariff.costingPrice,
          originalMinSellingPrice: cTariff.minSellingPrice,
          actualRateValue: rateValue,
          actualSellingPriceValue: isCurrency
            ? Prototype.number.round(parseFloat(mspValue), 2)
            : 0,
          actualCostingPrice: cTariff.costingPrice,
          actualMinSellingPrice: cTariff.minSellingPrice,
          quantity: 1,
          amountWithoutTax: isCurrency
            ? Prototype.number.round(parseFloat(mspValue), 2)
            : 0,
          tax: defaultTax,
          taxableValue: defaultTax?.value,
          taxableAmount:
            defaultTax && isCurrency
              ? Prototype.number.round(
                  (parseFloat(mspValue) * defaultTax.value) / 100,
                  2
                )
              : 0,
          totalAmount: isCurrency
            ? Prototype.number.round(
                parseFloat(mspValue) +
                  (defaultTax
                    ? Prototype.number.round(
                        (parseFloat(mspValue) * defaultTax.value) / 100,
                        2
                      )
                    : 0),
                2
              )
            : 0,
          invoiceChargeComponents: (components ?? []).map((c: any) => {
            delete c.id;
            return {
              ...c,
              chargeCodeComponentValue: '',
              originalWeight: c.weight,
              actualWeight: c.weight
            };
          }),
          note: cTariff.name
        };
      });

      methods.setValue('charges', [...data, ...newCharges]);
    },
    [data, defaultTax, methods]
  );

  const fParams = useMemo(
    () => ({
      ...invoiceFParams,
      selectedLevelNumber: 1,
      excludeIds: data.map(i => i.companyTariffId)
    }),
    [data, invoiceFParams]
  );

  const onAdd = useCallback(() => {
    UIUtils.popup.open({
      title: trans('add_extra_company_tariff'),
      maxWidth: 'md',
      content: () => (
        <ExtraCompanyTariff
          fParams={fParams}
          onSuccess={onCompanyTariffSuccess}
          chargeCodeIds={data
            .map(i => i?.companyTariff?.chargeCodeId)
            .filter(i => i)}
        />
      )
    });
  }, [data, fParams, onCompanyTariffSuccess]);

  const onChooseCharges = useCallback(
    (res: any[]) => {
      UIUtils.popup.open({
        title: trans('choose_charges'),
        maxWidth: 'lg',
        content: () => (
          <ChooseCharges
            data={res}
            onSuccess={v => methods.setValue('charges', [...data, ...v])}
          />
        )
      });
    },
    [data, methods]
  );

  const onChooseFromTemplate = useCallback(() => {
    if (!template) {
      methods.setError('chargeParams.chargeCodeTemplate', {
        message: trans('validation.required')
      });
      return;
    }

    const excludeChargeCodeIds = data
      .map(i => i?.companyTariff?.chargeCodeId)
      .filter(i => i);

    const mParams = {
      chargeCodeTemplateId: template?.id,
      selectedLevelNumber: defaultLevel,
      excludeIds: excludeChargeCodeIds,
      ...invoiceFParams
    };

    mutateFetchCompanyTariffForInvoice(mParams, {
      onSuccess: _data => {
        const res = _data.map(i => {
          const level = i.companyTariffLevels.find(
            (c: any) => c.levelNumber === mParams.selectedLevelNumber
          );
          const {
            matchedSellingPriceValue: mspValue,
            rateValue,
            matchedCompanyTariffLevelComponents: components
          } = level;
          const rest = omit(level, [
            'id',
            'status',
            'matchedSellingPriceValue',
            'sellingPriceValue',
            'matchedCompanyTariffLevelComponents',
            'companyTariffLevelComponents'
          ]);
          const isCurrency = [
            MarkupType.Currency,
            MarkupType.Percentage
          ].includes(level.levelValueType);

          return {
            ...rest,
            companyTariff: i,
            companyTariffId: i.id,
            status: i.status,
            levelValueType: level.levelValueType,
            originalPriceValue: mspValue,
            originalRateValue: rateValue,
            companyTariffLevel: level,
            companyTariffLevelId: level.id,
            originalCostingPrice: i.costingPrice,
            originalMinSellingPrice: i.minSellingPrice,
            actualRateValue: rateValue,
            actualSellingPriceValue: isCurrency
              ? Prototype.number.round(parseFloat(mspValue), 2)
              : 0,
            actualCostingPrice: i.costingPrice,
            actualMinSellingPrice: i.minSellingPrice,
            quantity: 1,
            amountWithoutTax: isCurrency
              ? Prototype.number.round(parseFloat(mspValue), 2)
              : 0,
            tax: defaultTax,
            taxableValue: defaultTax?.value,
            taxableAmount:
              defaultTax && isCurrency
                ? Prototype.number.round(
                    (parseFloat(mspValue) * defaultTax.value) / 100,
                    2
                  )
                : 0,
            totalAmount: isCurrency
              ? Prototype.number.round(
                  parseFloat(mspValue) +
                    (defaultTax
                      ? Prototype.number.round(
                          (parseFloat(mspValue) * defaultTax.value) / 100,
                          2
                        )
                      : 0),
                  2
                )
              : 0,
            invoiceChargeComponents: (components ?? []).map((c: any) => {
              delete c.id;
              return {
                ...c,
                chargeCodeComponentValue: '',
                originalWeight: c.weight,
                actualWeight: c.weight
              };
            }),
            note: i.name,
            chargeCodeTemplateId: mParams.chargeCodeTemplateId
          };
        });

        onChooseCharges(res);
      }
    });
  }, [
    data,
    defaultLevel,
    defaultTax,
    invoiceFParams,
    methods,
    mutateFetchCompanyTariffForInvoice,
    onChooseCharges,
    template
  ]);

  return (
    <>
      <KContainer.RenderWhen>
        <KContainer.RenderWhen.If isTrue={!isView}>
          <KContainer.RenderWhen>
            <KContainer.RenderWhen.If isTrue={!isAr}>
              <KGrid.Item xs={3}>
                <Controller
                  name="chargeParams.chargeCodeTemplate"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('template')}
                        apiURL={ENDPOINTS.chargeCodeTemplate()}
                        apiParams={invoiceFParams}
                        getOptionLabel={(o: any) => o?.code ?? ''}
                        inputProps={{
                          // required: true,
                          message: error?.message
                        }}
                        disabled={invoiceContainerLoading}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={1}>
                <Controller
                  name="chargeParams.defaultLevel"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans('default_level')}
                        options={Array.from(
                          Array(Number(numberOfLevels)),
                          (_, i) => ({
                            key: i + 1,
                            label: `${i + 1}`
                          })
                        )}
                        disabled={invoiceContainerLoading}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={7}>
                <KButton.Solid
                  icon="BrightnessAuto"
                  title={trans('choose_from_template')}
                  onPress={onChooseFromTemplate}
                  isLoading={invoiceContainerLoading}
                />
              </KGrid.Item>
            </KContainer.RenderWhen.If>
          </KContainer.RenderWhen>
        </KContainer.RenderWhen.If>
      </KContainer.RenderWhen>

      <KGrid.Item xs={12}>
        <KContainer.Card
          border
          size="nm"
          header={{
            title: trans('charge_code_list'),
            border: true
          }}
          height="100%"
        >
          <DataTable {...table} onAdd={isView ? undefined : () => onAdd()} />
        </KContainer.Card>
      </KGrid.Item>
    </>
  );
};

export default memo(InvoiceCharges);
