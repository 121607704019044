import constants from '@constants';
import { ToastManager } from '@utils';
import { pick } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface IParams {
  status?: 'ACTIVE' | 'INACTIVE';
  shipmentType?: 'FCL' | 'LCL';
  countryId?: number;
  quotationId?: number;
  containerSizeId?: number;
  zoneId?: number;
  jobType?: string;
  source?: 'CLIENT' | 'NOTE' | 'EDOC';
}

interface IOptions {
  disabled?: boolean;
  api: (payload: any) => Promise<any>;
  onChange: (value: any) => void;
  onEdit?: () => void;
  onAddNew?: () => void;
  href?: string;
  editHref?: string;
  searchKey?: string;
  isMax?: boolean;
  mountOnce?: boolean;
  filteredFields?: string[];
}

export const useDataList = (options: IOptions & IParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const {
    disabled,
    api,
    onChange,
    onEdit,
    onAddNew,
    href,
    editHref,
    searchKey = 'keyword',
    isMax,
    mountOnce,
    filteredFields,
    ...other
  } = options || {};

  const fetchDataList = useCallback(
    async (keyword = '') => {
      if (!disabled) {
        setIsLoading(true);
        const params = {
          page: 0,
          size: isMax
            ? constants.DEFAULT_PAGE_SIZE_MAX
            : constants.DEFAULT_PAGE_SIZE_SELECT
        };
        if (searchKey) {
          params[searchKey] = keyword;
        }
        try {
          const res = await api({
            ...params,
            ...other
          });
          setDataList(
            filteredFields
              ? res.data.map(i => pick(i, filteredFields))
              : res?.data ?? res
          );
        } catch (e) {
          console.log(e);
          ToastManager.notify();
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, disabled, JSON.stringify(other), searchKey, isMax]
  );

  useEffect(
    () => {
      fetchDataList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    mountOnce ? [disabled] : [disabled, JSON.stringify(other)]
  );

  const handleEdit = () => {
    try {
      if (onEdit) {
        onEdit();
      } else if (editHref) {
        window.open(editHref, '_blank');
      }
    } catch (e) {
      console.log(e);
      ToastManager.notify();
    }
  };

  const handleChange = v => {
    if (
      v?.id === 'addNew' ||
      (Array.isArray(v) && v.some(i => i.id === 'addNew'))
    ) {
      if (onAddNew) {
        onAddNew();
      } else {
        window.open(href, '_blank');
      }
      return;
    }
    onChange(v);
  };

  return { isLoading, dataList, fetchDataList, handleEdit, handleChange };
};
