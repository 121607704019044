import { WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { useLocalTable, useResolverForm } from '@hooks';
import { TableUtils, UIUtils } from '@utils';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import { KButton, KContainer, KForm } from 'uikit';
import * as yup from 'yup';

interface IProps {
  data: any[];
  onSuccess: (data: any[]) => void;
}

interface IFormData {
  selectedData: any[];
}

const ChooseCharges = ({ data, onSuccess }: IProps) => {
  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({}),
    configs: {
      defaultValues: {}
    }
  });

  const columns = useMemo(() => {
    return [
      {
        label: trans('charge_code'),
        name: 'companyTariff.code',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('charge_code_name'),
        name: 'companyTariff.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('note'),
        name: 'note',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('remark'),
        name: 'companyTariff.remark',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('category'),
        name: 'companyTariff.chargeCode.chargeCodeCategory.name',
        options: TableUtils.options.baseLg
      },
      {
        label: trans('per_unit'),
        name: 'companyTariff.chargeCode.chargeCodeUnit.name',
        options: TableUtils.options.baseSm
      },
      {
        label: trans('unit_price'),
        name: 'actualSellingPriceValue',
        options: TableUtils.options.currency()
      },
      {
        label: trans('quantity'),
        name: 'quantity'
      },
      {
        label: trans('amount'),
        name: 'amountWithoutTax',
        options: TableUtils.options.currency()
      },
      {
        label: trans('taxable_value'),
        name: 'taxableValue',
        options: TableUtils.options.number('', {
          unit: '%',
          revert: true,
          ignoreUnit: false
        })
      },
      {
        label: trans('taxable_amount'),
        name: 'taxableAmount',
        options: TableUtils.options.currency()
      },
      {
        label: trans('total'),
        name: 'totalAmount',
        options: TableUtils.options.currency()
      }
    ];
  }, []);

  const table = useLocalTable({
    name: WebTable.OTHER,
    data,
    columns,
    showDownload: false
  });

  const onFormValid = useCallback(() => {
    onSuccess(table.selectedData);
    UIUtils.popup.dismiss();
  }, [onSuccess, table.selectedData]);

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <DataTable {...table} showRefresh={false} />

      <KContainer.View marginT="0.75rem" style={{ textAlign: 'right' }}>
        <KButton.Solid type="submit" title={trans('submit')} />
      </KContainer.View>
    </KForm>
  );
};

export default memo(ChooseCharges);
