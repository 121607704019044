import { t } from '@lingui/macro';
import { ToastContent, ToastOptions, toast } from 'react-toastify';

export default class ToastManager {
  static toastId = null;

  static notify = (content: ToastContent, options: ToastOptions = {}) => {
    // if (this.toastId) {
    //   this.dismissCurrent();
    // }
    if (content?.length > 100) {
      options = {
        style: { width: 400 },
        ...options
      };
    }

    this.toastId = toast.error(
      content || t`Something went wrong. Please contact us`,
      { type: 'error', ...options }
    );
  };

  static dismissCurrent = () => {
    if (this.toastId) {
      toast.dismiss(this.toastId);
    }
  };

  static dismiss = () => {
    toast.dismiss();
  };
}
