import trans from 'translation';

export const generateOptionsC = <T extends string, TValue extends string>(
  e: {
    [key in T]: TValue;
  },
  eLabel: {
    [key in T]: TValue;
  }
) => {
  return Object.keys(e).map(i => {
    return {
      key: (e as any)[i],
      label: (
        (eLabel as any)?.[i] ?? (eLabel as any)?.[(e as any)[i]]
      ).toUpperCase()
    };
  });
};

export const generateOptions = <T extends string, TValue extends string>(
  e: {
    [key in T]: TValue;
  },
  options?: { withNone?: boolean; key?: string; uppercase?: boolean }
) => {
  const { withNone, key, uppercase = true } = options || {};
  const data = Object.keys(e).map(i => {
    const obj = {
      key: (e as any)[i],
      label: trans(`option.${(e as any)[i].toLowerCase()}`)
    };
    if (uppercase) {
      obj.label = obj.label.toUpperCase();
    }
    if (key) {
      obj[key] = obj.key;
    }
    return obj;
  });

  return withNone
    ? [{ key: '', label: trans('option.none').toUpperCase() }].concat(data)
    : data;
};
