import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { KColors } from 'uikit';

const patchJson = () => {
  const parse = JSON.parse;
  const stringify = JSON.stringify;

  JSON.parse = (str, defaultValue) => {
    try {
      return parse(str);
    } catch (error) {
      return defaultValue;
    }
  };

  JSON.stringify = obj => {
    try {
      return stringify(obj);
    } catch (error) {
      return '';
    }
  };
};

const patchConsole = () => {
  const e = console.error.bind(console);

  const modifyArguments = (args: any, prefix: string) => {
    try {
      let [tag, message] = args;

      if (typeof tag !== 'string') {
        const clone = [...args];
        clone.unshift(`${prefix}[DEFAULT-TAG] ::::: `);
        return clone;
      }
      if (typeof message === 'function') {
        message = '[FUNCTION]';
      }
      if (typeof message === 'object') {
        message = JSON.stringify(message);
      }

      args[0] = prefix + tag + ' ::::: ';
      args[1] = message || '';
      return args;
    } catch (error) {
      return args;
    }
  };

  // console.log = (...args) => {
  //   l(...modifyArguments(args, '\x1b[36m'));
  // };

  console.error = (...args) => {
    if (typeof args?.[0] === 'string') {
      if (args?.[0]?.indexOf('The pseudo class ":nth-child"') === 0) {
        return;
      }
      if (args?.[0]?.indexOf('Warning: %s is deprecated in StrictMode') === 0) {
        return;
      }
      if (args?.[0]?.indexOf('Warning: React does not recognize') === 0) {
        return;
      }
      if (args?.[0]?.indexOf('Warning: Failed %s type: %s%s') === 0) {
        return;
      }
    }
    // e(...modifyArguments(args, '\x1b[31m'));
    e(...args);
  };

  // console.warn = (...args) => {
  //   w(...modifyArguments(args, '\x1b[35m'));
  // };
};

const patchChartJs = () => {
  ChartJS.register(
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  ChartJS.defaults.font.size = 12;
  ChartJS.defaults.font.family = 'Roboto';
  ChartJS.defaults.plugins.legend.display = false;
  ChartJS.defaults.maintainAspectRatio = false;
  // ChartJS.defaults.datasets.bar.maxBarThickness = 20;
  // ChartJS.defaults.datasets.bar.categoryPercentage = 1;
  // ChartJS.defaults.datasets.bar.barPercentage = 1;
  // ChartJS.defaults.datasets.bar.borderSkipped = true;
  // ChartJS.defaults.scale.border.dash = [5, 5];
  ChartJS.defaults.set('datasets.bar', {
    maxBarThickness: 20,
    borderSkipped: true
  });
  ChartJS.defaults.set('scale.border', {
    dash: [5, 5]
  });
  ChartJS.defaults.set('plugins.datalabels', {
    color: KColors.white,
    formatter: function (value: any, context: any) {
      return context.chart.config.type === 'pie'
        ? context.chart.data.labels[context.dataIndex]
        : null;
    }
  });
};

patchJson();
// patchConsole();
patchChartJs();
