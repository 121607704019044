import loading_icon from '@assets/img/loading_icon.svg';
import loading_icon_green from '@assets/img/loading_icon_green.svg';
import styles from '@assets/jss/material-dashboard-react/components/primaryButtonStyle';
import {
  ButtonProps,
  Button as MuiButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';

const useStyles = makeStyles(styles);

interface IButton extends ButtonProps {
  isLoading?: boolean;
}

const Button = memo(({ children, isLoading, ...otherProps }: IButton) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant="contained"
      className={classes.containedButton}
      {...otherProps}
      disabled={isLoading || otherProps?.disabled}
    >
      {isLoading ? (
        <img
          src={
            otherProps.variant === 'outlined'
              ? loading_icon_green
              : loading_icon
          }
          width={34}
          height={34}
        />
      ) : (
        <Typography variant="subtitle2">{children}</Typography>
      )}
    </MuiButton>
  );
});

const TextButton = memo(({ children, isLoading, ...otherProps }: IButton) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant="text"
      {...otherProps}
      className={classes.textButton}
      disabled={isLoading || otherProps?.disabled}
    >
      {isLoading ? (
        <img src={loading_icon_green} width={34} height={34} />
      ) : (
        children
      )}
    </MuiButton>
  );
});

export { Button, TextButton };
