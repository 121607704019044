import { toast } from 'react-toastify';
import trans from 'translation';
import { useMount } from 'uikit-common';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

export const usePosMap = (onPosSuccess: (pos: GeolocationPosition) => void) => {
  useMount(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            // If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(onPosSuccess);
          } else if (result.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(
              onPosSuccess,
              b => console.log(b),
              options
            );
          } else if (result.state === 'denied') {
            toast.warning(trans('geolocation_denied'));
            // If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      toast.error(trans('geolocation_is_not_supported'));
    }
  });
};
