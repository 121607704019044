import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function (validationSchema, defaultValues, configs) {
  const form = useForm({
    shouldUnregister: false,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    delayError: 100,
    defaultValues,
    values: defaultValues,
    ...configs,
    resolver: yupResolver(validationSchema())
  });

  return form;
}
