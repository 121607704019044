import Log from '@containers/Log';
import React, { memo } from 'react';
import trans from 'translation';
import { KBreadcrumbs } from 'uikit';

const SystemLogs = () => {
  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.system_settings'),
          trans('routes.system_logs')
        ]}
      />

      <Log noHeader margin="0.75rem" tableMaxHeight="68vh" />
    </>
  );
};

export default memo(SystemLogs);
