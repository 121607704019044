import { combineReducers } from 'redux';

import * as allocation from './allocation';
import * as auth from './auth';

export { auth, allocation };

const rootReducer = combineReducers({
  auth: auth.reducer,
  allocation: allocation.reducer
});

export default rootReducer;
