import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  Status,
  WebTable
} from '@constants';
import {
  ICancelInvoiceParams,
  ICheckAutoRateForInvoiceParams,
  ICheckAutoRateForInvoiceResponse,
  IFetchContainerZonesParams
} from '@request-dto';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchInvoiceList = (transportJobId?: number | string) => {
  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.invoiceList,
      transportJobId ? parseInt(transportJobId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoice(),
        body: {
          transportJobId,
          excludeFields: ['invoiceContainerCharges']
        }
      });

      return res.data?.data;
    },
    enabled: !!transportJobId
  });
};

export const useFetchInvoice = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.invoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoice(':invoiceId', {
          invoiceId: id
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useMutateCancelInvoice = () => {
  return useMutationEnhancer<any, ICancelInvoiceParams>({
    mutationFn: async data => {
      const { invoiceId } = data;
      const res = await APIManager.request({
        url: ENDPOINTS.invoice(':invoiceId/cancel', {
          invoiceId
        }),
        method: 'POST'
      });

      return res.data?.data ?? [];
    },
    mutationKeys: [[QUERY_KEYS.invoice]]
  });
};

export const useCUDInvoice = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.invoice(),
    webTable: WebTable.TRANSPORT_JOB_AR_INVOICE,
    dependentWebTable: [
      WebTable.ACCOUNTING_AR_INVOICE,
      WebTable.TRANSPORT_JOB_AP_INVOICE,
      WebTable.ACCOUNTING_AP_INVOICE,
      WebTable.ACCOUNTING_AP_WIP_INVOICE
    ],
    mutationKeys: [[QUERY_KEYS.invoice]],
    returnedData: true,
    deleteWithoutRefetch: true
  });
};

export const useFetchTaxList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.taxes],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.tax(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC',
          status: Status.Active
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useCheckAutoRateForInvoice = () => {
  return useMutationEnhancer<
    ICheckAutoRateForInvoiceResponse,
    ICheckAutoRateForInvoiceParams
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientRate('auto-rate/check-for-invoice'),
        body: data
      });

      return res.data;
    }
  });
};

export const useMutateFetchContainerZones = () => {
  return useMutationEnhancer<any[], IFetchContainerZonesParams>({
    mutationFn: async data => {
      const { transportJobId, containerId } = data;
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob(
          ':id/job-container/:containerId/get-zones',
          { id: transportJobId, containerId }
        )
      });

      return res.data?.data ?? [];
    }
  });
};

export const useMutateFetchContainerRouting = () => {
  return useMutationEnhancer<any[], number>({
    mutationFn: async containerId => {
      const res = await APIManager.request({
        url: ENDPOINTS.container(':id/routing', { id: containerId }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data ?? [];
    }
  });
};

export const useMutateFetchCompanyTariffForInvoice = () => {
  return useMutationEnhancer<any[], any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.companyTariff('for-quotation-request'),
        body: {
          page: 0,
          size: PAGE_SIZE_DEFAULT_MAX,
          status: Status.Active,
          ...data
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useMutateDataForInvoiceContainer = () => {
  const {
    mutate: mutateCheckAutoRateForInvoice,
    isLoading: containerLoading1
  } = useCheckAutoRateForInvoice();
  const { mutate: mutateFetchContainerZones, isLoading: containerLoading2 } =
    useMutateFetchContainerZones();
  const { mutate: mutateFetchContainerRouting, isLoading: containerLoading3 } =
    useMutateFetchContainerRouting();
  const {
    mutate: mutateFetchCompanyTariffForInvoice,
    isLoading: containerLoading4
  } = useMutateFetchCompanyTariffForInvoice();

  return {
    mutateCheckAutoRateForInvoice,
    mutateFetchContainerZones,
    mutateFetchContainerRouting,
    mutateFetchCompanyTariffForInvoice,
    invoiceContainerLoading:
      containerLoading1 ||
      containerLoading2 ||
      containerLoading3 ||
      containerLoading4
  };
};
