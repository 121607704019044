import {
  ConversationMessageType,
  ConversationType,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT,
  QUERY_KEYS,
  ResourceType,
  Status
} from '@constants';
import APIManager from '@services';

import {
  useInfiniteEnhancer,
  useMutationEnhancer,
  useUploadFile,
  useQueryEnhancer
} from '../core';

export const useFetchConversation = (sourceId: number) => {
  return useQueryEnhancer<number>({
    queryKey: [QUERY_KEYS.conversation, sourceId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.conversation('detail'),
        body: {
          sourceId,
          conversationType: ConversationType.ScheduleTripContainer
        }
      });

      return res.data?.id;
    },
    enabled: !!sourceId
  });
};

export const useFetchMessageList = (id?: number) => {
  return useInfiniteEnhancer({
    queryKey: [QUERY_KEYS.messageList, id],
    queryFn: async ({ pageParam }) => {
      const { page = 0, pageSize = PAGE_SIZE_DEFAULT } = pageParam || {};
      const res = await APIManager.request({
        url: ENDPOINTS.conversation(':id/conversation-message', { id }),
        body: {
          conversationId: id,
          size: pageSize,
          status: Status.Active,
          page,
          sortBy: 'createdAt:DESC'
        }
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useReadAllMessages = () => {
  return useMutationEnhancer<any, number>({
    mutationKeys: [[QUERY_KEYS.scheduleDriverTripList]],
    mutationFn: async id => {
      const res = await APIManager.request({
        url: ENDPOINTS.conversation(':id/conversation-message/read-all', {
          id
        }),
        method: 'PUT'
      });

      return res.success;
    }
  });
};

export const useSendMessage = () => {
  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.messageList]],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.conversation(':id/conversation-message', {
          id: data.conversationId
        }),
        method: 'POST',
        body: {
          ...data,
          messageType: ConversationMessageType.Text
        }
      });

      return res.success;
    }
  });
};

export const useSendFile = () => {
  const { mutateAsync: mutateFile } = useUploadFile();

  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.messageList]],
    mutationFn: async data => {
      const { file, ...rest } = data;
      const isImage = file.type.includes('image');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('resourceType', ResourceType.Conversation);
      const fileRes = await mutateFile(formData);

      if (fileRes) {
        const res = await APIManager.request({
          url: ENDPOINTS.conversation(':id/conversation-message', {
            id: rest.conversationId
          }),
          method: 'POST',
          body: {
            ...rest,
            data: {
              resourceId: fileRes.id,
              resourceUrl: fileRes.url,
              fileName: file.name,
              fileType: file.type
            },
            messageType: isImage
              ? ConversationMessageType.Image
              : ConversationMessageType.File
          }
        });

        return res.success;
      }

      return false;
    }
  });
};

export const useSendVoice = () => {
  const { mutateAsync: mutateFile } = useUploadFile();

  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.messageList]],
    mutationFn: async data => {
      const { file, ...rest } = data;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('resourceType', ResourceType.Conversation);
      const fileRes = await mutateFile(formData);

      if (fileRes) {
        const res = await APIManager.request({
          url: ENDPOINTS.conversation(':id/conversation-message', {
            id: rest.conversationId
          }),
          method: 'POST',
          body: {
            ...rest,
            data: {
              resourceId: fileRes.id,
              resourceUrl: fileRes.url,
              fileName: file.name,
              fileType: file.type
            },
            messageType: ConversationMessageType.Voice
          }
        });

        return res.success;
      }

      return false;
    }
  });
};

export const useDeleteMessage = () => {
  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.messageList]],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.conversation(':id/conversation-message/:messageId', {
          id: data.conversationId,
          messageId: data.messageId
        }),
        method: 'DELETE'
      });

      return res.success;
    }
  });
};
