import { KColors } from 'uikit';

export enum Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CustomsStatus {
  Clear = 'CLEAR',
  Held = 'HELD'
}

export enum JobTypeBase {
  Import = 'IMPORT',
  Export = 'EXPORT'
}

export enum JobTypeBaseWithAll {
  All = 'ALL',
  Import = 'IMPORT',
  Export = 'EXPORT'
}

export enum JobType {
  Import = 'IMPORT',
  Export = 'EXPORT',
  Misc = 'MISC',
  RailInbound = 'RAIL_INBOUND',
  RailOutbound = 'RAIL_OUTBOUND'
}

export enum JobTypeWithAll {
  All = 'ALL',
  Import = 'IMPORT',
  Export = 'EXPORT',
  Misc = 'MISC',
  RailInbound = 'RAIL_INBOUND',
  RailOutbound = 'RAIL_OUTBOUND'
}

export enum DehireByDateFrom {
  Available1stDate = 'AVAILABLE_1ST_DATE',
  Free1stDate = 'FREE_1ST_DATE',
  Discharge1stDate = 'DISCHARGE_1ST_DATE',
  EtaDate = 'ETA_DATE',
  ExpRecv = 'EXP_RECV'
}

export enum DehireByDateFromExport {
  PickupDate = 'PICKUP_DATE_FROM_EMPTY_YARD'
}

export enum RoutingType {
  Cto = 'CTO',
  EmptyYard = 'EMPTY_YARD',
  Warehouse = 'WAREHOUSE',
  RailHead = 'RAIL_HEAD',

  DeliveryWarehouse = 'DELIVERY_WAREHOUSE',
  PickupWarehouse = 'PICKUP_WAREHOUSE',

  DeliveryWarehouseEmpty = 'WAREHOUSE_EMPTY',
  PickupWarehouseFull = 'WAREHOUSE_FULL',

  WaitDepotMiscStopBy = 'WAIT_DEPOT_MISC_STOP_BY',
  WaitDepotMiscDropOff = 'WAIT_DEPOT_MISC_DROP_OFF',
  WaitDepotMiscPickUp = 'WAIT_DEPOT_MISC_PICK_UP',

  WaitDepotFullStopBy = 'WAIT_DEPOT_FULL_STOP_BY',
  WaitDepotFullDropOff = 'WAIT_DEPOT_FULL_DROP_OFF',
  WaitDepotFullPickUp = 'WAIT_DEPOT_FULL_PICK_UP',

  WaitDepotEmptyStopBy = 'WAIT_DEPOT_EMPTY_STOP_BY',
  WaitDepotEmptyDropOff = 'WAIT_DEPOT_EMPTY_DROP_OFF',
  WaitDepotEmptyPickUp = 'WAIT_DEPOT_EMPTY_PICK_UP'
}

export enum ClientType {
  ForwarderAgent = 'FORWARDER_AGENT',
  CustomsAgent = 'CUSTOMS_AGENT',
  Consignee = 'CONSIGNEE',
  Consignor = 'CONSIGNOR',
  Warehouse = 'WAREHOUSE',
  AccountReceivable = 'ACCOUNTS_RECEIVABLE',
  EmptyYard = 'EMPTY_YARD',
  WaitDepot = 'WAIT_DEPOT',
  Cto = 'CTO',
  Cfs = 'CFS',
  ShippingLine = 'SHIPING_LINE',
  RailHead = 'RAIL_HEAD',
  RailLine = 'RAIL_LINE',
  Workshop = 'WORKSHOP'
}

export enum RoutingStatusBase {
  Booked = 'BOOKED',
  ReadySchedule = 'READY_SCHEDULE'
}

export enum RoutingStatus {
  Booked = 'BOOKED',
  ReadySchedule = 'READY_SCHEDULE',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED'
}

export enum JobTitle {
  Driver = 'DRIVER',
  InHouseWorkshopManager = 'IN_HOUSE_WORKSHOP_MANAGER',
  FleetController = 'FLEET_CONTROLLER',
  Mechanic = 'MECHANIC',
  OperationManager = 'OPERATION_MANAGER'
}

export enum JobTitleId {
  Driver = -1,
  InHouseWorkshopManager = -2,
  FleetController = -3,
  Mechanic = -4,
  OperationManager = -5
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER'
}

export enum CountryDivisionType {
  Nothing = 'NOTHING',
  Suburb = 'SUBURB',
  District = 'DISTRICT',
  State = 'STATE'
}

export enum CategorySource {
  Client = 'CLIENT',
  Note = 'NOTE',
  Edoc = 'EDOC'
}

export enum FullEmptyServiceType {
  Full = 'FULL',
  Empty = 'EMPTY',
  All = 'ALL'
}

export enum DropoffStopByServiceType {
  Dropoff = 'DROPOFF',
  Stopby = 'STOPBY',
  All = 'ALL'
}

export enum ResourceType {
  Avatar = 'AVATAR',
  ContainerSize = 'CONTAINER_SIZE',
  Edoc = 'EDOC',
  ClientTariffTerm = 'CLIENT_TARIFF_TERM',
  QuotationTerm = 'QUOTATION_TERM',
  AutoRateTerm = 'AUTO_RATE_TERM',
  Conversation = 'CONVERSATION',
  Issue = 'ISSUE',
  Signature = 'SIGNATURE'
}

export enum OverMassType {
  Cml = 'CML',
  Hml = 'HML',
  None = 'NONE'
}

export enum TripType {
  Schedule = 'SCHEDULE_TRIP',
  Connection = 'CONNECTION_TRIP'
}

export enum EquipmentType {
  Vehicle = 'TRUCK',
  Trailer = 'TRAILER',
  Combination = 'COMBINATION'
}

export enum ContainerStatus {
  InTransit = 'IN_TRANSIT',
  Booked = 'BOOKED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum ContainerStatusId {
  InTransit = -1,
  Booked = -2,
  InProgress = -3,
  Completed = -4,
  Closed = -9,
  Cancelled = -10
}

export enum ContainerInvoiceStatus {
  DraftInvoice = 'DRAFT_INVOICE',
  Invoiced = 'INVOICED',
  PartiallyPaid = 'PARTIALLY_PAID',
  Paid = 'PAID'
}

export enum JobStatusId {
  Draft = -30,
  Active = -31,
  Completed = -32,
  Closed = -33,
  Cancelled = -34
}

export enum JobStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Wip = 'WIP',
  Invoiced = 'INVOICED',
  Paid = 'Paid',
  PartiallyPaid = 'PARTIALLY_PAID',
  Cancelled = 'CANCELLED'
}

export enum InvoiceStatusId {
  Draft = -20,
  Invoiced = -21,
  Paid = -22,
  PartiallyPaid = -23,
  Cancelled = -24,
  Wip = -25
}

export enum TripRoutingStatus {
  Booked = 'BOOKED',
  ReadySchedule = 'READY_SCHEDULE',
  Scheduled = 'SCHEDULED',
  InTransit = 'IN_TRANSIT',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  CancelledByDriver = 'CANCELLED_BY_DRIVER',
  InCompleted = 'INCOMPLETED',
  UnCompleted = 'UNCOMPLETED'
}

export enum ShipmentType {
  FCL = 'FCL',
  LCL = 'LCL'
}

export enum UserType {
  Internal = 'INTERNAL_USER',
  External = 'EXTERNAL_USER'
}

export enum UserDataGroup {
  Driver = 'DRIVER',
  Sales = 'SALES',
  Acc = 'ACC'
}

export enum CreditPaymentType {
  Credit = 'CREDIT',
  Cod = 'COD'
}

export enum CreditTermType {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS'
}

export enum AddressCapability {
  PickupWarehouse = 'PIC',
  DeliveryWarehouse = 'DLV',
  ArMailingAddress = 'ARM',
  ApMailingAddress = 'APM'
}

export enum TemplateId {
  VehicleFaultyReport = -40,
  TrailerDailyPreStartChecklist = -39,
  TruckPreStartChecklist = -38
}

export enum TemplateBusinessType {
  Alert = 'ALERT',
  Email = 'EMAIL',
  Download = 'DOWNLOAD',
  Document = 'DOCUMENT'
}

export enum TemplateReportDeclarationTimeType {
  RoutingIn = 'ROUTING_IN',
  RoutingOut = 'ROUTING_OUT'
}

export enum WorkingType {
  InHouse = 'IN_HOUSE',
  SubContractor = 'SUB_CONTRACTOR'
}

export enum DefectNotice {
  NotYet = 'NOT_YET',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE'
}

export enum WorkingCondition {
  Good = 'GOOD',
  Damaged = 'DAMAGED',
  InRepair = 'IN_REPAIR'
}

export enum WorkingConditionId {
  Good = -1,
  Damaged = -2,
  InRepair = -3
}

export enum SafetyCheckType {
  Driver = 'DRIVER',
  Truck = 'TRUCK',
  Trailer = 'TRAILER'
}

export enum YesOrNo {
  Yes = 'YES',
  No = 'NO'
}

export enum RadioTypeOk {
  Ok = 'OK',
  NotOk = 'NOT_OK'
}

export enum SocketEvent {
  NewMessage = 'new_message',
  DeleteMessage = 'delete_message',
  TripLocation = 'trip_location',
  LeaveOfTrip = 'leave_of_trip'
}

export enum GlobalEvent {
  SignOut = 'logout',
  NewNotification = 'new_notification'
}

export enum ConversationType {
  ScheduleTripContainer = 'SCHEDULE_TRIP_CONTAINER'
}

export enum ConversationMessageType {
  Text = 'TEXT',
  Voice = 'VOICE',
  Image = 'IMAGE',
  File = 'FILE'
}

export enum ParentSource {
  Client = 'CLIENT',
  CompanyTariff = 'COMPANY_TARIFF',
  ClientTariff = 'CLIENT_TARIFF',
  Quotation = 'QUOTATION',
  ClientRate = 'CLIENT_RATE',
  TransportJob = 'TRANSPORT_JOB',
  Statement = 'STATEMENT',
  User = 'USER',
  Staff = 'STAFF',
  Equipment = 'EQUIPMENT',
  Schedule = 'SCHEDULE',
  Allocation = 'ALLOCATION'
}

export enum ProgressStatusType {
  Job = 'JOB',
  Container = 'CONTAINER',
  Invoice = 'INVOICE'
}

export enum RelatedClientType {
  ForwarderAgent = 'FORWARDER_AGENT',
  CustomsAgent = 'CUSTOMS_AGENT',
  AccountsReceivable = 'ACCOUNTS_RECEIVABLE',
  ConsignorConsignee = 'CONSIGNOR_CONSIGNEE',
  Consignee = 'CONSIGNEE',
  Consignor = 'CONSIGNOR'
}

export enum RelatedAddressType {
  DeliveryWarehouse = 'DELIVERY_WAREHOUSE',
  PickupWarehouse = 'PICKUP_WAREHOUSE'
}

export enum SystemSettingCode {
  PriceLevel = 'PRICE_LEVEL',
  InvoiceSyncType = 'INVOICE_SYNC_TYPE',
  Tax = 'TAX',
  Xero = 'XERO',
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
  OneStop = 'ONE_STOP',
  OvermassSheet = 'OVERMASS_SHEET'
}

export enum NotificationReadStatus {
  All = 'ALL',
  Unread = 'UNREAD',
  Read = 'READ'
}

export enum NotificationReadStatusTab {
  All = 'ALL',
  Unread = 'UNREAD'
}

export enum LeaveOfTripReason {
  SickLeave = 'SICK_LEAVE',
  TruckLeave = 'TRUCK_LEAVE',
  TrailerLeave = 'TRAILER_LEAVE'
}

export const LeaveOfTripReasonMap = {
  [LeaveOfTripReason.SickLeave]: {
    color: KColors.secondary.normal
  },
  [LeaveOfTripReason.TruckLeave]: {
    color: KColors.primary.normal
  },
  [LeaveOfTripReason.TrailerLeave]: {
    color: KColors.blue.normal
  }
};

export enum ReportType {
  Template = 'TEMPLATE',
  Faulty = 'FAULTY',
  LeaveOfTrip = 'LEAVE_OF_TRIP',
  PdfCustomized = 'PDF_CUSTOMIZED'
}

export enum TemplateReportId {
  DriverDailyReport = -1,
  OvermassDailyReport = -4
}

export enum FaultyReportStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  Cancelled = 'CANCELLED',
  Done = 'DONE'
}

export enum ActionType {
  Faulty = 'FAULTY',
  Maintenance = 'MAINTENANCE'
}

export enum RepairMethod {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export enum RepairMethodWithNoAction {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  NoAction = 'NO_ACTION'
}

export enum InvoiceProvider {
  Xero = 'Xero'
}

export enum NotificationProvider {
  OneSignal = 'ONE_SIGNAL'
}

export enum OneStopCategory {
  Vessel = '3PT_VESSEL',
  Announcement = '3PT_ANNOUNCEMENT',
  ContainerChain = '3PT_CONTAINERCHAIN',
  GateInOut = '3PT_GATEINOUT'
}

export enum CalculatorType {
  Composite = 'COMPOSITE',
  Currency = 'CURRENCY',
  Descriptive = 'DESCRIPTIVE'
}

export enum MarkupType {
  Composite = 'COMPOSITE',
  Currency = 'CURRENCY',
  Percentage = 'PERCENTAGE',
  Descriptive = 'DESCRIPTIVE'
}

export enum MarkupTypeCurrency {
  Currency = 'CURRENCY',
  Percentage = 'PERCENTAGE'
}

export enum MarkupTypeComposite {
  Composite = 'COMPOSITE'
}

export enum MarkupTypeDescriptive {
  Descriptive = 'DESCRIPTIVE'
}

export enum ArApType {
  Ar = 'AR',
  Ap = 'AP'
}

export enum InvoiceType {
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  Disbursement = 'DISBURSEMENT'
}

export enum ArInvoiceType {
  ArInvoice = 'AR_INVOICE',
  ArCreditNote = 'AR_CREDIT_NOTE',
  ArDisbursement = 'AR_DISBURSEMENT'
}

export enum ApInvoiceType {
  ApInvoice = 'AP_INVOICE',
  ApCreditNote = 'AP_CREDIT_NOTE',
  ApDisbursement = 'AP_DISBURSEMENT'
}

export enum SignatureSignType {
  Report = 'REPORT',
  Repair = 'REPAIR',
  Review = 'REVIEW',
  Approve = 'APPROVE',
  InCharge = 'IN_CHARGE',
  Maintain = 'MAINTAIN'
}

export enum PaymentType {
  DirectDebit = 'DIRECT_DEBIT',
  Cash = 'CASH',
  EFTPOS = 'EFTPOS'
}

export enum DropMode {
  SLD = 'SIDELOADER',
  SLWUP = 'SIDELOADER_WAIT_UNPACKING_PACKING',
  SDT = 'STANDARD_TRAILER_DROP_TRAILER',
  SDWUP = 'STANDARD_TRAILER_WAIT_UNPACKING_PACKING',
  SPLO = 'STANDARD_TRAILER_PREMISE_LIFT_OF_CONTAINER',
  SPLW = 'STANDARD_TRAILER_PREMISE_LIFT_OF_CONTAINER_WAIT_UNPACKING_PACKING'
}

export enum OneStopFeature {
  Vessel = '3PT_VESSEL',
  Announcement = '3PT_ANNOUNCEMENT',
  ContainerChain = '3PT_CONTAINERCHAIN',
  GateInOut = '3PT_GATEINOUT'
}

export enum SyncStatus {
  Success = 'success',
  Failed = 'failed'
}

export enum DashboardSection {
  Issues = 'DASHBOARD_ISSUES',
  Allocation = 'DASHBOARD_ALLOCATION',
  TransportJobs = 'DASHBOARD_TRANSPORT_JOBS',
  Containers = 'DASHBOARD_CONTAINERS',
  Payments = 'DASHBOARD_PAYMENTS',
  InvoiceOverdue = 'DASHBOARD_INVOICES_OVERDUE',
  AROverdue = 'DASHBOARD_AR_OVERDUE',
  ScheduleTripContainerWaitingTime = 'DASHBOARD_SCHEDULE_TRIP_CONTAINER_WAITING_TIME'
}

export enum RepeatUnit {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS'
}

export enum NotificationChannel {
  Notification = 'NOTIFICATION',
  Email = 'EMAIL'
}

export enum UIMenuCode {
  Dashboard = 'DASHBOARD',
  TransportJobs = 'TRANSPORT_JOB_LIST',
  Operation = 'OPERATION',
  Invoices = 'INVOICE_LIST',
  Statements = 'STATEMENT_LIST',
  Clients = 'CLIENT_LIST',
  ClientRates = 'CLIENT_RATE_LIST',
  Quotations = 'QUOTATION_LIST',
  ClientTariffs = 'CLIENT_TARIFF_LIST',
  CompanyTariffs = 'COMPANY_TARIFF_LIST',
  ReportOperations = 'REPORT_OPERATION',
  ReportAccounting = 'REPORT_ACCOUNTING',
  ReportSales = 'REPORT_SALES',
  Equipment = 'EQUIPMENT_LIST',
  FaultyReport = 'FAULTY_REPORT_LIST',
  Maintenance = 'MAINTENANCE_SCHEDULE_LIST',
  EmailLogs = 'EMAIL_LIST',
  Library = 'LIBRARY',
  Miscellaneous = 'MISCELLANEOUS',
  SystemLogs = 'SYSTEM_LOG_LIST',
  ApiIntegration = 'API_INTEGRATION',
  Staff = 'STAFF_LIST',
  Users = 'USER_LIST',
  Roles = 'ROLE_LIST',
  Cases = 'CASE_LIST',
  UserGuide = 'USER_GUIDE',
  Faq = 'FAQ',
  Accounting = 'ACCOUNTING',

  ClientDetails = 'CLIENT_DETAIL',
  CompanyTariffDetails = 'COMPANY_TARIFF_DETAIL',
  ClientTariffDetails = 'CLIENT_TARIFF_DETAIL',
  QuotationDetails = 'QUOTATION_DETAIL',
  ClientRateDetails = 'CLIENT_RATE_DETAIL',
  TransportJobDetails = 'TRANSPORT_JOB_DETAIL',
  UserDetails = 'USER_DETAIL',
  StaffDetails = 'STAFF_DETAIL',
  StatementDetails = 'STATEMENT_DETAIL',
  EquipmentDetails = 'EQUIPMENT_DETAIL',
  ScheduleDetails = 'SCHEDULE_DETAIL',
  CompanyProfileDetails = 'COMPANY_PROFILE_DETAIL',
  CaseDetails = 'CASE_DETAIL',
  UserGuideDetails = 'USER_GUIDE_DETAIL'
}

export enum UIMenuItem {
  Dashboard = 'Dashboard',
  TransportJobs = 'Transport Jobs',
  Invoices = 'Invoices',
  Statements = 'Statements',
  Clients = 'Clients',
  ClientRates = 'Client Rates',
  Quotations = 'Quotations',
  ClientTariffs = 'Client Tariffs',
  CompanyTariffs = 'Company Tariffs',
  Operation = 'Operation',
  Billing = 'Billing',
  Sales = 'Sales',
  Emails = 'Emails',
  Equipment = 'Equipment',
  Users = 'Users',
  Roles = 'Roles',
  Library = 'Library',
  Miscellaneous = 'Miscellaneous',
  SystemLogs = 'System Logs',
  Staff = 'Staff',
  CompanyProfile = 'Company Profile',
  ReportOperations = 'Report Operations',
  ReportAccounting = 'Report Accounting',
  Accounting = 'Accounting'
}

export enum UITab {
  Client = 'CLIENT_DETAIL',
  ClientAddresses = 'CLIENT_DETAIL_ADDRESSES',
  ClientContacts = 'CLIENT_DETAIL_CONTACTS',
  ClientAR = 'CLIENT_DETAIL_AR',
  ClientAP = 'CLIENT_DETAIL_AP',
  ClientRelatedParties = 'CLIENT_DETAIL_RELATED_PARTIES',
  ClientSalePersons = 'CLIENT_DETAIL_SALE_PERSONS',
  ClientNotes = 'CLIENT_DETAIL_NOTES',
  ClientEdocs = 'CLIENT_DETAIL_EDOCS',
  ClientLogs = 'CLIENT_DETAIL_LOGS',

  Company = 'COMPANY_PROFILE_DETAIL',
  CompanyAddresses = 'COMPANY_PROFILE_DETAIL_ADDRESSES',
  CompanyContacts = 'COMPANY_PROFILE_DETAIL_CONTACTS',
  CompanyBankAccounts = 'COMPANY_PROFILE_DETAIL_BANK_ACCOUNTS',

  User = 'USER_DETAIL',
  UserRolePermissions = 'USER_DETAIL_ROLE_PERMISSIONS',
  UserNotes = 'USER_DETAIL_NOTES',
  UserEdocs = 'USER_DETAIL_EDOCS',
  UserLogs = 'USER_DETAIL_LOGS',

  Staff = 'STAFF_DETAIL',
  StaffAlerts = 'STAFF_DETAIL_ALERTS',
  StaffNotes = 'STAFF_DETAIL_NOTES',
  StaffEdocs = 'STAFF_DETAIL_EDOCS',
  StaffLogs = 'STAFF_DETAIL_LOGS',

  TransportJob = 'TRANSPORT_JOB_DETAIL',
  TransportJobContainers = 'TRANSPORT_JOB_DETAIL_CONTAINERS',
  TransportJobInvoices = 'TRANSPORT_JOB_DETAIL_INVOICES',
  TransportJobAlerts = 'TRANSPORT_JOB_DETAIL_ALERTS',
  TransportJobEmails = 'TRANSPORT_JOB_DETAIL_EMAILS',
  TransportJobNotes = 'TRANSPORT_JOB_DETAIL_NOTES',
  TransportJobEdocs = 'TRANSPORT_JOB_DETAIL_EDOCS',
  TransportJobLogs = 'TRANSPORT_JOB_DETAIL_LOGS',

  Equipment = 'EQUIPMENT_DETAIL',
  EquipmentFaultyReports = 'EQUIPMENT_DETAIL_FAULTY_REPORTS',
  EquipmentMaintenanceHistory = 'EQUIPMENT_DETAIL_MAINTENANCE_HISTORY',
  EquipmentAlerts = 'EQUIPMENT_DETAIL_ALERTS',
  EquipmentNotes = 'EQUIPMENT_DETAIL_NOTES',
  EquipmentEdocs = 'EQUIPMENT_DETAIL_EDOCS',
  EquipmentLogs = 'EQUIPMENT_DETAIL_LOGS',

  ClientRate = 'CLIENT_RATE_DETAIL',
  ClientRateAutoRates = 'CLIENT_RATE_DETAIL_AUTO_RATES',
  ClientRateZonesSuburbs = 'CLIENT_RATE_DETAIL_ZONES_SUBURBS',
  ClientRateAlerts = 'CLIENT_RATE_DETAIL_ALERTS',
  ClientRateNotes = 'CLIENT_RATE_DETAIL_NOTES',
  ClientRateEdocs = 'CLIENT_RATE_DETAIL_EDOCS',
  ClientRateLogs = 'CLIENT_RATE_DETAIL_LOGS',

  ClientTariff = 'CLIENT_TARIFF_DETAIL',
  ClientTariffZonesSuburbs = 'CLIENT_TARIFF_DETAIL_ZONES_SUBURBS',
  ClientTariffAlerts = 'CLIENT_TARIFF_DETAIL_ALERTS',
  ClientTariffEmails = 'CLIENT_TARIFF_DETAIL_EMAILS',
  ClientTariffNotes = 'CLIENT_TARIFF_DETAIL_NOTES',
  ClientTariffEdocs = 'CLIENT_TARIFF_DETAIL_EDOCS',
  ClientTariffLogs = 'CLIENT_TARIFF_DETAIL_LOGS',

  CompanyTariff = 'COMPANY_TARIFF_DETAIL',
  CompanyTariffZonesSuburbs = 'COMPANY_TARIFF_DETAIL_ZONES_SUBURBS',
  CompanyTariffEmails = 'COMPANY_TARIFF_DETAIL_EMAILS',
  CompanyTariffNotes = 'COMPANY_TARIFF_DETAIL_NOTES',
  CompanyTariffEdocs = 'COMPANY_TARIFF_DETAIL_EDOCS',
  CompanyTariffLogs = 'COMPANY_TARIFF_DETAIL_LOGS',

  Statement = 'STATEMENT_DETAIL',
  StatementAlerts = 'STATEMENT_DETAIL_ALERTS',
  StatementEmails = 'STATEMENT_DETAIL_EMAILS',
  StatementNotes = 'STATEMENT_DETAIL_NOTES',
  StatementEdocs = 'STATEMENT_DETAIL_EDOCS',
  StatementLogs = 'STATEMENT_DETAIL_LOGS',

  Quotation = 'QUOTATION_DETAIL',
  QuotationRequests = 'QUOTATION_DETAIL_REQUESTS',
  QuotationZonesSuburbs = 'QUOTATION_DETAIL_ZONES_SUBURBS',
  QuotationAlerts = 'QUOTATION_DETAIL_ALERTS',
  QuotationEmails = 'QUOTATION_DETAIL_EMAILS',
  QuotationNotes = 'QUOTATION_DETAIL_NOTES',
  QuotationEdocs = 'QUOTATION_DETAIL_EDOCS',
  QuotationLogs = 'QUOTATION_DETAIL_LOGS',

  LibraryClient = 'LIBRARY_CLIENT',
  LibraryTariffRate = 'LIBRARY_TARIFF_RATE',
  LibraryTransport = 'LIBRARY_TRANSPORT',
  LibraryWorkshop = 'LIBRARY_WORKSHOP',
  LibraryOperation = 'LIBRARY_OPERATION',
  LibraryStaff = 'LIBRARY_STAFF',
  LibraryMiscellaneous = 'LIBRARY_MISCELLANEOUS',

  Operation = 'OPERATION_DETAIL',
  OperationAllocation = 'OPERATION_DETAIL_ALLOCATION',
  OperationPreScheduleTrip = 'OPERATION_DETAIL_PRE_SCHEDULE_TRIP',
  OperationNotReadyTrip = 'OPERATION_DETAIL_NOT_READY_TRIP',
  OperationNotReadyTripAll = 'OPERATION_DETAIL_NOT_READY_TRIP_ALL',
  OperationNotReadyTripWD = 'OPERATION_DETAIL_NOT_READY_TRIP_WD',
  OperationTrailers = 'OPERATION_DETAIL_TRAILERS',

  Schedule = 'SCHEDULE_DETAIL',
  ScheduleAlerts = 'SCHEDULE_DETAIL_ALERTS',
  ScheduleEmails = 'SCHEDULE_DETAIL_EMAILS',
  ScheduleNotes = 'SCHEDULE_DETAIL_NOTES',
  ScheduleEdocs = 'SCHEDULE_DETAIL_EDOCS',
  ScheduleLogs = 'SCHEDULE_DETAIL_LOGS',

  Allocation = 'ALLOCATION_DETAIL',
  AllocationContainerMovement = 'ALLOCATION_DETAIL_CONTAINER_MOVEMENT',
  AllocationReports = 'ALLOCATION_DETAIL_REPORTS',
  AllocationAlerts = 'ALLOCATION_DETAIL_ALERTS',
  AllocationEmails = 'ALLOCATION_DETAIL_EMAILS',
  AllocationEdocs = 'ALLOCATION_DETAIL_EDOCS',
  AllocationLogs = 'ALLOCATION_DETAIL_LOGS',

  ReportOperations = 'REPORT_OPERATIONS_DETAIL',
  ReportAccounting = 'REPORT_ACCOUNTING_DETAIL',

  AccountingSales = 'ACCOUNTING_SALES',
  AccountingArInvoices = 'ACCOUNTING_AR_INVOICES',
  AccountingArClientsSummary = 'ACCOUNTING_AR_CLIENTS_SUMMARY',

  AccountingPurchases = 'ACCOUNTING_PURCHASES',
  AccountingApInvoices = 'ACCOUNTING_AP_INVOICES',
  AccountingApWipInvoices = 'ACCOUNTING_AP_WIP_INVOICES',
  AccountingApAccounts = 'ACCOUNTING_AP_ACCOUNTS',

  AccountingReport = 'ACCOUNTING_REPORT'
}

export enum IssueType {
  Bug = 'BUG',
  HowToUse = 'HOW_TO_USE',
  AdditionalRequest = 'ADDITIONAL_REQUEST'
}

export enum IssueUrgencyLevel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export enum IssueBrowser {
  Chrome = 'Chrome',
  Edge = 'Edge'
}

export enum IssuePlatform {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Android = 'Android',
  iOS = 'iOS'
}

export enum IssueStatus {
  Open = 'OPEN',
  Archived = 'ARCHIVED',
  Resolved = 'RESOLVED'
}

export enum ReportExcelType {
  TransportJob = 'TRANSPORT_JOB',
  Invoice = 'INVOICE'
}

export enum PaymentStatusId {
  Draft = -40,
  Success = -41,
  Cancelled = -42
}

export enum ReportExcelCode {
  JobGeneral = 'JOB_GENERAL',
  ConsignorConsignee = 'CONSIGNOR_CONSIGNEE',
  AccountReceivable = 'ACCOUNT_RECEIVABLES',
  Agent = 'AGENT',
  Warehouse = 'WAREHOUSE',
  WaitDepotStopBy = 'WAIT_DEPOT_STOP_BY',
  WaitDepotDropOff = 'WAIT_DEPOT_DROP_OFF',
  TripReport = 'TRIP_REPORT',
  WaitingTime = 'WAITING_TIME'
}
