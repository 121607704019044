import loading_icon_green from '@assets/img/loading_icon_green.svg';
import styles from '@assets/jss/material-dashboard-react/views/profileStyle';
import constants, { ResourceType } from '@constants';
import { useUser } from '@hooks';
import { Trans, t } from '@lingui/macro';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { auth } from '@reducers';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KBreadcrumbs } from 'uikit';
import { useMount } from 'uikit-common';

import { Card, CardBody, GridContainer, GridItem, UIKit } from 'components';

import ModalChangePass from './ModalChangePass';

const useStyles = makeStyles(styles);

export default function Profile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openChangePassModal, setOpenChangePassModal] = React.useState(false);

  const { account, refetch } = useUser();

  useMount(() => {
    refetch();
  });

  const uploadAvatarLoading = useSelector(auth.selectors.uploadAvatarLoading);
  const handleUploadAvatar = e => {
    const files = e.target?.files;
    if (files && !isEmpty(files)) {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('resourceType', ResourceType.Avatar);
      dispatch(auth.actions.uploadAvatar(formData));
    }
  };

  return (
    <div style={{ height: 'calc(100vh - 112px' }}>
      <KBreadcrumbs hasBackIcon={false} title={t`Profile`} />

      <div className={classes.profileWrapper}>
        <Card className={classes.card}>
          <CardBody>
            <GridContainer style={{ margin: '30px 0' }}>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <Avatar
                  src={
                    uploadAvatarLoading
                      ? loading_icon_green
                      : account?.avatar || ''
                  }
                  style={{ width: 80, height: 80, margin: '0 auto' }}
                />
                <Button
                  component="label"
                  className={classes.uploadBtn}
                  size="small"
                >
                  <Trans>Change avatar</Trans>
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleUploadAvatar}
                  />
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer noPadding>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="email"
                      label={<Trans>Email</Trans>}
                      value={account?.email || ''}
                      fullWidth
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="username"
                      label={<Trans>Username</Trans>}
                      value={account?.displayName || ''}
                      disabled
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="firstName"
                      label={<Trans>First name</Trans>}
                      disabled
                      value={account?.firstname || ''}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="lastName"
                      label={<Trans>Last name</Trans>}
                      disabled
                      value={account?.lastname || ''}
                      fullWidth
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Select
                      name="gender"
                      label={t`Gender`}
                      data={constants.GENDER}
                      labelData={constants.GENDER_STRING}
                      value={account?.gender}
                      disabled
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="birthdate"
                      label={<Trans>Date of Birth</Trans>}
                      disabled
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="title"
                      label={<Trans>Title</Trans>}
                      disabled
                      value={account?.type || ''}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="position"
                      label={<Trans>Position</Trans>}
                      disabled
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <UIKit.Input
                      name="group"
                      label={<Trans>Group</Trans>}
                      disabled
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <div style={{ position: 'relative' }}>
                      <UIKit.Input
                        name="password"
                        label={<Trans>Password</Trans>}
                        type="password"
                        value="******"
                        disabled
                        fullWidth
                      />
                      <IconButton
                        size="small"
                        className={classes.editBtn}
                        onClick={() => setOpenChangePassModal(true)}
                      >
                        <CreateIcon className={classes.changePasswordIcon} />
                      </IconButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
      <ModalChangePass
        open={openChangePassModal}
        handleClose={() => {
          setOpenChangePassModal(false);
        }}
      />
    </div>
  );
}
