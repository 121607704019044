import { JobType, MarkupType, Status } from '@constants';
import { Prototype } from '@core';
import { TableUtils, UIUtils } from '@utils';
import { omit } from 'lodash';
import React, { useMemo } from 'react';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel } from 'uikit';

export interface IFormData {
  suburb: any;
  zone: any;
  jobType: JobType;
  dropMode: any;
  containerSize: any;
  grossWeight: number;
  isDamagedGood: boolean;
  // isRefrigeratedStorage: boolean;
  chargeCodeTemplate: any;
  charges: any[];
  status: Status;
}

export const useChargeColumns = (data: any[], options?: any) => {
  const columns = useMemo(() => {
    let _columns = [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithEdit(data, options?.onShowPopup)
      },
      {
        label: trans('code'),
        name: 'companyTariff.code'
      },
      {
        label: trans('original_code'),
        name: 'companyTariff.chargeCode.code'
      },
      {
        label: trans('zone'),
        name: 'companyTariff.zone.name'
      },
      {
        label: trans('name'),
        name: 'companyTariff.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('remark'),
        name: 'companyTariff.remark',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('category'),
        name: 'companyTariff.chargeCode.chargeCodeCategory.name',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('per_unit'),
        name: 'companyTariff.chargeCode.chargeCodeUnit.name'
      },
      {
        label: trans('calculator_type'),
        name: 'companyTariff.chargeCode.calculateType',
        options: TableUtils.options.mappedOption
      },
      {
        label: trans('costing_price'),
        name: 'companyTariff.costingPrice',
        options: TableUtils.options.currency()
      },
      {
        label: trans('min_selling_price'),
        name: 'companyTariff.minSellingPrice',
        options: TableUtils.options.currency()
      },
      {
        label: trans('markup_level_type'),
        name: 'companyTariff.markupType',
        options: TableUtils.options.mappedOption
      },
      {
        label: trans('selected_markup_level'),
        name: 'levelValue',
        options: {
          customBodyRenderLite: (index: number) => {
            const item = data?.[index];
            if (item && item.selectedLevelNumber) {
              const markupType = item.levelValueType;
              if (markupType === MarkupType.Percentage) {
                return `${item.levelValue} %`;
              } else if (markupType === MarkupType.Currency) {
                return Prototype.number.formatCurrency(item.levelValue);
              } else {
                return item.levelValue;
              }
            } else {
              return 'N/A';
            }
          }
        }
      },
      {
        label: trans('selected_selling_price_level'),
        name: 'sellingPriceValue',
        options: {
          customBodyRenderLite: (index: number) => {
            const item = data?.[index];
            if (item?.selectedLevelNumber) {
              const markupType = item.levelValueType;
              if (
                [MarkupType.Percentage, MarkupType.Currency].includes(
                  markupType
                )
              ) {
                return Prototype.number.formatCurrency(item.sellingPriceValue);
              } else {
                return (
                  <span
                    style={{
                      maxWidth: '100%',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 4,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.sellingPriceValue}
                  </span>
                );
              }
            } else {
              return 'N/A';
            }
          }
        }
      },
      {
        label: trans('ref_level'),
        name: 'selectedLevelNumber',
        options: TableUtils.options.withNA
      },
      {
        label: trans('original_sub_charge_codes'),
        name: 'companyTariff.chargeCode.subChargeCodes',
        options: {
          sort: false,
          ...TableUtils.options.withMoreIcon({
            onPress: v => {
              UIUtils.popup.open({
                title: trans('sub_charge_codes'),
                maxWidth: 'md',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {v.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`sub-charge-code-popup-${i.id}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.warning.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text
                            typo="TextMdNormal"
                            textTransform="uppercase"
                          >
                            {i.code} - {i.name}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i, idx, rowIdx) => {
              const item = data?.[rowIdx];
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <>
                  {idx === 0 && (
                    <KChip
                      key={`${item?.id}-rate`}
                      background={KColors.palette.warning.w50}
                      brC={KColors.warning.normal}
                      label={trans('rate')}
                      textTransform={'uppercase'}
                      color={KColors.warning.normal}
                      marginL={0}
                      marginR={'0.25rem'}
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  )}

                  <KChip
                    key={`${item?.id}-sub-charge-code-${i.id}`}
                    background={KColors.palette.warning.w50}
                    brC={KColors.warning.normal}
                    label={i.code}
                    textTransform={'uppercase'}
                    color={KColors.warning.normal}
                    marginL={marginL}
                    style={{ whiteSpace: 'nowrap' }}
                  />
                </>
              );
            }
          })
        }
      },
      {
        label: trans('price_instruction'),
        name: 'companyTariff.chargeCode.priceInstruction'
      },
      {
        label: trans('shipment_type'),
        name: 'companyTariff.chargeCode.shipmentType'
      },
      {
        label: trans('container_sizes'),
        name: 'companyTariff.chargeCode.containerSizes',
        options: {
          sort: false,
          ...TableUtils.options.withMoreIcon({
            onPress: v => {
              UIUtils.popup.open({
                title: trans('container_sizes'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {v.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`container-size-popup-${i.id}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.warning.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text
                            typo="TextMdNormal"
                            textTransform="uppercase"
                          >
                            {i.code}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i, idx, rowIdx) => {
              const item = data?.[rowIdx];
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <KChip
                  key={`${item?.id}-container-size-${i.id}`}
                  background={KColors.palette.warning.w50}
                  brC={KColors.warning.normal}
                  label={i.code}
                  textTransform={'uppercase'}
                  color={KColors.warning.normal}
                  marginL={marginL}
                  style={{ whiteSpace: 'nowrap' }}
                />
              );
            }
          })
        }
      },
      {
        label: trans('job_type'),
        name: 'companyTariff.chargeCode.jobType',
        options: TableUtils.options.mappedOptionCustom()
      },
      {
        label: trans('drop_mode'),
        name: 'companyTariff.chargeCode.dropMode.name',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('with_unit.max_gross_weight'),
        name: 'companyTariff.chargeCode.maxGrossWeight',
        options: TableUtils.options.number()
      },
      {
        label: trans('overweight_surcharge'),
        name: 'companyTariff.chargeCode.isOverweightSurcharge',
        options: TableUtils.options.checkbox
      },
      {
        label: trans('with_unit.max_gross_overweight'),
        name: 'companyTariff.chargeCode.overweightChargeCode.maxGrossWeight',
        options: TableUtils.options.number()
      },
      {
        label: trans('overweight_surcharge_code'),
        name: 'companyTariff.chargeCode.overweightChargeCode.code'
      },
      {
        label: trans('hazardous_goods'),
        name: 'companyTariff.chargeCode.isDamagedGood',
        options: TableUtils.options.checkbox
      },
      {
        label: trans('refrigerated_storage'),
        name: 'companyTariff.chargeCode.isRefrigeratedStorage',
        options: TableUtils.options.checkbox
      },
      {
        label: trans('status'),
        name: 'status',
        options: TableUtils.options.switch
      }
    ];

    if (options?.noAction) {
      _columns = _columns.slice(1);
    }

    return _columns;
  }, [data, options?.noAction, options?.onShowPopup]);

  return columns;
};

export const genSellingPrice = (data: any): any => {
  let result = '';

  const markupType = data.levelValueType;
  const components = data.quotationRequestChargeComponents;

  if (markupType === MarkupType.Composite) {
    const componentsPriceArr = components
      .filter((i: any) => i.weight > 0)
      .map(
        (i: any) => `${i.weight}% ${i.chargeCodeComponent.subChargeCode.code}`
      );
    const resultArr = data.rateValue
      ? [Prototype.number.formatCurrency(data.rateValue), ...componentsPriceArr]
      : componentsPriceArr;
    result = resultArr.join(' + ');
  } else {
    result = data.levelValue;
  }

  return result;
};

export const mapChargeByCompanyTariff = (v: any[]): any[] => {
  return v.map(i => {
    const { companyTariff, selectedLevelNumber, components } = i;
    const level = companyTariff.companyTariffLevels.find(
      (j: any) => j.levelNumber === selectedLevelNumber
    );

    const rest = omit(i, ['components']);

    const result: any = {
      ...rest,
      companyTariffId: companyTariff.id,
      status: companyTariff.status,
      levelValueType: companyTariff.markupType,
      companyTariffLevel: level,
      companyTariffLevelId: level.id,
      quotationRequestChargeComponents: components
    };

    if (
      [MarkupType.Composite, MarkupType.Descriptive].includes(
        companyTariff.markupType
      )
    ) {
      result.sellingPriceValue = genSellingPrice(result);
    }

    return result;
  });
};
