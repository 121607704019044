import loadable from '@loadable/component';
import { UIUtils } from '@utils';
import React from 'react';
import trans from 'translation';

const Form = loadable(() => import('./Form'));

export const onShowLog = (options: { item: any }) => {
  const { item } = options;
  return UIUtils.popup.open({
    title: trans('view_log'),
    content: () => <Form item={item} />
  });
};
