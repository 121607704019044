import { Editor } from '@tinymce/tinymce-react';
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import { useAPIContext } from '../../context/api';

interface IProps {
  defaultValue?: string;
  apiURL?: string;
}

const TextEditor = ({ defaultValue = '', apiURL }: IProps, ref: any) => {
  const editorRef = useRef<any>(null);

  const { apiInstance } = useAPIContext();

  const [rawHTML, setRawHTML] = useState(defaultValue || '');

  useEffect(() => {
    setRawHTML(defaultValue);
  }, [defaultValue]);

  useImperativeHandle(ref, () => ({
    getContent: () => {
      return rawHTML;
    }
  }));

  const handleEditorChange = (content: string, _: any) => {
    // console.log('Content was updated:', content);
    setRawHTML(content);
  };

  return (
    <Editor
      tinymceScriptSrc={'/tinymce/tinymce.min.js'}
      onInit={(_, editor) => (editorRef.current = editor)}
      initialValue={defaultValue}
      init={{
        height: 500,
        // menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'preview',
          'help',
          'wordcount',
          'pagebreak',
          'emoticons',
          'nonbreaking',
          'importcss'
        ],
        toolbar:
          'fullscreen | undo redo | blocks fontfamily fontsize | ' +
          'bold italic underline strikethrough | link image | forecolor backcolor removeformat | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist | outdent indent | ' +
          'pagebreak | help',
        toolbar_mode: 'sliding',
        toolbar_sticky: true,
        content_style: 'body { font-family:Roboto; font-size:12px }',
        promotion: false,
        image_title: true,
        font_size_input_default_unit: 'px',
        font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
        images_reuse_filename: true,
        images_upload_url: 'postAcceptor.php',
        images_upload_handler: (blobInfo, progress) => {
          const formData = new FormData();
          formData.append('file', blobInfo.blob());
          formData.append('resourceType', 'EDOC');

          return new Promise(async (resolve, reject) => {
            const res = await apiInstance({
              url: apiURL || '/resource/upload',
              method: 'POST',
              body: formData,
              showToast: false,
              showError: false,
              configs: {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: p => {
                  if (p.total) {
                    progress((p.loaded / p.total) * 100);
                  }
                }
              }
            });

            if (res.error) {
              reject({ message: res.error.message });
              return;
            }

            resolve(res.data.url);
          });
        }
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default memo(forwardRef(TextEditor));
