import { alertRef, popperRef, popupRef } from '@constants';
import { KAlertProps, KPopupProps, KPopperProps } from 'uikit';

class UIUtils {
  alert = {
    open: (params: KAlertProps) => {
      alertRef.current?.open(params);
    },
    dismiss: () => {
      alertRef.current?.dismiss();
    },
    dismissAll: () => {
      alertRef.current?.dismissAll();
    }
  };

  popup = {
    open: (params: KPopupProps) => {
      popupRef.current?.open(params);
    },
    dismiss: () => {
      popupRef.current?.dismiss();
    },
    dismissAll: () => {
      popupRef.current?.dismissAll();
    }
  };

  popper = {
    open: (params: KPopperProps) => {
      popperRef.current?.open(params);
    },
    dismiss: () => {
      popperRef.current?.dismiss();
    },
    dismissAll: () => {
      popperRef.current?.dismissAll();
    }
  };
}

export default new UIUtils();
