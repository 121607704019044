import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgTableProps } from '@ui';
import { ColDef } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import React, { memo, useMemo, useRef } from 'react';
import { KContainer, KLoading } from 'uikit';
import { v4 as uuidv4 } from 'uuid';

import './index.css';
import { useColTypes } from './helpers';
import LeftActions from './LeftActions';
import RightActions from './RightActions';

const PAGE_SIZES = [20, 50, 100];

const AgTable = <T extends any>(props: AgTableProps<T>) => {
  const {
    gridRef,

    onAdd,
    onSearch,
    onRefresh,

    leftNode,
    rightNode,

    showActions,
    showLeftActions,
    showRightActions,

    showSearch,
    keyword,

    showViewColumns = true,

    rowData,
    isLoading,

    name,
    height,

    ...rest
  } = props;

  const defaultColDef = useRef<ColDef<T, any>>({
    filter: false,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    wrapText: true,
    initialWidth: 130,
    minWidth: 130
  });

  const uuid = useMemo(() => uuidv4(), []);

  const colTypes = useColTypes();

  const len = (rowData ?? []).length;

  const options: AgGridReactProps<T> = useMemo(() => {
    return {
      pagination: true,
      paginationPageSize: PAGE_SIZES[0],
      paginationPageSizeSelector: PAGE_SIZES,

      suppressRowClickSelection: true,
      rowDragManaged: true,
      suppressMoveWhenRowDragging: true,
      reactiveCustomComponents: true,
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      enableCellTextSelection: true,

      rowSelection: 'multiple',

      animateRows: len < 20,

      ...rest
    };
  }, [len, rest]);

  const _showActions = useMemo(() => {
    return showActions || showLeftActions || showRightActions || showSearch;
  }, [showActions, showLeftActions, showRightActions, showSearch]);

  return (
    <>
      {_showActions && (
        <KContainer.View
          row
          alignItems
          justifyContent="space-between"
          marginB="0.75rem"
        >
          {(showLeftActions || showSearch) && (
            <LeftActions
              id={uuid}
              leftNode={leftNode}
              showSearch={showSearch}
              keyword={keyword}
              onAdd={onAdd}
              onSearch={onSearch}
            />
          )}

          {showRightActions && (
            <RightActions
              id={uuid}
              name={name}
              showViewColumns={showViewColumns}
              rightNode={rightNode}
              onRefresh={onRefresh}
              gridRef={gridRef as any}
            />
          )}
        </KContainer.View>
      )}

      <KContainer.View
        id={uuid}
        className="ag-theme-quartz"
        height={height ?? 400}
        width="100%"
        position="relative"
      >
        {isLoading && <KLoading thickness={3} />}

        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef.current}
          rowData={rowData}
          loadingOverlayComponent={KLoading}
          columnTypes={colTypes}
          {...options}
        />
      </KContainer.View>
    </>
  );
};

export default memo(AgTable);
