import { ConversationMessageType } from '@constants';
import { Prototype } from '@core';
import { useFetchDashboardAllocationMessages } from '@hooks';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KColors, KContainer, KLabel, KLoading } from 'uikit';

interface IProps {
  date: string | null;
}

const Messages = ({ date }: IProps) => {
  const { data = [], isLoading } = useFetchDashboardAllocationMessages(date);

  const renderRowContent = useCallback((item: any) => {
    let content;
    switch (item.messageType) {
      case ConversationMessageType.Text:
        content = (
          <KLabel.Text color={'#7B809A'} numberOfLines={2}>
            {item.text}
          </KLabel.Text>
        );
        break;

      default:
        content = (
          <KLabel.Text
            color={KColors.primary.normal}
            numberOfLines={2}
            href={item.data.resourceUrl || ''}
            target="_blank"
          >
            {item.data.fileName.split('.').slice(0, -1).join('.') ||
              item.data.filename}
          </KLabel.Text>
        );
        break;
    }
    return content;
  }, []);

  const renderRow = useCallback(
    (item: any) => {
      return (
        <KContainer.View row marginB={'1rem'} key={`m-${item.id}`}>
          <KContainer.View flex>
            <KLabel.Text typo="TextNmBold" color={KColors.primary.normal}>
              {Prototype.date.formatT(item.timeStart)} -{' '}
              {Prototype.date.formatT(item.timeEnd)}
            </KLabel.Text>
            <br />
            <KLabel.Text color={'#344767'}>
              {trans('sent_at')} {Prototype.date.formatT(item.createdAt)}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View flex>
            <KLabel.Text typo="TextNmBold" color={'#344767'}>
              {item.createdUsername}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View flex={2}>{renderRowContent(item)}</KContainer.View>
        </KContainer.View>
      );
    },
    [renderRowContent]
  );

  if (!isLoading && data.length === 0) {
    return (
      <KContainer.View dp="flex" center flex>
        <KLabel.Text typo="TextXNmMedium">{trans('no_data_found')}</KLabel.Text>
      </KContainer.View>
    );
  }

  return (
    <>
      {isLoading && <KLoading />}

      <KContainer.View dp="flex" flex position="relative">
        <KContainer.View row alignItems>
          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">{trans('trip')}</KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">{trans('sender')}</KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex={2}>
            <KLabel.Text typo="TextXNmMedium">{trans('messages')}</KLabel.Text>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View dp="flex" flex marginT={'0.75rem'}>
          {data.map(i => renderRow(i))}
        </KContainer.View>
      </KContainer.View>
    </>
  );
};

export default memo(Messages);
