import { UIUtils } from '@utils';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import trans from 'translation';
import { KColors } from 'uikit';

export const useRecursiveAlert = () => {
  const onShowRecursiveAlert = useCallback(
    (
      messages: string[],
      index: number,
      onSuccess?: () => void,
      onCancel?: () => void
    ) => {
      if ((messages ?? []).length === 0) {
        return toast.error(trans('something_went_wrong'));
      }

      UIUtils.alert.open({
        message: messages?.[index],
        messageColor: KColors.secondary.normal,
        buttons: [
          {
            title: 'OK',
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => {
              if (index === messages.length - 1) {
                onSuccess?.();
              } else {
                setTimeout(() => {
                  onShowRecursiveAlert(
                    messages,
                    index + 1,
                    onSuccess,
                    onCancel
                  );
                }, 500);
              }
            }
          },
          {
            title: trans('cancel'),
            onPress: () => {
              onCancel?.();
            }
          }
        ]
      });
    },
    []
  );

  return { onShowRecursiveAlert };
};
