import constants, { ENDPOINTS, WebTable } from '@constants';
import { useDataTable, useGlobalTable } from '@hooks';
import { ILogOptions } from '@ui';
import { TableUtils } from '@utils';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import { KCardProps, KContainer } from 'uikit';

import { onShowLog } from './helpers';

import DataTable from '../DataTable';

interface IProps extends KCardProps, ILogOptions {}

const Log = (props: IProps) => {
  const {
    title,
    source,
    parentId,
    noHeader,
    tableMaxHeight,
    isLoading,
    ...rest
  } = props;

  const columnsFunc = useCallback((data: any[]) => {
    return [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithView(data, item =>
          onShowLog({ item })
        )
      },
      {
        label: trans('event_time'),
        name: 'dmlTimestamp',
        options: TableUtils.options.date()
      },
      {
        label: trans('created_by'),
        name: 'dmlCreatedUser.displayName'
      },
      {
        label: trans('event_source'),
        name: 'source',
      },
      {
        label: trans('source_code'),
        name: 'sourceCode',
        options: {
          ...TableUtils.options.baseLg,
        }
      },
      {
        label: trans('event_child_source'),
        name: 'childSource',
      },
      {
        label: trans('related_code'),
        name: 'childSourceCode',
      },
      {
        label: trans('event_type'),
        name: 'dmlType'
      },
      {
        label: trans('changed_data'),
        name: 'changedData',
        options: TableUtils.options.withTooltip(v =>
          Object.keys(v)
            .map(i => `${i}: ${v[i][0]} --> ${v[i][1]}`)
            .join('; ')
        )
      },
      {
        label: trans('old_row_data'),
        name: 'oldRowData',
        options: TableUtils.options.withTooltip(v =>
          Object.keys(v)
            .map(i => `${i}: ${v[i]}`)
            .join('; ')
        )
      },
      {
        label: trans('new_row_data'),
        name: 'newRowData',
        options: TableUtils.options.withTooltip(v =>
          Object.keys(v)
            .map(i => `${i}: ${v[i]}`)
            .join('; ')
        )
      }
    ];
  }, []);

  const mappedFields = useMemo(() => {
    return {
      'childSourceType.name': 'childSource',
      'dmlCreatedUser.displayName': 'dmlCreatedBy'
    };
  }, []);

  const otherParams = useMemo(() => {
    return {
      sourceId: parentId,
      source
    };
  }, [parentId, source]);

  const table = useDataTable({
    name: WebTable.LOG,
    // tableName: TableName.LOG,
    apiURL: ENDPOINTS.log(),
    columnsFunc,
    mappedFields,
    otherOptions: {
      tableBodyMaxHeight: tableMaxHeight || '50vh'
    },
    showFilter: false,
    otherParams
  });

  useGlobalTable(table);

  return (
    <KContainer.Card
      marginT={noHeader ? undefined : '0.25rem'}
      header={
        !noHeader
          ? {
              icon: 'History',
              title: title || trans('logs')
            }
          : undefined
      }
      {...rest}
    >
      <DataTable {...table} isModifying={isLoading} />
    </KContainer.Card>
  );
};

export default memo(Log);
