import { Prototype } from '@core';
import { useFetchDashboardAllocationReports } from '@hooks';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel, KLoading } from 'uikit';

interface IProps {
  date: string | null;
}

const Reports = ({ date }: IProps) => {
  const { data = [], isLoading } = useFetchDashboardAllocationReports(date);

  const renderRow = useCallback((item: any) => {
    return (
      <KContainer.View row marginB={'1rem'} key={`r-${item.id}`}>
        <KContainer.View flex>
          <KLabel.Text typo="TextNmBold" color={KColors.primary.normal}>
            {item.code}
          </KLabel.Text>
          <br />
          <KLabel.Text color={'#7B809A'}>
            {Prototype.date.formatT(item.createdAt)}
          </KLabel.Text>
        </KContainer.View>

        <KContainer.View flex={2}>
          <KLabel.Text typo="TextNmBold" color={'#344767'}>
            {item.driverReport?.displayName ?? ''}
          </KLabel.Text>
          <br />
          {(item.scheduleTrips?.length ?? 0) > 0 && (
            <KLabel.Text color={'#7B809A'}>
              {trans('trip')}{' '}
              {item.scheduleTrips
                .map(
                  (i: any) =>
                    `${Prototype.date.formatT(
                      i.scheduleTrip.timeStart
                    )} - ${Prototype.date.formatT(i.scheduleTrip.timeEnd)}`
                )
                .join(', ')}
            </KLabel.Text>
          )}
        </KContainer.View>

        <KContainer.View flex={2}>
          <KChip
            background={'#FFF1E0'}
            label={item.templateReport?.name ?? item.type ?? ''}
          />
        </KContainer.View>

        <KContainer.View width={30}>
          <KLabel.Text
            color={KColors.primary.normal}
            href={item.resource?.url ?? ''}
            target="_blank"
          >
            PDF
          </KLabel.Text>
        </KContainer.View>
      </KContainer.View>
    );
  }, []);

  if (!isLoading && data.length === 0) {
    return (
      <KContainer.View dp="flex" center flex>
        <KLabel.Text typo="TextXNmMedium">{trans('no_data_found')}</KLabel.Text>
      </KContainer.View>
    );
  }

  return (
    <>
      {isLoading && <KLoading />}

      <KContainer.View dp="flex" flex position="relative">
        <KContainer.View row alignItems>
          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">{trans('report')}</KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex={2}>
            <KLabel.Text typo="TextXNmMedium">
              {trans('created_by')}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex={2}>
            <KLabel.Text typo="TextXNmMedium">{trans('type')}</KLabel.Text>
          </KContainer.View>

          <KContainer.View width={30} />
        </KContainer.View>

        <KContainer.View dp="flex" flex marginT={'0.75rem'}>
          {data.map(i => renderRow(i))}
        </KContainer.View>
      </KContainer.View>
    </>
  );
};

export default memo(Reports);
