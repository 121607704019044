import { SYSTEM_FIELDS, WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { useFetchPermissions, useLocalTable } from '@hooks';
import { TableUtils, UIUtils } from '@utils';
import { isEmpty, omit } from 'lodash';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KContainer } from 'uikit';

import Permission from './components/Permission';
import { IFormData, IFormPermissionsInstance } from './helpers';

interface IProps {
  currentData: any[];
}

const Permissions = forwardRef<IFormPermissionsInstance, IProps>(
  ({ currentData }, ref) => {
    const methods = useFormContext<IFormData>();

    const [userData] = useWatch({
      control: methods.control,
      name: ['permissions']
    });

    const { data: remoteData = [], isLoading } = useFetchPermissions();

    const [data, setData] = useState(remoteData);
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

    useEffect(() => {
      if (!isEmpty(remoteData)) {
        const indexes: number[] = [];
        const newRemoteData = remoteData.map((i, idx) => {
          const pm = userData.find(p => p.permissionId === i.permissionId);
          if (pm) {
            indexes.push(idx);
          }
          return omit(pm ?? i, SYSTEM_FIELDS);
        });

        setData(newRemoteData);
        setSelectedIndexes(indexes);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(remoteData), userData]);

    const onRefresh = useCallback(() => {
      methods.setValue('permissions', currentData);
    }, [currentData, methods]);

    const onSuccess = useCallback(
      (v: any) => {
        const newData = data.map(i => {
          if (i.permissionId === v.permissionId) {
            return {
              ...i,
              ...v
            };
          }
          return i;
        });

        setData(newData);
      },
      [data]
    );

    const onShowPermission = useCallback(
      (v: any) => {
        UIUtils.popup.open({
          title: trans('permission'),
          content: () => <Permission item={v} onSuccess={onSuccess} />
        });
      },
      [onSuccess]
    );

    const columns = useMemo(
      () => [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowPermission(item)
            }
          ])
        },
        {
          label: trans('permission_group'),
          name: 'permission.permissionGroup'
        },
        {
          label: trans('permission_code'),
          name: 'permission.code'
        },
        {
          label: trans('permission_name'),
          name: 'permission.name'
        },
        {
          label: trans('limited_by_creator'),
          name: 'isLimitedByOwner',
          options: TableUtils.options.boolean()
        },
        {
          label: trans('limited_by_ip_address'),
          name: 'limitedIp',
          options: TableUtils.options.withNA
        }
      ],
      [data, onShowPermission]
    );

    const table = useLocalTable({
      name: WebTable.ROLE_PERMISSION,
      columns,
      data,
      showDownload: false,
      otherOptions: {
        tableBodyMaxHeight: '22vh'
      },
      onRefresh
    });

    useImperativeHandle(ref, () => ({
      getData: () =>
        selectedIndexes.map(i => omit(data?.[i] ?? {}, ['permission']))
    }));

    return (
      <KContainer.Card
        size="nm"
        border
        header={{
          title: trans('permission_details'),
          border: true
        }}
      >
        <DataTable
          {...table}
          isModifying={isLoading}
          options={{
            ...table.options,
            rowsSelected: selectedIndexes,
            onRowSelectionChange: (_, __, rows) => {
              if (rows) {
                setSelectedIndexes(rows);
              }
            }
          }}
        />
      </KContainer.Card>
    );
  }
);

export default memo(Permissions);
