import { ENDPOINTS, QUERY_KEYS, WebTable, tableRef } from '@constants';
import APIManager from '@services';
import { UIUtils } from '@utils';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './autoRate';

export const useFileClientRate = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientRate('auto-rate/create-from-request'),
        method: 'POST',
        body: data,
        showToast: true
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismissAll();
      }
    }
  });
};

export const useFetchClientRate = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.clientRate, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientRate(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDClientRate = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.clientRate(),
    webTable: WebTable.CLIENT_RATE,
    queryKey: QUERY_KEYS.clientRate,
    redirectPath: '/admin/client-rates'
  });
};

export const useUpdateCharge = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientRate('auto-rate/update-charge'),
        method: 'PUT',
        body: data,
        showToast: true
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        tableRef?.[WebTable.CLIENT_RATE_AUTO_RATE]?.init?.();
      }
    }
  });
};
