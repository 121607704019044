import { ArApType } from '@constants';
import { useAccountingContext } from '@context/Accounting';
import { Prototype } from '@core';
import { useFetchAccountSetting, useFetchClient } from '@hooks';
import { capitalize } from 'lodash';
import React, { memo } from 'react';
import trans from 'translation';
import { KContainer, KGrid, KInput } from 'uikit';

import { OPTIONS } from '../helpers';

const Client = () => {
  const { clientId, arApType } = useAccountingContext();

  const { data: item, isLoading: clientLoading } = useFetchClient(clientId);
  const { data: arAp, isLoading: arApLoading } = useFetchAccountSetting({
    id: clientId,
    accountSettingType: arApType as ArApType
  });

  const options = OPTIONS[arApType as ArApType];

  return (
    <KContainer.Card
      border
      marginT={0}
      size="nm"
      isLoading={clientLoading || arApLoading}
      header={{
        title: trans('client_information'),
        border: true
      }}
    >
      <KGrid.Container>
        <KGrid.Item xs={12} sm={5} md={4}>
          <KInput.Autocomplete
            name="clientCode"
            label={trans('code')}
            value={item}
            getOptionLabel={(o: any) => o?.code ?? ''}
            disabled
            hasEdit
            editURL="clients"
          />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={7} md={8}>
          <KInput.Autocomplete
            name="clientName"
            label={trans('name')}
            value={item}
            disabled
            hasEdit
            editURL="clients"
          />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={12} md={12}>
          <KInput.TextField
            name="address"
            label={trans(options.title.address)}
            value={arAp?.defaultSite?.address ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={4} md={4}>
          <KInput.TextField
            name="paymentType"
            label={trans('payment_type')}
            value={arAp?.paymentType ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={4} md={4}>
          <KInput.TextField
            name="creditTerm"
            label={trans('credit_term')}
            value={
              arAp?.creditHold || arAp?.creditTermType
                ? `${arAp.creditHold ?? 0} ${capitalize(arAp.creditTermType)}`
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12} sm={4} md={4}>
          <KInput.TextField
            name="creditLimit"
            label={trans('credit_limit')}
            value={Prototype.number.formatCurrency(arAp?.creditLimit, {
              withSpace: true
            })}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default memo(Client);
