import React from 'react-redux';

import EmptyTable from 'components/EmptyTable';

/**
 *
 * @param page
 * @param total
 * @param isLoading
 * @param customToolbar
 * @param handleChangePage
 * @param handleChangePageSize
 * @param handleChangeSort
 * @param enabledDraggableColumns
 * @param emptyMessage
 * @param otherOptions
 * @returns {{elevation: number, customToolbarSelect: null, count, onChangeRowsPerPage, draggableColumns: {enabled: boolean}, onRowClick: null, serverSide: boolean, filter: boolean, textLabels: {body: {noMatch: (boolean|JSX.Element)}}, selectableRows: boolean, print: boolean, download: boolean, search: boolean, rowsSelected: *[], onChangePage, responsive: string, onColumnSortChange: onColumnSortChange, customToolbar, page}}
 */
export const commonMUITableOptions = ({
  page,
  total,
  isLoading,
  customToolbar,
  handleChangePage,
  handleChangePageSize,
  handleChangeSort,
  enabledDraggableColumns = true,
  emptyMessage = 'No data found',
  onColumnOrderChange,
  columnOrder,
  onViewColumnsChange,
  ...otherOptions
}) => {
  return {
    serverSide: true,
    count: total,
    print: false,
    download: false,
    filter: false,
    sort: true,
    // responsive: 'scroll',
    responsive: 'standard',
    tableBodyHeight: '100%',
    search: false,
    selectableRows: false,
    onRowClick: null,
    elevation: 0,
    rowsSelected: [],
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangePageSize,
    customToolbarSelect: null,
    draggableColumns: {
      enabled: !!enabledDraggableColumns
    },
    columnOrder,
    page: page,
    customToolbar,
    textLabels: {
      body: {
        noMatch: !isLoading && <EmptyTable message={emptyMessage} />
      }
      // ...generateTableTranslation(t).textLabels,
    },
    onColumnSortChange: (changedColumn, direction) => {
      if (changedColumn && direction) {
        handleChangeSort({
          field: changedColumn,
          value: direction.toUpperCase()
        });
      }
    },
    onColumnOrderChange,
    onViewColumnsChange,
    ...(otherOptions ?? {})
  };
};

/**
 *
 * @param columns
 * @param excludedColumns
 * @returns {*[]|*}
 */
export const mapMUITableColumnsToFilterColumns = (
  columns,
  excludedColumns = []
) => {
  if (!columns || !columns.length) {
    return [];
  }

  return columns
    .filter(col => excludedColumns && !excludedColumns.includes(col.label))
    .map(col => ({ Header: col.label, id: col.name }));
};

export const commonColumnOptions = {
  setCellHeaderProps: () => {
    return {
      style: { whiteSpace: 'pre' }
    };
  }
};

export const parseCacheTableConfigs = name => {
  const tableConfigs = localStorage.getItem('tableConfigs');

  if (!tableConfigs) return undefined;

  return JSON.parse(tableConfigs)[name];
};

export const getColumnOrderFromCache = (name, latestLength) => {
  const tableConfigs = parseCacheTableConfigs(name);
  if (!tableConfigs) return undefined;

  if (tableConfigs.columnOrder?.length === latestLength) {
    return tableConfigs.columnOrder;
  } else {
    // reset cache if add or delete in columns
    let columnOrder = [];
    for (let i = 0; i < latestLength; i++) {
      columnOrder.push(i);
    }
    cacheColumnOrderInt(name, columnOrder);
    return columnOrder;
  }
};

export const getHideColumnFromCache = name => {
  const tableConfigs = parseCacheTableConfigs(name);
  if (!tableConfigs) return undefined;
  return tableConfigs?.hideColumn;
};

export const reorderAndShowColumnsBasedOnCache = (columns, name) => {
  const columnOrder = getColumnOrderFromCache(name, columns.length);
  const hideColumn = getHideColumnFromCache(name);

  if (hideColumn) {
    columns = columns.map(e => {
      if (hideColumn[e.name]) {
        e.options = { ...(e.options || {}), display: false };
      } else {
        e.options = { ...(e.options || {}), display: true };
      }
      return e;
    });
  }

  if (columnOrder?.length === columns.length) {
    return columnOrder.map(order => columns[order]);
  } else {
    return columns;
  }
};

export const cacheColumnOrderInt = (name, newColumnOrderInt) => {
  let tableConfigs = localStorage.getItem('tableConfigs');
  if (!tableConfigs) {
    tableConfigs = {
      [name]: {
        columnOrder: newColumnOrderInt
      }
    };
  } else {
    tableConfigs = JSON.parse(tableConfigs);
    if (!tableConfigs[name]) {
      tableConfigs[name] = {
        columnOrder: newColumnOrderInt
      };
    } else {
      tableConfigs[name]['columnOrder'] = newColumnOrderInt;
    }
  }
  localStorage.setItem('tableConfigs', JSON.stringify(tableConfigs));
};

export const cacheViewColumn = (name, columnName, action) => {
  let tableConfigs = localStorage.getItem('tableConfigs');

  if (!tableConfigs) {
    tableConfigs = {
      [name]: {
        hideColumn: action === 'remove' ? { [columnName]: true } : {}
      }
    };
  } else {
    tableConfigs = JSON.parse(tableConfigs);
    if (!tableConfigs[name]) {
      tableConfigs[name] = {
        hideColumn: action === 'remove' ? { [columnName]: true } : {}
      };
    } else if (tableConfigs[name]['hideColumn']) {
      if (action === 'remove') {
        tableConfigs[name]['hideColumn'][columnName] = true;
      } else if (action === 'add') {
        delete tableConfigs[name]['hideColumn'][columnName];
      }
    } else {
      if (action === 'remove') {
        tableConfigs[name]['hideColumn'] = {
          [columnName]: true
        };
      }
    }
  }
  localStorage.setItem('tableConfigs', JSON.stringify(tableConfigs));
};
