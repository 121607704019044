import {
  ENDPOINTS,
  QUERY_KEYS,
  ReportType,
  ResourceType,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { UIUtils } from '@utils';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer,
  useUploadFile
} from '../core';

export const useFetchReport = (id?: number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.report, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.report(':id', { id })
      });
      return res.data;
    },
    enabled: !!id,
    initialData: undefined
  });
};

export const useFetchReportLeaveOfTrip = (options: {
  scheduleTripId?: number;
  reportDriverId?: number;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.reportLeaveOfTrip, options],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.report(),
        body: {
          ...options,
          type: ReportType.LeaveOfTrip,
          size: 1
        }
      });

      return res.data?.data?.[0];
    },
    enabled: !!options.scheduleTripId && !!options.reportDriverId,
    initialData: undefined
  });
};

export const useCUDReport = (webTable: WebTable) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.report(),
    webTable
  });
};

export const useApproveReport = () => {
  const { mutateAsync: mutateFile } = useUploadFile();

  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.report]],
    mutationFn: async data => {
      const { blob, id, ...rest } = data;
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('resourceType', ResourceType.Signature);
        const fRes = await mutateFile(formData);

        if (fRes?.id) {
          rest['approveSignatureId'] = fRes.id;
        }
      }

      const res = await APIManager.request({
        url: ENDPOINTS.report(':id/approve', {
          id
        }),
        method: 'PUT',
        body: rest
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismissAll();
        tableRef?.[WebTable.REPORT_DRIVER]?.init();
      }
    }
  });
};
