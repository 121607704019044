import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchStatementLatestPayment = (clientId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.statementLatestPayment,
      clientId ? parseInt(clientId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.payment(),
        body: {
          sortBy: 'createdAt:DESC',
          size: 1,
          clientId
        }
      });

      return res.data?.data?.[0];
    },
    enabled: !!clientId
  });
};

export const useFetchPayment = (id?: number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.payment, id ?? null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.payment(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDPayment = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.payment(),
    webTable: WebTable.ACCOUNTING_AR_CLIENTS_SUMMARY_PAYMENT,
    dependentWebTable: [
      WebTable.TRANSPORT_JOB_AR_INVOICE,
      WebTable.ACCOUNTING_AP_ACCOUNTS_PAYMENT,
      WebTable.ACCOUNTING_AP_ACCOUNTS_INVOICE,
      WebTable.ACCOUNTING_AR_CLIENTS_SUMMARY_INVOICE
    ],
    mutationKeys: [[QUERY_KEYS.statement], [QUERY_KEYS.statementLatestPayment]]
  });
};

export const useFetchPaymentList = (clientId?: number | string) => {
  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.paymentList,
      clientId ? parseInt(clientId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.payment(),
        body: {
          clientId
        }
      });

      return res.data?.data;
    },
    enabled: !!clientId
  });
};

export const useFetchPaymentInvoiceList = (clientId: number, isAr: boolean) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.paymentInvoiceList, clientId, isAr],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoice(),
        body: {
          page: 0,
          size: PAGE_SIZE_DEFAULT_MAX,
          isNotFinish: true,
          arClientId: isAr ? clientId : undefined,
          clientId: !isAr ? clientId : undefined,
          excludeFields: ['invoiceContainerCharges'],
          isAR: isAr
        }
      });

      return res.data?.data;
    },
    enabled: !!clientId
  });
};
