import {
  useAlertMutationEnhancer,
  useCUDAutoRate,
  useCUDClientRate
} from '@hooks';
import { UIUtils } from '@utils';
import qs from 'query-string';
import trans from 'translation';

import AutoRateForm from './AutoRate/Form';

export const onShowClientRate = (id: number | string | 'new', query?: any) => {
  window.open(
    `/admin/client-rates/${id}${query ? `?${qs.stringify(query)}` : ''}`,
    '_blank'
  );
};

export const onShowAutoRate = (clientRateId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('auto_rate'),
    maxWidth: 'lg',
    content: () => <AutoRateForm item={v} clientRateId={clientRateId} />
  });
};

export const useDeleteClientRate = () => {
  const { deleteMutation } = useCUDClientRate();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useDeleteAutoRate = (id: number | string) => {
  const { deleteMutation } = useCUDAutoRate(id);

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};
