import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { compact, isEmpty } from 'lodash';

interface IExportParams {
  columnWidths: number[];
  header?: any[];
  footer?: any[];
}

// const ALIGNMENT_STYLE = {
//     wrapText: true
// };

const FONT_STYLE = {
  //   name: 'Arial',
  size: 10
};

// const getTextWidth = (text: string) => {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   if (ctx) {
//     ctx.font = '12px Arial';
//     return ctx.measureText(text).width;
//   }
//   return text.length;
// };

const MIN_WIDTH = 16; // Font size = 10

export const exportDataToExcel = async (
  name: string,
  data: string[][],
  exportParams: IExportParams
) => {
  if (data.length === 0) {
    return undefined;
  }

  const { columnWidths, header, footer } = exportParams;

  console.log('exportParams', header, footer);

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(name);

  const rowCount = data.length;
  const columnCount = data[0].length;

  let headerRowCount = 0;

  // Header
  if (header) {
    const _header = compact(header);
    if (!isEmpty(_header)) {
      headerRowCount = _header.length;
      for (let i = 0; i < headerRowCount; i++) {
        const fData = _header[i].data ?? [];
        const row = sheet.addRow(fData);
        if (_header[i].mergeCells) {
          sheet.mergeCells(_header[i].mergeCells);
        }
        row.font = {
          ...FONT_STYLE,
          bold: !!_header[i].mergeCells,
          name: !!_header[i].mergeCells ? 'Arial' : 'Arial (Body)'
        };
        // for (let j = 0; j < fData.length; j++) {
        //   if (_header[i].mergeCells) {
        //     sheet.mergeCells(_header[i].mergeCells);
        //   }
        //   const cell = sheet.getCell(i + 1, j + 1);
        //   cell.value = fData[j];
        //   cell.font = {
        //     ...FONT_STYLE,
        //     bold: !!_header[i].mergeCells,
        //     name: !!_header[i].mergeCells ? 'Arial' : 'Arial (Body)'
        //   };
        // }
      }

      sheet.addRow([]);
    }
  }

  // Body
  for (let i = 0; i < rowCount; i++) {
    const row = sheet.addRow(data[i]);
    row.font = {
      ...FONT_STYLE,
      bold: i === 0,
      name: i === 0 ? 'Arial' : 'Arial (Body)'
    };
    // for (let j = 0; j < columnCount; j++) {
    //   const cell = sheet.getCell(i + 1 + headerRowCount, j + 1);
    //   cell.value = data[i][j];
    //   // cell.alignment = ALIGNMENT_STYLE;
    //   cell.font = {
    //     ...FONT_STYLE,
    //     bold: i === 0,
    //     name: i === 0 ? 'Arial' : 'Arial (Body)'
    //   };
    // }
  }

  sheet.addRow([], 'i');

  // Column widths
  for (let i = 0; i < columnCount; i++) {
    const minWidth = (columnWidths[i] * MIN_WIDTH) / 130;
    sheet.getColumn(i + 1).width = minWidth;
  }

  // Footer
  if (footer) {
    const _footer = compact(footer);
    const footerRowCount = _footer.length;
    for (let i = 0; i < footerRowCount; i++) {
      const rowIndex = headerRowCount + 1 + rowCount + 1 + i + 1;
      const fData = _footer[i].data ?? [];
      for (let j = 0; j < fData.length; j++) {
        const cell = sheet.getCell(rowIndex, j + 1);
        cell.value = fData[j];
        cell.font = {
          ...FONT_STYLE,
          name: 'Arial (Body)'
        };
      }
    }
  }

  // Export
  const buffer = await workbook.xlsx.writeBuffer();
  const eData = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  });

  saveAs(eData, name);
};
