import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export * from './request';

export const useFetchQuotation = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.quotation, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.quotation(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDQuotation = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.quotation(),
    webTable: WebTable.QUOTATION,
    queryKey: QUERY_KEYS.quotation,
    redirectPath: '/admin/quotations'
  });
};
