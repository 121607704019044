import { yupResolver } from '@hookform/resolvers/yup';
import { UseResolverFieldArrayProps, UseResolverFormProps } from '@hooks-dto';
import {
  FieldArray,
  FieldArrayMethodProps,
  FieldArrayPath,
  FieldValues,
  UseFormReturn,
  useFieldArray,
  useForm
} from 'react-hook-form';

export const useResolverForm = <T extends FieldValues>({
  schema,
  configs
}: UseResolverFormProps<T>): UseFormReturn<T> => {
  return useForm<T>({
    shouldUnregister: false,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    delayError: 100,
    shouldFocusError: true,
    ...configs,
    resolver: yupResolver(schema)
  });
};

export const useResolverFieldArray = <T extends FieldValues>(
  configs: UseResolverFieldArrayProps<T>
) => {
  const { noFocus, ...rest } = configs;
  const fieldArray = useFieldArray<T, FieldArrayPath<T>, 'formId'>({
    ...rest,
    keyName: 'formId'
  });
  if (noFocus) {
    return {
      ...fieldArray,
      append: (
        value: FieldArray<T> | FieldArray<T>[],
        options?: FieldArrayMethodProps
      ) => fieldArray.append(value, { shouldFocus: false, ...options }),
      prepend: (
        value: FieldArray<T> | FieldArray<T>[],
        options?: FieldArrayMethodProps
      ) => fieldArray.prepend(value, { shouldFocus: false, ...options }),
      insert: (
        index: number,
        value: FieldArray<T> | FieldArray<T>[],
        options?: FieldArrayMethodProps
      ) => fieldArray.insert(index, value, { shouldFocus: false, ...options })
    };
  }
  return fieldArray;
};
