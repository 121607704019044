import constants, {
  GlobalEvent,
  ENDPOINTS,
  SystemSettingCode
} from '@constants';
import { AppEvent } from '@core';
import APIManager from '@services';
import OneSignal from 'react-onesignal';

export default class NotificationManager {
  static initialize = async () => {
    console.log('INIT ONESIGNAL CALL API');

    const res = await APIManager.request({
      url: ENDPOINTS.systemSetting('detail'),
      body: {
        code: SystemSettingCode.Notification
      }
    });

    if (!res.error && res.data) {
      const v = JSON.parse(res.data.settingValue);
      const appId = v?.appWebId ?? v?.appId;

      if (appId) {
        console.log('INIT ONESIGNAL');

        await OneSignal.init({
          appId,
          notificationClickHandlerMatch: 'origin',
          notificationClickHandlerAction: 'navigate'
        });

        console.log('INIT ONESIGNAL SUCCESSFULLY');

        OneSignal.on('notificationDisplay', () => {
          AppEvent.dispatch(GlobalEvent.NewNotification);
        });
        OneSignal.on('addListenerForNotificationOpened', data => {
          console.log('OPENED');
          console.log(data);
        });
      } else {
        console.log('APP ID NOT FOUND');
      }
    } else {
      console.log('INIT ONESIGNAL FAILED');
    }
  };

  static setExternalUserId = async (userId: string) => {
    const uid = await OneSignal.getExternalUserId();
    if (!uid || uid !== userId) {
      await OneSignal.setExternalUserId(userId);
    }
  };

  static removeExternalUserId = async () => {
    await OneSignal.removeExternalUserId();
  };

  static genRedirectLink = (
    childSource: any,
    childSourceId: any,
    sourceId: any
  ) => {
    let link = '';
    switch (childSource) {
      case constants.SOURCE_TYPE.TRANSPORT_JOB:
        link = `/admin/transport-jobs/${sourceId}`;
        break;

      case constants.SOURCE_TYPE.JOB_CONTAINER:
        link = `/admin/transport-jobs/${sourceId}?tab=1&containerId=${childSourceId}`;
        break;

      case constants.SOURCE_TYPE.INVOICE:
        link = `/admin/transport-jobs/${sourceId}?tab=2&invoiceId=${childSourceId}`;
        break;

      case constants.SOURCE_TYPE.CLIENT_RATE:
        link = `/admin/client-rates/${sourceId}`;
        break;

      case constants.SOURCE_TYPE.AUTO_RATE:
        link = `/admin/client-rates/${sourceId}?tab=1&autoRateId=${childSourceId}`;
        break;

      case constants.SOURCE_TYPE.QUOTATION:
        link = `/admin/quotations/${sourceId}`;
        break;

      case constants.SOURCE_TYPE.QUOTATION_REQUEST:
        link = `/admin/quotations/${sourceId}?tab=1&requestId=${childSourceId}`;
        break;

      case constants.SOURCE_TYPE.CLIENT_TARIFF:
      case constants.SOURCE_TYPE.CLIENT_TARIFF_CHARGE:
        link = `/admin/client-tariffs/${sourceId}`;
        break;

      case constants.SOURCE_TYPE.PAYMENT:
        link = `/admin/statements/${sourceId}`;
        break;

      default:
        break;
    }
    return link;
  };
}
