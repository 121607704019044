import {
  InputAdornment,
  InputProps,
  MenuItem,
  TextField
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import React, { memo } from 'react';

import UIKit from 'components/UIKit';

type Props = {
  keywordProps: InputProps;
  fieldProps: InputProps;
  selectData: any[];
};

const TableInput = (props: Props) => {
  const { keywordProps, fieldProps, selectData } = props;

  return (
    <UIKit.Input
      name="keyword"
      style={{ marginRight: 10 }}
      {...keywordProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TextField
              id="field"
              name="field"
              select
              InputProps={{ disableUnderline: true }}
              {...fieldProps}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}
            >
              {selectData.map(column => (
                <MenuItem key={column.id} value={column.id}>
                  {column.Header}
                </MenuItem>
              ))}
            </TextField>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        )
      }}
    />
  );
};

export default memo(TableInput);
