import { Prototype } from '@core';
import { useFetchDashboardContainers } from '@hooks';
import React, { memo } from 'react';
import trans from 'translation';
import { KLabel, KContainer } from 'uikit';

const Containers = () => {
  const { data, isLoading } = useFetchDashboardContainers();

  return (
    <KContainer.Card
      style={{
        background:
          'linear-gradient(180deg, rgba(198, 248, 244, 0.36) 0%, rgba(198, 248, 244, 0) 100%)'
      }}
      isLoading={isLoading}
    >
      <KContainer.View
        marginB={'0.25rem'}
        row
        alignItems
        justifyContent="space-between"
      >
        <KLabel.Text typo="TextMdBold">{trans('containers')}</KLabel.Text>

        <KLabel.Text color={'#848AA2'}>
          {Prototype.date.now().format('[Today] DD/MM/YYYY')}
        </KLabel.Text>
      </KContainer.View>

      <KContainer.View row alignItems justifyContent="space-between">
        <KLabel.Text typo="Text2xLgBold" color={'#344767'}>
          {data?.numOfBookedContainer ?? '--'} {trans('new_book')}
        </KLabel.Text>

        <KLabel.Text typo="Text2xLgBold" color={'#344767'}>
          {data?.numOfInTransitContainer ?? '--'} {trans('in_transit')}
        </KLabel.Text>

        <KLabel.Text typo="Text2xLgBold" color={'#344767'}>
          {data?.numOfCompletedContainer ?? '--'} {trans('completed')}
        </KLabel.Text>

        <KLabel.Text typo="Text2xLgBold" color={'#344767'}>
          {data?.numOfInvoicedContainer ?? '--'} {trans('invoiced')}
        </KLabel.Text>
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(Containers);
