import { ArApType, UITab } from '@constants';
import { Prototype } from '@core';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from 'react';
import { useMount } from 'uikit-common';

interface IAction {
  type: string;
  payload?: any;
}

interface IState {
  tab?: UITab;
  invoiceId?: number | 'new';
  arApType?: ArApType;
  isAr?: boolean;
  clientId?: number;
}

interface IValue extends IState {
  setTab: (tab?: UITab) => void;
  setInvoiceId: (invoiceId?: number | 'new') => void;
  setArApType: (arApType?: ArApType) => void;
  setDefaultArApType: (arApType?: ArApType) => void;
  setClientId: (clientId?: number) => void;

  clear: () => void;
  clearInvoice: () => void;
  clearStatement: () => void;
}

const initState: IState = {
  tab: undefined,
  invoiceId: undefined,
  arApType: undefined,
  isAr: undefined,
  clientId: undefined
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'setInvoiceId':
      return {
        ...state,
        ...action.payload,
        arApType: !action.payload.invoiceId ? undefined : state.arApType
      };

    case 'setTab':
    case 'setClientId':
    case 'setArApType':
      return {
        ...state,
        ...action.payload
      };

    case 'clearInvoice':
      return {
        ...state,
        invoiceId: undefined
      };

    case 'clearStatement':
      return {
        ...state,
        clientId: undefined
      };

    case 'clear':
      return {
        ...state,
        tab: undefined,
        invoiceId: undefined,
        arApType: undefined,
        clientId: undefined
      };

    default:
      return { ...initState };
  }
};

// @ts-ignore
const AccountingContext = createContext<IValue>();

const AccountingProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const setTab = useCallback((tab?: UITab) => {
    dispatch({
      type: 'setTab',
      payload: {
        tab
      }
    });
  }, []);

  const setInvoiceId = useCallback((invoiceId?: number | 'new') => {
    dispatch({
      type: 'setInvoiceId',
      payload: {
        invoiceId
      }
    });
  }, []);

  const setArApType = useCallback((arApType?: ArApType) => {
    dispatch({
      type: 'setArApType',
      payload: {
        arApType,
        isAr: Prototype.core.isNullOrUndefined(arApType)
          ? undefined
          : arApType === ArApType.Ar
      }
    });
  }, []);

  const setDefaultArApType = useCallback((arApType?: ArApType) => {
    dispatch({
      type: 'setArApType',
      payload: {
        arApType,
        isAr: Prototype.core.isNullOrUndefined(arApType)
          ? undefined
          : arApType === ArApType.Ar,
        invoiceId: undefined,
        clientId: undefined
      }
    });
  }, []);

  const setClientId = useCallback((clientId?: number) => {
    dispatch({
      type: 'setClientId',
      payload: {
        clientId
      }
    });
  }, []);

  const clearInvoice = useCallback(() => {
    dispatch({
      type: 'clearInvoice'
    });
  }, []);

  const clearStatement = useCallback(() => {
    dispatch({
      type: 'clearStatement'
    });
  }, []);

  const clear = useCallback(() => {
    dispatch({
      type: 'clear'
    });
  }, []);

  const value: IValue = useMemo(
    () => ({
      ...state,

      setTab,
      setInvoiceId,
      setArApType,
      setDefaultArApType,
      setClientId,

      clear,
      clearInvoice,
      clearStatement
    }),
    [
      clear,
      clearInvoice,
      clearStatement,
      setArApType,
      setClientId,
      setDefaultArApType,
      setInvoiceId,
      setTab,
      state
    ]
  );

  useMount(() => {
    return () => {
      clear();
    };
  });

  return (
    <AccountingContext.Provider value={value}>
      {children}
    </AccountingContext.Provider>
  );
};

export const useAccountingContext = (): IValue => useContext(AccountingContext);

export const withAccountingProvider = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return memo((props: P) => {
    return (
      <AccountingProvider>
        <WrappedComponent {...props} />
      </AccountingProvider>
    );
  });
};

export default AccountingProvider;
