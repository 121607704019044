import {
  ArApType,
  ClientType,
  RelatedAddressType,
  RelatedClientType
} from '@constants';
import { useAlertMutationEnhancer, useCUDClient } from '@hooks';
import loadable from '@loadable/component';
import { UIUtils } from '@utils';
import React from 'react';
import trans from 'translation';

const AddressForm = loadable(() => import('./Address/Form'));
const ContactForm = loadable(() => import('./Contact/Form'));
const BankAccountForm = loadable(() => import('./BankAccount/Form'));
const CurrencyUpliftForm = loadable(() => import('./CurrencyUplift/Form'));
const SalePersonForm = loadable(() => import('./SalePerson/Form'));
const RelatedClientForm = loadable(() => import('./RelatedParty/Form.Client'));
const RelatedAddressForm = loadable(
  () => import('./RelatedParty/Form.Address')
);

export const useDeleteClient = () => {
  const { deleteMutation } = useCUDClient();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const onShowClient = (id?: string | number | 'new') => {
  window.open(`/admin/clients/${id}`);
};

export const onShowAddress = (clientId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('address'),
    maxWidth: 'md',
    content: () => <AddressForm item={v} clientId={clientId} />
  });
};

export const onShowContact = (clientId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('contact'),
    content: () => <ContactForm item={v} clientId={clientId} />
  });
};

export const onShowBankAccount = (
  clientId: number,
  accountSettingType: ArApType,
  v?: any
) => {
  UIUtils.popup.open({
    title: trans('bank_account'),
    content: () => (
      <BankAccountForm
        item={v}
        accountSettingType={accountSettingType}
        clientId={clientId}
      />
    )
  });
};

export const onShowCurrencyUplift = (clientId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('currency_uplift'),
    content: () => <CurrencyUpliftForm item={v} clientId={clientId} />
  });
};

export const onShowSalePerson = (clientId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('sale_person'),
    content: () => <SalePersonForm item={v} clientId={clientId} />
  });
};

export const useClientType = (arr?: any[]) => {
  const showForwarderAgent =
    arr?.some(i =>
      [ClientType.Consignee, ClientType.Consignor].includes(i.code)
    ) ?? false;

  const showConsignorConsignee =
    arr?.some(i =>
      [
        ClientType.ForwarderAgent,
        ClientType.CustomsAgent,
        ClientType.AccountReceivable,
        ClientType.Warehouse
      ].includes(i.code)
    ) ?? false;

  const showDeliveryWarehouse =
    arr?.some(i => i.code === ClientType.Consignee) ?? false;

  const showPickupWarehouse =
    arr?.some(i => i.code === ClientType.Consignor) ?? false;

  return {
    showForwarderAgent,
    showConsignorConsignee,
    showDeliveryWarehouse,
    showPickupWarehouse
  };
};

export const onShowRelatedClient = (
  clientId: number,
  type: RelatedClientType,
  v?: any
) => {
  UIUtils.popup.open({
    title: trans(`option.${type.toLowerCase()}`),
    content: () => (
      <RelatedClientForm item={v} clientId={clientId} type={type} />
    )
  });
};

export const onShowRelatedAddress = (
  clientId: number,
  type: RelatedAddressType,
  v?: any
) => {
  UIUtils.popup.open({
    title: trans(`option.${type.toLowerCase()}`),
    content: () => (
      <RelatedAddressForm item={v} clientId={clientId} type={type} />
    )
  });
};
