import { ENDPOINTS, PAGE_SIZE_DEFAULT_MAX, QUERY_KEYS } from '@constants';
import { IRoutingParams } from '@request-dto';
import APIManager from '@services';

import { useMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchContainerRouting = (containerId?: number | string) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.containerRouting, containerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.container(':id/routing', { id: containerId }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data;
    },
    enabled: !!containerId
  });
};

export const useValidateContainerRouting = () => {
  return useMutationEnhancer<any, IRoutingParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url:
          ENDPOINTS.container(':id/routing/validate', {
            id: data.containerId
          }) + (data.validateAllRouting ? '?validateAllRouting=true' : ''),
        method: 'PUT',
        body: data.data
      });

      return res;
    }
  });
};

export const useModifyContainerRouting = () => {
  return useMutationEnhancer<any, IRoutingParams>({
    // mutationKeys: [[QUERY_KEYS.containerRouting]],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.container(':id/routing', { id: data.containerId }),
        method: 'PUT',
        showToast: true,
        body: data.data
      });

      return res.success;
    }
  });
};

export const useModifyContainerRoutingForAllocation = () => {
  return useMutationEnhancer<any, IRoutingParams>({
    // mutationKeys: [[QUERY_KEYS.containerRouting]],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.container(':id/routing/update-for-allocation', {
          id: data.containerId
        }),
        method: 'PUT',
        showToast: true,
        body: data.data
      });

      return res.success;
    }
  });
};
