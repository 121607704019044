import {
  ApInvoiceType,
  ArInvoiceType,
  InvoiceStatus,
  InvoiceStatusId
} from '@constants';
import { Prototype } from '@core';
import { useCallback } from 'react';

const NOW = Prototype.date.now();

const DRAFT_STATUS = {
  id: InvoiceStatusId.Draft,
  name: InvoiceStatus.Draft
};

export const useInitValues = () => {
  const normalizeIvInitValues = useCallback((item?: any, isAr?: boolean) => {
    const initValues = {
      code: item?.code ?? '',
      invoiceType:
        item?.invoiceType ??
        (isAr ? ArInvoiceType.ArInvoice : ApInvoiceType.ApInvoice),
      invoiceDate: Prototype.date.toLocal(item?.invoiceDate) || NOW,
      referenceNumber: item?.referenceNumber ?? '',
      dueDate: Prototype.date.toLocal(item?.dueDate),
      attachedEdoc: item?.attachedEdoc?.resource,
      invoiceProgress: item?.progressStatus ?? DRAFT_STATUS,
      totalAmount: item?.totalAmount ?? 0,
      totalDue: item?.totalDue ?? 0,
      charges: item?.invoiceContainerCharges ?? [],
      client: item?.client,

      tenantId: null,

      chargeParams: {
        container: undefined,
        zone: undefined,
        hasAutoRate: true,
        hasQuotationRequest: true,
        routings: [],
        autoRate: undefined,
        chargeCodeTemplate: undefined,
        defaultLevel: 1
      }
    };

    return initValues;
  }, []);

  return { normalizeIvInitValues };
};
