import { Button, TextButton } from './Button';
import { Checkbox } from './Checkbox';
import { Input, PasswordInput } from './Input';
import Select from './Select';

const UIKit = {
  Button,
  TextButton,

  Checkbox,

  Input,
  PasswordInput,

  Select
};

export default UIKit;
