import { DataTableProps } from '@ui';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { memo, useCallback, useMemo } from 'react';
import { KContainer, KLoading } from 'uikit';
import { v4 as uuidv4 } from 'uuid';

import { noActionColumns } from './helpers';
import LeftActions from './LeftActions';
import RightActions from './RightActions';

const DataTable = <T extends any>(props: DataTableProps<T>) => {
  const {
    id,
    columns,
    data,
    options,
    keyword,
    showFilter = true,
    showDownload = true,
    showRefresh = true,
    selectedData,
    isLoading,
    isModifying,
    excludedExportColumns,
    isLocal,
    showLeftActions = true,
    showRightActions = true,
    rightButtons,

    setSearchConditions,

    init,

    onAdd,
    onCopy,
    onDelete,
    onDownload,
    onRefresh,

    onNormalizeData,

    ...rest
  } = props;

  const randomId = useMemo(() => id || uuidv4(), [id]);

  const renderLeftActions = useMemo(() => {
    return (
      <LeftActions
        id={randomId}
        keyword={keyword}
        setSearchConditions={setSearchConditions}
        onAdd={onAdd}
        onCopy={onCopy ? () => onCopy?.(selectedData) : undefined}
        onDelete={onDelete ? () => onDelete?.(selectedData) : undefined}
        selectedData={selectedData}
        showFilter={showFilter}
        rightButtons={rightButtons}
        {...rest}
      />
    );
  }, [
    keyword,
    onAdd,
    onCopy,
    onDelete,
    randomId,
    rest,
    rightButtons,
    selectedData,
    setSearchConditions,
    showFilter
  ]);

  const renderLeftActionsForLocal = useCallback(
    (searchText: string, handleSearch: (text: string) => void) => {
      return (
        <LeftActions
          id={randomId}
          keyword={searchText}
          setKeyword={handleSearch}
          setSearchConditions={setSearchConditions}
          advanceSearch={[]}
          searchConditions={[]}
          selectedData={selectedData}
          showFilter={false}
          onDelete={onDelete ? () => onDelete?.(selectedData) : undefined}
          onAdd={onAdd}
          rightButtons={rightButtons}
        />
      );
    },
    [onAdd, onDelete, randomId, rightButtons, selectedData, setSearchConditions]
  );

  const renderRightActions = useMemo(() => {
    return (
      <RightActions
        id={randomId}
        onDownload={onDownload}
        showDownload={showDownload}
        showRefresh={showRefresh}
        onRefresh={onRefresh || init}
        columns={columns.filter(
          i =>
            i.name &&
            ![...noActionColumns(), ...(excludedExportColumns || [])].includes(
              i.label
            ) &&
            i.options?.display !== 'excluded'
        )}
      />
    );
  }, [
    columns,
    excludedExportColumns,
    init,
    onDownload,
    onRefresh,
    randomId,
    showDownload,
    showRefresh
  ]);

  const mOptions = useMemo(() => {
    const _options: MUIDataTableOptions = {
      ...options,
      customToolbar: () => (showRightActions ? renderRightActions : <></>)
    };
    if (isLocal) {
      _options.customSearchRender = renderLeftActionsForLocal;
    }
    if (!showLeftActions) {
      _options.customSearchRender = () => <></>;
    }
    if (!showRightActions) {
      _options.viewColumns = false;
    }
    if (!showLeftActions && !showRightActions) {
      _options.search = false;
      _options.customToolbar = undefined;
      _options.sort = false;
    }

    return _options;
  }, [
    isLocal,
    options,
    renderLeftActionsForLocal,
    renderRightActions,
    showLeftActions,
    showRightActions
  ]);

  return (
    <KContainer.View position="relative">
      {(isModifying || isLoading) && <KLoading thickness={3} />}

      <MUIDataTable
        title={isLocal ? '' : renderLeftActions}
        data={onNormalizeData ? onNormalizeData(data?.data) : data?.data}
        columns={columns}
        options={mOptions}
      />
    </KContainer.View>
  );
};

export default memo(DataTable);
