import { useAlertMutationEnhancer, useCUDDataGroup, useCUDRole } from '@hooks';
import { UIUtils } from '@utils';
import React from 'react';
import trans from 'translation';

import DataGroupForm from './DataGroup/Form';
import RoleForm from './Roles/Form';

export const onShowDataGroup = (v?: any) => {
  UIUtils.popup.open({
    title: trans('data_group'),
    content: () => <DataGroupForm item={v} />
  });
};

export const onShowRole = (v?: any) => {
  UIUtils.popup.open({
    title: trans('role'),
    maxWidth: 'md',
    content: () => <RoleForm item={v} />
  });
};

export const useDeleteDataGroup = () => {
  const { deleteMutation } = useCUDDataGroup();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useDeleteRole = () => {
  const { deleteMutation } = useCUDRole();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};
