/* eslint-disable */
import { createAction, handleActions } from 'redux-actions';
import {
  error,
  generateName,
  generateStatus,
  loading,
  success
} from '@utils/reduxGenerate';

import { AppStorage } from '@core';
import { StorageKeys } from '@constants';

export const types = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  GET_AUTH_PROFILE: 'GET_AUTH_PROFILE',
  GET_AUTH_PROFILE_SUCCESS: 'GET_AUTH_PROFILE_SUCCESS',
  GET_AUTH_PROFILE_ERROR: 'GET_AUTH_PROFILE_ERROR',
  AUTH_USER_CLEAN_UP: 'AUTH_USER_CLEAN_UP',

  UPLOAD_AVATAR: 'UPLOAD_AVATAR',
  UPLOAD_AVATAR_SUCCESS: 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_ERROR: 'UPLOAD_AVATAR_ERROR',

  UPDATE_AUTH_PROFILE: 'UPDATE_AUTH_PROFILE',
  UPDATE_AUTH_PROFILE_SUCCESS: 'UPDATE_AUTH_PROFILE_SUCCESS',
  UPDATE_AUTH_PROFILE_ERROR: 'UPDATE_AUTH_PROFILE_ERROR'
};
export const actions = {
  changePassword: createAction(types.CHANGE_PASSWORD),
  changePasswordSuccess: createAction(types.CHANGE_PASSWORD_SUCCESS),
  changePasswordError: createAction(types.CHANGE_PASSWORD_ERROR),

  getAuthProfile: createAction(types.GET_AUTH_PROFILE),
  getAuthProfileSuccess: createAction(types.GET_AUTH_PROFILE_SUCCESS),
  getAuthProfileError: createAction(types.GET_AUTH_PROFILE_ERROR),
  authUserCleanUp: createAction(types.AUTH_USER_CLEAN_UP),

  uploadAvatar: createAction(types.UPLOAD_AVATAR),
  uploadAvatarSuccess: createAction(types.UPLOAD_AVATAR_SUCCESS),
  uploadAvatarError: createAction(types.UPLOAD_AVATAR_ERROR),

  updateAuthProfile: createAction(types.UPDATE_AUTH_PROFILE),
  updateAuthProfileSuccess: createAction(types.UPDATE_AUTH_PROFILE_SUCCESS),
  updateAuthProfileError: createAction(types.UPDATE_AUTH_PROFILE_ERROR)
};

const changePasswordActionName = generateName(types.CHANGE_PASSWORD);
const getAuthProfileActionName = generateName(types.GET_AUTH_PROFILE);
const uploadAvatarActionName = generateName(types.UPLOAD_AVATAR);
const updateAuthProfileActionName = generateName(types.UPDATE_AUTH_PROFILE);

export const selectors = {
  getAuthProfile: state => state.auth.authUser,
  uploadAvatarLoading: state => {
    return (
      // state.auth[uploadAvatarActionName].isLoading ||
      state.auth[updateAuthProfileActionName].isLoading
    );
  }
};

const defaultState = {
  authUser: AppStorage.get(StorageKeys.user),

  [changePasswordActionName]: { ...generateStatus() },
  [getAuthProfileActionName]: { ...generateStatus() },
  [uploadAvatarActionName]: { ...generateStatus() },
  [updateAuthProfileActionName]: { ...generateStatus() }
};

export const reducer = handleActions(
  {
    [types.AUTH_USER_CLEAN_UP]: (state, action) => {
      return {
        ...state,
        authUser: null
      };
    },

    [types.CHANGE_PASSWORD]: (state, action) => {
      return {
        ...state,
        [changePasswordActionName]: loading()
      };
    },
    [types.CHANGE_PASSWORD_SUCCESS]: (state, action) => {
      return {
        ...state,
        [changePasswordActionName]: success()
      };
    },
    [types.CHANGE_PASSWORD_ERROR]: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        [changePasswordActionName]: error(payload)
      };
    },

    [types.GET_AUTH_PROFILE]: (state, action) => {
      return {
        ...state,
        [getAuthProfileActionName]: loading()
      };
    },
    [types.GET_AUTH_PROFILE_SUCCESS]: (state, action) => {
      return {
        ...state,
        authUser: action.payload,
        [getAuthProfileActionName]: success()
      };
    },
    [types.GET_AUTH_PROFILE_ERROR]: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        [getAuthProfileActionName]: error(payload)
      };
    },
    [types.UPLOAD_AVATAR]: (state, action) => {
      return {
        ...state,
        [uploadAvatarActionName]: loading()
      };
    },
    [types.UPLOAD_AVATAR_SUCCESS]: (state, action) => {
      return {
        ...state,
        [uploadAvatarActionName]: success()
      };
    },
    [types.UPLOAD_AVATAR_ERROR]: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        [uploadAvatarActionName]: error(payload)
      };
    },

    [types.UPDATE_AUTH_PROFILE]: (state, action) => {
      return {
        ...state,
        [updateAuthProfileActionName]: loading()
      };
    },
    [types.UPDATE_AUTH_PROFILE_SUCCESS]: (state, action) => {
      return {
        ...state,
        authUser: action.payload,
        [updateAuthProfileActionName]: success()
      };
    },
    [types.UPDATE_AUTH_PROFILE_ERROR]: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        [updateAuthProfileActionName]: error(payload)
      };
    }
  },
  defaultState
);
