import React, { memo } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KGrid } from 'uikit';

import DataGroup from './DataGroup';
import Roles from './Roles';

const RolesIndex = () => {
  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.administration'), trans('routes.roles')]}
      />

      <KContainer.Card margin="0.75rem">
        <KGrid.Container alignItems="stretch">
          <KGrid.Item xs={12} sm={6} md={6}>
            <Roles />
          </KGrid.Item>

          <KGrid.Item xs={12} sm={6} md={6}>
            <DataGroup />
          </KGrid.Item>
        </KGrid.Container>
      </KContainer.Card>
    </>
  );
};

export default memo(RolesIndex);
