import { NotificationProvider, SystemSettingCode } from '@constants';
import { useFetchSetting, useResolverForm, useUpdateSetting } from '@hooks';
import { ValidationUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

interface IProps {
  hasHeight?: boolean;
}

interface IFormData {
  appWebId: string;
  appWebKey: string;
  appMobileId?: string;
  appMobileKey?: string;
}

const schema = yup.object().shape({
  appWebId: ValidationUtils.required(),
  appWebKey: ValidationUtils.required(),
  appMobileId: ValidationUtils.required(),
  appMobileKey: ValidationUtils.required()
});

const NotificationSetting = ({ hasHeight }: IProps) => {
  const { data: setting, isLoading } = useFetchSetting(
    SystemSettingCode.Notification
  );

  const { mutate, isLoading: updateLoading } = useUpdateSetting();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        appWebId: setting?.appWebId ?? '',
        appWebKey: setting?.appWebKey ?? '',
        appMobileId: setting?.appMobileId ?? '',
        appMobileKey: setting?.appMobileKey ?? ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        data: {
          ...setting,
          ...data
        },
        code: SystemSettingCode.Notification
      };

      mutate(mParams);
    },
    [mutate, setting]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KContainer.Card
        header={{
          icon: 'Notifications',
          title: trans('notification_setting'),
          rightNode: (
            <KButton.Icon icon="Save" type="submit" kind="primary" size="md" />
          )
        }}
        isLoading={isLoading || updateLoading}
      >
        <KContainer.View height={hasHeight ? 226 : undefined}>
          <KGrid.Container>
            <KGrid.Item xs={12}>
              <KInput.TextField
                name="notificationProvider"
                label={trans('provider_name')}
                value={trans(
                  `option.${NotificationProvider.OneSignal.toLowerCase()}`
                )}
                disabled
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="appWebId"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('web_app_id')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="appWebKey"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('web_app_key')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="appMobileId"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('mobile_app_id')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="appMobileKey"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('mobile_app_key')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KGrid.Container>
        </KContainer.View>
      </KContainer.Card>
    </KForm>
  );
};

export default memo(NotificationSetting);
