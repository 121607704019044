import { OperandTypes } from '@constants';
import { Prototype } from '@core';
import { ISearchConditionWithFormat } from '@dto';
import { useDidMount } from '@hooks';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from 'react';

interface IValue {
  conditions: ISearchConditionWithFormat[];

  setConditions: (
    value: ISearchConditionWithFormat[],
    onSuccess?: (d: any) => void
  ) => void;

  clear: () => void;
}

type Action = {
  type: string;
  payload?: any;
};

interface State {
  conditions: ISearchConditionWithFormat[];
}

const initState: State = {
  conditions: []
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setConditions':
      return {
        ...state,
        conditions: action.payload
      };

    case 'clear':
      return {
        ...state,
        conditions: []
      };

    default:
      return { ...initState };
  }
};

// @ts-ignore
const ReportContext = createContext<IValue>();

const ReportProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const setConditions = useCallback(
    (value: ISearchConditionWithFormat[], onSuccess?: (d: any) => void) => {
      const v = value.map(i => ({
        ...i,
        dataFormat:
          i.operandType === OperandTypes.DATE
            ? Prototype.date.formatD(i.data as any)
            : i.operandType === OperandTypes.DATETIME
              ? Prototype.date.formatDT(i.data as any)
              : i.operandType === OperandTypes.TIME
                ? Prototype.date.formatT(i.data as any)
                : i.data,
        minDateFormat:
          i.operandType === OperandTypes.DATE
            ? Prototype.date.formatD(i.minDate as any)
            : i.operandType === OperandTypes.DATETIME
              ? Prototype.date.formatDT(i.minDate as any)
              : i.operandType === OperandTypes.TIME
                ? Prototype.date.formatT(i.minDate as any)
                : i.minDate,
        maxDateFormat:
          i.operandType === OperandTypes.DATE
            ? Prototype.date.formatD(i.maxDate as any)
            : i.operandType === OperandTypes.DATETIME
              ? Prototype.date.formatDT(i.maxDate as any)
              : i.operandType === OperandTypes.TIME
                ? Prototype.date.formatT(i.maxDate as any)
                : i.maxDate
      }));
      dispatch({
        type: 'setConditions',
        payload: v
      });
      onSuccess?.(value);
    },
    []
  );

  const clear = useCallback(() => {
    dispatch({
      type: 'clear'
    });
  }, []);

  const value: IValue = useMemo(
    () => ({
      ...state,
      setConditions,
      clear
    }),
    [clear, setConditions, state]
  );

  useDidMount(() => {
    clear();
  });

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
};

const useReportContext = (): IValue => useContext(ReportContext);

export const withReportContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return React.memo((props: P) => {
    return (
      <ReportProvider>
        <WrappedComponent {...props} />
      </ReportProvider>
    );
  });
};

export default withReportContext;

export { useReportContext };
