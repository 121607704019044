import {
  InvoiceProvider,
  SystemSettingCode,
  generateOptions
} from '@constants';
import { useFetchSetting, useResolverForm, useUpdateSetting } from '@hooks';
import { ValidationUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

interface IProps {
  hasHeight?: boolean;
}

interface IFormData {
  invoiceProvider: string;
  xero: {
    xeroId: string;
    xeroSecret: string;
    xeroReceivableAccountCode: string;
    xeroReceivableDisbursementAccountCode: string;
    xeroReceivableBankAccountCode: string;

    xeroPayableAccountCode: string;
    xeroPayableDisbursementAccountCode: string;
    xeroPayableBankAccountCode: string;
  };
}

const schema = yup.object().shape({
  invoiceProvider: ValidationUtils.required()
  // xero: yup.object().shape({
  //   xeroId: yup.string(),
  //   xeroSecret: yup.string(),
  //   xeroAccountCode: yup.string(),
  //   xeroBankAccountCode: yup.string()
  // })
});

const InvoiceSetting = ({ hasHeight }: IProps) => {
  const { data: setting, isLoading } = useFetchSetting(SystemSettingCode.Xero);
  const { data: invoiceType, isLoading: invoiceTypeLoading } = useFetchSetting(
    SystemSettingCode.InvoiceSyncType,
    false
  );

  const { mutate, isLoading: updateLoading } = useUpdateSetting();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        invoiceProvider: invoiceType ?? InvoiceProvider.Xero,
        xero: {
          xeroId: setting?.xeroId ?? '',
          xeroSecret: setting?.xeroSecret ?? '',

          xeroReceivableAccountCode: setting?.xeroReceivableAccountCode ?? '',
          xeroReceivableDisbursementAccountCode:
            setting?.xeroReceivableDisbursementAccountCode ?? '',
          xeroReceivableBankAccountCode:
            setting?.xeroReceivableBankAccountCode ?? '',

          xeroPayableAccountCode: setting?.xeroPayableAccountCode ?? '',
          xeroPayableDisbursementAccountCode:
            setting?.xeroPayableDisbursementAccountCode ?? '',
          xeroPayableBankAccountCode: setting?.xeroPayableBankAccountCode ?? ''
        }
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        data: {
          ...setting,
          ...data.xero
        },
        code: SystemSettingCode.Xero
      };

      mutate(mParams);
    },
    [mutate, setting]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KContainer.Card
        header={{
          icon: 'Payment',
          title: trans('invoice_setting'),
          rightNode: (
            <KButton.Icon icon="Save" type="submit" kind="primary" size="md" />
          )
        }}
        isLoading={isLoading || updateLoading || invoiceTypeLoading}
      >
        <KContainer.View height={hasHeight ? 226 : undefined}>
          <KGrid.Container>
            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="invoiceProvider"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('provider_name')}
                      options={generateOptions(InvoiceProvider)}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={6}>
              <Controller
                control={methods.control}
                name="xero.xeroId"
                render={({ field }) => {
                  return (
                    <KInput.TextField {...field} label={trans('xero_id')} />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={6}>
              <Controller
                control={methods.control}
                name="xero.xeroSecret"
                render={({ field }) => {
                  return (
                    <KInput.TextField {...field} label={trans('xero_secret')} />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="xero.xeroReceivableAccountCode"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('xero_receivable_account_code')}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="xero.xeroReceivableDisbursementAccountCode"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('xero_receivable_disbursement_account_code')}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="xero.xeroReceivableBankAccountCode"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('xero_receivable_bank_account_code')}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="xero.xeroPayableAccountCode"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('xero_payable_account_code')}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="xero.xeroPayableDisbursementAccountCode"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('xero_payable_disbursement_account_code')}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="xero.xeroPayableBankAccountCode"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('xero_payable_bank_account_code')}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KGrid.Container>
        </KContainer.View>
      </KContainer.Card>
    </KForm>
  );
};

export default memo(InvoiceSetting);
