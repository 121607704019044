import { DATE_FORMAT_SHORT, ENDPOINTS, WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { Prototype } from '@core';
import { useDataTable, useGlobalTable } from '@hooks';
import { TableUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KLabel } from 'uikit';

import { useDeleteSchedule } from './helpers';

const Schedule = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const onShowSchedule = useCallback(
    (v: any) => {
      history.push(`${url}/${v.id}`);
    },
    [history, url]
  );

  const { onAlert, deleteLoading } = useDeleteSchedule();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('view'),
            icon: 'Visibility',
            onPress: () => onShowSchedule(item)
          },
          {
            title: trans('delete'),
            icon: 'Delete',
            onPress: () => onAlert({ id: item.id })
          }
        ])
      },
      {
        label: trans('schedule_date'),
        name: 'scheduleDate',
        options: {
          ...TableUtils.options.date(DATE_FORMAT_SHORT),
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowSchedule(item)}>
                {Prototype.date.toLocal(v)?.format(DATE_FORMAT_SHORT) ?? ''}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('last_updated_by'),
        name: 'updatedUsername'
      },
      {
        label: trans('last_updated_at'),
        name: 'updatedAt'
      }
    ],
    [onAlert, onShowSchedule]
  );

  const table = useDataTable({
    name: WebTable.SCHEDULE,
    apiURL: ENDPOINTS.schedule(),
    columnsFunc,
    showFilter: false
  });

  useGlobalTable(table);

  return (
    <>
      <KBreadcrumbs hasBackIcon={false} title={trans('routes.operation')} />

      <KContainer.Card margin="0.75rem">
        <DataTable {...table} isModifying={deleteLoading} />
      </KContainer.Card>
    </>
  );
};

export default memo(Schedule);
