import { ENDPOINTS, QUERY_KEYS, IssueStatus, WebTable } from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useCUDIssue = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.issue(),
    webTable: WebTable.ISSUE,
    mutationKeys: [[QUERY_KEYS.issue]]
  });
};

export const useFetchIssue = (id?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.issue, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.issue(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useUpdateStatusIssue = () => {
  return useMutationEnhancer<
    boolean | undefined,
    { id: string | number; status: IssueStatus }
  >({
    mutationKeys: [[QUERY_KEYS.issue]],
    mutationFn: async mParams => {
      const { id, status } = mParams;
      const res = await APIManager.request({
        url: ENDPOINTS.issue(':id/update-status', { id }),
        method: 'PUT',
        body: {
          issueStatus: status
        }
      });

      return res.success;
    }
  });
};

export const useCUDIssueReply = (id: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.issue(':id', { id }),
    webTable: WebTable.ISSUE,
    mutationKeys: [[QUERY_KEYS.issue]]
  });
};
