import { Trans, t } from '@lingui/macro';
import { auth } from '@reducers';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { GridContainer, GridItem, UIKit } from 'components';
import BasicModal from 'components/Modal/BasicModal';

const schema = yup.object().shape({
  currentPassword: yup.string().required(t`Current Password is required`),
  newPassword: yup.string().required(t`New Password is required`)
});

export default function FormDialog({ open, handleClose }) {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.auth.changePassword.isLoading);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: ''
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch(
        auth.actions.changePassword({
          values: {
            password: values.currentPassword,
            newPassword: values.newPassword
          },
          callback: () => {
            handleClose();
          }
        })
      );
    }
  });

  const { values, touched, errors } = formik;

  React.useEffect(() => {
    formik.resetForm();
  }, [open]);

  return (
    <BasicModal
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true
      }}
      open={open}
      handleClose={handleClose}
      title={t`Change password`}
      titleId="change-password-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <UIKit.PasswordInput
              name="currentPassword"
              label={t`Current Password`}
              value={values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={touched.currentPassword && Boolean(errors.currentPassword)}
              helperText={touched.currentPassword && errors.currentPassword}
              fullWidth
              inputProps={{ tabIndex: '1' }}
              autoFocus
              autoComplete="current-password"
            />
          </GridItem>

          <GridItem xs={12}>
            <UIKit.PasswordInput
              name="newPassword"
              label={t`New Password`}
              value={values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={touched.newPassword && Boolean(errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
              fullWidth
              inputProps={{ tabIndex: '2' }}
              autoComplete="new-password"
            />
          </GridItem>

          <GridItem xs={12} style={{ textAlign: 'right' }}>
            <UIKit.Button type="submit" isLoading={isLoading}>
              <Trans>Save</Trans>
            </UIKit.Button>
          </GridItem>
        </GridContainer>
      </form>
    </BasicModal>
  );
}
