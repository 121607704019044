import { RoutingType } from '@constants';

const useRoutingType = (v: RoutingType) => {
  const isWaitDepotDropOff = [
    RoutingType.WaitDepotEmptyDropOff,
    RoutingType.WaitDepotFullDropOff,
    RoutingType.WaitDepotMiscDropOff
  ].includes(v);

  const isWaitDepotStopBy = [
    RoutingType.WaitDepotEmptyStopBy,
    RoutingType.WaitDepotFullStopBy,
    RoutingType.WaitDepotMiscStopBy
  ].includes(v);

  const isWaitDepotPickUp = [
    RoutingType.WaitDepotEmptyPickUp,
    RoutingType.WaitDepotFullPickUp,
    RoutingType.WaitDepotMiscPickUp
  ].includes(v);

  const isMainWarehouse = [
    RoutingType.DeliveryWarehouse,
    RoutingType.PickupWarehouse
  ].includes(v);

  const isConditionalWarehouse = [
    RoutingType.DeliveryWarehouseEmpty,
    RoutingType.PickupWarehouseFull
  ].includes(v);

  const isDestinationWarehouse = RoutingType.Warehouse === v;

  return {
    // WAIT DEPOT
    isWaitDepotDropOff,
    isWaitDepotStopBy,
    isWaitDepotPickUp,
    isWaitDepot: isWaitDepotDropOff || isWaitDepotStopBy || isWaitDepotPickUp,

    // WAREHOUSE
    isMainWarehouse,
    isConditionalWarehouse,
    isDestinationWarehouse,
    isWarehouse:
      isMainWarehouse || isConditionalWarehouse || isDestinationWarehouse,

    // Others
    isCTO: v === RoutingType.Cto,
    isRailHead: v === RoutingType.RailHead,
    isEmptyYard: v === RoutingType.EmptyYard
  };
};

export default useRoutingType;
