import { DashboardSection } from '@constants';
import { Prototype } from '@core';
import { useFetchDashboardIssuesDriver } from '@hooks';
import { camelCase, startCase } from 'lodash';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KChip, KColors, KContainer, KImage, KLabel, KLoading } from 'uikit';

import { useDashboardIssues, useDashboardItem } from '../helpers';

interface IProps {
  date: string | null;
}

const Driver = ({ date }: IProps) => {
  const { onCheckDashboardItem } = useDashboardItem();

  const { data = [], isLoading } = useFetchDashboardIssuesDriver(
    date,
    onCheckDashboardItem(
      `${DashboardSection.Issues}_DRIVER` as DashboardSection
    )
  );

  const { onExtraField } = useDashboardIssues();

  const renderRow = useCallback(
    (item: any) => {
      const extraField = onExtraField(item.issueType);

      return (
        <KContainer.View
          row
          marginB={'0.75rem'}
          key={`d-${item.id}-${item.issueType}`}
        >
          <KContainer.View flex>
            <KLabel.Text
              color={KColors.primary.normal}
              isLink
              to={`/admin/staff/${item.id}`}
              target="_blank"
            >
              {item.displayName}
            </KLabel.Text>

            {item.phoneNumber && (
              <KContainer.View row alignItems marginT={'0.25rem'}>
                <KImage.MuiIcon
                  icon="Phone"
                  color={KColors.primary.normal}
                  style={{
                    width: 12,
                    height: 12
                  }}
                />

                <KLabel.Text marginL={'0.25rem'} color={KColors.primary.normal}>
                  {item.phoneNumber}
                </KLabel.Text>
              </KContainer.View>
            )}
          </KContainer.View>

          <KContainer.View flex>
            <KChip
              key={`driver-issue-${item.id}`}
              background={KColors.palette.warning.w50}
              brC={KColors.warning.normal}
              label={startCase(camelCase(item.issueType)).replace(
                /MSIC/i,
                'MSIC'
              )}
              color={KColors.warning.normal}
              style={{ whiteSpace: 'nowrap' }}
            />

            {extraField && (
              <KContainer.View marginT={'0.25rem'}>
                <KLabel.Text typo="TextSmNormal" color={'#7B809A'}>
                  {trans('date')}: {Prototype.date.formatD(item[extraField])}
                </KLabel.Text>
              </KContainer.View>
            )}
          </KContainer.View>
        </KContainer.View>
      );
    },
    [onExtraField]
  );

  if (!isLoading && data.length === 0) {
    return (
      <KContainer.View dp="flex" center flex>
        <KLabel.Text typo="TextXNmMedium">{trans('no_data_found')}</KLabel.Text>
      </KContainer.View>
    );
  }

  return (
    <>
      {isLoading && <KLoading />}

      <KContainer.View dp="flex" flex position="relative">
        <KContainer.View row alignItems>
          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">{trans('driver')}</KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">{trans('issue')}</KLabel.Text>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View dp="flex" flex marginT={'0.75rem'}>
          {data.map(i => renderRow(i))}
        </KContainer.View>
      </KContainer.View>
    </>
  );
};

export default memo(Driver);
