import { SystemSettingCode } from '@constants';
import { useFetchSetting, useResolverForm, useUpdateSetting } from '@hooks';
import { ValidationUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput, KListItem } from 'uikit';
import * as yup from 'yup';

interface IProps {
  hasHeight?: boolean;
}

interface IFormData {
  smtpHost: string;
  smtpProtocol: string;
  smtpPort: string;
  enableTls: boolean;
  username: string;
  password: string;
}

const schema = yup.object().shape({
  smtpProtocol: ValidationUtils.required(),
  smtpPort: ValidationUtils.required(),
  username: ValidationUtils.required(),
  password: ValidationUtils.required()
});

const EmailSetting = ({ hasHeight }: IProps) => {
  const { data: setting, isLoading } = useFetchSetting(SystemSettingCode.Email);

  const { mutate, isLoading: updateLoading } = useUpdateSetting();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        smtpHost: setting?.smtpHost ?? '',
        smtpProtocol: setting?.smtpProtocol ?? '',
        smtpPort: setting?.smtpPort ?? '',
        enableTls: setting?.enableTls ?? false,
        username: setting?.username ?? '',
        password: setting?.password ?? ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        data,
        code: SystemSettingCode.Email
      };

      mutate(mParams);
    },
    [mutate]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KContainer.Card
        header={{
          icon: 'EmailOutlined',
          title: trans('email_setting'),
          rightNode: (
            <KButton.Icon icon="Save" type="submit" kind="primary" size="md" />
          )
        }}
        isLoading={isLoading || updateLoading}
      >
        <KContainer.View height={hasHeight ? 272 : undefined}>
          <KGrid.Container>
            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="smtpHost"
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('provider_name')}
                      disabled
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="smtpProtocol"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('protocol')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="smtpPort"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('port')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <Controller
                control={methods.control}
                name="enableTls"
                render={({ field }) => {
                  return (
                    <KListItem.Checkbox
                      {...field}
                      label={trans('enabled_tls')}
                      checked={field.value}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="username"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('username')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={12}>
              <Controller
                control={methods.control}
                name="password"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.Password
                      {...field}
                      label={trans('password')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KGrid.Container>
        </KContainer.View>
      </KContainer.Card>
    </KForm>
  );
};

export default memo(EmailSetting);
