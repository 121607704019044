import {
  ActionType,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  SignatureSignType,
  Status,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { UIUtils } from '@utils';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useUploadFile,
  useQueryEnhancer
} from '../core';

export const useFetchEquipmentFaultyReport = (
  id?: number,
  equipmentId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentFaultyReport,
      equipmentId ? parseInt(equipmentId as string) : null,
      id
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/faulty-report/:faultyReportId', {
          id: equipmentId,
          faultyReportId: id
        })
      });
      return res.data;
    },
    enabled: !!id && !!equipmentId
  });
};

export const useFetchEquipmentLatestFaultyReport = (
  equipmentId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentLatestFaultyReport,
      equipmentId ? parseInt(equipmentId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/faulty-report', {
          id: equipmentId
        }),
        body: {
          sortBy: 'createdAt:DESC',
          isCancelled: false,
          size: 1
        }
      });

      return res.data?.data?.[0];
    },
    enabled: !!equipmentId
  });
};

export const useFetchFaultyReportActionList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.faultyReportActionList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.action(),
        body: {
          status: Status.Active,
          type: ActionType.Faulty,
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useFetchFaultyReportLevelOfFaultList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.faultyReportLevelOfFaultList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.levelOfFault(),
        body: {
          status: Status.Active,
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useCUDEquipmentFaultyReport = (equipmentId?: string | number) => {
  return useCUDMutationEnhancer({
    endPoint: ENDPOINTS.equipment(':equipmentId/faulty-report', {
      equipmentId
    }),
    webTable: WebTable.EQUIPMENT_FAULTY_REPORT,
    dependentWebTable: [WebTable.FAULTY_REPORT],
    noDismiss: true,
    isPage: true,
    mutationKeys: [[QUERY_KEYS.equipmentFaultyReport]],
    deleteWithoutRefetch: true
  });
};

export const useSignFaultyReport = (signType: SignatureSignType) => {
  const { mutateAsync: mutateFile } = useUploadFile();

  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.equipmentFaultyReport]],
    mutationFn: async data => {
      const { blob, equipmentId, id, ...rest } = data;
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob);
        const fRes = await mutateFile(formData);

        if (fRes?.id) {
          rest[`${signType.toLowerCase()}SignatureId`] = fRes.id;
        }
      }

      const res = await APIManager.request({
        url: ENDPOINTS.equipment(
          ':id/faulty-report/:faultyReportId/:signType',
          {
            id: equipmentId,
            faultyReportId: id,
            signType: signType.toLowerCase()
          }
        ),
        method: 'PUT',
        body: rest
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        tableRef?.[WebTable.EQUIPMENT_FAULTY_REPORT]?.init();
      }
    }
  });
};
