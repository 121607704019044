import {
  ClientType,
  ENDPOINTS,
  JobType,
  PAGE_SIZE_DEFAULT_MAX,
  Status,
  generateOptions
} from '@constants';
import {
  useAlertMutationEnhancer,
  useResolverForm,
  useFetchQuotation,
  useCUDQuotationRequest,
  useFetchZoneFromSuburb,
  useFetchQuotationRequest
} from '@hooks';
import { UIUtils, ValidationUtils, RequestUtils } from '@utils';
import { isEmpty } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KContainer,
  KGrid,
  KForm,
  KInput,
  KListItem,
  KLoading
} from 'uikit';
import * as yup from 'yup';

import ChooseFromTemplate from './components/ChooseFromTemplate';
import FileClientRate from './components/FileClientRate';
import Charges from './Form.Charges';
import { IFormData } from './helpers';

interface IProps {
  item?: any;
  quotationId: number | string;
}

const schema = yup.object().shape({
  suburb: ValidationUtils.requiredAny(),
  zone: ValidationUtils.requiredAny(),
  dropMode: ValidationUtils.requiredAny(),
  containerSize: ValidationUtils.requiredAny(),
  grossWeight: ValidationUtils.requiredNum()
});

const Form = ({ item: localItem, quotationId }: IProps) => {
  const { data: quotation } = useFetchQuotation(quotationId);

  const { data: remoteItem, isLoading } = useFetchQuotationRequest(
    quotationId,
    localItem?.id
  );

  const item = useMemo(() => remoteItem ?? localItem, [localItem, remoteItem]);

  const isAgent = useMemo(() => {
    return (
      quotation?.client?.clientTypes?.some((i: any) =>
        [ClientType.ForwarderAgent, ClientType.CustomsAgent].includes(i.code)
      ) ?? false
    );
  }, [quotation?.client?.clientTypes]);

  const {
    createMutation: { mutate: mutateCreate },
    updateMutation: { mutate: mutateUpdate },
    deleteMutation,
    modifyLoading
  } = useCUDQuotationRequest(quotationId);

  const { mutate: mutateZone, isLoading: zoneLoading } =
    useFetchZoneFromSuburb();

  const { onAlert, isLoading: deleteLoading } = useAlertMutationEnhancer({
    mutation: deleteMutation
  } as any);

  const isEdit = !isEmpty(item);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        suburb: item?.suburb,
        zone: item?.zone,
        jobType: item?.jobType ?? JobType.Import,
        dropMode: item?.dropMode,
        containerSize: item?.containerSize,
        grossWeight: item?.grossWeight ?? '',
        isDamagedGood: item?.isDamagedGood ?? false,
        // isRefrigeratedStorage: item?.isRefrigeratedStorage ?? false,
        chargeCodeTemplate: item?.chargeCodeTemplate,
        charges: item?.quotationRequestCharges ?? [],
        status: item?.status ?? Status.Active
      }
    }
  });

  const [
    zone,
    jobType,
    dropMode,
    containerSize,
    grossWeight,
    isDamagedGood,
    // isRefrigeratedStorage,
    charges
  ] = useWatch({
    control: methods.control,
    name: [
      'zone',
      'jobType',
      'dropMode',
      'containerSize',
      'grossWeight',
      'isDamagedGood',
      // 'isRefrigeratedStorage',
      'charges'
    ]
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { charges: _charges, ...rest } = data;

      const mParams: any = {
        id: item?.id,
        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'suburb',
            'zone',
            'dropMode',
            'containerSize',
            'chargeCodeTemplate'
          ]
        }),
        quotationId,
        quotationRequestCharges: _charges
      };

      if (!!mParams.id) {
        mutateUpdate(mParams);
      } else {
        mutateCreate(mParams);
      }
    },
    [item?.id, mutateCreate, mutateUpdate, quotationId]
  );

  const onDelete = useCallback(() => {
    onAlert({ id: item?.id });
  }, [item?.id, onAlert]);

  const onFetchZoneFromSuburb = useCallback(
    (v: any) => {
      if (v) {
        const mParams = {
          suburbId: v.id,
          shipmentType: quotation?.shipmentType
        };
        mutateZone(mParams, {
          onSuccess: (data: any) => {
            methods.setValue('zone', data ?? null);
            methods.trigger('zone');
          }
        });
      } else {
        methods.setValue('zone', null);
      }
    },
    [methods, mutateZone, quotation?.shipmentType]
  );

  const fParams = useMemo(() => {
    return {
      zoneId: zone?.id,
      jobType: jobType,
      shipmentType: quotation?.shipmentType,
      dropModeId: dropMode?.id,
      containerSizeId: containerSize?.id,
      grossWeight: grossWeight,
      isDamagedGood: isDamagedGood,
      // isRefrigeratedStorage: isRefrigeratedStorage,
      selectedLevelNumber: 1,
      excludeIds: charges.map(i => i.companyTariffId),
      isAgent,
      clientId: quotation?.clientId
    };
  }, [
    charges,
    containerSize?.id,
    dropMode?.id,
    grossWeight,
    isAgent,
    isDamagedGood,
    jobType,
    quotation?.clientId,
    quotation?.shipmentType,
    zone?.id
  ]);

  const onTemplateSuccess = useCallback(
    (v: any) => {
      const newCharges = v?.charges;
      methods.setValue('charges', newCharges);
      methods.setValue('chargeCodeTemplate', v?.template);
    },
    [methods]
  );

  const onShowTemplate = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      UIUtils.popup.open({
        title: trans('choose_from_template'),
        maxWidth: 'md',
        content: () => (
          <ChooseFromTemplate fParams={fParams} onSuccess={onTemplateSuccess} />
        )
      });
    }
  }, [fParams, methods, onTemplateSuccess]);

  const onFcr = useCallback(() => {
    UIUtils.popup.open({
      title: trans('file_client_rate'),
      maxWidth: 'md',
      content: () => (
        <FileClientRate
          isAgent={isAgent}
          quotation={quotation}
          quotationRequest={item}
        />
      )
    });
  }, [isAgent, item, quotation]);

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container position="relative">
          {isLoading && <KLoading />}

          <KGrid.Item xs={3}>
            <Controller
              name="suburb"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('suburb')}
                    onChange={(v: any) => {
                      methods.setValue('suburb', v);
                      onFetchZoneFromSuburb(v);
                    }}
                    apiURL={ENDPOINTS.suburb()}
                    getOptionLabel={(o: any) => `${o.name} (${o.postcode})`}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="zone"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('zone')}
                    value={field.value?.name ?? ''}
                    disabled
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="jobType"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('job_type')}
                    options={generateOptions(JobType)}
                    // onChange={e => onChangeJobType(e.target.value as JobType)}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="dropMode"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('drop_mode')}
                    apiURL={ENDPOINTS.dropMode()}
                    apiParams={{
                      size: PAGE_SIZE_DEFAULT_MAX
                    }}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="containerSize"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('container_size')}
                    apiURL={ENDPOINTS.containerSize()}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="grossWeight"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('with_unit.gross_weight')}
                    type="number"
                    required
                    message={error?.message}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="isDamagedGood"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('hazardous_goods')}
                    checked={field.value}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item>

          {/* <KGrid.Item xs={3}>
            <Controller
              name="isRefrigeratedStorage"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('refrigerated_storage_applied')}
                    checked={field.value}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </KGrid.Item> */}

          <KContainer.RenderWhen>
            <KContainer.RenderWhen.If isTrue={!isEdit}>
              <KGrid.Item xs={12}>
                <KButton.Solid
                  title={trans('choose_from_template')}
                  icon="BrightnessAuto"
                  isLoading={zoneLoading}
                  onPress={onShowTemplate}
                />
              </KGrid.Item>
            </KContainer.RenderWhen.If>
          </KContainer.RenderWhen>

          <KGrid.Item xs={12}>
            <Charges item={item} fParams={fParams} />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KContainer.View row alignItems justifyContent="space-between">
              <KContainer.RenderWhen>
                <KContainer.RenderWhen.If isTrue={isEdit}>
                  <KButton.Solid
                    onPress={() => onFcr()}
                    title={trans('file_client_rate')}
                    disabled={methods.formState.isDirty}
                  />
                </KContainer.RenderWhen.If>

                <KContainer.RenderWhen.If isTrue>
                  <KContainer.View />
                </KContainer.RenderWhen.If>
              </KContainer.RenderWhen>

              <KContainer.View row alignItems justifyContent="flex-end">
                <KContainer.RenderWhen>
                  <KContainer.RenderWhen.If isTrue={isEdit}>
                    <KButton.Solid
                      kind="secondary"
                      isLoading={deleteLoading}
                      disabled={modifyLoading}
                      onPress={onDelete}
                      marginR="0.5rem"
                      title={trans('delete')}
                    />
                  </KContainer.RenderWhen.If>
                </KContainer.RenderWhen>

                <KButton.Solid
                  type="submit"
                  isLoading={modifyLoading}
                  disabled={deleteLoading}
                  title={trans('save')}
                />
              </KContainer.View>
            </KContainer.View>
          </KGrid.Item>
        </KGrid.Container>
      </KForm>
    </FormProvider>
  );
};

export default memo(Form);
