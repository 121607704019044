import {
  backgroundColor1Dark,
  blackColor,
  boxShadow,
  defaultFont,
  drawerMiniWidth,
  drawerWidth,
  grayColor,
  hexToRgb,
  primaryBlack,
  primaryGreen,
  successColor,
  transition,
  whiteColor
} from '@assets/jss/material-dashboard-react.js';
import constants from '@constants';

const sidebarStyle = theme => ({
  drawerPaperRTL: {
    [theme.breakpoints.up('md')]: {
      left: 'auto !important',
      right: '0 !important'
    },
    [theme.breakpoints.down('sm')]: {
      left: '0  !important',
      right: 'auto !important'
    }
  },
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    // overflow: 'auto',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0'
    }
  },
  blackBackground: {
    color: whiteColor,
    '&:after': {
      background:
        theme.palette.type === constants.THEME.LIGHT
          ? primaryBlack
          : backgroundColor1Dark
    }
  },

  drawerPaperMini: {
    [theme.breakpoints.up('md')]: {
      width: drawerMiniWidth + 'px!important'
    }
  },
  logo: {
    // padding: '15px 0px',
    paddingTop: '12px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: '4'
  },
  logoNormal: {
    ...defaultFont,
    transition: 'all 300ms linear',
    display: 'block',
    opacity: '1',
    transform: 'translate3d(0px, 0, 0)',
    textTransform: 'uppercase',
    padding: '5px 0px',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    fontWeight: '400',
    lineHeight: '27px',
    overflow: 'hidden',
    textAlign: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor
    }
  },
  logoNormalRTL: {
    textAlign: 'right'
  },
  img: {
    verticalAlign: 'middle',
    border: '0'
  },
  imgMini: {
    [theme.breakpoints.up('md')]: {
      width: '35px'
    }
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear'
  },
  list: {
    marginTop: '12px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "'
    },
    '&:after': {
      clear: 'both'
    },
    '&::-webkit-scrollbar': {
      backgroundColor:
        theme.palette.type === constants.THEME.LIGHT
          ? primaryBlack
          : backgroundColor1Dark
    }
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: whiteColor,
    //   color: whiteColor
    // }
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0'
  },
  itemLink: {
    paddingLeft: '10px',
    paddingRight: '10px',
    transition: 'all 300ms linear',
    margin: '8px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
      boxShadow: 'none'
    },
    '&,&:hover,&:focus': {
      color: 'inherit'
    }
  },
  itemIcon: {
    color: 'inherit',
    width: '24px',
    height: '20px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '12px',
    textAlign: 'center',
    verticalAlign: 'middle',
    opacity: '0.8'
  },
  itemIconRTL: {
    float: 'right',
    marginLeft: '15px',
    marginRight: '1px'
  },
  itemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '27px',
    fontSize: '13px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
    padding: '0 14px !important'
  },
  itemTextRTL: {
    marginRight: '45px',
    textAlign: 'right'
  },
  itemTextMini: {
    [theme.breakpoints.up('md')]: {
      transform: 'translate3d(-25px, 0, 0)',
      opacity: '0'
    }
  },
  itemTextMiniRTL: {
    [theme.breakpoints.up('md')]: {
      transform: 'translate3d(25px, 0, 0) !important'
    }
  },
  collapseList: {
    marginTop: '0',
    '& $caret': {
      marginTop: '8px'
    },
    marginLeft: '32px'
  },
  collapseListMini: {
    marginLeft: '0'
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0'
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
    boxShadow: 'none'
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
      boxShadow: 'none'
    },
    '&,&:hover,&:focus': {
      color: 'inherit'
    }
  },
  collapseItemMini: {
    // color: 'inherit',
    // ...defaultFont,
    // textTransform: 'uppercase',
    // width: '30px',
    // marginRight: '15px',
    // textAlign: 'center',
    // letterSpacing: '1px',
    // position: 'relative',
    // float: 'left',
    // display: 'inherit',
    // transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    // fontSize: '14px'
    // paddingLeft: '8px'
  },
  collapseItemMiniRTL: {
    float: 'right',
    marginLeft: '30px',
    marginRight: '1px'
  },
  collapseItemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '27px',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '13px'
  },
  collapseItemTextRTL: {
    textAlign: 'right'
  },
  collapseItemTextMiniRTL: {
    [theme.breakpoints.up('md')]: {
      transform: 'translate3d(25px, 0, 0) !important'
    }
  },
  collapseItemTextMini: {
    [theme.breakpoints.up('md')]: {
      transform: 'translate3d(-25px, 0, 0)',
      opacity: '0'
    }
  },
  caret: {
    marginTop: '12px',
    position: 'absolute',
    right: '6px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent'
  },
  caretRTL: {
    left: '11px',
    right: 'auto'
  },
  caretActive: {
    transform: 'rotate(180deg)'
  },
  green: {
    '&,&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: primaryGreen,
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(successColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 8px -5px rgba(' +
        hexToRgb(successColor[0]) +
        ',.2)'
    }
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 55px)',
    overflow: 'auto',
    width: drawerWidth,
    zIndex: '4',
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    marginBottom: '45px'
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important'
  },
  copyright: {
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: 10,
    width: drawerWidth,
    ...defaultFont,
    fontSize: '12px'
  },
  copyrightMini: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

export default sidebarStyle;
