import { DashboardSection } from '@constants';
import { Prototype } from '@core';
import { useFetchDashboardIssuesEquipment } from '@hooks';
import { camelCase, startCase } from 'lodash';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel, KLoading } from 'uikit';

import { useDashboardIssues, useDashboardItem } from '../helpers';

interface IProps {
  date: string | null;
}

const Equipment = ({ date }: IProps) => {
  const { onCheckDashboardItem } = useDashboardItem();

  const { data = [], isLoading } = useFetchDashboardIssuesEquipment(
    date,
    onCheckDashboardItem(
      `${DashboardSection.Issues}_EQUIPMENT` as DashboardSection
    )
  );

  const { onExtraField } = useDashboardIssues();

  const renderRow = useCallback(
    (item: any) => {
      const extraField = onExtraField(item.issueType);

      return (
        <KContainer.View
          row
          marginB={'0.75rem'}
          key={`e-${item.id}-${item.issueType}`}
        >
          <KContainer.View flex>
            <KLabel.Text
              color={KColors.primary.normal}
              isLink
              to={`/admin/equipment/${item.id}`}
              target="_blank"
            >
              {item.name}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View flex>
            <KLabel.Text color={'#344767'}>
              {item.equipmentType?.name ?? ''}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View flex={2}>
            <KChip
              key={`equipment-issue-${item.id}`}
              background={KColors.palette.warning.w50}
              brC={KColors.warning.normal}
              label={startCase(camelCase(item.issueType))}
              color={KColors.warning.normal}
              style={{ whiteSpace: 'nowrap' }}
            />

            {extraField && (
              <KContainer.View marginT={'0.25rem'}>
                <KLabel.Text typo="TextSmNormal" color={'#7B809A'}>
                  {trans('date')}: {Prototype.date.formatD(item[extraField])}
                </KLabel.Text>
              </KContainer.View>
            )}
          </KContainer.View>
        </KContainer.View>
      );
    },
    [onExtraField]
  );

  if (!isLoading && data.length === 0) {
    return (
      <KContainer.View dp="flex" center flex>
        <KLabel.Text typo="TextXNmMedium">{trans('no_data_found')}</KLabel.Text>
      </KContainer.View>
    );
  }

  return (
    <>
      {isLoading && <KLoading />}

      <KContainer.View dp="flex" flex>
        <KContainer.View row alignItems>
          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">{trans('equipment')}</KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex>
            <KLabel.Text typo="TextXNmMedium">
              {trans('equipment_type')}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View dp="flex" flex={2}>
            <KLabel.Text typo="TextXNmMedium">{trans('issue')}</KLabel.Text>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View dp="flex" flex marginT={'0.75rem'}>
          {data.map(i => renderRow(i))}
        </KContainer.View>
      </KContainer.View>
    </>
  );
};

export default memo(Equipment);
