import { growthBook } from '@constants';
import { get } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMount } from 'uikit-common';

import { useUser } from '../auth';

export const useSyncGrowthbook = () => {
  const { account } = useUser();
  const isMounted = useRef(false);
  const [isMaintained, setIsMaintained] = useState(false);

  const effect = useCallback(async () => {
    try {
      console.log('GROWTHBOOK LOAD FEATURES');
      await growthBook.loadFeatures({
        autoRefresh: true,
        skipCache: true
      });
      console.log('GROWTHBOOK LOAD FEATURES SUCCESSFULLY');

      if (!isMounted.current) {
        isMounted.current = true;
      }
    } catch (error) {
      console.log('GROWTHBOOK LOAD FEATURES FAILED');
      console.warn(error);
    }
  }, []);

  useMount(() => {
    effect();
  });

  useEffect(() => {
    console.log('GROWTHBOOK SET ATTRIBUTES');
    growthBook.setAttributes({
      id: get(account, 'id'),
      email: get(account, 'email'),
      company: get(account, 'companyId')
    });
  }, [account]);

  return { isMaintained };
};
