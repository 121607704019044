import { ENDPOINTS, ReportExcelType } from '@constants';
import { normalizeFilterData } from '@containers/DataTable/helpers';
import { ISearchConditionWithFormat } from '@dto';
import { useReportTransportJob, useResolverForm } from '@hooks';
import { ValidationUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel
} from 'uikit';
import * as yup from 'yup';

interface IProps {
  conditions: ISearchConditionWithFormat[];
  reportType: ReportExcelType;
}

interface IFormData {
  conditions: ISearchConditionWithFormat[];
  document?: any;
  client?: any;
}

const schema = yup.object().shape({
  document: ValidationUtils.requiredAny(),
  client: yup
    .mixed()
    .nullable()
    .when('document', ([v], _schema) => {
      return !!v?.hasClient ? _schema.required() : _schema;
    })
});

const Print = ({ conditions: _conditions, reportType }: IProps) => {
  const { mutate, isLoading } = useReportTransportJob();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      defaultValues: {
        conditions: _conditions,
        document: undefined,
        client: undefined
      }
    }
  });

  const [conditions, document] = useWatch({
    control: methods.control,
    name: ['conditions', 'document']
  });

  const { fields, remove } = useFieldArray({
    control: methods.control,
    name: 'conditions',
    keyName: 'formId'
  });

  const renderConditionItem = useCallback(
    (item: ISearchConditionWithFormat, index: number) => {
      const {
        label,
        dataFormat,
        operatorType,
        min,
        max,
        maxDateFormat,
        minDateFormat
      } = item;

      return (
        <KContainer.View
          row
          alignItems
          br="2x"
          brC={KColors.border.normal}
          brW={1}
          marginR="0.5rem"
          paddingV="0.25rem"
          paddingH="0.5rem"
          marginB="0.5rem"
        >
          <KLabel.Text>
            {`'${label}'`}{' '}
            {trans(`advanced_search.${operatorType.toLowerCase()}`)}{' '}
            {dataFormat
              ? `'${dataFormat}'`
              : [min, max, minDateFormat, maxDateFormat]
                  .filter(i => i)
                  .map(i => `'${i}'`)
                  .join(' and ')}
          </KLabel.Text>

          <KButton.Icon
            icon="Close"
            onPress={() => remove(index)}
            size="sm"
            tight
            marginL="0.5rem"
          />
        </KContainer.View>
      );
    },
    [remove]
  );

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { document: _document, client } = data;

      const mParams = {
        searchCondition: JSON.stringify(normalizeFilterData(data.conditions)),
        reportExcelType: _document.code,
        reportClientId: _document.hasClient ? client?.id : undefined
      };

      mutate(mParams);
    },
    [mutate]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={conditions.length > 0}>
            <KGrid.Item xs={12}>
              <KContainer.View
                row
                alignItems
                dp="flex"
                flexW="wrap"
                marginB={-8 as any}
              >
                <KButton.Outline
                  key="reset-filter"
                  kind="secondary"
                  icon="Close"
                  size="sm"
                  onPress={() => methods.setValue('conditions', [])}
                  title={trans('reset_filter')}
                  marginB="0.5rem"
                  marginR="0.5rem"
                />

                {fields.map((d, idx) => (
                  <React.Fragment key={d.formId}>
                    {renderConditionItem(d, idx)}
                  </React.Fragment>
                ))}
              </KContainer.View>
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KGrid.Item xs={12}>
          <Controller
            name="document"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('document')}
                  apiURL={ENDPOINTS.reportExcelType()}
                  apiParams={{
                    type: reportType
                  }}
                  getOptionSelected={(o: any, v: any) => o.code === v?.code}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={!!document?.hasClient}>
            <KGrid.Item xs={5}>
              <Controller
                name="client"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.Autocomplete
                      {...field}
                      label={trans('client_code')}
                      apiURL={ENDPOINTS.client()}
                      getOptionLabel={(o: any) => o?.code ?? ''}
                      hasAddNew
                      addNewKey="code"
                      addNewURL="clients"
                      hasEdit
                      editURL="clients"
                      inputProps={{
                        required: true,
                        message: error?.message
                      }}
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={7}>
              <Controller
                name="client"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.Autocomplete
                      {...field}
                      label={trans('client_name')}
                      apiURL={ENDPOINTS.client()}
                      hasAddNew
                      addNewURL="clients"
                      hasEdit
                      editURL="clients"
                      inputProps={{
                        required: true,
                        message: error?.message
                      }}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid
            type="submit"
            title={trans('download')}
            isLoading={isLoading}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(Print);
