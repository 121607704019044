import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  SafetyCheckType,
  WebTable
} from '@constants';
import APIManager from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

export * from './message';
export * from './map';

interface IFetchSafetyCheckOptions {
  scheduleDriverId?: number;
  scheduleId?: number;
  driverId?: number;
  truckId?: number;
  trailerId?: number;
}

export const useFetchScheduleDriverStatistic = (id?: number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.scheduleDriverStatistic, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleDriver(':id/statistic', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchScheduleDriverTripList = (
  scheduleId: number,
  driverId: number
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.scheduleDriverTripList, scheduleId, driverId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTrip('planning'),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          scheduleId,
          driverId,
          includeSafetyCheck: true,
          includeConversationUnreadCount: true,
          includeReportStatus: true
        }
      });

      return res.data?.data;
    },
    enabled: !!scheduleId && !!driverId
  });
};

export const useFetchCheckItemList = (
  type: SafetyCheckType = SafetyCheckType.Driver
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.checkItemList, type],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.checkItem(),
        body: {
          type,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data;
    }
  });
};

export const useFetchSafetyCheck = (
  type: SafetyCheckType = SafetyCheckType.Driver,
  options: IFetchSafetyCheckOptions
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.safetyCheck, type, JSON.stringify(options)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.safetyCheck(),
        body: {
          ...options,
          type,
          size: 1
        }
      });

      return res.data?.data?.[0] ?? null;
    }
  });
};

export const useCUDSafetyCheck = (noDismiss?: boolean) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.safetyCheck(),
    webTable: WebTable.SCHEDULE_DRIVER,
    mutationKeys: [[QUERY_KEYS.scheduleDriverTripList]],
    noDismiss
  });
};
