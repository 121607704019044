import { ENDPOINTS, QUERY_KEYS } from '@constants';
import APIManager from '@services';

import { useQueryEnhancer } from '../core';

export * from './payment';

export const useFetchStatement = (isAr?: boolean, id?: number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statement, isAr, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statement(
          isAr ? 'ar-client-summary/:id' : 'ap-client-summary/:id',
          { id }
        )
      });

      return res.data;
    },
    enabled: !!id
  });
};
