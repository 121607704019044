const Colors = {
  black: '#000',
  white: '#fff',
  transparent: 'transparent',

  green: '#13A89E',
  greenDark: '0d756e',

  blue: '#3995C5',

  red: '#f44336',
  redDark: '#A81313',
  red1: '#dc3545',
  red3: '#A80D0D',

  orange: '#ff9800',
  orange1: '#FB8C00',

  text: '#263238',
  textDark: '#fff',

  background1: '#F9FAFB',
  background2: '#EEEEEE',
  backgroundDark1: '#2e2e2e',
  backgroundDark2: '#121212',
  backgroundDark3: '#424242',

  border: 'rgba(0, 0, 0, 0.23);',
  borderDark: 'rgba(255, 255, 255, 0.23)',

  success: '#13A89E',
  error: '#f44336',
  warning: '#ff9800',
  danger: '#D4483E'
};

export default Colors;
