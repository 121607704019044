import { useAccountingContext } from '@context/Accounting';
import React, { memo } from 'react';

import Details from './Details';
import List from './Statement.List';

const Statement = () => {
  const { clientId } = useAccountingContext();

  return <>{!clientId ? <List /> : <Details />}</>;
};

export default memo(Statement);
