import { ENDPOINTS, QUERY_KEYS, Status, TableName } from '@constants';
import { IAdvanceSearch } from '@dto';
import APIManager from '@services';

import { useQueryEnhancer } from '../core';

export const useFetchAdvanceSearch = (
  tableName: TableName,
  mappedFields?: { [key: string]: string },
  excludeFields?: string[]
) => {
  return useQueryEnhancer<IAdvanceSearch[]>({
    queryKey: [QUERY_KEYS.advanceSearch, tableName],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.advanceSearch(),
        body: {
          tableName,
          status: Status.Active
        }
      });

      return (res.data ?? [])
        .filter((i: IAdvanceSearch) => !excludeFields?.includes(i.columnName))
        .map((i: IAdvanceSearch) => ({
          ...i,
          label: mappedFields?.[i.columnName] ?? i.columnName
        }));
    },
    enabled: !!tableName
  });
};
