import Autocomplete from './Autocomplete';
import Currency from './Currency';
import Dropzone from './Dropzone';
import Editor from './Editor';
import File from './File';
import Password from './Password';
import Search from './Search';
// import TextEditor from './TextEditor';
import TextField from './TextField';

export default class KInput {
  static TextField = TextField;

  static Password = Password;

  static Autocomplete = Autocomplete;

  static Currency = Currency;

  static Search = Search;

  static File = File;

  static Dropzone = Dropzone;

  // static TextEditor = TextEditor;

  static Editor = Editor;
}
