import { MarkupType } from '@constants';
import { Prototype } from '@core';
import { useResolverForm } from '@hooks';
import { UIUtils, ValidationUtils } from '@utils';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KChip,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel
} from 'uikit';
import * as yup from 'yup';

import { genSellingPrice } from '../helpers';

interface IProps {
  item: any;
  chargeCodeIds: number[];
  onSuccess: (data: any) => void;
}

interface IFormData {
  companyTariff: any;
  levelValue: number | string;
  sellingPriceValue: number | string;
  rateValue: number | string;
  charges: any[];
}

const schema = yup.object().shape({
  levelValue: yup
    .mixed()
    .nullable()
    .test('min', trans('validation.min_number', { min: 0 }), function (v: any) {
      const markupType = this.parent.companyTariff.markupType;
      if (
        v &&
        parseFloat(v) < 0 &&
        [MarkupType.Currency, MarkupType.Percentage].includes(markupType)
      ) {
        return false;
      }
      return true;
    })
    .test('isRequired', trans('validation.required'), function (v: any) {
      const markupType = this.parent.companyTariff.markupType;
      if (
        v >= 0 &&
        [MarkupType.Currency, MarkupType.Percentage].includes(markupType)
      ) {
        return true;
      }
      if (!v && markupType !== MarkupType.Composite) {
        return false;
      }
      return true;
    }),
  charges: yup.array().of(
    yup.object().shape({
      weight: ValidationUtils.requiredNum()
    })
  )
});

const EditChargeCodePrice = (props: IProps) => {
  const { item, chargeCodeIds, onSuccess } = props;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        companyTariff: item?.companyTariff,
        levelValue: item?.levelValue ?? '',
        sellingPriceValue: item?.sellingPriceValue ?? '',
        rateValue: item?.rateValue ?? '',
        charges: item?.autoRateChargeComponents ?? []
      }
    }
  });

  const [tariff, charges] = useWatch({
    control: methods.control,
    name: ['companyTariff', 'charges']
  });

  const markupType = useMemo(() => tariff?.markupType, [tariff?.markupType]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'charges',
    keyName: 'formId'
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...item,
        ...data,
        autoRateChargeComponents: data.charges.map(i => {
          if (chargeCodeIds.includes(i.chargeCodeComponent?.subChargeCodeId)) {
            return i;
          }

          return {
            ...i,
            weight: 0
          };
        })
      };

      if ([MarkupType.Composite, MarkupType.Descriptive].includes(markupType)) {
        mParams.sellingPriceValue = genSellingPrice(mParams);
      }

      onSuccess(mParams);
      UIUtils.popup.dismiss();
    },
    [chargeCodeIds, item, markupType, onSuccess]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <KInput.TextField
            name="code"
            label={trans('code')}
            value={tariff?.code ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KInput.TextField
            name="name"
            label={trans('name')}
            value={tariff?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="originalCode"
            label={trans('original_code')}
            value={tariff?.chargeCode?.code ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="perUnit"
            label={trans('per_unit')}
            value={tariff?.chargeCode?.chargeCodeUnit?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="calculatorType"
            label={trans('calculator_type')}
            value={
              tariff?.chargeCode?.calculateType
                ? trans(
                    `option.${tariff?.chargeCode?.calculateType.toLowerCase()}`
                  ).toUpperCase()
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KInput.TextField
            name="category"
            label={trans('category')}
            value={tariff?.chargeCode?.chargeCodeCategory?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KInput.TextField
            name="remark"
            label={trans('remark')}
            value={tariff?.remark ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="zone"
            label={trans('zone')}
            value={
              tariff?.zone ? tariff.zone.name ?? trans('all').toUpperCase() : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="costingPrice"
            label={trans('costing_price')}
            value={
              tariff?.costingPrice
                ? Prototype.number.formatCurrency(tariff?.costingPrice) ?? ''
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="minSellingPrice"
            label={trans('min_selling_price')}
            value={
              tariff?.minSellingPrice
                ? Prototype.number.formatCurrency(tariff?.minSellingPrice) ?? ''
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="markupLevelType"
            label={trans('markup_level_type')}
            value={
              markupType
                ? trans(`option.${markupType.toLowerCase()}`).toUpperCase()
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="selectedLevelNumber"
            label={trans('selected_level')}
            value={item?.selectedLevelNumber ?? ''}
            disabled
          />
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={[MarkupType.Currency, MarkupType.Percentage].includes(
              markupType
            )}
          >
            <KGrid.Item xs={3}>
              <Controller
                name="levelValue"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('selected_markup_value')}
                      value={
                        markupType === MarkupType.Percentage
                          ? `${field.value} %`
                          : Prototype.number.formatCurrency(field.value)
                      }
                      disabled
                    />
                  );
                }}
              />
            </KGrid.Item>

            <KGrid.Item xs={3}>
              <Controller
                name="sellingPriceValue"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.Currency
                      {...field}
                      label={trans('selected_selling_price')}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={markupType === MarkupType.Composite}
          >
            <KGrid.Item xs={12}>
              <KContainer.Fieldset
                title={trans('selected_selling_price')}
                marginT={0}
                paddingB="0.75rem"
                paddingT="0.5rem"
              >
                <KGrid.Container spacing={2}>
                  <KContainer.View row alignItems flexW="wrap">
                    <KContainer.View
                      width={100}
                      marginL="0.5rem"
                      marginT="0.75rem"
                    >
                      <Controller
                        name="rateValue"
                        control={methods.control}
                        render={({ field }) => {
                          return (
                            <KInput.Currency
                              {...field}
                              label={trans('with_unit.base_value')}
                            />
                          );
                        }}
                      />
                    </KContainer.View>

                    {fields.map((d, idx) => {
                      if (
                        chargeCodeIds.includes(
                          d.chargeCodeComponent.subChargeCodeId
                        )
                      ) {
                        return (
                          <React.Fragment key={d.formId}>
                            <KContainer.View row alignItems marginT="0.75rem">
                              <KContainer.View
                                dp="flex"
                                size={30}
                                br="x"
                                brW={1}
                                brC={KColors.hexToRgba(KColors.black, 0.26)}
                                center
                                background="#EEEEEE"
                                marginH="0.5rem"
                              >
                                <KLabel.Text
                                  color={KColors.hexToRgba(KColors.black, 0.4)}
                                  typo="TextLgNormal"
                                >
                                  +
                                </KLabel.Text>
                              </KContainer.View>

                              <KContainer.View width={60} marginR="0.5rem">
                                <Controller
                                  name={`charges.${idx}.weight`}
                                  control={methods.control}
                                  render={({
                                    field,
                                    fieldState: { error }
                                  }) => {
                                    return (
                                      <KInput.TextField
                                        {...field}
                                        label="%"
                                        type="number"
                                        message={error?.message}
                                      />
                                    );
                                  }}
                                />
                              </KContainer.View>

                              <KContainer.View dp="flex" flex>
                                <KChip
                                  id={
                                    charges?.[idx]?.chargeCodeComponent
                                      ?.subChargeCode?.code ?? ''
                                  }
                                  label={
                                    charges?.[idx]?.chargeCodeComponent
                                      ?.subChargeCode?.code ?? ''
                                  }
                                />
                              </KContainer.View>
                            </KContainer.View>
                          </React.Fragment>
                        );
                      }

                      return null;
                    })}
                  </KContainer.View>
                </KGrid.Container>
              </KContainer.Fieldset>
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={markupType === MarkupType.Descriptive}
          >
            <KGrid.Item xs={9}>
              <Controller
                name="levelValue"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('selected_selling_price')}
                      required
                      message={error?.message}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
          <KButton.Solid
            title={trans('delete')}
            kind="secondary"
            onPress={() => onSuccess({ ...item, isDelete: true })}
            marginR="0.5rem"
          />

          <KButton.Solid type="submit" title={trans('update')} />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(EditChargeCodePrice);
