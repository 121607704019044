import {
  ENDPOINTS,
  NotificationReadStatus,
  PAGE_SIZE_DEFAULT,
  QUERY_KEYS,
  Status
} from '@constants';
import APIManager from '@services';

import { useUser } from '../auth';
import {
  useInfiniteEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useOneStopCountNotification = () => {
  const { account } = useUser();

  const { features = [] } = account || {};

  return useQueryEnhancer<number>({
    queryKey: [QUERY_KEYS.oneStopNotificationCount],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification('count-unread'),
        body: {
          include3PT: true
        }
      });

      return res.data;
    },
    initialData: 0,
    enabled: features.length > 0
  });
};

export const useOneStopFetchNotificationList = (
  readStatus: NotificationReadStatus,
  otherParams?: any
) => {
  const { account } = useUser();

  const { features = [] } = account || {};

  return useInfiniteEnhancer({
    queryKey: [
      QUERY_KEYS.oneStopNotification,
      account?.id,
      readStatus,
      JSON.stringify(otherParams)
    ],
    queryFn: async ({ pageParam }) => {
      const { page = 0, pageSize = PAGE_SIZE_DEFAULT } = pageParam || {};
      const res = await APIManager.request({
        url: ENDPOINTS.notification(),
        body: {
          size: pageSize,
          status: Status.Active,
          page,
          sortBy: 'createdAt:DESC',
          include3PT: true,
          userId: account?.id,
          readStatus:
            readStatus === NotificationReadStatus.All ? null : readStatus,
          ...otherParams
        }
      });

      return res.data;
    },
    enabled: features.length > 0
  });
};

export const useOneStopMarkAllAsRead = () => {
  return useMutationEnhancer({
    mutationKeys: [
      [QUERY_KEYS.oneStopNotificationCount],
      [QUERY_KEYS.oneStopNotification]
    ],
    mutationFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification('read-all'),
        method: 'POST'
      });

      return res.success;
    }
  });
};

export const useOneStopMarkAsRead = () => {
  return useMutationEnhancer<any, number>({
    mutationKeys: [
      [QUERY_KEYS.oneStopNotificationCount],
      [QUERY_KEYS.oneStopNotification]
    ],
    mutationFn: async id => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification(':id/read', { id }),
        method: 'POST'
      });

      return res.success;
    }
  });
};
