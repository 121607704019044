import { ArApType, DATE_FORMAT_SHORT, ENDPOINTS, TableName } from '@constants';
import DataTable from '@containers/DataTable';
import { DownloadCsvContext } from '@context';
import { useAccountingContext } from '@context/Accounting';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  useDataTable,
  useFetchStatementLatestPayment,
  useGlobalTable
} from '@hooks';
import APIManager from '@services';
import { TableUtils, UIUtils } from '@utils';
import { mappedSyncStatus } from '@utils/mapped';
import React, { memo, useCallback, useContext, useMemo } from 'react';
import trans from 'translation';
import { KButton, KContainer, KLabel } from 'uikit';

import FormNew from './Form.New';
import FormView from './Form.View';

import { OPTIONS } from '../helpers';

const Payments = () => {
  const { clientId, arApType } = useAccountingContext();

  const options = OPTIONS[arApType as ArApType];

  const { show } = useContext(DownloadCsvContext);

  const isPaymentV2 = useFeatureIsOn('payment_v2');

  const { data: latestPayment, isLoading } =
    useFetchStatementLatestPayment(clientId);

  const onShowPayment = useCallback(
    (item?: any) => {
      if (item) {
        UIUtils.popup.open({
          title: trans('view_payment'),
          maxWidth: 'lg',
          content: () => (
            <FormView
              item={item}
              isLastPayment={isPaymentV2 ? true : item.id === latestPayment?.id}
            />
          )
        });
      } else {
        UIUtils.popup.open({
          title: trans('new_payment'),
          maxWidth: 'lg',
          content: () => (
            <FormNew
              clientId={clientId as number}
              arApType={arApType as ArApType}
            />
          )
        });
      }
    },
    [arApType, clientId, isPaymentV2, latestPayment?.id]
  );

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowPayment(item)
          }
        ])
      },
      {
        label: trans('payment_#'),
        name: 'code',
        options: {
          ...TableUtils.options.baseMd,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowPayment(item)}>{v}</KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('payment_date'),
        name: 'paymentDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('sync_status'),
        name: 'syncStatus',
        options: TableUtils.options.chips({
          uppercase: true,
          mapLabelData: mappedSyncStatus()
        })
      },
      {
        label: trans('last_sync_date'),
        name: 'syncDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('last_sync_by'),
        name: 'syncByPerson.displayName',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('ref_no'),
        name: 'referenceNumber'
      },
      {
        label: trans('total_amount'),
        name: 'totalReceived',
        options: TableUtils.options.currency()
      },
      {
        label: trans('payment_status'),
        name: 'paymentProgress',
        options: TableUtils.options.chip()
      },
      {
        label: trans('note'),
        name: 'note',
        options: TableUtils.options.baseNm
      },
      {
        label: trans('payment_method'),
        name: 'paymentType',
        options: TableUtils.options.mappedOption
      },
      {
        label: trans('account_number'),
        name: 'accountNumber'
      },
      {
        label: trans('account_name'),
        name: 'accountName'
      },
      {
        label: trans('bank_name'),
        name: 'bankCode'
      },
      {
        label: trans('bank_branch'),
        name: 'bankBranch'
      }
    ],
    [onShowPayment]
  );

  const mappedFields = useMemo(() => {
    return {
      'syncByPerson.displayName': 'syncByPerson'
    };
  }, []);

  const otherParams = useMemo(() => {
    return {
      clientId,
      accountingType: arApType
    };
  }, [arApType, clientId]);

  const table = useDataTable({
    name: options.webTable.payment,
    tableName: TableName.PAYMENT,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.payment(),
    columnsFunc,
    mappedFields,
    otherParams
  });

  useGlobalTable(table);

  const detailsColumns = useMemo(
    () => [
      {
        label: trans('payment_date'),
        name: 'payment.paymentDate',
        mappedName: 'paymentDate'
      },
      {
        label: trans('invoice_number'),
        name: 'invoice.code',
        mappedName: 'invoiceCode'
      },
      {
        label: trans('invoice_status'),
        name: 'invoice.progressStatus.code',
        mappedName: 'invoiceProgress'
      },
      {
        label: trans('invoice_date'),
        name: 'invoice.invoiceDate',
        mappedName: 'invoiceDate'
      },
      {
        label: trans('invoice_sync_status'),
        name: 'invoice.syncStatus',
        mappedName: 'invoiceSyncStatus'
      },
      {
        label: trans('invoice_amount'),
        name: 'invoiceAmount'
      },
      {
        label: trans('amount_due'),
        name: 'totalDue'
      },
      {
        label: trans('payment_amount'),
        name: 'totalAmount'
      },
      {
        label: trans('payment_sync_status'),
        name: 'syncStatus'
      }
    ],
    []
  );

  const onDownloadDetails = useCallback(
    async (params: any) => {
      const mParams = {
        ...params,
        ...otherParams,
        searchCondition: table?.searchConditions
      };
      const dParams = {
        url: ENDPOINTS.paymentDetail('export-excel'),
        body: mParams,
        showToast: false
      };
      const { data } = await APIManager.request(dParams);
      return Promise.resolve({ data });
    },
    [otherParams, table?.searchConditions]
  );

  const handleExportDetails = useCallback(() => {
    show({
      onDownload: onDownloadDetails,
      columns: detailsColumns.map(i => ({
        label: i.label,
        name: i.name,
        mappedName: i.mappedName || i.name,
        allowedToSort: true,
        isSelected: true
      }))
    });
  }, [detailsColumns, onDownloadDetails, show]);

  return (
    <KContainer.Card
      border
      size="nm"
      header={{
        border: true,
        title: trans('payments'),
        rightNode: (
          <KButton.Solid
            title={trans('export_details')}
            onPress={handleExportDetails.bind(this)}
          />
        )
      }}
    >
      <DataTable
        {...table}
        onAdd={() => onShowPayment()}
        isModifying={isLoading}
      />
    </KContainer.Card>
  );
};

export default memo(Payments);
