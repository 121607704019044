import { IUseAgTableProps, IUseAgTableResult } from '@hooks-dto';
import { exportDataToExcel } from '@utils/excel';
import {
  CellValueChangedEvent,
  RowDataUpdatedEvent,
  SelectionChangedEvent
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

const useAgTable = <T = any>({
  name,
  data,
  colDefs,

  onAdd,
  onAddParams,

  onEndEditing,

  onExportParams
}: IUseAgTableProps<T>): IUseAgTableResult<T> => {
  const gridRef = useRef<AgGridReact<T>>(null);

  const [rowData, setRowData] = useState<T[]>([]);
  const [selectedData, setSelectedData] = useState<T[]>([]);

  const [keyword, setKeyword] = useState<string>('');

  // const isMounted = useRef(false);

  useEffect(() => {
    if (!isEmpty(data) && JSON.stringify(rowData) !== JSON.stringify(data)) {
      setRowData(data as T[]);
    }
  }, [data, rowData]);

  useEffect(() => {
    gridRef.current?.api?.setGridOption('quickFilterText', keyword);
  }, [keyword]);

  const onGetData = useCallback(() => {
    const rows: T[] = [];
    gridRef.current?.api?.forEachNode(rowNode => rows.push(rowNode.data as T));
    return rows;
  }, []);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<T>) => {
    setSelectedData(event.api.getSelectedRows());
  }, []);

  const onAddLastRow = useCallback(
    (initialRowData: T[]) => {
      onAdd?.() ??
        gridRef.current?.api.applyTransaction({
          add: initialRowData ?? [
            { action: 'addLastRow', ...onAddParams?.initialValue }
          ],
          addIndex: 0
        });
    },
    [onAdd, onAddParams?.initialValue]
  );

  const onEndEditingRows = useCallback(
    (event: RowDataUpdatedEvent<T>) => {
      const rows: T[] = [];
      event.api.forEachNode(rowNode => rows.push(rowNode.data as T));
      if (JSON.stringify(rowData) != JSON.stringify(rows)) {
        onEndEditing?.(rows);
      }
    },
    [onEndEditing, rowData]
  );

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent<T>) => {
      const rows: T[] = [];
      event.api.forEachNode(rowNode => rows.push(rowNode.data as T));
      onEndEditing?.(rows);
    },
    [onEndEditing]
  );

  const onExport = useCallback(
    async (fileName?: string) => {
      if (gridRef.current) {
        const exportData =
          gridRef.current.api
            .getDataAsCsv({
              suppressQuotes: true,
              columnSeparator: '__'
            })
            ?.split('\r\n')
            .map(i => {
              return i.split('__');
            }) ?? [];

        const columnWidths = gridRef.current.api
          .getColumnState()
          .map(i => i.width) as number[];

        const exportParams = {
          ...onExportParams,
          columnWidths
        };

        await exportDataToExcel(
          fileName || 'report.xlsx',
          exportData,
          exportParams
        );
      }
    },
    [onExportParams]
  );

  return {
    gridRef,

    name,

    rowData,
    columnDefs: colDefs,
    selectedData,
    keyword,

    onAdd: onAddParams?.hidden ? undefined : onAddLastRow,
    onExport,
    onSearch: setKeyword,
    onGetData,

    onSelectionChanged,
    onRowDataUpdated: onEndEditingRows,
    onRowEditingStopped: onEndEditingRows,
    onCellValueChanged
  };
};

export default useAgTable;
