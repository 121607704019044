import {
  ENDPOINTS,
  ResourceType,
  ShipmentType,
  Status,
  UITab,
  UserDataGroup,
  UserType,
  generateOptions
} from '@constants';
import { Prototype } from '@core';
import {
  useCUDAutoRate,
  useFetchClientRate,
  useFetchZoneFromSuburb,
  useResolverForm,
  useUploadFile
} from '@hooks';
import { onShowQuotation } from '@pages/Quotation/helpers';
import { IItemProps } from '@ui';
import { ValidationUtils, RequestUtils } from '@utils';
import { isEmpty, pick } from 'lodash';
import React, { memo, useCallback, useEffect } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KListItem,
  KLoading,
  KPicker
} from 'uikit';
import * as yup from 'yup';

import Charges from './Form.Charges';
import { IFormData, onGenCode } from './helpers';

import { useDeleteAutoRate } from '../helpers';

interface IProps extends IItemProps {
  clientRateId: string | number;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  quotation: ValidationUtils.requiredAny(),
  quotationRequest: ValidationUtils.requiredAny(),
  contractStart: ValidationUtils.requiredDate(),
  contractEnd: ValidationUtils.nullableDate().test(
    'minDate',
    'Must greater or equal than Contract Start',
    function (v: any) {
      const start: any = this.parent.contractStart;
      if (v && start) {
        return (
          Prototype.date
            .toMoment(v)
            ?.isSameOrAfter(Prototype.date.toMoment(start)) ?? true
        );
      }
      return true;
    }
  ),
  reviewDate: ValidationUtils.nullableDate().test(
    'minDate',
    'Must greater or equal than Contract Start',
    function (v: any) {
      const start: any = this.parent.contractStart;
      if (v && start) {
        return (
          Prototype.date
            .toMoment(v)
            ?.isSameOrAfter(Prototype.date.toMoment(start)) ?? true
        );
      }
      return true;
    }
  ),
  suburb: yup.mixed().nullable(),
  zone: yup
    .mixed()
    .nullable()
    .test('isRequired', trans('validation.required'), function (v: any) {
      const suburb = this.parent.suburb;
      if (!suburb && !v) {
        return false;
      }
      return true;
    })
});

const Form = ({ item, clientRateId }: IProps) => {
  const isEdit = !!item;

  const { data: clientRate } = useFetchClientRate(clientRateId);

  const address = clientRate?.relatedPartySite?.relatedSite;

  const { mutate: mutateZone, isLoading: zoneLoading } =
    useFetchZoneFromSuburb();

  const {
    createMutation: { mutate: mutateCreate },
    updateMutation: { mutate: mutateUpdate },
    modifyLoading
  } = useCUDAutoRate(clientRateId);

  const { onAlert, deleteLoading } = useDeleteAutoRate(clientRateId);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? onGenCode(clientRate?.consignClient?.code ?? ''),
        quotation: item?.quotation,
        quotationRequest: item?.quotationRequest,
        contractStart: Prototype.date.toLocal(item?.contractStart),
        contractEnd: Prototype.date.toLocal(item?.contractEnd),
        reviewDate: Prototype.date.toLocal(item?.reviewDate),
        salePerson: item?.salePerson,
        termResource: item?.termResource,
        status: item?.status ?? Status.Active,
        zone: item?.zone,
        charges: item?.autoRateCharges ?? []
      }
    }
  });

  const [quotation, quotationRequest, zone] = useWatch({
    control: methods.control,
    name: ['quotation', 'quotationRequest', 'zone']
  });

  const onFetchZoneFromSuburb = useCallback(
    (suburbId?: number) => {
      if (suburbId) {
        const mParams = {
          suburbId,
          shipmentType: clientRate?.shipmentType
        };
        mutateZone(mParams, {
          onSuccess: (data: any) => {
            methods.setValue('zone', data ?? null);
            methods.trigger('zone');
          }
        });
      } else {
        methods.setValue('zone', null);
      }
    },
    [clientRate?.shipmentType, methods, mutateZone]
  );

  useEffect(() => {
    if (!isEdit && address?.suburbId) {
      onFetchZoneFromSuburb(address?.suburbId);
    }
  }, [address?.suburbId, isEdit, onFetchZoneFromSuburb]);

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { quotationRequest: _quotationRequest, charges, ...rest } = data;
      const mParams = {
        ...RequestUtils.normalizeData({
          data: rest,
          idFields: ['quotation', 'salePerson', 'termResource'],
          dateFields: ['contractStart', 'contractEnd', 'reviewDate']
        }),
        clientRateId,
        id: item?.id,
        ...pick(_quotationRequest, [
          'suburbId',
          'zoneId',
          'dropModeId',
          'containerSizeId',
          'grossWeight',
          'isDamagedGood',
          // 'isRefrigeratedStorage',
          'chargeCodeTemplateId'
        ]),
        quotationRequestId: _quotationRequest.id,
        autoRateCharges: charges
      };

      if (!!mParams.id) {
        mutateUpdate(mParams);
      } else {
        mutateCreate(mParams);
      }
    },
    [clientRateId, item?.id, mutateCreate, mutateUpdate]
  );

  const onDelete = useCallback(() => {
    onAlert({ id: item?.id });
  }, [item?.id, onAlert]);

  const { mutateAsync: mutateFile } = useUploadFile();

  const onChangeFile = useCallback(
    async (e: any) => {
      if (e.target.files && !isEmpty(e.target.files)) {
        const v = e.target.files[0];
        const formData = new FormData();
        formData.append('file', v);
        formData.append('resourceType', ResourceType.AutoRateTerm);

        try {
          const res = await mutateFile(formData);
          methods.setValue('termResource', res);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [methods, mutateFile]
  );

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container style={{ position: 'relative' }}>
          {zoneLoading && <KLoading />}

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="suburb"
              label={trans('suburb')}
              value={
                address?.suburb
                  ? `${address?.suburb?.name} (${address?.suburb?.postcode})`
                  : ''
              }
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KContainer.RenderWhen>
              <KContainer.RenderWhen.If isTrue={!!address?.suburb}>
                <KInput.TextField
                  key="zoneItem"
                  name="zone"
                  label={trans('zone')}
                  value={zone?.name ?? ''}
                  disabled
                />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If isTrue={!address?.suburb}>
                <Controller
                  key="zoneAutocomplete"
                  name="zone"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('zone')}
                        apiURL={ENDPOINTS.zone()}
                        apiParams={{
                          shipmentType: ShipmentType.FCL
                        }}
                        getOptionLabel={(o: any) => o?.code ?? ''}
                        inputProps={{
                          required: true,
                          message: error?.message
                        }}
                      />
                    );
                  }}
                />
              </KContainer.RenderWhen.If>
            </KContainer.RenderWhen>
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="quotation"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('quotation')}
                    onChange={(v: any) => {
                      methods.setValue('quotation', v);
                      if (v?.contractStart) {
                        const contractStart = Prototype.date.toLocal(
                          v.contractStart
                        );
                        methods.setValue('contractStart', contractStart);
                        methods.setValue(
                          'contractEnd',
                          v.contractEnd
                            ? Prototype.date.toLocal(v.contractEnd)
                            : contractStart?.clone()?.add(6, 'M')
                        );
                        methods.setValue(
                          'reviewDate',
                          v.reviewDate
                            ? Prototype.date.toLocal(v.reviewDate)
                            : contractStart?.clone()?.add(5, 'M')
                        );
                      }
                    }}
                    apiURL={ENDPOINTS.quotation()}
                    apiParams={{
                      shipmentType: ShipmentType.FCL,
                      clientId:
                        clientRate?.agentClientId ?? clientRate?.consignClientId
                    }}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    hasAddNew
                    addNewKey="code"
                    onAddNew={() =>
                      onShowQuotation('new', { shipmentType: ShipmentType.FCL })
                    }
                    hasEdit
                    onEdit={i => onShowQuotation(i.id)}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="quotationRequest"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('quotation_request')}
                    onChange={(v: any) => {
                      methods.setValue('quotationRequest', v);
                      methods.setValue(
                        'charges',
                        (v?.quotationRequestCharges ?? []).map((i: any) => ({
                          ...pick(i, [
                            'companyTariff',
                            'companyTariffId',
                            'companyTariffLevelId',
                            'selectedLevelNumber',
                            'levelValueType',
                            'levelValue',
                            'rateValue',
                            'sellingPriceValue',
                            'status'
                          ]),
                          autoRateChargeComponents: (
                            i.quotationRequestChargeComponents ?? []
                          ).map((c: any) => ({
                            weight: c.weight,
                            chargeCodeComponentId: c.chargeCodeComponentId,
                            chargeCodeComponent: c.chargeCodeComponent
                          }))
                        }))
                      );
                    }}
                    apiURL={ENDPOINTS.quotation(':id/quotation-request', {
                      id: quotation?.id
                    })}
                    apiParams={{
                      shipmentType: ShipmentType.FCL,
                      zoneId: zone?.id,
                      jobType: clientRate?.jobType
                    }}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    hasAddNew
                    addNewKey="code"
                    onAddNew={() =>
                      onShowQuotation(quotation?.id, {
                        tab: UITab.QuotationRequests
                      })
                    }
                    hasEdit
                    onEdit={i =>
                      onShowQuotation(i.quotationId, {
                        tab: UITab.QuotationRequests
                      })
                    }
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    disabled={!quotation || !zone}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="quotationEffectiveTo"
              label={trans('quotation_effective_to')}
              value={Prototype.date.formatD(quotation?.contractEnd, '')}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="dropMode"
              label={trans('drop_mode')}
              value={quotationRequest?.dropMode?.name ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="containerSize"
              label={trans('container_size')}
              value={
                quotationRequest
                  ? quotationRequest?.containerSize?.code ??
                    trans('option.all').toUpperCase()
                  : ''
              }
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="grossWeight"
              label={trans('with_unit.gross_weight')}
              value={Prototype.number.formatNumber(
                quotationRequest?.grossWeight
              )}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KListItem.Checkbox
              name="isDamagedGood"
              label={trans('hazardous_goods')}
              checked={!!quotationRequest?.isDamagedGood}
              disabled
            />
          </KGrid.Item>

          {/* <KGrid.Item xs={3}>
            <KListItem.Checkbox
              name="isRefrigeratedStorage"
              label={trans('refrigerated_storage')}
              checked={!!quotationRequest?.isRefrigeratedStorage}
              disabled
            />
          </KGrid.Item> */}

          <KGrid.Item xs={3}>
            <Controller
              name="code"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('contract_code')}
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="contractStart"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Date
                    {...field}
                    label={trans('contract_start')}
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="contractEnd"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Date
                    {...field}
                    label={trans('contract_end')}
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="reviewDate"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Date
                    {...field}
                    label={trans('review_date')}
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="termResource"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.File
                    {...field}
                    label={trans('terms_and_conditions')}
                    accept="*"
                    value={field.value?.fileName ?? ''}
                    onChange={e => onChangeFile(e)}
                    onPress={() => {
                      if (field.value?.url) {
                        window.open(field.value.url, '_blank');
                      }
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="addressName"
              label={trans('address_name')}
              value={address?.name ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="salePerson"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('sales_person')}
                    apiURL={ENDPOINTS.user()}
                    apiParams={{
                      type: UserType.Internal,
                      dataGroup: UserDataGroup.Sales
                    }}
                    getOptionLabel={(o: any) => o?.displayName ?? ''}
                    hasAddNew
                    addNewKey="displayName"
                    addNewURL="users"
                    hasEdit
                    editURL="users"
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('status')}
                    options={generateOptions(Status)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="updatedBy"
              disabled
              label={trans('updated_by')}
              value={item?.updatedUsername ?? ''}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KInput.TextField
              name="updatedAt"
              disabled
              label={trans('updated_at')}
              value={Prototype.date.formatDT(item?.updatedAt, '')}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Charges />
          </KGrid.Item>

          <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
            {isEdit && (
              <KButton.Solid
                kind="secondary"
                marginR="0.5rem"
                isLoading={deleteLoading}
                disabled={modifyLoading}
                onPress={onDelete}
                title={trans('delete')}
              />
            )}

            <KButton.Solid
              type="submit"
              isLoading={modifyLoading}
              disabled={deleteLoading}
              title={trans('save')}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KForm>
    </FormProvider>
  );
};

export default memo(Form);
