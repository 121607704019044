import React, { memo } from 'react';

import TableInput from 'components/CustomInput/TableInput';

const TextFieldSearch = ({
  columns,
  handleSearchValue,
  handleChangeSearchField,
  mapColumns,
  excludedColumns,
  fullWidth
}: any) => {
  const [searchField, setSearchField] = React.useState('keyword');
  let timer;

  const handleChangeKeyword = event => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      handleSearchValue(event.target.value);
    }, 1000);
  };

  const onChangeSearchField = event => {
    setSearchField(event.target.value);
    handleChangeSearchField(event.target.value);
  };

  return (
    <TableInput
      keywordProps={{
        onChange: handleChangeKeyword,
        fullWidth
      }}
      fieldProps={{
        onChange: onChangeSearchField,
        value: searchField
      }}
      selectData={[
        { id: 'keyword', Header: 'All' },
        ...mapColumns(columns, excludedColumns)
      ]}
    />
  );
};

export default memo(TextFieldSearch);
