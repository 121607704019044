import common_en from './en.common.json';
import navigator_en from './en.navigator.json';
import option_en from './en.option.json';
import unit_en from './en.unit.json';
import validation_en from './en.validation.json';

const en = {
  translation: {
    ...common_en,
    ...navigator_en,
    ...option_en,
    ...unit_en,
    ...validation_en
  }
};

const vi = {
  translation: {
    ...common_en,
    ...navigator_en
  }
};

export { vi, en };
