import { DATE_FORMAT, DATE_FORMAT_SHORT } from '@constants';
import { Prototype } from '@core';
import { ColTypeDef, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import trans from 'translation';

const dateTimeFormatter = (p: ValueFormatterParams) => {
  const v = p.value;
  return Prototype.date.toLocal(v)?.format(DATE_FORMAT);
};

const dateFormatter = (p: ValueFormatterParams) => {
  const v = p.value;
  return Prototype.date.toLocal(v)?.format(DATE_FORMAT_SHORT);
};

const optionFormatter = (p: ValueFormatterParams) => {
  const v = p.value;
  return v ? trans(`option.${v.toLowerCase()}`).toUpperCase() : '';
};

const transFormatter = (p: ValueFormatterParams) => {
  const v = p.value;
  return v ? trans(v.toLowerCase()).toUpperCase() : '';
};

export const useColTypes = () => {
  return useMemo(() => {
    return {
      vnDate: {
        minWidth: 120,
        valueFormatter: dateFormatter
      },
      vnDateTime: {
        minWidth: 130,
        valueFormatter: dateTimeFormatter
      },
      trans: {
        valueFormatter: transFormatter
      }
    } as { [key: string]: ColTypeDef<unknown> };
  }, []);
};
