import { useAccountingContext } from '@context/Accounting';
import React, { memo } from 'react';

import Details from './Details';
import { IProps } from './helpers';
import List from './Invoices.List';

const Invoices = (props: IProps) => {
  const { invoiceId } = useAccountingContext();

  return <>{!invoiceId ? <List {...props} /> : <Details />}</>;
};

export default memo(Invoices);
