import styles from '@assets/jss/material-dashboard-react/components/commonHeaderStyle';
import {
  useCountNotification,
  useOneStopCountNotification,
  useUser
} from '@hooks';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { UIUtils } from '@utils';
import React, { memo, useCallback, useRef } from 'react';
import { KButton, KContainer, KImage, KLabel } from 'uikit';

import Notification from './Notification';
import Notification1Stop from './Notification1Stop';
import UserMenu from './UserMenu';

interface IProps {
  sidebarMinimize: any;
  openMobileSidebar: any;
}

const useStyles = makeStyles(styles as any);

const CommonHeader = ({ sidebarMinimize, openMobileSidebar }: IProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const { account } = useUser();

  const { features = [], displayName, avatar } = account || {};

  const { data: count, refetch: refetchCount } = useCountNotification();
  const { data: countOneStop, refetch: refetchCountOneStop } =
    useOneStopCountNotification();

  const refs = useRef({
    notif: React.createRef(),
    '1stop': React.createRef(),
    userMenu: React.createRef()
  });

  const onPressNoti = useCallback(() => {
    refetchCount();
    UIUtils.popper.open({
      anchorEl: refs.current.notif.current as any,
      placement: 'bottom-end',
      content: () => <Notification />,
      touchOutsideToDismiss: true,
      withMaxZIndex: true
    });
  }, [refetchCount]);

  const onPress1Stop = useCallback(() => {
    refetchCountOneStop();
    UIUtils.popper.open({
      anchorEl: refs.current['1stop'].current as any,
      placement: 'bottom-end',
      content: () => <Notification1Stop />,
      touchOutsideToDismiss: true,
      withMaxZIndex: true
    });
  }, [refetchCountOneStop]);

  const onPressUserMenu = useCallback(() => {
    UIUtils.popper.open({
      anchorEl: refs.current.userMenu.current as any,
      placement: 'bottom-end',
      content: () => <UserMenu />,
      touchOutsideToDismiss: true,
      withMaxZIndex: true,
      cardProps: {
        marginT: '0.5rem'
      }
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      <KContainer.View flex>
        <KButton.Icon
          icon="Menu"
          padding="0.5rem"
          onPress={!isSm ? sidebarMinimize : openMobileSidebar}
        />
      </KContainer.View>

      <KContainer.View row alignItems>
        <KButton.Icon icon="ChatOutlined" padding="0.5rem" marginR="0.5rem" />

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={features.length > 0}>
            <KButton.Icon
              ref={refs.current['1stop'] as any}
              icon="DeviceHubOutlined"
              padding="0.5rem"
              marginR="0.5rem"
              badge={countOneStop}
              aria-haspopup="true"
              onPress={onPress1Stop}
            />
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KButton.Icon
          ref={refs.current.notif as any}
          icon="Notifications"
          padding="0.5rem"
          marginR="0.5rem"
          badge={count}
          aria-haspopup="true"
          onPress={onPressNoti}
        />

        {/* <Profile /> */}
        {/* <KButton.Transparent title={displayName || ''} kind="normal" /> */}
        <KContainer.Touchable
          ref={refs.current.userMenu as any}
          row
          alignItems
          paddingH="1rem"
          onPress={onPressUserMenu}
        >
          <KLabel.Text typo="TextNmMedium" textTransform="uppercase">
            {displayName || ''}
          </KLabel.Text>

          <KImage.Avatar
            source={avatar}
            alt={displayName}
            size="sm"
            marginL="0.5rem"
          />
        </KContainer.Touchable>
      </KContainer.View>
    </div>
  );
};

export default memo(CommonHeader);
