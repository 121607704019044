import { MarkupType } from '@constants';
import { Prototype } from '@core';
import qs from 'query-string';
import trans from 'translation';

export const onShowCompanyTariff = (
  id: number | string | 'new',
  query?: any
) => {
  window.open(
    `/admin/company-tariffs/${id}${query ? `?${qs.stringify(query)}` : ''}`,
    '_blank'
  );
};

export const generateLevelColumns = (data: any[], numberOfLevels: number) => {
  let result: any[] = [];

  for (let i = 0; i < numberOfLevels; i++) {
    const level = i + 1;
    result = result.concat([
      {
        label: trans('level') + ` ${level}`,
        name: `level${level}.levelValue`,
        options: {
          sort: false,
          customBodyRenderLite: (index: number) => {
            const item = data?.[index]?.[`level${level}`];
            if (item) {
              const markupType = item.levelValueType;
              if (markupType === MarkupType.Percentage) {
                return `${item.levelValue} %`;
              } else if (markupType === MarkupType.Currency) {
                return `$${item.levelValue}`;
              } else {
                return item.levelValue;
              }
            } else {
              return '';
            }
          }
        }
      },
      {
        label: trans('selling_price_level') + ` ${level}`,
        name: `level${level}.sellingPriceValue`,
        options: {
          sort: false,
          customBodyRenderLite: (index: number) => {
            const item = data?.[index]?.[`level${level}`];
            if (item) {
              const markupType = item.levelValueType;
              if (
                [MarkupType.Percentage, MarkupType.Currency].includes(
                  markupType
                )
              ) {
                return Prototype.number.formatCurrency(item.sellingPriceValue);
              } else {
                return (
                  <span
                    style={{
                      maxWidth: '100%',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 4,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.sellingPriceValue}
                  </span>
                );
              }
            } else {
              return '';
            }
          }
        }
      }
    ]);
  }
  return result;
};
