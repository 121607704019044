import { GlobalEvent, StorageKeys } from '@constants';
import { AppEvent, NotificationManager, AppStorage } from '@core';
import {
  changePasswordService,
  getAuthProfileService,
  updateAuthProfileService
} from '@redux/services/auth';
import { uploadResourceService } from '@redux/services/common';
import { handleApiErrors } from '@utils/requestHelper/errorResponse';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as auth from '../../reducers/auth';

const { types, actions } = auth;

function* changePasswordSaga({ payload }) {
  const { callback, values } = payload;
  try {
    yield call(changePasswordService, values);
    yield put(actions.changePasswordSuccess());
    callback?.();
  } catch (error) {
    console.log(error);
    yield put(actions.changePasswordError(handleApiErrors(error)));
  }
}

function* getAuthProfileSaga() {
  try {
    const res = yield call(getAuthProfileService);

    AppStorage.set(StorageKeys.user, res);
    yield put(actions.getAuthProfileSuccess(res));
    NotificationManager.setExternalUserId(`${res.id}`);
  } catch (error) {
    console.log(error);
    AppEvent.dispatch(GlobalEvent.SignOut);
    yield put(actions.getAuthProfileError(handleApiErrors(error)));
  }
}

function* uploadAvatarSaga({ payload }) {
  try {
    const res = yield call(uploadResourceService, payload);
    yield put(actions.uploadAvatarSuccess());
    yield put(
      actions.updateAuthProfile({
        avatar: res.url
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.uploadAvatarError(handleApiErrors(error)));
  }
}

function* updateAuthProfileSaga({ payload }) {
  try {
    const res = yield call(updateAuthProfileService, payload);
    AppStorage.set(StorageKeys.user, res);
    yield put(actions.updateAuthProfileSuccess(res));
  } catch (error) {
    console.log(error);
    yield put(actions.updateAuthProfileError(handleApiErrors(error)));
  }
}

const watchers = function* () {
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(types.GET_AUTH_PROFILE, getAuthProfileSaga);
  yield takeLatest(types.UPLOAD_AVATAR, uploadAvatarSaga);
  yield takeLatest(types.UPDATE_AUTH_PROFILE, updateAuthProfileSaga);
};

export default watchers;
