import loadable from '@loadable/component';
import { UIUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KButton } from 'uikit';

const DownloadCsv = loadable(() => import('./DownloadCsv'));

interface Props {
  id?: string;
  showDownload?: boolean;
  showRefresh?: boolean;
  columns: any[];

  onDownload?: (dParams: any) => Promise<{ data: any }>;
  onRefresh?: () => void;
}

const RightActions = ({
  id,
  columns,
  showDownload,
  showRefresh,

  onDownload,
  onRefresh
}: Props) => {
  const _onDownload = useCallback(() => {
    if (onDownload) {
      UIUtils.popup.open({
        title: trans('download_csv'),
        content: dismiss => (
          <DownloadCsv
            dismiss={dismiss}
            onDownload={onDownload}
            columns={columns}
          />
        )
      });
    }
  }, [columns, onDownload]);

  return (
    <>
      {showDownload && onDownload && (
        <KButton.Icon
          key={`download-${id}`}
          icon="CloudDownloadOutlined"
          onPress={_onDownload.bind(null)}
          marginR="0.5rem"
        />
      )}

      {showRefresh && onRefresh && (
        <KButton.Icon
          key={`refresh-${id}`}
          icon="RefreshOutlined"
          onPress={onRefresh}
          marginR="0.5rem"
        />
      )}
    </>
  );
};

export default memo(RightActions);
