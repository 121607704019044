import { ApInvoiceType, ArInvoiceType, UITab } from '@constants';
import { t } from '@lingui/macro';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import trans from 'translation';

import { roundToTwo } from './formatHelper/number';
import ToastManager from './toast';

interface LineItem {
  code: string;
  description: string;
  quantity: number;
  unitAmount: string | number;
  accountCode: number;
  lineAmount: string | number;
}

interface InvoiceData {
  lineItems: LineItem[];
  reference: string;
  date: string;
}

interface InvoiceResult {
  invoices: InvoiceData[];
}

interface PaymentItem {
  invoice: { invoiceId: string };
  account: { code: string };
  date: string;
  amount: string | number;
}

interface PaymentResult {
  payments: PaymentItem[];
  paymentIds: number[];
}

export default class XeroManager {
  static prepareInvoiceLink = (
    invoiceId: number,
    tab: UITab = UITab.TransportJobInvoices
  ): string => {
    return (
      window.location.href.split('?')[0] + `?tab=${tab}&invoiceId=${invoiceId}`
    );
  };

  static prepareStatementLink = (statementId: number, tab: UITab): string => {
    return (
      window.location.href.split('?')[0] +
      `?tab=${tab}&statementId=${statementId}`
    );
  };

  static prepareInvoiceData = (invoice): InvoiceResult | undefined => {
    if ((invoice?.invoiceContainerCharges ?? []).length === 0) {
      ToastManager.notify(t`This invoice has no charge`);
      return undefined;
    }

    try {
      const transportJob = invoice.transportJob;
      const result: InvoiceResult = { invoices: [] };
      const invoiceData: InvoiceData = {
        lineItems: [],
        reference: [transportJob?.jobNumber, transportJob?.referenceNumber]
          .filter(i => i)
          .join(' / '),
        date: new Date().toISOString().slice(0, 10),
        invoiceNumber: invoice.code
      };

      if (invoice.dueDate) {
        invoiceData.dueDate = invoice.dueDate;
      }

      for (let i of invoice.invoiceContainerCharges ?? []) {
        const description = [
          i.companyTariff.code,
          i.jobContainer?.containerNumber,
          i.note
        ]
          .filter(e => e)
          .join(' - ');

        const quantity = Math.abs(i.quantity);
        const unitAmount = roundToTwo(
          i.actualSellingPriceValue * (1 + (i.taxableValue ?? 0) / 100)
        );
        const totalAmount = roundToTwo(Math.abs(i.totalAmount));

        invoiceData.lineItems.push({
          code: i.companyTariff.code,
          description,
          quantity,
          unitAmount,
          lineAmount: totalAmount,
          tax: i.tax
        });
      }

      result.invoices.push(invoiceData);

      return result;
    } catch (e) {
      console.log(e);
      ToastManager.notify();
      return undefined;
    }
  };

  static prepareCreditNoteData = (invoice): InvoiceResult | undefined => {
    if ((invoice?.invoiceContainerCharges ?? []).length === 0) {
      ToastManager.notify(t`This credit note has no charge`);
      return undefined;
    }

    try {
      const transportJob = invoice.transportJob;
      const result: InvoiceResult = { creditNotes: [] };
      const invoiceData: InvoiceData = {
        lineItems: [],
        reference: [transportJob?.jobNumber, transportJob?.referenceNumber]
          .filter(i => i)
          .join(' / '),
        date: new Date().toISOString().slice(0, 10),
        creditNoteNumber: invoice.code
      };

      for (let i of invoice.invoiceContainerCharges ?? []) {
        const description = [
          i.companyTariff.code,
          i.jobContainer?.containerNumber,
          i.note
        ]
          .filter(e => e)
          .join(' - ');

        const quantity = Math.abs(i.quantity);
        const unitAmount = roundToTwo(
          i.actualSellingPriceValue * (1 + (i.taxableValue ?? 0) / 100)
        );
        const totalAmount = roundToTwo(Math.abs(i.totalAmount));

        invoiceData.lineItems.push({
          code: i.companyTariff.code,
          description,
          quantity,
          unitAmount,
          lineAmount: totalAmount,
          tax: i.tax
        });
      }

      result.creditNotes.push(invoiceData);

      return result;
    } catch (e) {
      console.log(e);
      ToastManager.notify();
      return undefined;
    }
  };

  static preparePaymentData = (payments: any[]): PaymentResult | undefined => {
    if ((payments ?? []).length === 0) {
      toast.error(trans('no_payments_found'));
      return undefined;
    }

    const result: PaymentResult = { payments: [], paymentIds: [] };

    try {
      payments.forEach(item => {
        if (item.paymentDetails) {
          for (let i of item.paymentDetails) {
            if (i.invoice?.syncNumber && !i.syncNumber) {
              const pItem: PaymentItem = {
                account: {},
                amount: roundToTwo(Math.abs(i.totalAmount)),
                date: item.paymentDate ?? moment().format('YYYY-MM-DD'),
                reference: i.invoice.code?.replace(/-/g, ' ') ?? '',
                refID: i.invoice.syncNumber,
                id: i.id
              };
              if (
                [
                  ArInvoiceType.ArCreditNote,
                  ApInvoiceType.ApCreditNote
                ].includes(i.invoice.invoiceType)
              ) {
                pItem.creditNote = { creditNoteNumber: i.invoice.code };
              } else {
                pItem.invoice = { invoiceNumber: i.invoice.code };
              }

              result.payments.push(pItem);
              result.paymentIds.push(i.id);
            }
          }
        }
      });

      if (result.payments.length === 0) {
        toast.error(trans('no_available_payments'));
        return undefined;
      }

      return result;
    } catch (e) {
      console.log(e);
      ToastManager.notify();
      return undefined;
    }
  };
}
