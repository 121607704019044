import React from 'react';

const EmptyTable = ({ isLoading, message }) => {
  // const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: minHeight ?? 'calc(100vh - 283px)',
        flexGrow: 1
        // borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      {isLoading ? null : (
        <>
          {/* <Icon style={{ fontSize: 96 }} color="primary" />
          <Spacing vertical={3} /> */}
          {/*{message && t(message)}*/}
          {message}
        </>
      )}
    </div>
  );
};

export default EmptyTable;
