import { Prototype } from '@core';
import { useFetchDashboardAROverdue } from '@hooks';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KContainer, KLabel, KColors } from 'uikit';

const AROverdue = () => {
  const { data = [], isLoading } = useFetchDashboardAROverdue();

  const renderRow = useCallback((item: any) => {
    return (
      <KContainer.View
        key={item.code}
        marginB={'0.5rem'}
        background={'#F8F9FA'}
        br={'2x'}
        padding={'0.75rem'}
      >
        <KContainer.View row alignItems marginB={'0.25rem'}>
          <KContainer.View row alignItems flex={3}>
            <KLabel.Text
              typo="TextNmBold"
              color={KColors.primary.normal}
              marginR={'1.5rem'}
              isLink
              to={`/admin/statements/${item.id}`}
              target="_blank"
            >
              {item.code}
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View row flex={1.2}>
            <KLabel.Text typo="TextNmBold" color={KColors.black}>
              {trans('credit_limit')}:{' '}
              {Prototype.number.formatCurrency(item.creditLimit)}
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View row alignItems>
          <KContainer.View flex={3}>
            <KLabel.Text color={'#7B809A'}>
              {trans('client_name')}:{' '}
              <KLabel.Text
                typo="TextNmBold"
                color={KColors.primary.normal}
                isLink
                to={`/admin/clients/${item.id}`}
                target="_blank"
              >
                {item.name}
              </KLabel.Text>
            </KLabel.Text>
          </KContainer.View>

          <KContainer.View flex={1.2}>
            <KLabel.Text typo="TextNmBold" color={KColors.secondary.normal}>
              {trans('amount_exceed')}:{' '}
              {Prototype.number.formatCurrency(item.overdue)}
            </KLabel.Text>
          </KContainer.View>
        </KContainer.View>
      </KContainer.View>
    );
  }, []);

  return (
    <KContainer.Card
      height={400}
      marginT={0}
      isLoading={isLoading}
      header={{
        title: trans('accounts_receivable_overdue'),
        typo: 'TextMdBold'
      }}
    >
      <KContainer.View flex overflow>
        {data.map(i => renderRow(i))}
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(AROverdue);
