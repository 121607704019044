import {
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  ShipmentType,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { useDataTable, useGlobalTable, useNewButton } from '@hooks';
import { TableUtils, UIUtils } from '@utils';
import React, { memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KChip, KColors, KContainer, KLabel } from 'uikit';

import { onShowQuotation, useDeleteQuotation } from './helpers';

const Quotation = () => {
  const [tab] = useState(ShipmentType.FCL);

  const { onAlert, deleteLoading } = useDeleteQuotation();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowQuotation(item.id)
          },
          {
            title: trans('delete'),
            icon: 'Delete',
            onPress: () => onAlert({ id: item.id })
          }
        ])
      },
      {
        label: trans('quotation_number'),
        name: 'code',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowQuotation(item.id)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('client_code'),
        name: 'client.code'
      },
      {
        label: trans('client_name'),
        name: 'client.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('address'),
        name: 'client.defaultSite.address',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('suburb'),
        name: 'client.defaultSite.suburb.name'
      },
      {
        label: trans('country'),
        name: 'client.defaultSite.country.name'
      },
      {
        label: trans('client_types'),
        name: 'client.clientTypes',
        options: TableUtils.options.withMoreIcon({
          onPress: v => {
            UIUtils.popup.open({
              title: trans('client_types'),
              maxWidth: 'xs',
              touchOutsideToDismiss: true,
              content: () => (
                <>
                  {v.map((i, idx) => {
                    const marginT = idx === 0 ? 0 : '0.75rem';
                    return (
                      <KContainer.View
                        key={`client-type-popup-${i.id}`}
                        dp="flex"
                        center
                        paddingV="0.25rem"
                        paddingH="0.75rem"
                        br="x"
                        brW={1}
                        brC={KColors.warning.normal}
                        marginT={marginT}
                      >
                        <KLabel.Text
                          typo="TextMdNormal"
                          textTransform="uppercase"
                        >
                          {i.code}
                        </KLabel.Text>
                      </KContainer.View>
                    );
                  })}
                </>
              )
            });
          },
          renderItem: (i, idx, rowIdx) => {
            const item = data?.[rowIdx];
            const marginL = idx === 0 ? 0 : '0.5rem';
            return (
              <KChip
                key={`${item?.id}-client-type-${i.id}`}
                background={KColors.palette.warning.w50}
                brC={KColors.warning.normal}
                label={i.code}
                textTransform={'uppercase'}
                color={KColors.warning.normal}
                marginL={marginL}
                style={{ whiteSpace: 'nowrap' }}
              />
            );
          }
        })
      },
      {
        label: trans('contract_start'),
        name: 'contractStart'
      },
      {
        label: trans('contract_end'),
        name: 'contractEnd'
      },
      {
        label: trans('review_date'),
        name: 'reviewDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('quote_date'),
        name: 'createdAt'
      },
      {
        label: trans('sales_person'),
        name: 'salePerson.displayName'
      },
      {
        label: trans('remark'),
        name: 'remark'
      },
      {
        label: trans('status'),
        name: 'status',
        options: TableUtils.options.switch
      }
    ],
    [onAlert]
  );

  const mappedFields = useMemo(
    () => ({
      'client.code': 'clientCode',
      'client.name': 'clientName',
      'client.defaultSite.address': 'siteAddress',
      'client.defaultSite.suburb.name': 'siteSuburb',
      'client.defaultSite.country.name': 'siteCountry',
      'client.clientTypes': 'clientType',
      'salePerson.displayName': 'salePerson'
    }),
    []
  );

  const otherParams = useMemo(
    () => ({
      shipmentType: tab
    }),
    [tab]
  );

  const table = useDataTable({
    name: WebTable.QUOTATION,
    tableName: TableName.QUOTATION,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.quotation(),
    columnsFunc,
    mappedFields,
    otherParams
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    onShowQuotation('new', { shipmentType: tab });
  }, [tab]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.tariffs_and_rates'),
          trans('routes.quotations')
        ]}
      />

      <KContainer.Card
        margin="0.75rem"
        // header={{
        //   renderHeader: () => (
        //     <Tabs.Shipment onChangeTab={setTab} disabled={table.isLoading} />
        //   )
        // }}
      >
        <DataTable {...table} onAdd={onAdd} isModifying={deleteLoading} />
      </KContainer.Card>

      {newButton}
    </>
  );
};

export default memo(Quotation);
