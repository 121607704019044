import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchCompanyTariff = (chargeCodeId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.companyTariff,
      chargeCodeId ? parseInt(chargeCodeId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.companyTariff(),
        body: {
          chargeCodeId
        }
      });

      const data = {
        chargeCode: res.data?.data?.[0].chargeCode,
        companyTariffs: res.data?.data
      };

      return data;
    },
    enabled: !!chargeCodeId
  });
};

export const useCUDCompanyTariff = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.companyTariff(),
    webTable: WebTable.COMPANY_TARIFF,
    queryKey: QUERY_KEYS.companyTariff,
    redirectPath: '/admin/company-tariffs',
    redirectKey: 'chargeCodeId',
    putWithoutId: true,
    putWithoutSuccess: true,
    deleteWithoutSuccess: true
  });
};
