import { ApInvoiceType, ArInvoiceType } from '@constants';
import {
  useAlertMutationEnhancer,
  useCUDInvoice,
  useMutateCancelInvoice
} from '@hooks';
import trans from 'translation';

export interface IFormData {
  code: string;
  invoiceType: ArInvoiceType | ApInvoiceType;
  invoiceDate: any;
  referenceNumber: string;
  dueDate?: any;
  attachedEdoc?: any;
  invoiceProgress: any;
  totalAmount: number;
  totalDue: number;
  charges: any[];
  client?: any;

  chargeParams: {
    chargeCodeTemplate?: any;
    tariff?: any;
    defaultLevel: number;
  };

  tenantId?: string | null;
}

export const useDeleteInvoice = () => {
  const { deleteMutation } = useCUDInvoice();

  return useAlertMutationEnhancer({
    mutation: deleteMutation,
    message: trans('message_delete_invoice')
  });
};

export const useCancelInvoice = () => {
  const mutation = useMutateCancelInvoice();

  return useAlertMutationEnhancer({
    mutation: mutation,
    message: trans('message_cancel_invoice'),
    okLabel: trans('ok')
  });
};

export const useInvoiceFParams = () => ({
  isAgent: false,
  isMisc: true
});
