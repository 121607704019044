import { ReportExcelType, UIMenuItem, UITab } from '@constants';
import { useCheckScreenWithCallback, useToolsButton } from '@hooks';
import { UIUtils } from '@utils';
import React, { createRef, memo, useCallback, useState } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KTab } from 'uikit';

import Details from './Details';

import Print from '../components/Print';
import withReportContext, { useReportContext } from '../Report.Provider';

const Operations = () => {
  const { conditions } = useReportContext();

  const ref = createRef<HTMLButtonElement>();

  const [tabIndex, setTabIndex] = useState(0);

  const cb = useCallback(() => {
    return [
      {
        code: UITab.ReportOperations,
        label: trans('details')
      }
    ];
  }, []);

  const tabs = useCheckScreenWithCallback(cb, UIMenuItem.ReportOperations);

  const onPrint = useCallback(() => {
    UIUtils.popup.open({
      title: trans('print'),
      content: () => (
        <Print
          conditions={conditions}
          reportType={ReportExcelType.TransportJob}
        />
      )
    });
  }, [conditions]);

  const { onShowTools } = useToolsButton(ref, [
    {
      title: trans('print'),
      icon: 'Print',
      onPress: onPrint
    }
  ]);

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.reports'), trans('routes.operations')]}
        // rightNode={{
        //   tools: {
        //     ref,
        //     onPress: onShowTools
        //   }
        // }}
      />

      <KContainer.View padding="0.75rem">
        <Details />
      </KContainer.View>
    </>
  );
};

export default memo(withReportContext(Operations));
