import {
  FormControlLabel,
  FormControlLabelProps,
  Checkbox as MuiCheckbox
} from '@material-ui/core';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import React, { memo } from 'react';

const Checkbox = memo(
  ({
    label,
    labelPlacement,
    disabled,
    ...otherProps
  }: FormControlLabelProps) => {
    return (
      <FormControlLabel
        control={
          <MuiCheckbox
            checkedIcon={<CheckBoxOutlinedIcon />}
            icon={<CheckBoxOutlineBlankOutlinedIcon />}
            color="primary"
            {...otherProps}
          />
        }
        label={label}
        labelPlacement={labelPlacement ?? 'end'}
        disabled={disabled}
      />
    );
  }
);

export { Checkbox };
