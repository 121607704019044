import { ArApType, ENDPOINTS, WebTable } from '@constants';

export const OPTIONS = {
  [ArApType.Ar]: {
    webTable: {
      statement: WebTable.ACCOUNTING_AR_CLIENTS_SUMMARY,
      payment: WebTable.ACCOUNTING_AR_CLIENTS_SUMMARY_PAYMENT,
      invoice: WebTable.ACCOUNTING_AR_CLIENTS_SUMMARY_INVOICE,
      email: WebTable.ACCOUNTING_AR_CLIENTS_SUMMARY_EMAIL
    },
    title: {
      statement: 'ar_clients_summary',
      invoice: 'receivable_invoices',
      address: 'ar_mailing_address',
      lastAmount: 'last_received',
      lastDate: 'last_received_date'
    },
    apiURL: ENDPOINTS.statement('ar-client-summary')
  },
  [ArApType.Ap]: {
    webTable: {
      statement: WebTable.ACCOUNTING_AP_ACCOUNTS,
      payment: WebTable.ACCOUNTING_AP_ACCOUNTS_PAYMENT,
      invoice: WebTable.ACCOUNTING_AP_ACCOUNTS_INVOICE,
      email: WebTable.ACCOUNTING_AP_ACCOUNTS_EMAIL
    },
    title: {
      statement: 'ap_accounts',
      invoice: 'payable_invoices',
      address: 'ap_mailing_address',
      lastAmount: 'last_paid',
      lastDate: 'last_paid_date'
    },
    apiURL: ENDPOINTS.statement('ap-client-summary')
  }
};
