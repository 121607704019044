import { WebTable } from '@constants';
import DataTable from '@containers/DataTable';
import { useLocalTable } from '@hooks';
import { useChargeColumns } from '@pages/Quotation/Request/helpers';
import { IItemProps } from '@ui';
import { UIUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KContainer } from 'uikit';

import EditChargeCodePrice from './components/EditChargeCodePrice';
import { IFormData } from './helpers';

const Charges = ({ item }: IItemProps) => {
  const methods = useFormContext<IFormData>();

  const [data] = useWatch({
    control: methods.control,
    name: ['charges']
  });

  const onEditSuccess = useCallback(
    (v: any) => {
      if (v?.isDelete) {
        methods.setValue(
          'charges',
          data.filter(i => i.companyTariffId !== v.companyTariffId),
          { shouldDirty: true }
        );
      } else {
        const index = data.findIndex(
          i => i.companyTariffId === v.companyTariffId
        );
        if (index !== -1) {
          data[index] = {
            ...data[index],
            ...v
          };
        }
        methods.setValue('charges', data, { shouldDirty: true });
      }
    },
    [data, methods]
  );

  const onShowPopup = useCallback(
    (v: any) => {
      UIUtils.popup.open({
        title: trans('edit_price_of_charge_code'),
        content: () => (
          <EditChargeCodePrice
            item={v}
            chargeCodeIds={data.map(i => i.companyTariff.chargeCodeId)}
            onSuccess={onEditSuccess}
          />
        )
      });
    },
    [data, onEditSuccess]
  );

  const columns = useChargeColumns(data, { onShowPopup });

  const table = useLocalTable({
    name: WebTable.OTHER,
    data,
    columns,
    onRefresh: () => methods.setValue('charges', item?.autoRateCharges ?? []),
    otherOptions: {
      selectableRows: 'none'
    }
  });

  return (
    <KContainer.Card
      border
      size="nm"
      header={{
        title: trans('charge_code_details'),
        border: true
      }}
    >
      <DataTable {...table} showDownload={false} />
    </KContainer.Card>
  );
};

export default memo(Charges);
