import { ENDPOINTS, QUERY_KEYS, Status } from '@constants';
import APIManager from '@services';

import { useQueryEnhancer } from '../core';

export const useFetchScheduleDriverTripListForMap = (
  scheduleId: number,
  driverId: number
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.scheduleDriverTripListForMap, scheduleId, driverId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTrip(),
        body: {
          scheduleId,
          driverId,
          includeSafetyCheck: true,
          includeConversationUnreadCount: true,
          includeReportStatus: true
        }
      });

      return res.data?.data;
    },
    enabled: !!scheduleId && !!driverId
  });
};

export const useFetchDriverLocationListForMap = (
  scheduleId: number,
  scheduleDriverId: number
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.driverLocationListForMap,
      scheduleId,
      scheduleDriverId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripLocation(),
        body: {
          scheduleId,
          scheduleDriverId,
          status: Status.Active
        }
      });

      return res.data?.data;
    },
    enabled: !!scheduleId && !!scheduleDriverId
  });
};
