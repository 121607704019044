import { UIUtils } from '@utils';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import trans from 'translation';
import { KColors } from 'uikit';
import { useStateWithCallback } from 'uikit-common';

export const useAskBeforeLeave = (when?: boolean) => {
  const history = useHistory();
  const currentLocation = useLocation();

  const ref = useRef<() => void>();
  const currentRef = useRef<string>('');

  const [isInitial, setIsInitial] = useStateWithCallback<boolean>(false);

  const onRequestLeave = useCallback(
    (cb?: () => void) => {
      UIUtils.alert.open({
        message: trans('changes_will_not_be_save'),
        buttons: [
          {
            title: trans('ok'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => {
              setIsInitial(true, () => {
                if (
                  currentRef.current &&
                  currentRef.current !== currentLocation.pathname
                ) {
                  history.push(currentRef.current);
                } else {
                  cb?.();
                  setIsInitial(false);
                }
              });
            }
          },
          {
            title: trans('cancel'),
            onPress: () => {}
          }
        ]
      });
    },
    [currentLocation.pathname, history, setIsInitial]
  );

  useEffect(() => {
    ref.current = history.block((location: any) => {
      if (when && !isInitial) {
        currentRef.current = location.pathname;
        setTimeout(() => onRequestLeave(), 100);
        return false;
      }
      currentRef.current = '';
      return true;
    });

    return () => {
      ref.current?.();
    };
  }, [history, isInitial, onRequestLeave, when]);

  //   const onPrompt = useCallback(
  //     (options: Omit<PromptProps, 'message'>) => {
  //       return <Prompt {...options} message={handleBlockedNavigation} />;
  //     },
  //     [handleBlockedNavigation]
  //   );

  const onLeaveWithoutCheck = useCallback(
    (cb?: (onSettled: () => void) => void) => {
      setIsInitial(true, () => cb?.(() => setIsInitial(false)));
    },
    [setIsInitial]
  );

  return { onRequestLeave, setIsInitial, onLeaveWithoutCheck };
};
