import SecureLocalStorage from 'react-secure-storage';

class AppStorage {
  set = (key: string, value: string | object | number | boolean) => {
    SecureLocalStorage.setItem(key, value);
  };

  get = (key: string) => {
    const value = SecureLocalStorage.getItem(key);
    return value;
  };

  delete = (key: string) => {
    SecureLocalStorage.removeItem(key);
  };

  clear = () => {
    SecureLocalStorage.clear();
  };
}

export default new AppStorage();
