import { GlobalEvent } from '@constants';
import { AppEvent } from '@core';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { LogicError } from './errorResponse';
import { handleToastOnSuccess } from './successResponse';

class RequestHelper {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_API;
    this.defaultConfig = {};
    this.defaultHeaders = {
      responseType: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-Zone-Id': moment.tz.guess(true)
      // 'X-companyId': 18
    };
  }

  prepareHeaders(headers) {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      this.defaultHeaders.Authorization = `Bearer ${accessToken}`;
    }
    this.defaultHeaders = {
      ...this.defaultHeaders,
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Request-Id': uuidv4(),
      ...headers
    };
  }

  prepareParams(payload) {
    if (!payload) return '';
    return `?${queryString.stringify(payload)}`;
  }

  handleResponse(response, endPoint, hasToast = true) {
    // endpoint return custom data structure
    if (endPoint.includes('/render') && response && response.data) {
      return response.data;
    }
    if (endPoint.includes('/export-excel') && response && response.data) {
      return response.data;
    }
    if (!response || !response.data.success) {
      throw new LogicError(
        response?.data.message || 'Something went wrong. Please contact us',
        endPoint
      );
    }
    if (hasToast) {
      handleToastOnSuccess(endPoint, response);
    }
    return response.data.payload;
  }

  handleError(error) {
    if (error?.data.code === 401) {
      AppEvent.dispatch(GlobalEvent.SignOut);
    }
    throw new LogicError(
      error?.data?.message || 'Something went wrong. Please contact us',
      error?.data?.code || 502,
      error
    );
  }

  getAsync(endPoint, payload, headers = {}) {
    this.prepareHeaders(headers);
    return axios
      .get(`${this.baseUrl}${endPoint}${this.prepareParams(payload)}`, {
        ...this.defaultConfig,
        headers: this.defaultHeaders
      })
      .then(response => this.handleResponse(response, endPoint, false))
      .catch(error => this.handleError(error.response));
  }

  postAsync(endPoint, payload, headers = {}, configs = {}) {
    this.prepareHeaders(headers);
    let hasToast = true;
    if (payload?.hasToast !== undefined) {
      hasToast = payload.hasToast;
      delete payload.hasToast;
    }
    if (payload instanceof FormData && payload.get('hasToast')) {
      hasToast = payload.get('hasToast') === 'true' ? true : false;
      payload.delete('hasToast');
    }
    return axios
      .post(`${this.baseUrl}${endPoint}`, payload, {
        ...this.defaultConfig,
        ...configs,
        headers: this.defaultHeaders
      })
      .then(response => this.handleResponse(response, endPoint, hasToast))
      .catch(error => this.handleError(error.response));
  }

  postAsyncWithoutBaseUrl(endPoint, payload, headers = {}, configs = {}) {
    this.prepareHeaders(headers);
    let hasToast = true;
    if (payload?.hasToast !== undefined) {
      hasToast = payload.hasToast;
      delete payload.hasToast;
    }
    if (payload instanceof FormData && payload.get('hasToast')) {
      hasToast = payload.get('hasToast') === 'true' ? true : false;
      payload.delete('hasToast');
    }
    return axios
      .post(`${endPoint}`, payload, {
        ...this.defaultConfig,
        ...configs,
        headers: this.defaultHeaders
      })
      .then(response => this.handleResponse(response, endPoint, hasToast))
      .catch(error => this.handleError(error.response));
  }

  putAsync(endPoint, payload, headers = {}) {
    this.prepareHeaders(headers);
    let hasToast = true;
    if (payload?.hasToast !== undefined) {
      hasToast = payload.hasToast;
      delete payload.hasToast;
    }

    return axios
      .put(`${this.baseUrl}${endPoint}`, payload, {
        ...this.defaultConfig,
        headers: this.defaultHeaders
      })
      .then(response => this.handleResponse(response, endPoint, hasToast))
      .catch(error => this.handleError(error.response));
  }

  deleteAsync(endPoint, payload, headers = {}) {
    this.prepareHeaders(headers);
    return axios
      .delete(`${this.baseUrl}${endPoint}${this.prepareParams(payload)}`, {
        ...this.defaultConfig,
        headers: this.defaultHeaders
      })
      .then(response => this.handleResponse(response, endPoint))
      .catch(error => this.handleError(error.response));
  }
}

const request = new RequestHelper();

export default request;
