import { DATE_FORMAT_SERVER } from '@constants';
import { useFetchScheduleCalendar } from '@hooks';
import { makeStyles } from '@material-ui/core/styles';
import { UIUtils } from '@utils';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React, { memo, useCallback, useMemo, useState } from 'react';
import Calendar, { OnArgs, TileArgs } from 'react-calendar';
import { KColors, KContainer, KLabel, KLoading } from 'uikit';

interface IProps {
  current: string;
  isEdit?: boolean;
  onChange: (v: string) => void;
  isFetchData?: boolean;
}

const styles = {
  wrapper: {
    borderRadius: 8,
    borderColor: KColors.primary.normal,
    zIndex: 1000
  },
  currentDate: {
    backgroundColor: KColors.primary.normal,
    fontWeight: 600,
    color: KColors.white,

    '&:hover': {
      color: KColors.black
    }
  }
};

const useStyles = makeStyles(styles);

const ScheduleCalendar = ({
  onChange,
  current,
  isEdit,
  isFetchData = true
}: IProps) => {
  const classes = useStyles();

  const [currentDate, setCurrentDate] = useState(current);

  const mParams = useMemo(() => {
    if (currentDate) {
      return {
        fromDate: moment(currentDate)
          .startOf('month')
          .format(DATE_FORMAT_SERVER),
        toDate: moment(currentDate).endOf('month').format(DATE_FORMAT_SERVER)
      };
    }
    return {};
  }, [currentDate]);

  const { data, isLoading } = useFetchScheduleCalendar({
    ...mParams,
    isFetchData
  });

  const tileClassName = useCallback(
    (p: TileArgs) => {
      const { date } = p;

      if (
        currentDate &&
        isEdit &&
        moment(date).diff(moment(currentDate), 'day') === 0
      ) {
        return clsx(classes.currentDate);
      }

      return null;
    },
    [classes.currentDate, currentDate, isEdit]
  );

  const tileDisabled = useCallback(
    (p: TileArgs) => {
      const { date, view } = p;

      if (view === 'year') {
        if (!isEdit && moment(date).diff(moment(), 'month') < 0) {
          return true;
        }
      }

      if (view === 'month') {
        if (isEdit) {
          if (
            !data?.some(
              i => moment(date).diff(moment(i.scheduleDate), 'day') === 0
            )
          ) {
            return true;
          }
        } else {
          if (
            moment(date).diff(moment(), 'day') < 0 ||
            data?.some(
              i => moment(date).diff(moment(i.scheduleDate), 'day') === 0
            )
          ) {
            return true;
          }
        }
      }

      return false;
    },
    [data, isEdit]
  );

  const tileContent = useCallback(
    (p: TileArgs) => {
      const { date, view } = p;

      if (view === 'month') {
        if (
          data?.some(
            i => moment(date).diff(moment(i.scheduleDate), 'day') === 0
          )
        ) {
          return <KLabel.Text color={KColors.secondary.normal}> *</KLabel.Text>;
        }
      }

      return null;
    },
    [data]
  );

  const onActiveStartDateChange = useCallback((v: OnArgs) => {
    const { view, activeStartDate, action } = v;
    if (
      view === 'month' &&
      ['prev', 'prev2', 'next', 'next2', 'drillDown'].includes(action)
    ) {
      setCurrentDate(moment(activeStartDate).format(DATE_FORMAT_SERVER));
    }
  }, []);

  const onChangeWrapper = useCallback(
    (v: Date) => {
      UIUtils.popper.dismiss();
      if (isEdit) {
        onChange?.(
          data?.find(
            i => i.scheduleDate === moment(v).format(DATE_FORMAT_SERVER)
          )
        );
      } else {
        onChange?.(moment(v).format(DATE_FORMAT_SERVER));
      }
    },
    [data, isEdit, onChange]
  );

  return (
    <KContainer.View position="relative">
      {isLoading && <KLoading />}

      <Calendar
        defaultValue={currentDate ? new Date(currentDate) : undefined}
        defaultView="month"
        minDetail="year"
        className={clsx(classes.wrapper)}
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
        tileContent={tileContent}
        onActiveStartDateChange={onActiveStartDateChange}
        onChange={onChangeWrapper}
      />
    </KContainer.View>
  );
};

export default memo(ScheduleCalendar);
