const END_POINTS = {
  ADVANCE_SEARCH: {
    GET_FILTER_COLUMNS: '/advance-search'
  },

  TEMPLATE: {
    GET_TEMPLATES: '/template',

    RENDER_TEMPLATE: '/template/render'
  }
};

export { END_POINTS };
