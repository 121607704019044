import sidebarStyle from '@assets/jss/material-dashboard-react/components/sidebarStyle.js';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { IAdminRoute } from '@ui';
import cx from 'classnames';
import { snakeCase } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import trans from 'translation';
import { KContainer, KImage } from 'uikit';
import { useMount } from 'uikit-common';

import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js';

const useStyles = makeStyles(sidebarStyle as any);

let ps: any;

function SidebarWrapper({
  className,
  headerLinks,
  links,
  bottomClassName
}: any) {
  const sidebarWrapper = React.useRef<any>(null);

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div className={className} ref={sidebarWrapper}>
      {headerLinks}
      {links}
      <div className={bottomClassName}>
        <span>
          &copy; {new Date().getFullYear()} Apollogix{' '}
          {process.env.REACT_APP_VERSION}
        </span>
      </div>
    </div>
  );
}

const imagesToBePreload = ['/images/logo.webp', '/images/logo_mini.webp'];

function Sidebar(props: any) {
  const classes = useStyles();
  const [miniActive] = React.useState(true);
  // to check for active links and opened collapses
  const location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const [logo, setLogo] = React.useState('/images/logo.webp');

  const isMinimize = useMemo(
    () => props.miniActive && miniActive,
    [miniActive, props.miniActive]
  );

  useMount(() => {
    imagesToBePreload.forEach(img => {
      new Image().src = img;
    });

    setState(getCollapseStates(props.routes));
  });

  React.useEffect(() => {
    if (isMinimize) {
      setLogo('/images/logo_mini.webp');
    } else {
      setLogo('/images/logo.webp');
    }
  }, [isMinimize]);

  const mainPanel = React.useRef<any>(null);

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes: IAdminRoute[]) => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse && prop.state && prop.views) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes: IAdminRoute[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        (routes[i].collapse &&
          routes[i].views &&
          getCollapseInitialState(routes[i].views as any)) ||
        location.pathname === (routes[i].layout || '/admin') + routes[i].path
      ) {
        return true;
      }
    }
    return false;
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname === routeName ? 'active' : '';
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: IAdminRoute[]) => {
    return routes.map((prop, key) => {
      if (prop.other) {
        return null;
      }

      // Menu Group
      if (prop.collapse && prop.state && prop.views) {
        var st = {};
        st[prop['state']] = !state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: getCollapseInitialState(prop.views)
          });
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]: isMinimize
          });
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]: isMinimize
          });
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseList =
          classes.collapseList +
          ' ' +
          cx({
            [classes.collapseListMini]: props.miniActive
          });

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.level !== undefined },
              { [classes.collapseItem]: prop.level === undefined }
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon !== undefined && (
                <KImage.MuiIcon className={itemIcon} icon={prop.icon} />
              )}

              <ListItemText
                primary={trans(`routes.${snakeCase(prop.key)}`)}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      (state[prop.state] ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.level !== undefined },
                  { [collapseItemText]: prop.level === undefined }
                )}
              />
            </NavLink>

            <Collapse in={state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + collapseList}>
                {createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      // Menu Item
      const to = (prop.layout as string) + (prop.path as string);
      const innerNavLinkClasses =
        classes.collapseItemLink +
        ' ' +
        cx({
          [classes.green]: activeRoute(to)
        });
      const navLinkClasses =
        classes.itemLink +
        ' ' +
        cx({
          [classes.green]: activeRoute(to)
        });
      const itemText =
        classes.itemText +
        ' ' +
        cx({
          [classes.itemTextMini]: props.miniActive && miniActive
        });
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]: props.miniActive && miniActive
        });
      const itemIcon = classes.itemIcon;

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.level !== undefined },
            { [classes.collapseItem]: prop.level === undefined }
          )}
        >
          <NavLink
            to={to}
            className={cx(
              { [navLinkClasses]: prop.level !== undefined },
              { [innerNavLinkClasses]: prop.level === undefined }
            )}
          >
            {prop.icon !== undefined && (
              <KImage.MuiIcon className={itemIcon} icon={prop.icon} />
            )}

            <ListItemText
              primary={trans(`routes.${snakeCase(prop.key)}`)}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.level !== undefined },
                { [collapseItemText]: prop.level === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  const { routes } = props;

  let links = <List className={classes.list}>{createLinks(routes)}</List>;

  const logoNormal = classes.logoNormal;
  const logoClasses = classes.logo;
  const imgClasses = classes.img + ' ' + cx({ [classes.imgMini]: isMinimize });

  const brand = useMemo(() => {
    return (
      <div className={logoClasses}>
        <a href="/admin" className={logoNormal}>
          <img
            src={logo}
            alt="logo"
            className={imgClasses}
            width={isMinimize ? '35' : '90'}
            height={isMinimize ? '35' : '90'}
          />
        </a>
      </div>
    );
  }, [imgClasses, isMinimize, logo, logoClasses, logoNormal]);

  const drawerPaper =
    classes.drawerPaper +
    ' ' +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
      [classes.blackBackground]: true
    });
  const sidebarWrapper =
    classes.sidebarWrapper +
    ' ' +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1
    });
  const copyright =
    classes.copyright +
    ' ' +
    cx({
      [classes.copyrightMini]: props.miniActive && miniActive
    });

  return (
    <div ref={mainPanel}>
      <KContainer.Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={props.open}
          classes={{
            paper: drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            headerLinks={<AdminNavbarLinks />}
            links={links}
            // @ts-ignore
            bottomClassName={copyright}
          />
        </Drawer>
      </KContainer.Hidden>

      <KContainer.Hidden smDown implementation="css">
        <Drawer
          // onMouseOver={() => setMiniActive(false)}
          // onMouseOut={() => setMiniActive(true)}
          anchor={'left'}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            links={links}
            // @ts-ignore
            bottomClassName={copyright}
          />
        </Drawer>
      </KContainer.Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  logo: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  headerLinks: PropTypes.object,
  links: PropTypes.object
};

export default Sidebar;
