import { Spacing } from '@constants';
import {
  Popper as MuiPopper,
  PopperProps,
  styled,
  Typography
} from '@material-ui/core';
import React, { memo, useCallback } from 'react';

import { Card, CardBody, GridContainer } from 'components';

interface IProps extends PopperProps {
  items: {
    label: string;
    onClick: () => void;
  }[];
  content: JSX.Element;
}

const itemHeight = Spacing.s26;

const MPopper = styled(MuiPopper)({
  zIndex: 1100
});

const Popper = ({
  items,
  anchorEl,
  placement = 'right-start',
  content
}: IProps) => {
  const renderContent = useCallback(() => {
    if (content) {
      return content;
    }

    return (
      <GridContainer
        style={{
          height: items.length * itemHeight + Spacing.s12,
          padding: Spacing.s12
        }}
        justifyContent="space-between"
        direction="column"
      >
        {items.map(i => {
          return (
            <Typography
              key={i.label}
              variant="body2"
              style={{
                cursor: i.disabled ? 'auto' : 'pointer',
                color: i.disabled ? '#c8c8c8' : 'initial'
              }}
              onClick={
                i.disabled
                  ? undefined
                  : e => {
                      e.stopPropagation();
                      i.onClick();
                    }
              }
            >
              {i.label}
            </Typography>
          );
        })}
      </GridContainer>
    );
  }, [content, items]);

  return (
    <MPopper anchorEl={anchorEl} open={Boolean(anchorEl)} placement={placement}>
      <Card style={{ marginTop: 0 }}>
        <CardBody style={{ padding: 0 }}>{renderContent()}</CardBody>
      </Card>
    </MPopper>
  );
};

export default memo(Popper);
