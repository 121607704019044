import { GrowthBook } from '@growthbook/growthbook-react';
import { IUseAgTableResult } from '@hooks-dto';
import { t } from '@lingui/macro';
import { WithAlertDialogProps, WithPopperProps, WithPopupProps } from '@ui';
import { createRef } from 'react';

import Colors from './colors';
import Spacing from './spacing';

export * from './search';
export * from './table';
export * from './storage';
export * from './query';
export * from './option';
export * from './enum';

const constants = {
  DEV: process.env.NODE_ENV,
  APPEARANCE: {
    DARK: 'dark',
    LIGHT: 'light'
  },
  THEME: {
    DARK: 'dark',
    LIGHT: 'light'
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
  },
  GENDER: {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    OTHER: 'OTHER'
  },
  GENDER_STRING: {
    FEMALE: t`FEMALE`,
    MALE: t`MALE`,
    OTHER: t`OTHER`
  },
  DATE_FORMAT: 'DD/MM/YYYY HH:mm',
  TIME_FORMAT: 'HH:mm',

  DEFAULT_PAGE_SIZE: 15,
  DEFAULT_PAGE_SIZE_SELECT: 20,
  DEFAULT_PAGE_SIZE_MAX: 2147483647,

  RELATED_SITE_TYPE: {
    DELIVERY_WAREHOUSE: 'DELIVERY_WAREHOUSE',
    PICKUP_WAREHOUSE: 'PICKUP_WAREHOUSE'
  },
  CLIENT_TYPE_SYSTEM: {
    FORWARDER_AGENT: 'FORWARDER_AGENT',
    CUSTOMS_AGENT: 'CUSTOMS_AGENT',
    CONSIGNEE: 'CONSIGNEE',
    WAREHOUSE: 'WAREHOUSE',
    CONSIGNOR: 'CONSIGNOR',
    ACCOUNTS_RECEIVABLE: 'ACCOUNTS_RECEIVABLE',
    EMPTY_YARD: 'EMPTY_YARD',
    WAIT_DEPOT: 'WAIT_DEPOT',
    CTO: 'CTO',
    CFS: 'CFS',
    SHIPING_LINE: 'SHIPING_LINE',
    RAIL_HEAD: 'RAIL_HEAD',
    RAIL_LINE: 'RAIL_LINE',
    WORKSHOP: 'WORKSHOP'
  },
  SOURCE_TYPE: {
    ACCOUNT_TAX: 'ACCOUNT_TAX',
    ACTIVATION_CODE: 'ACTIVATION_CODE',
    AUTO_RATE: 'AUTO_RATE',
    AUTO_RATE_CHARGE: 'AUTO_RATE_CHARGE',
    AUTO_RATE_CHARGE_COMPONENT: 'AUTO_RATE_CHARGE_COMPONENT',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    CATEGORY: 'CATEGORY',
    CHARGE_CODE: 'CHARGE_CODE',
    CHARGE_CODE_CATEGORY: 'CHARGE_CODE_CATEGORY',
    CHARGE_CODE_COMPONENT: 'CHARGE_CODE_COMPONENT',
    CHARGE_CODE_CONTAINER_SIZE: 'CHARGE_CODE_CONTAINER_SIZE',
    CHARGE_CODE_TEMPLATE: 'CHARGE_CODE_TEMPLATE',
    CHARGE_CODE_TEMPLATE_COMPONENT: 'CHARGE_CODE_TEMPLATE_COMPONENT',
    CHARGE_CODE_UNIT: 'CHARGE_CODE_UNIT',
    CITY: 'CITY',
    CLIENT: 'CLIENT',
    CLIENT_HAS_TAX: 'CLIENT_HAS_TAX',
    CLIENT_HAS_TYPE: 'CLIENT_HAS_TYPE',
    CLIENT_SITE: 'CLIENT_SITE',
    CLIENT_CONTACT: 'CLIENT_CONTACT',
    AR: 'AR',
    AP: 'AP',
    FORWARDER_AGENT: 'FORWARDER_AGENT',
    CUSTOMS_AGENT: 'CUSTOMS_AGENT',
    ACCOUNTS_RECEIVABLE: 'ACCOUNTS_RECEIVABLE',
    CONSIGNOR_CONSIGNEE: 'CONSIGNOR_CONSIGNEE',
    PICKUP_WAREHOUSE: 'PICKUP_WAREHOUSE',
    DELIVERY_WAREHOUSE: 'DELIVERY_WAREHOUSE',
    CLIENT_RATE: 'CLIENT_RATE',
    CLIENT_SALE_PERSON: 'CLIENT_SALE_PERSON',
    CLIENT_STAGE: 'CLIENT_STAGE',
    CLIENT_TARIFF: 'CLIENT_TARIFF',
    CLIENT_TARIFF_CHARGE: 'CLIENT_TARIFF_CHARGE',
    CLIENT_TARIFF_LEVEL_COMPONENT: 'CLIENT_TARIFF_LEVEL_COMPONENT',
    CLIENT_TYPE: 'CLIENT_TYPE',
    COMPANY_TARIFF: 'COMPANY_TARIFF',
    COMPANY_TARIFF_LEVEL: 'COMPANY_TARIFF_LEVEL',
    COMPANY_TARIFF_LEVEL_COMPONENT: 'COMPANY_TARIFF_LEVEL_COMPONENT',
    CONTAINER_LENGTH: 'CONTAINER_LENGTH',
    CONTAINER_SIZE: 'CONTAINER_SIZE',
    CONTAINER_TYPE: 'CONTAINER_TYPE',
    COUNTRY: 'COUNTRY',
    CURRENCY: 'CURRENCY',
    DATA_AUDIT_LOG: 'DATA_AUDIT_LOG',
    DATA_GROUP: 'DATA_GROUP',
    DATA_GROUP_USER: 'DATA_GROUP_USER',
    DIVISION: 'DIVISION',
    DOOR_TYPE: 'DOOR_TYPE',
    DROP_MODE: 'DROP_MODE',
    EDOC: 'EDOC',
    EMAIL_LOG: 'EMAIL_LOG',
    EMAIL_LOG_CHILD_SOURCE: 'EMAIL_LOG_CHILD_SOURCE',
    EMAIL_LOG_EDOC: 'EMAIL_LOG_EDOC',
    EMAIL_LOG_PERSON: 'EMAIL_LOG_PERSON',
    EMAIL_LOG_USER: 'EMAIL_LOG_USER',
    FLYWAY_SCHEMA_HISTORY: 'FLYWAY_SCHEMA_HISTORY',
    INVOICE: 'INVOICE',
    INVOICE_CHARGE_COMPONENT: 'INVOICE_CHARGE_COMPONENT',
    INVOICE_CONTAINER_CHARGE: 'INVOICE_CONTAINER_CHARGE',
    JOB_CONTAINER: 'JOB_CONTAINER',
    JOB_CONTAINER_ROUTING: 'JOB_CONTAINER_ROUTING',
    LANGUAGE: 'LANGUAGE',
    NATIONALITY: 'NATIONALITY',
    NOTE: 'NOTE',
    NOTIFICATION_CHANNEL: 'NOTIFICATION_CHANNEL',
    NOTIFICATION_LOG: 'NOTIFICATION_LOG',
    NOTIFICATION_SCHEDULE: 'NOTIFICATION_SCHEDULE',
    NOTIFICATION_USER: 'NOTIFICATION_USER',
    OVERWEIGHT: 'OVERWEIGHT',
    PAYMENT: 'PAYMENT',
    PAYMENT_ACCOUNT_SETTING: 'PAYMENT_ACCOUNT_SETTING',
    PAYMENT_DETAIL: 'PAYMENT_DETAIL',
    PDF_TEMPLATE: 'PDF_TEMPLATE',
    PERSON: 'PERSON',
    PROGRESS_STATUS: 'PROGRESS_STATUS',
    P_ROW_COUNT: 'P_ROW_COUNT',
    QUOTATION: 'QUOTATION',
    QUOTATION_REQUEST: 'QUOTATION_REQUEST',
    QUOTATION_REQUEST_CHARGE: 'QUOTATION_REQUEST_CHARGE',
    QUOTATION_REQUEST_CHARGE_COMPONENT: 'QUOTATION_REQUEST_CHARGE_COMPONENT',
    RELATED_PARTY_CLIENT: 'RELATED_PARTY_CLIENT',
    RELATED_PARTY_SITE: 'RELATED_PARTY_SITE',
    RESOURCE: 'RESOURCE',
    SHIPPING_LINES_DEHIRE: 'SHIPPING_LINES_DEHIRE',
    SITE: 'SITE',
    SITE_CAPABILITY: 'SITE_CAPABILITY',
    SITE_HAS_CAPABILITY: 'SITE_HAS_CAPABILITY',
    SITE_HAS_CONTACT: 'SITE_HAS_CONTACT',
    STATE: 'STATE',
    SUBURB: 'SUBURB',
    SYSTEM_SETTING: 'SYSTEM_SETTING',
    TAX: 'TAX',
    TEMPLATE: 'TEMPLATE',
    TRANSPORT_JOB: 'TRANSPORT_JOB',
    UNLOCO: 'UNLOCO',
    UPLIFT: 'UPLIFT',
    UPLIFT_HAS_CHARGE: 'UPLIFT_HAS_CHARGE',
    UPLIFT_HAS_ZONE: 'UPLIFT_HAS_ZONE',
    USERS: 'USERS',
    VESSEL: 'VESSEL',
    ZONE: 'ZONE',
    ZONE_SUBURB: 'ZONE_SUBURB',
    FAULTY_REPORT: 'FAULTY_REPORT',
    MAINTENANCE: 'MAINTENANCE',
    SCHEDULE_DRIVER: 'SCHEDULE_DRIVER',
    SCHEDULE_TRIP: 'SCHEDULE_TRIP',
    SCHEDULE_TRIP_CONTAINER: 'SCHEDULE_TRIP_CONTAINER',
    SAFETY_CHECK: 'SAFETY_CHECK',
    VEHICLE_FAULTY: 'VEHICLE_FAULTY',
    ALLOCATION_SCHEDULE_TRIP_CONTAINER: 'ALLOCATION_SCHEDULE_TRIP_CONTAINER'
  },

  JOB_TYPE: {
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
    MISC: 'MISC',
    RAIL_INBOUND: 'RAIL_INBOUND',
    RAIL_OUTBOUND: 'RAIL_OUTBOUND'
  },
  SHIPMENT_TYPE: {
    FCL: 'FCL',
    LCL: 'LCL'
    // TL: 'TL'
  },
  SHIPMENT_TYPE_STR: {
    FCL: t`Full Container Load`,
    LCL: t`Less than Container Load`
    // TL: t`TL`
  },

  TEMPLATE_BUSINESS_TYPE: {
    ALERT: 'ALERT',
    EMAIL: 'EMAIL',
    DOWNLOAD: 'DOWNLOAD',
    DOCUMENT: 'DOCUMENT'
  },

  CONVERSATION_MESSAGE_TYPE: {
    TEXT: 'TEXT',
    VOICE: 'VOICE',
    IMAGE: 'IMAGE',
    FILE: 'FILE'
  },

  SOCKET_EVENT: {
    NEW_MESSAGE: 'new_message',
    DELETE_MESSAGE: 'delete_message',
    TRIP_LOCATION: 'trip_location',
    LEAVE_OF_TRIP: 'leave_of_trip'
  }
};

export { Colors, Spacing };

export default constants;

export const _DEV_ = process.env.NODE_ENV === 'development';

export const IS_LOCAL_XERO = false;

const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER + 100;

export const Z_INDEX_PRIORITY = {
  alert: MAX_SAFE_INTEGER,
  modal: MAX_SAFE_INTEGER - 1,
  overlay: MAX_SAFE_INTEGER - 1,
  toast: MAX_SAFE_INTEGER - 2,
  actionSheet: MAX_SAFE_INTEGER - 3,
  imageViewer: MAX_SAFE_INTEGER - 4,
  bottomSheet: MAX_SAFE_INTEGER - 5,
  popup: MAX_SAFE_INTEGER - 7,
  popper: MAX_SAFE_INTEGER - 6
};

export const alertRef = createRef<WithAlertDialogProps>();
export const popupRef = createRef<WithPopupProps>();
export const popperRef = createRef<WithPopperProps>();

export const tableRef: { [key: string]: any | undefined } = {};
export const agTableRef: { [key: string]: IUseAgTableResult<any> | undefined } =
  {};

export const DEFAULT_TAX_ID = -1;

export const PAGE_DEFAULT = 0;
export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_DEFAULT_TABLE = 15;
export const PAGE_SIZE_DEFAULT_MAX = 2147483647; // JAVA Max integer

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_DISPLAY = 'DD-MM-YYYY HH:mm';
export const DATE_FORMAT_TIMEZONE = 'YYYY-MM-DDTHH:mm';
export const DATE_FORMAT_SHORT_TIMEZONE = 'YYYY-MM-DDT';
export const DATE_FORMAT_SHORT = 'DD/MM/YYYY';
export const DATE_FORMAT_SERVER = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

export const SCREEN_HEIGHT_FIXED = 376;
export const TABLE_BODY_MAX_HEIGHT = `calc(100vh - ${SCREEN_HEIGHT_FIXED}px)`;
export const TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS = `calc(100vh - ${SCREEN_HEIGHT_FIXED}px + 70px)`;
export const TABLE_BODY_MAX_HEIGHT_WITH_TABS = `calc(100vh - ${SCREEN_HEIGHT_FIXED}px + 26px)`;

export const SYSTEM_FIELDS = [
  'createdAt',
  'createdBy',
  'createdUsername',
  'updatedAt',
  'updatedBy',
  'updatedUsername',
  'status'
];

export const INVOICE_MAPPED_FIELDS = {
  progressStatus: 'invoiceProgress',
  'syncByPerson.displayName': 'syncByPerson',
  'salePerson.displayName': 'salePerson',
  'transportJob.jobNumber': 'jobNumber',
  'transportJob.eta': 'eta',
  'transportJob.etd': 'etd',
  'transportJob.consignClient.name': 'consignClient',
  'transportJob.relatedPartyArClient.relatedClient.name': 'arClient',
  'transportJob.jobProgress': 'jobProgress',
  'transportJob.jobType': 'jobType',
  'transportJob.vessel.vessel': 'vessel',
  'transportJob.vessel.lloyd': 'lloyd',
  'transportJob.voyage': 'voyage',
  'client.name': 'client'
};

export const growthBook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_ENV !== 'production',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});
