import {
  ENDPOINTS,
  InvoiceStatusId,
  PAGE_SIZE_DEFAULT_MAX,
  ParentSource,
  QUERY_KEYS,
  Status
} from '@constants';
import { Prototype } from '@core';
import APIManager from '@services';

import { useQueryEnhancer } from '../core';

export const useFetchDashboardIssuesEquipment = (
  date: string | null,
  enabled?: boolean
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardIssuesEquipment, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment('dashboard'),
        body: {
          date
        }
      });

      return (res.data ?? []).filter((i: any) => i.status === Status.Active);
    },
    enabled
  });
};

export const useFetchDashboardIssuesDriver = (
  date: string | null,
  enabled?: boolean
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardIssuesDriver, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('dashboard'),
        body: {
          date
        }
      });

      return res.data;
    },
    enabled
  });
};

export const useFetchDashboardIssuesClientRate = (
  date: string | null,
  enabled?: boolean
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardIssuesClientRate, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientRate('dashboard'),
        body: {
          date
        }
      });

      return res.data;
    },
    enabled
  });
};

export const useFetchDashboardTransportJobs = () => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.dashboardTransportJobs],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob('dashboard'),
        body: {
          date: Prototype.date.formatDB(Prototype.date.now())
        }
      });

      return res.data;
    }
  });
};

export const useFetchDashboardContainers = () => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.dashboardContainers],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.transportJob('job-container/dashboard'),
        body: {
          date: Prototype.date.formatDB(Prototype.date.now())
        }
      });

      return res.data;
    }
  });
};

export const useFetchDashboardPayments = () => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.dashboardPayments],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.payment('dashboard'),
        body: {
          date: Prototype.date.formatDB(Prototype.date.now())
        }
      });

      return res.data;
    }
  });
};

export const useFetchDashboardInvoicesOverdue = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardInvoicesOverdue],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoice(),
        body: {
          excludeFields: ['invoiceContainerCharges'],
          invoiceProgressId: InvoiceStatusId.Invoiced,
          size: PAGE_SIZE_DEFAULT_MAX,
          filterDueDate: Prototype.date.formatDB(Prototype.date.now())
        }
      });

      return res.data?.data ?? [];
    },
    initialData: []
  });
};

export const useFetchDashboardAROverdue = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardAROverdue],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statement('ar-client-summary'),
        body: {
          extraFields: ['creditLimit'],
          size: PAGE_SIZE_DEFAULT_MAX,
          searchCondition: JSON.stringify([
            {
              fieldName: 'overdue',
              operandType: 'DECIMAL',
              operatorType: 'GREATER_EQUAL',
              data: '0.01'
            }
          ])
        }
      });

      return res.data?.data ?? [];
    },
    initialData: []
  });
};

export const useFetchDashboardAllocationReports = (date: string | null) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardAllocationReports, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.report(),
        body: {
          reportDate: date,
          size: PAGE_SIZE_DEFAULT_MAX,
          source: ParentSource.Allocation
        }
      });

      return res.data?.data ?? [];
    },
    initialData: []
  });
};

export const useFetchDashboardAllocationMessages = (date: string | null) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardAllocationMessages, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.conversation('conversation-message/find-unread'),
        body: {
          date
        }
      });

      return res.data ?? [];
    },
    initialData: []
  });
};

export const useFetchDashboardSTCWaitingTime = (date: string | null) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.dashboardScheduleTripContainer, date],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripContainer('dashboard'),
        body: {
          date
        }
      });

      return res.data ?? [];
    },
    initialData: []
  });
};
