import { WebTable } from '@constants';
import loadable from '@loadable/component';
import React, { memo } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer } from 'uikit';

const Emails = loadable(() => import('@containers/Email'));

const EmailLogs = () => {
  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.system_settings'),
          trans('routes.email_logs')
        ]}
      />

      <KContainer.View padding="0.75rem">
        <Emails
          noHeader
          source={'all' as any}
          webTable={WebTable.EMAIL_LOG}
          isView
        />
      </KContainer.View>
    </>
  );
};

export default memo(EmailLogs);
