import { ENDPOINTS, MarkupType } from '@constants';
import { Prototype } from '@core';
import { useResolverForm } from '@hooks';
import { UIUtils, ValidationUtils } from '@utils';
import { isNumber, omit } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel
} from 'uikit';
import * as yup from 'yup';

interface IProps {
  item: any;
  chargeCodeIds: number[];
  onSuccess: (data: any) => void;
  isView?: boolean;
}

interface IFormData {
  companyTariff: any;
  markupType: MarkupType;
  actualCostingPrice: number;
  actualRateValue: number;
  actualSellingPriceValue: number;
  quantity: number;
  amountWithoutTax: number;
  totalAmount: number;
  tax?: any;
  invoiceChargeComponents: any[];
  note: string;
}

const schema = yup.object().shape({
  actualSellingPriceValue: ValidationUtils.requiredNum(),
  quantity: ValidationUtils.requiredNum()
});

const EditChargeCodePrice = ({
  item,
  chargeCodeIds,
  onSuccess,
  isView
}: IProps) => {
  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        companyTariff: item?.companyTariff,
        markupType: item?.levelValueType,
        actualCostingPrice: item?.actualCostingPrice ?? '',
        actualRateValue: item?.actualRateValue ?? '',
        actualSellingPriceValue: item?.actualSellingPriceValue ?? '',
        quantity: item?.quantity ?? 1,
        amountWithoutTax: item?.amountWithoutTax ?? '',
        totalAmount: item?.totalAmount ?? '',
        tax: item?.tax,
        invoiceChargeComponents: item?.invoiceChargeComponents ?? [],
        note: item?.note ?? ''
      }
    }
  });

  const [tariff, components, acpValue, arValue, quantity, tax, markupType] =
    useWatch({
      control: methods.control,
      name: [
        'companyTariff',
        'invoiceChargeComponents',
        'actualSellingPriceValue',
        'actualRateValue',
        'quantity',
        'tax',
        'markupType'
      ]
    });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'invoiceChargeComponents',
    keyName: 'formId'
  });

  const amountWithoutTax = useMemo(() => {
    if (acpValue) {
      return Prototype.number.round(acpValue * quantity, 2);
    }
    return 0;
  }, [acpValue, quantity]);

  const taxableAmount = useMemo(() => {
    if (amountWithoutTax && tax) {
      return Prototype.number.round((amountWithoutTax * tax.value) / 100, 2);
    }
    return 0;
  }, [amountWithoutTax, tax]);

  const totalAmount = useMemo(() => {
    if (amountWithoutTax) {
      if (tax) {
        return Prototype.number.round(amountWithoutTax + taxableAmount, 2);
      }
      return Prototype.number.round(amountWithoutTax, 2);
    }
    return 0;
  }, [amountWithoutTax, tax, taxableAmount]);

  const onCalculatePrice = useCallback(() => {
    try {
      let result = 0;
      if (components.length > 0) {
        components.forEach(i => {
          if (chargeCodeIds.includes(i.chargeCodeComponent.subChargeCodeId)) {
            result += (i.actualWeight / 100) * i.chargeCodeComponentValue;
          }
        });
      }
      if (arValue) {
        result += +arValue;
      }
      result = +(Math.round((result + 'e+2') as any) + 'e-2');
      methods.setValue('actualSellingPriceValue', result);
    } catch (error) {
      console.log(error);
      methods.trigger();
    }
  }, [arValue, chargeCodeIds, components, methods]);

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...omit(item, ['markupType']),
        ...data,
        taxId: data.tax?.id,
        taxableValue: data.tax?.value,
        amountWithoutTax,
        taxableAmount,
        totalAmount,
        invoiceChargeComponents: data.invoiceChargeComponents.map(i => {
          if (chargeCodeIds.includes(i.chargeCodeComponent.subChargeCodeId)) {
            return i;
          }

          return {
            ...i,
            actualWeight: 0,
            chargeCodeComponentValue: ''
          };
        })
      };

      onSuccess(mParams);
      UIUtils.popup.dismiss();
    },
    [
      amountWithoutTax,
      chargeCodeIds,
      item,
      onSuccess,
      taxableAmount,
      totalAmount
    ]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <KInput.TextField
            name="code"
            label={trans('code')}
            value={tariff?.code ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KInput.TextField
            name="name"
            label={trans('name')}
            value={tariff?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="originalCode"
            label={trans('original_code')}
            value={tariff?.chargeCode?.code ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="perUnit"
            label={trans('per_unit')}
            value={tariff?.chargeCode?.chargeCodeUnit?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="calculatorType"
            label={trans('calculator_type')}
            value={
              tariff?.chargeCode?.calculateType
                ? trans(
                    `option.${tariff?.chargeCode?.calculateType.toLowerCase()}`
                  ).toUpperCase()
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KInput.TextField
            name="category"
            label={trans('category')}
            value={tariff?.chargeCode?.chargeCodeCategory?.name ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="zone"
            label={trans('zone')}
            value={
              tariff?.zone ? tariff.zone.name ?? trans('all').toUpperCase() : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={9}>
          <KInput.TextField
            name="remark"
            label={trans('remark')}
            value={tariff?.remark ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="note"
            control={methods.control}
            render={({ field }) => {
              return <KInput.TextField {...field} label={trans('note')} />;
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="actualCostingPrice"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Currency {...field} label={trans('costing_price')} />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="minSellingPrice"
            label={trans('min_selling_price')}
            value={
              isNumber(tariff?.minSellingPrice)
                ? Prototype.number.formatCurrency(tariff.minSellingPrice)
                : ''
            }
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="markupLevelType"
            label={trans('markup_level_type')}
            value={
              markupType
                ? trans(`option.${markupType.toLowerCase()}`).toUpperCase()
                : ''
            }
            disabled
          />
        </KGrid.Item>

        {markupType === MarkupType.Descriptive && (
          <KGrid.Item xs={3}>
            <KInput.TextField
              name="originalPriceValue"
              label={trans('selling_price_descriptive')}
              value={item?.originalPriceValue ?? ''}
              disabled
            />
          </KGrid.Item>
        )}

        {markupType === MarkupType.Currency && (
          <KGrid.Item xs={3}>
            <KInput.TextField
              name="levelValue"
              label={trans('markup_level')}
              value={Prototype.number.formatCurrency(item?.levelValue ?? '')}
              disabled
            />
          </KGrid.Item>
        )}

        {markupType === MarkupType.Percentage && (
          <KGrid.Item xs={3}>
            <KInput.TextField
              name="levelValue"
              label={trans('markup_level')}
              value={item?.levelValue ? `${item?.levelValue} %` : ''}
              disabled
            />
          </KGrid.Item>
        )}

        {markupType === MarkupType.Composite && (
          <KGrid.Item xs={12}>
            <KContainer.Fieldset
              title={trans('selected_selling_price')}
              marginT={0}
              paddingB="0.75rem"
              paddingT="0.5rem"
            >
              <KGrid.Container spacing={2}>
                <KContainer.View row alignItems flexW="wrap">
                  <KContainer.View
                    width={100}
                    marginL="0.5rem"
                    marginT="0.75rem"
                  >
                    <Controller
                      name="actualRateValue"
                      control={methods.control}
                      render={({ field }) => {
                        return (
                          <KInput.Currency
                            {...field}
                            label={trans('with_unit.base_value')}
                          />
                        );
                      }}
                    />
                  </KContainer.View>

                  {fields.map((d, idx) => {
                    if (
                      chargeCodeIds.includes(
                        d.chargeCodeComponent.subChargeCodeId
                      )
                    ) {
                      return (
                        <React.Fragment key={d.formId}>
                          <KContainer.View row alignItems marginT="0.75rem">
                            <KContainer.View
                              dp="flex"
                              size={30}
                              br="x"
                              brW={1}
                              brC={KColors.hexToRgba(KColors.black, 0.26)}
                              center
                              background="#EEEEEE"
                              marginH="0.5rem"
                            >
                              <KLabel.Text
                                color={KColors.hexToRgba(KColors.black, 0.4)}
                                typo="TextLgNormal"
                              >
                                +
                              </KLabel.Text>
                            </KContainer.View>

                            <KContainer.View width={60} marginR="0.5rem">
                              <Controller
                                name={`invoiceChargeComponents.${idx}.actualWeight`}
                                control={methods.control}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <KInput.TextField
                                      {...field}
                                      label="%"
                                      type="number"
                                      message={error?.message}
                                    />
                                  );
                                }}
                              />
                            </KContainer.View>

                            <KContainer.View width={150}>
                              <Controller
                                name={`invoiceChargeComponents.${idx}.chargeCodeComponentValue`}
                                control={methods.control}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <KInput.Currency
                                      {...field}
                                      label={
                                        d.chargeCodeComponent.subChargeCode.code
                                      }
                                      message={error?.message}
                                    />
                                  );
                                }}
                              />
                            </KContainer.View>
                          </KContainer.View>
                        </React.Fragment>
                      );
                    }

                    return null;
                  })}

                  <KButton.Solid
                    title={trans('calculate_price')}
                    onPress={onCalculatePrice}
                    marginT="0.75rem"
                    marginL="0.5rem"
                  />
                </KContainer.View>
              </KGrid.Container>
            </KContainer.Fieldset>
          </KGrid.Item>
        )}

        <KGrid.Item xs={3}>
          <Controller
            name="actualSellingPriceValue"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Currency
                  {...field}
                  label={trans(
                    markupType === MarkupType.Composite
                      ? 'calculated_selling_price'
                      : 'selling_price'
                  )}
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="quantity"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('quantity')}
                  type="number"
                  required
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="amountWithoutTax"
            label={trans('amount')}
            value={Prototype.number.formatCurrency(amountWithoutTax)}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}></KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="tax"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('tax')}
                  apiURL={ENDPOINTS.tax()}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="taxableValue"
            label={trans('taxable_value')}
            value={tax?.value ? `${tax.value} %` : ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="taxableAmount"
            label={trans('taxable_amount')}
            value={Prototype.number.formatCurrency(taxableAmount)}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="total"
            label={trans('total')}
            value={Prototype.number.formatCurrency(totalAmount)}
            disabled
          />
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={!isView}>
            <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
              <KButton.Solid
                title={trans('delete')}
                kind="secondary"
                onPress={() => onSuccess({ ...item, isDelete: true })}
                marginR="0.5rem"
              />

              <KButton.Solid type="submit" title={trans('update')} />
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(EditChargeCodePrice);
